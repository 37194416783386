import { useHistory, useParams } from "react-router";
import { useState, useEffect } from "react";
import { useApp } from "./useApp";
import { singular, plural, upperCaseFirstLetter } from "src/utils/string";
import axios from "src/utils/axios";

const createBreadcrumbs = async (
  { type, currentApp, urlParams, path },
  curBreadcrumbState
) => {
  let href = currentApp.path;
  let breadcrumbs = [{ title: currentApp.appName, href }];
  if (urlParams.assignee) {
    href = `${href}/${urlParams.assignee}`;
    breadcrumbs = [
      ...breadcrumbs,
      { title: upperCaseFirstLetter(urlParams.assignee), href },
    ];
    href = `${href}/${urlParams.assigneeId}`;
  }
  const assetTypeHierarchy = ["suite", "app", "module", "function", "feature"];
  for (let i = 0; i < assetTypeHierarchy.length; i++) {
    const currentAssetType = assetTypeHierarchy[i];
    const pluralCurrentType = plural(currentAssetType);
    const upperCasePluralCurrentType = upperCaseFirstLetter(pluralCurrentType);
    href = `${href}/${pluralCurrentType}`;
    breadcrumbs = [
      ...breadcrumbs,
      {
        title: upperCasePluralCurrentType,
        ...(currentAssetType !== type && { href }),
      },
    ];
    href = `${href}/${urlParams[`${currentAssetType}Id`]}`;
    if (currentAssetType === type) {
      break;
    }
  }
  const returnedBreadcrumbs = curBreadcrumbState
    ? await createBreadCrumbMappings(path, breadcrumbs, curBreadcrumbState)
    : await createBreadCrumbMappings(path, breadcrumbs);
  return returnedBreadcrumbs;
};

const isNumberInString = (string) => {
  let nubmerFound = false;
  string.split("").forEach((str) => {
    if (!isNaN(str)) {
      nubmerFound = true;
    }
  });
  return nubmerFound;
};

// generic is the breadcrumbs generated for the page, named is the previous state
const createBreadCrumbMappings = async (
  path,
  genericBreadcrumbs,
  namedBreadcrumbs
) => {
  if (!genericBreadcrumbs) return;
  let syncCurrentState = null;
  if (namedBreadcrumbs) {
    //Check the old state for paths we already have
    syncCurrentState = syncBreadcrumbnames(
      genericBreadcrumbs,
      namedBreadcrumbs
    );
  }
  // Fetch everything with out an href property
  const targetBreadcrumbs = syncCurrentState || genericBreadcrumbs;
  const mappings = targetBreadcrumbs
    .reduce((acc, { title, href, resourceName }) => {
      if (resourceName) return [...acc];
      if (!href) return [...acc];
      const startPos = path.indexOf(href);
      // Get the substring between the next 2 slashes
      const startSlash = path.indexOf("/", startPos + href.length);
      const endSlash = path.indexOf("/", startSlash + 1);
      const nextPath = path.substring(startSlash + 1, endSlash);
      if (!isNumberInString(nextPath)) return [...acc];
      let resourcePath;
      let previousBreadcrumbInfo = namedBreadcrumbs || acc;
      switch (title) {
        case "Admin":
        case "Roles":
        case "Suites":
        case "Packages":
          resourcePath = `${title.toLowerCase()}/${nextPath}`;
          return [
            ...acc,
            {
              title,
              href,
              resourcePath,
              resourceName: "",
              resourceId: nextPath,
            },
          ];
        case "Apps":
          const { resourceId: suiteId } = previousBreadcrumbInfo.find(
            ({ title }) => title === "Suites"
          );
          resourcePath = `${title.toLowerCase()}?appId=${nextPath}&suiteId=${suiteId}&onlyLoadIfCompanyHasAccess=true`;
          return [
            ...acc,
            {
              title,
              href,
              resourcePath,
              resourceName: "",
              resourceId: nextPath,
            },
          ];
        case "Modules":
          const { resourceId: appId } = previousBreadcrumbInfo.find(
            ({ title }) => title === "Apps"
          );
          resourcePath = `${title.toLowerCase()}?moduleId=${nextPath}&appId=${appId}&onlyLoadIfCompanyHasAccess=true`;
          return [
            ...acc,
            {
              title,
              href,
              resourcePath,
              resourceName: "",
              resourceId: nextPath,
            },
          ];
        case "Functions":
          const { resourceId: moduleId } = previousBreadcrumbInfo.find(
            ({ title }) => title === "Modules"
          );
          resourcePath = `${title.toLowerCase()}?functionId=${nextPath}&moduleId=${moduleId}&onlyLoadIfCompanyHasAccess=true`;
          return [
            ...acc,
            {
              title,
              href,
              resourcePath,
              resourceName: "",
              resourceId: nextPath,
            },
          ];

        case "Features":
          const { resourceId: functionId } = previousBreadcrumbInfo.find(
            ({ title }) => title === "Functions"
          );
          resourcePath = `${title.toLowerCase()}?featureId=${nextPath}&functionId=${functionId}&onlyLoadIfCompanyHasAccess=true`;
          return [
            ...acc,
            {
              title,
              href,
              resourcePath,
              resourceName: "",
              resourceId: nextPath,
            },
          ];
        default:
          return [...acc];
      }
    }, [])
    .filter((resource) => resource !== undefined);
  //Fetch the resource names
  const resolvedNames = await Promise.allSettled(
    mappings.map(({ resourcePath }) => axios.get(resourcePath))
  );
  // Add the fetched names back into object
  // Looks like {value:{data:{app | suite | role}}}
  const fetchedMappings = mappings.map((element) => {
    const singularResource = singular(element.title).toLowerCase();
    let name;
    resolvedNames.forEach((response) => {
      if (response?.value?.data?.[singularResource]) {
        name = response.value.data[singularResource].name;
      }
    });
    if (name) return { ...element, resourceName: name };
    return { ...element };
  });
  //Finally merge the fetched mappings with the normal breadcrumbs
  const finalBreadcrumbs = syncBreadcrumbnames(
    targetBreadcrumbs,
    fetchedMappings
  );
  return finalBreadcrumbs;
};

const syncBreadcrumbnames = (genericBreadcrumbs, namedBreadcrumbs) => {
  if (!genericBreadcrumbs || !namedBreadcrumbs) return;
  const finalBreadcrumbs = [];
  genericBreadcrumbs.forEach((genericElement) => {
    let found = false;
    namedBreadcrumbs.forEach((namedElement) => {
      if (genericElement.title === namedElement.title && genericElement.href) {
        found = true;
        finalBreadcrumbs.push({
          ...genericElement,
          ...namedElement,
        });
      }
    });
    if (!found) finalBreadcrumbs.push({ ...genericElement });
  });
  return finalBreadcrumbs;
};

/* 
    To generate breadcrumbs on component pageWithBreadCrumbs/ PageHeader
    Looks at path to determine what resource id to fetch in order to get the 
    specific resource name.
*/
const useBreadcrumbs = (type) => {
  const [finalBreadcrumbs, setFinalBreadcrumbs] = useState(null);
  const history = useHistory();
  const currentApp = useApp();
  const urlParams = useParams();

  useEffect(() => {
    const start = async () => {
      const breadcrumbs = await createBreadcrumbs(
        {
          type,
          currentApp,
          urlParams,
          path: history.location.pathname,
        },
        finalBreadcrumbs
      );
      setFinalBreadcrumbs(breadcrumbs);
    };
    start();
  }, [history.location]);
  return { finalBreadcrumbs };
};

export default useBreadcrumbs;
