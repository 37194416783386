import  React, { lazy } from "react";
import PropTypes from "prop-types";

/**
 * Component that converts a included Font Awesome SVG
 * to an SVG Icon component that can be customized with props;
 * this particular component lazy loads the icon
 * https://v4.mui.com/api/svg-icon/
 */
const DynamicIcon = ({ icon, ...rest }) => {
  const Icon = lazy(() => import(`src/icons/fontawesome/${icon}`));
  if (!icon) return;
  return <Icon {...rest} titleAccess={icon} />;
};

DynamicIcon.propTypes = {
  /**
   * Name of the SVG icon to be lazily loaded and rendered
   * as a component
   */
  icon: PropTypes.oneOf([
    "Angry",
    "Bolt",
    "BoxAlt",
    "Building",
    "Camera",
    "Car",
    "CarSide",
    "Cog",
    "Cubes",
    "Download",
    "Edit",
    "Envelope",
    "Function",
    "Handshake",
    "Headset",
    "FileInvoiceDollar",
    "FileChartPie",
    "FileSpreadsheet",
    "Key",
    "Lasso",
    "Link",
    "LiveChat",
    "MapMarkedAlt",
    "Microphone",
    "MoneyCheckAlt",
    "PaintBrush",
    "Palette",
    "Paperclip",
    "Plus",
    "Router",
    "Ruler",
    "SlidersH",
    "StickyNote",
    "SwatchBook",
    "Sync",
    "TachometerAltFast",
    "Tag",
    "Times",
    "Tire",
    "TireFlat",
    "UnlockAlt",
    "UserTag",
    "Uber",
  ]).isRequired,
};

export default DynamicIcon;
