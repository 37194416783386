import React, { lazy } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Menu as MenuIcon } from "react-feather";
import Logo from "src/components/Logo";
import { THEMES } from "src/constants";
import Account from "./Account";
import Applications from "./Applications";
import { useSelector } from "react-redux";

const Contacts = lazy(() => import("./Contacts"));
const Search = lazy(() => import("./Search"));
const Notifications = lazy(() => import("./Notifications"));
const DataContext = lazy(() => import("./DataContext"));
const Settings = lazy(() => import("./Settings"));

const NSD_LOGO_URL =
  "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Logos/Qore_Q_white_icon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  textHeadings: {
    color: "#fff",
  },
}));

/**
 * TopBar menu for most applications; can render
 * settings, contacts,  search  (TBD), and notification (TBD),
 * dropdown menus. Always includes a dropdown for the logged in users
 * profile settings and all available applications
 *
 * FIX: Custom JSX portion needs some more stlying but should
 * suffice for now (plus you can style your own JSX)
 */
function TopBar({
  className,
  onMobileNavOpen,
  apps,
  dataContextOptions,
  onDataContextChange,
  contacts,
  hidden,
  search,
  notifications,
  settings,
}) {
  const classes = useStyles();
  const theme = useTheme();
  // Logo for client company is located in blob storage with the link being on a field
  // in the theme object
  const { alt, src: clientCompanyLogo } = theme?.logo?.topBar || {};

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { appName, appVersion, type } = useSelector(
    (state) => state.application
  );
  if (!type || hidden) return null;

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={() => onMobileNavOpen()}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
              {
              clientCompanyLogo  ?
              <Logo image={clientCompanyLogo} altText={alt} />:
              <Logo image={NSD_LOGO_URL} altText={"NSD Logo"} />
              }
          </RouterLink>
        </Hidden>
        <Box ml={2}>
          <Typography variant="h3" className={classes.textHeadings}>
            {appName}
          </Typography>
          {appVersion && (
            <Typography variant="caption" className={classes.textHeadings}>
              Version {appVersion}
            </Typography>
          )}
        </Box>
        <Box ml={2} flexGrow={1} />
        {(dataContextOptions?.options?.length > 0 ||
          (dataContextOptions?.customJSX &&
            dataContextOptions?.enableDataContextOverride)) &&
          !isMobile && (
            <DataContext
              onChange={onDataContextChange}
              options={dataContextOptions?.options}
              enableDataContextOverride={
                dataContextOptions?.enableDataContextOverride
              }
              customJSX={dataContextOptions?.customJSX}
            />
          )}
        {search && <Search />}
        <Applications type={type} apps={apps} />
        {contacts && <Contacts />}
        {notifications && <Notifications />}
        {settings && <Settings />}
        <Box ml={2}>
          <Account type={type} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  // /**
  //  * The name of the application; appears in TopBar
  //  */
  // appName: PropTypes.string,
  // /**
  //  *  The version of the current application running
  //  */
  // appVersion: PropTypes.string,
  // /**
  //  * Used to determine how to style the navbar
  //  */
  // type: PropTypes.oneOf(["client", "employee"]),
  /**
   * Class name to apply to the outer most container element in
   * TopBar
   */
  className: PropTypes.string,
  /**
     * Used to render a series of additional data sources/options
     * if you wish to render more misc data in the TopBar
     *
     * enableDataContextOverride: Flag indicating weather or not you wish to override the default
     * behavior of DataContext (creating an AutoComplete from dataContextOptions array )
     * to just pass in a function that returns JSX or JSX. If this flag is set
     * onDataContextChange and dataContextOptions props will be ignored and
     * customJSX prop will be used
     *
     *  customJSX - A callback or JSX element that you wish to have rendered with the DataContext
     section of TopBar only if you set enableDataContextOverride prop to true
     *
     */
  dataContextOptions: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        value: PropTypes.any,
        name: PropTypes.string,
        secondLine: PropTypes.string,
      })
    ),
    enableDataContextOverride: PropTypes.bool,
    customJSX: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  }),
  /**
   * Callback that runs when the dataContext menu has a new option
   * selected. It receives 1 argument the generated event object (from Autocomplete)
   */
  onDataContextChange: PropTypes.func,
  /**
   * Callback that is invoked when the mobile hamburger
   * menu is clicked. It receives 1 argument; the generated
   * event object
   */
  onMobileNavOpen: PropTypes.func,
  /**
   * Weather or not to enable the contacts functionality in
   * the TopBar
   */
  contacts: PropTypes.bool,
  /**
   * Weather or not to enable the search functionality in
   * the TopBar
   */
  search: PropTypes.bool,
  /**
   * Weather or not to hide the TopBar entirely. Used when
   * the app is a plugin for Twilio Flex as an example
   */
  hidden: PropTypes.bool,
  /**
   * Weather or not to enable the notifications functionality in
   * the TopBar
   */
  notifications: PropTypes.bool,
  /**
   * Weather or not to enable the settings functionality in
   * the TopBar
   */
  settings: PropTypes.bool,
  /**
   * Array of app objects that the logged in user has access to
   */
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      appId: PropTypes.string.isRequired,
      appName: PropTypes.string.isRequired,
      site: PropTypes.string,
      icon: PropTypes.string.isRequired,
      priority: PropTypes.number,
      path: PropTypes.string.isRequired,
      guard: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          exact: PropTypes.bool,
          path: PropTypes.string.isRequired,
          redirect: PropTypes.string,
          view: PropTypes.object,
        })
      ),
      navConfig: PropTypes.shape({
        navBarItems: PropTypes.arrayOf(
          PropTypes.shape({
            subheader: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string.isRequired,
                icon: PropTypes.object,
                href: PropTypes.string.isRequired,
              })
            ),
          })
        ),
      }),
    })
  ),
};

TopBar.defaultProps = {
  apps: null,
  settings: false,
  notifications: false,
  search: false,
  hidden: false,
  contacts: false,
  onMobileNavOpen: () => {},
  onDataContextChange: () => {},
  dataContextOptions: null,
  className: "",
};

export default TopBar;
