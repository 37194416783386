import { getEnvironment, APP_ENV } from "src/utils/env";

const getCustomerPortalApiBaseUrl = () => {
  const environment = getEnvironment();
  let apiBaseUrl;

  switch (environment) {
    case APP_ENV.LOCAL:
      apiBaseUrl = process.env.REACT_APP_CUSTOMER_PORTAL_API_LOCAL;
      break;
    case APP_ENV.DEVELOPMENT:
      apiBaseUrl = process.env.REACT_APP_CUSTOMER_PORTAL_API_DEVELOPMENT;
      break;
    case APP_ENV.STAGING:
      apiBaseUrl = process.env.REACT_APP_CUSTOMER_PORTAL_API_STAGING;
      break;
    default:
      // Dev by default
      apiBaseUrl = process.env.REACT_APP_CUSTOMER_PORTAL_API;
  }

  return apiBaseUrl;
};

export default {
  CUSTOMER_PORTAL: getCustomerPortalApiBaseUrl(),
};
