import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Delete as DeleteIcon, PlusSquare as AddIcon } from "react-feather";
import clsx from "clsx";
import PropTypes from "prop-types";
import clone from "lodash/clone";
import axios from "src/utils/axios";

const useStyles = makeStyles((theme) => ({
  root: {},
  formType: { width: 200 },
  formInput: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  formButton: { margin: theme.spacing(1) },
  formGrid: { maxWidth: "100%", maxHeight: "100vh" },
  gridSpacing: {
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
}));
// Vin is always 17
const SET_VIN_LENGTH = 17;

/**
 * Multi-form component for entering vehicle information. Can be passed a starting list
 * of vehicle objects. Clicking the plus to add a new form will
 * trigger the updateVehicles callback with the array of entered
 * vehicles as the only argument.
 *
 * Any form that is added (has x arrow icon next to it) & when
 * a field is changed the updateVehicles callback is also invoked
 *
 * NOTE: Response is mocked so vehicle details always auto-complete
 * to the same car
 *
 */
const VehicleInput = ({ vehicles, updateVehicles, ...rest }) => {
  const classes = useStyles();
  const [newVehicle, setNewVehicle] = useState({
    make: "",
    model: "",
    year: "",
    vin: "",
    licensePlate: "",
  });
  const [collectingDetails, setCollectingDetails] = useState(false);
  const [localVehicles, setLocalVehicles] = useState(vehicles || []);
  const [errors, setErrors] = useState(false);

  const collectVehicleDetails = async (vinIn) => {
    if (vinIn.length < SET_VIN_LENGTH) return;
    if (vinIn) {
      try {
        setCollectingDetails(true);
        const carDetails = await axios.get(`/vin-decode?vinCode=${vin}`);
        const { vin } = carDetails.data;
        const { year, make, model } = vin.attributes;
        setNewVehicle((oldFields) => ({
          year,
          make,
          model,
          vin: vinIn,
          licensePlate: oldFields.licensePlate,
        }));
        setCollectingDetails(false);
      } catch (error) {
        console.error("error", error);
        setCollectingDetails(false);
      }
    }
  };

  useEffect(() => {
    if (newVehicle.vin && !collectingDetails)
      collectVehicleDetails(newVehicle.vin);
  }, [newVehicle.vin]);

  const updateNewVehicle = async (event) => {
    setErrors(false);
    let newVehicleObj = null;
    if (event.target.name === "year") {
      const year =
        event.target.value === "" ? "" : parseInt(event.target.value);
      newVehicleObj = {
        ...newVehicle,
        [event.target.name]: year,
      };
    } else {
      newVehicleObj = {
        ...newVehicle,
        [event.target.name]: event.target.value,
      };
    }
    setNewVehicle(newVehicleObj);
  };

  // Check if all fields for new vehicle are already set
  const validateFields = () => {
    const values = Object.values(newVehicle);
    return values.filter((element) => element).length;
  };

  const addNewVehicle = async () => {
    if (validateFields() !== Object.values(newVehicle).length) {
      setErrors(true);
      return;
    }
    setLocalVehicles((localVehicles) => [...localVehicles, newVehicle]);
    setNewVehicle({
      make: "",
      model: "",
      year: "",
      vin: "",
      licensePlate: "",
    });
  };

  useEffect(() => {
    if (updateVehicles) updateVehicles(localVehicles);
  }, [localVehicles]);

  const updateVehicle = (vehicleIndex) => (event) => {
    setErrors(false);
    const newLocalVehicles = clone(localVehicles);
    const { name, value } = event.target;
    if (event.target.name === "year") {
      const year =
        event.target.value === "" ? "" : parseInt(event.target.value);
      newLocalVehicles[vehicleIndex] = {
        ...newLocalVehicles[vehicleIndex],
        [name]: year,
      };
    } else {
      newLocalVehicles[vehicleIndex] = {
        ...newLocalVehicles[vehicleIndex],
        [name]: value,
      };
    }
    setLocalVehicles(newLocalVehicles);
  };

  const deleteVehicle = (vehicleIndex) =>
    setLocalVehicles(localVehicles.filter((_, idx) => idx !== vehicleIndex));

  return (
    <div className={classes.root}>
      <Grid container className={clsx(classes.gridSpacing, classes.formGrid)}>
        <Grid item xs={12}>
          {localVehicles.map((vehicle, vehicleIndex) => {
            return (
              <Box
                key={`vehicle${vehicleIndex}`}
                display="flex"
                flexDirection="row"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={2}>
                    <TextField
                      className={clsx(classes.formInput)}
                      fullWidth
                      variant="outlined"
                      label="Make"
                      name="make"
                      value={vehicle.make || ""}
                      onChange={updateVehicle(vehicleIndex)}
                      required
                      error={!vehicle.make && errors}
                      disabled={collectingDetails}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <TextField
                      className={clsx(classes.formInput)}
                      fullWidth
                      variant="outlined"
                      label="Model"
                      name="model"
                      value={vehicle.model || ""}
                      onChange={updateVehicle(vehicleIndex)}
                      required
                      error={!vehicle.model && errors}
                      disabled={collectingDetails}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <TextField
                      className={clsx(classes.formInput)}
                      fullWidth
                      variant="outlined"
                      label="Year"
                      type="number"
                      name="year"
                      value={vehicle.year || ""}
                      onChange={updateVehicle(vehicleIndex)}
                      required
                      error={!vehicle.year && errors}
                      disabled={collectingDetails}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <TextField
                      className={clsx(classes.formInput)}
                      fullWidth
                      variant="outlined"
                      label="License Plate"
                      name="licensePlate"
                      value={vehicle.licensePlate || ""}
                      onChange={updateVehicle(vehicleIndex)}
                      required
                      error={!vehicle.licensePlate && errors}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <TextField
                        className={clsx(classes.formInput)}
                        fullWidth
                        placeholder="Enter VIN to get car details"
                        variant="outlined"
                        label="VIN"
                        name="vin"
                        value={vehicle.vin || ""}
                        onChange={updateVehicle(vehicleIndex)}
                        required
                        error={!vehicle.vin && errors}
                        disabled={collectingDetails}
                      />
                      <IconButton
                        onClick={() => deleteVehicle(vehicleIndex)}
                        className={classes.formButton}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <Box display="flex" flexDirection="row">
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  className={clsx(classes.formInput)}
                  fullWidth
                  variant="outlined"
                  label="Make"
                  name="make"
                  value={newVehicle.make || ""}
                  onChange={updateNewVehicle}
                  required
                  error={!newVehicle.make && errors}
                  disabled={collectingDetails}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  className={clsx(classes.formInput)}
                  fullWidth
                  variant="outlined"
                  label="Model"
                  name="model"
                  value={newVehicle.model || ""}
                  onChange={updateNewVehicle}
                  required
                  error={!newVehicle.model && errors}
                  disabled={collectingDetails}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  className={clsx(classes.formInput)}
                  fullWidth
                  variant="outlined"
                  label="Year"
                  type="number"
                  name="year"
                  value={newVehicle.year || ""}
                  onChange={updateNewVehicle}
                  required
                  error={!newVehicle.year && errors}
                  disabled={collectingDetails}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  className={clsx(classes.formInput)}
                  fullWidth
                  variant="outlined"
                  label="License Plate"
                  name="licensePlate"
                  value={newVehicle.licensePlate || ""}
                  onChange={updateNewVehicle}
                  required
                  error={!newVehicle.licensePlate && errors}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <TextField
                    className={clsx(classes.formInput)}
                    fullWidth
                    placeholder="Enter VIN to get car details"
                    variant="outlined"
                    label=""
                    name="vin"
                    value={newVehicle.vin || ""}
                    onChange={updateNewVehicle}
                    required
                    error={!newVehicle.vin && errors}
                    disabled={collectingDetails}
                  />

                  {!collectingDetails && (
                    <IconButton
                      onClick={addNewVehicle}
                      className={classes.formButton}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                  {collectingDetails && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      width={81}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

VehicleInput.propTypes = {
  /**
   * Callback to run when the a vehicle is added or removed
   * from the list; passed one argument the array of vehicles
   * objects with the same key value pairs as the corresponding
   * form
   */
  updateVehicles: PropTypes.func,
  /**
   * Array of objects representing vehicles
   */
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      make: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      year: PropTypes.number.isRequired,
      vin: PropTypes.string.isRequired,
      licensePlate: PropTypes.string.isRequired,
    })
  ),
};

VehicleInput.defaultProps = {
  updateVehicles: null,
  vehicles: null,
};

export default VehicleInput;
