import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from 'src/components/ErrorPage';

const NoModulesView = ({ message, header, ...rest }) => {
  const noModuleIcon = 'https://nsdstaticasset.blob.core.windows.net/assets/Platform_Status_Images/no_module_access.png';
  return (
    <ErrorPage
      title='No Modules'
      header={header}
      message={message}
      imgSrc={noModuleIcon}
      imgAlt='No Module Access'
      {...rest}
    />
  );
};

NoModulesView.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
};

NoModulesView.defaultProps = {
  header: 'No Module Access',
  // message: `You don't have access to any modules in this app.
  //   Please ask an administrator for access, then logout and log back in.`
};

export default NoModulesView;
