import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
} from "@material-ui/core";
import  Alert  from "@material-ui/lab/Alert";
import { Close as CloseIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import AvatarEditor from "./AvatarEditor";

const DEFAULT_NOTIFICATION_TIME = 1800;
const useStyles = makeStyles((theme) => ({
  root: {},
  alert: {
    width: "100%",
    position: "fixed",
    zIndex: theme.zIndex.snackbar,
    top: 0,
    left: 0,
  },
  closeBtn: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(8),
    },
  },
}));

/**
 * Composite component that uses AvatarEditor component to
 * create a popup dialog that allows the user to upload a
 * new image for their account's avatar.
 */
const AvatarEditDialog = ({
  setShowDropZone,
  showDropZone,
  avatarType,
  entity,
  fileUploadComplete,
  height,
  width,
  ...props
}) => {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [error, setError] = useState(null);
  const REACT_APP_BASE_STANDARD_URL =
    process.env.REACT_APP_BASE_STANDARD_URL || window.location.origin;
  const REACT_APP_BASE_STANDARD_KEY =
    process.env.REACT_APP_BASE_STANDARD_KEY || "someKey123";

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, DEFAULT_NOTIFICATION_TIME);
    }
    return () => clearTimeout(timer);
  }, [error]);

  const onFileSelect = (selectedFile) => setFile(selectedFile);

  const onUploadAvatar = async () => {
    if (!file) return setError("Please choose a file to upload");
    try {
      let formData = new FormData();
      const newFileFetchResponse = await fetch(file);
      const newFile = await newFileFetchResponse.blob();
      formData.append("file", newFile);
      formData.append("entityId", `${entity.id}`);
      formData.append("fileName", `avatar${entity.id}.png`);
      const response = await fetch(
        `${REACT_APP_BASE_STANDARD_URL}/file${
          REACT_APP_BASE_STANDARD_KEY
            ? `?code=${REACT_APP_BASE_STANDARD_KEY}&avatar=${avatarType}`
            : `?avatar=${avatarType}`
        }`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (fileUploadComplete) {
        fileUploadComplete(data.imagePath);
      }
    } catch (e) {
      console.error(e);
      setError(
        "Failed to upload file; Error: " + e?.message || "Failed to upload file"
      );
    }
  };

  return (
    <>
      <Fade in={!!error}>
        <Alert
          severity="warning"
          variant="filled"
          className={classes.alert}
          action={
            <IconButton
              className={classes.closeBtn}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Fade>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={() => {
          setShowDropZone(false);
        }}
        open={showDropZone}
      >
        <DialogTitle id="simple-dialog-title">Select avatar image</DialogTitle>
        <DialogContent>
          <AvatarEditor
            onFileSelect={onFileSelect}
            width={width}
            height={height}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowDropZone(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="outlined" color="primary" onClick={onUploadAvatar}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AvatarEditDialog.propTypes = {
  /**
   * Function (most likely a useState setter variable) that is
   * called with true or false to change the showDropZone variable
   */
  setShowDropZone: PropTypes.func.isRequired,
  /**
   * Denotes the type of avatar to be passed a query parameter
   * to the endpoint that accepts file uploads (enum?)
   */
  avatarType: PropTypes.string.isRequired,
  /**
   * Object representing user account in Azure (needs confirmation).
   * The object shape is unknown(tbd) but it must include an id field
   */
  entity: PropTypes.object.isRequired,
  /**
   * Callback to run once the file upload process is completed.
   * Accepts 1 argument; A string with the publicly hosted
   * image path of the uploaded file
   */
  fileUploadComplete: PropTypes.func,
  /**
   * Boolean indicating weather the dialog is visible/rendered
   */
  showDropZone: PropTypes.bool.isRequired,
  /**
   * Width of filedrop area and preview area passed to
   * AvatarEditor
   */
  width: PropTypes.number,
  /**
   * Height of filedrop and preview area passed to AvatarEditor
   */
  height: PropTypes.number,
};

AvatarEditDialog.defaultProps = {
  setShowDropZone: (status) => console.log(`setDropZone(${status}) called`),
  avatarType: "test",
  entity: null,
  fileUploadComplete: (avatarUrl) =>
    console.log(`fileUploadComplete(${avatarUrl}) called`),
  showDropZone: true,
  width: 250,
  height: 300,
};
export default AvatarEditDialog;
