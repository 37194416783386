import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = (loaderColor, buttonWidth) =>
  makeStyles((theme) => ({
    wrapper: {
      position: "relative",
    },
    loadButton: {
      display: "flex",
      width: buttonWidth || "initial",
      textAlign: "center",
      justifyContent: "space-around",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
      "&:disabled": {
        color: theme.palette.background.paper,
      },
    },
    buttonProgress: {
      color: loaderColor || theme.palette.background.paper,
      marginRight: theme.spacing(1),
    },
  }));

/**
 * Component to show a button with specified text and a loading spinner to indicate a
 * pending action. Accepts an onClick callback to run when button is clicked
 */

const LoadingButton = ({
  children,
  loading,
  disabled,
  loaderSize,
  loaderColor,
  buttonWidth,
  ...rest
}) => {
  const classes = useStyles(loaderColor, buttonWidth)();
  const { startIcon } = rest || {};
  return (
    <Button
      disableRipple
      className={classes.loadButton}
      disabled={loading || disabled}
      {...rest}
      onClick={rest.onClick}
      startIcon={!loading && startIcon ? startIcon : <></>}
    >
      {loading && (
        <CircularProgress
          size={loaderSize}
          className={classes.buttonProgress}
        />
      )}
      {children}
    </Button>
  );
};

LoadingButton.propTypes = {
  /**
   * Renderable value to be wrapped in the <LoadingButton />. Used to customize
   * the button and add text/icons.
   */
  children: PropTypes.any,
  /**
   * Boolean variable (usually a state variable) to control if the loading indicator
   * is present. If true disabled is true as well.
   */
  loading: PropTypes.bool,
  /**
   * Boolean variable to control if the button is disabled or not. When loading is true
   * the button is automatically disabled
   */
  disabled: PropTypes.bool,
  /**
   * Valid CSS color string to set color of the loading indicator
   */
  loaderColor: PropTypes.string,
  /**
   * Number (in px) for the size of the circular loading indicator
   */
  loaderSize: PropTypes.number,
  /**
   * CSS string for the width of the button
   */
  buttonWidth: PropTypes.string,
};

LoadingButton.defaultProps = {
  children: <p> Button Text </p>,
  loading: true,
  disabled: false,
  loaderColor: null,
  loaderSize: 20,
};

export default LoadingButton;
