import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const Function = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M308.8 106.6l-12.7-9.1c-3.6-2.6-8.6-1.8-11.2 1.8-39.9 56.9-61 122-61 188.7s21.1 131.8 61 188.7c2.5 3.6 7.6 4.4 11.2 1.8l12.7-9.1c3.4-2.5 4.2-7.2 1.7-10.6-36.2-51.6-55.2-110.5-55.2-170.8s19.1-119.1 55.2-170.8c2.5-3.4 1.8-8.1-1.7-10.6zM224 40c0-4.4-3.6-8-8-8h-56c-44.2 0-80 35.8-80 80v80H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h72v144c0 26.5-21.5 48-48 48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h24c44.2 0 80-35.8 80-80V224h72c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-72v-80c0-26.5 21.5-48 48-48h56c4.4 0 8-3.6 8-8zm355 59.3c-2.5-3.6-7.6-4.4-11.2-1.8l-12.7 9.1c-3.4 2.5-4.2 7.2-1.7 10.6 36.2 51.6 55.2 110.5 55.2 170.8s-19.1 119.1-55.2 170.8c-2.4 3.5-1.7 8.2 1.7 10.6l12.7 9.1c3.6 2.6 8.7 1.8 11.2-1.8 39.9-56.9 61-122 61-188.7s-21.1-131.8-61-188.7zm-69.3 122.3l-11.3-11.3c-3.1-3.1-8.2-3.1-11.3 0l-55 55-55-55c-3.1-3.1-8.2-3.1-11.3 0l-11.3 11.3c-3.1 3.1-3.1 8.2 0 11.3l55 55-55 55c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3c3.1 3.1 8.2 3.1 11.3 0l55-55 55 55c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3l-55-55 55-55c3.1-3.1 3.1-8.1 0-11.3z',
    [STYLE.REGULAR]: 'M288.73 320c0-52.34 16.96-103.22 48.01-144.95 5.17-6.94 4.45-16.54-2.15-22.14l-24.69-20.98c-7-5.95-17.83-5.09-23.38 2.23C246.09 187.42 224 252.78 224 320c0 67.23 22.09 132.59 62.52 185.84 5.56 7.32 16.38 8.18 23.38 2.23l24.69-20.99c6.59-5.61 7.31-15.2 2.15-22.14-31.06-41.71-48.01-92.6-48.01-144.94zM224 16c0-8.84-7.16-16-16-16h-48C102.56 0 56 46.56 56 104v64H16c-8.84 0-16 7.16-16 16v48c0 8.84 7.16 16 16 16h40v128c0 13.2-10.8 24-24 24H16c-8.84 0-16 7.16-16 16v48c0 8.84 7.16 16 16 16h16c57.44 0 104-46.56 104-104V248h40c8.84 0 16-7.16 16-16v-48c0-8.84-7.16-16-16-16h-40v-64c0-13.2 10.8-24 24-24h48c8.84 0 16-7.16 16-16V16zm353.48 118.16c-5.56-7.32-16.38-8.18-23.38-2.23l-24.69 20.98c-6.59 5.61-7.31 15.2-2.15 22.14 31.05 41.71 48.01 92.61 48.01 144.95 0 52.34-16.96 103.23-48.01 144.95-5.17 6.94-4.45 16.54 2.15 22.14l24.69 20.99c7 5.95 17.83 5.09 23.38-2.23C617.91 452.57 640 387.22 640 320c0-67.23-22.09-132.59-62.52-185.84zm-54.17 231.9L477.25 320l46.06-46.06c6.25-6.25 6.25-16.38 0-22.63l-22.62-22.62c-6.25-6.25-16.38-6.25-22.63 0L432 274.75l-46.06-46.06c-6.25-6.25-16.38-6.25-22.63 0l-22.62 22.62c-6.25 6.25-6.25 16.38 0 22.63L386.75 320l-46.06 46.06c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L432 365.25l46.06 46.06c6.25 6.25 16.38 6.25 22.63 0l22.62-22.62c6.25-6.25 6.25-16.38 0-22.63z',
    [STYLE.SOLID]: 'M288.73 320c0-52.34 16.96-103.22 48.01-144.95 5.17-6.94 4.45-16.54-2.15-22.14l-24.69-20.98c-7-5.95-17.83-5.09-23.38 2.23C246.09 187.42 224 252.78 224 320c0 67.23 22.09 132.59 62.52 185.84 5.56 7.32 16.38 8.18 23.38 2.23l24.69-20.99c6.59-5.61 7.31-15.2 2.15-22.14-31.06-41.71-48.01-92.6-48.01-144.94zM224 16c0-8.84-7.16-16-16-16h-48C102.56 0 56 46.56 56 104v64H16c-8.84 0-16 7.16-16 16v48c0 8.84 7.16 16 16 16h40v128c0 13.2-10.8 24-24 24H16c-8.84 0-16 7.16-16 16v48c0 8.84 7.16 16 16 16h16c57.44 0 104-46.56 104-104V248h40c8.84 0 16-7.16 16-16v-48c0-8.84-7.16-16-16-16h-40v-64c0-13.2 10.8-24 24-24h48c8.84 0 16-7.16 16-16V16zm353.48 118.16c-5.56-7.32-16.38-8.18-23.38-2.23l-24.69 20.98c-6.59 5.61-7.31 15.2-2.15 22.14 31.05 41.71 48.01 92.61 48.01 144.95 0 52.34-16.96 103.23-48.01 144.95-5.17 6.94-4.45 16.54 2.15 22.14l24.69 20.99c7 5.95 17.83 5.09 23.38-2.23C617.91 452.57 640 387.22 640 320c0-67.23-22.09-132.59-62.52-185.84zm-54.17 231.9L477.25 320l46.06-46.06c6.25-6.25 6.25-16.38 0-22.63l-22.62-22.62c-6.25-6.25-16.38-6.25-22.63 0L432 274.75l-46.06-46.06c-6.25-6.25-16.38-6.25-22.63 0l-22.62 22.62c-6.25 6.25-6.25 16.38 0 22.63L386.75 320l-46.06 46.06c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L432 365.25l46.06 46.06c6.25 6.25 16.38 6.25 22.63 0l22.62-22.62c6.25-6.25 6.25-16.38 0-22.63z'
  };

  return (
    <FontAwesome
      viewBox="0 0 640 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

Function.propTypes = {
  type: PropTypes.string
};

export default Function;
