import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const Ruler = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M635.7 165.8L556.1 27.9C550.2 17.7 539.5 12 528.5 12c-5.4 0-10.9 1.4-15.9 4.3L15.9 302.8C.7 311.5-4.5 331 4.3 346.2L83.9 484c5.9 10.2 16.6 15.9 27.6 15.9 5.4 0 10.9-1.4 15.9-4.3L624 209.1c15.3-8.6 20.5-28.1 11.7-43.3zM111.5 468.2L31.9 330.3l69-39.8 43.8 75.8c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9l-43.8-75.8 55.2-31.8 27.9 48.2c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9l-27.9-48.2 55.2-31.8 43.8 75.8c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9L294 179.1l55.2-31.8 27.9 48.2c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9l-27.9-48.2L432 99.5l43.8 75.8c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9l-43.8-75.8 69-39.8 79.6 137.8-496.7 286.7z',
    [STYLE.REGULAR]: 'M635.7 179.2L543.2 16.3c-7.6-13.5-26.5-22-43.7-11.9L16 288.3c-15.3 9-20.6 28.9-11.7 44.5l92.5 162.9c7.6 13.4 26.5 22 43.7 11.9L624 223.7c15.3-9 20.5-28.9 11.7-44.5zm-505.4 278L53.9 322.5l46-27 34.2 60.3c2.2 3.9 7.1 5.2 10.9 3l26.6-15.6c3.8-2.2 5.1-7.2 2.9-11.1l-34.2-60.3 40.4-23.7 18.7 32.9c2.2 3.9 7.1 5.2 10.9 3l26.6-15.6c3.8-2.2 5.1-7.2 2.9-11.1l-18.7-32.9 40.4-23.7 34.2 60.3c2.2 3.9 7.1 5.2 10.9 3l26.6-15.6c3.8-2.2 5.1-7.2 2.9-11.1L302 176.8l40.4-23.7 18.7 32.9c2.2 3.9 7.1 5.2 10.9 3l26.6-15.6c3.8-2.2 5.1-7.2 2.9-11.1l-18.7-32.9 40.4-23.7 34.2 60.3c2.2 3.9 7.1 5.2 10.9 3l26.6-15.6c3.8-2.2 5.1-7.2 2.9-11.1L463.6 82l46-27 76.5 134.7-455.8 267.5z',
    [STYLE.SOLID]: 'M635.7 167.2L556.1 31.7c-8.8-15-28.3-20.1-43.5-11.5l-69 39.1L503.3 161c2.2 3.8.9 8.5-2.9 10.7l-13.8 7.8c-3.8 2.2-8.7.9-10.9-2.9L416 75l-55.2 31.3 27.9 47.4c2.2 3.8.9 8.5-2.9 10.7l-13.8 7.8c-3.8 2.2-8.7.9-10.9-2.9L333.2 122 278 153.3 337.8 255c2.2 3.7.9 8.5-2.9 10.7l-13.8 7.8c-3.8 2.2-8.7.9-10.9-2.9l-59.7-101.7-55.2 31.3 27.9 47.4c2.2 3.8.9 8.5-2.9 10.7l-13.8 7.8c-3.8 2.2-8.7.9-10.9-2.9l-27.9-47.5-55.2 31.3 59.7 101.7c2.2 3.7.9 8.5-2.9 10.7l-13.8 7.8c-3.8 2.2-8.7.9-10.9-2.9L84.9 262.9l-69 39.1C.7 310.7-4.6 329.8 4.2 344.8l79.6 135.6c8.8 15 28.3 20.1 43.5 11.5L624.1 210c15.2-8.6 20.4-27.8 11.6-42.8z'
  };

  return (
    <FontAwesome
      viewBox="0 0 640 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

Ruler.propTypes = {
  type: PropTypes.string
};

export default Ruler;
