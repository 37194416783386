import  React, { createContext } from 'react';
import PropTypes from 'prop-types';

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({
  FirebaseEvents,
  FirebaseCrud,
  children
}) => (
  <FirebaseContext.Provider
    value={{
      FirebaseEvents,
      FirebaseCrud
    }}
  >
    {children}
  </FirebaseContext.Provider>
);

FirebaseProvider.propTypes = {
  /**
   * Instance of src/services/notificationsFirebase/Event.js
   */
  FirebaseEvents: PropTypes.object,
  /**
   * Instance of src/services/notificationsFirebase/Crud.js
   */
  FirebaseCrud: PropTypes.object,
  children: PropTypes.any
};

export default FirebaseContext;
