import enStrings from 'src/constants/strings/en-US';
import esStrings from 'src/constants/strings/es';
import frStrings from 'src/constants/strings/fr';

/**
 * List of locales supported by our library
 * https://cdn.jsdelivr.net/npm/dayjs@1/locale.json
 */
export const LANGUAGE_CODES = {
  'enUS': 'en-US',
  'en': 'en',
  'enAU': 'en-AU',
  'enCA': 'en-CA',
  'enGB': 'en-GB',
  'enIE': 'en-IE',
  'enIL': 'en-IL',
  'enIN': 'en-IN',
  'enNZ': 'en-NZ',
  'enSG': 'en-SG',
  'enTT': 'en-TT',
  // Spanish
  'es': 'es',
  'esMX': 'es-MX',
  'esPR': 'es-PR',
  'esUS': 'es-US',

  //French
  'fr': 'fr',
  'frCA': 'fr-CA',
};

export const LANGUAGE_CODES_I18N_SETUP_OBJECT = {
    // English
    [LANGUAGE_CODES.enUS]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.en]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enAU]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enCA]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enGB]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enIE]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enIL]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enIN]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enNZ]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enSG]: {
      translation: {...enStrings},
    },
    [LANGUAGE_CODES.enTT]: {
      translation: {...enStrings},
    },
    // Spanish
    [LANGUAGE_CODES.es]: {
      translation: {...esStrings},
    },
    [LANGUAGE_CODES.esMX]: {
      translation: {...esStrings},
    },
    [LANGUAGE_CODES.esPR]: {
      translation: {...esStrings},
    },
    [LANGUAGE_CODES.esUS]: {
      translation: {...esStrings},
    },
    // French
    [LANGUAGE_CODES.fr]: {
      translation: {...frStrings},
    },
    [LANGUAGE_CODES.frCA]: {
      translation: {...frStrings},
    },
};