export default {
  pendingDocument: 'Pending Document',
  complete: 'Complete',
  escalateToNSD: 'Escalate to NSD',
  dispatched: 'Dispatched',
  pendingDispatch: 'Pending Dispatch',
  cancelled: 'Cancelled',
  policeHold: 'Police Hold',
  newCase: 'New Case',
  new: 'New',
  accepted: 'Accepted',
  inRoute: 'In Route',
  onScene: 'On Scene',
  towInProgress: 'Tow In Progress',
  destinationArrival: 'Destination Arrival',
  digitalDispatch: 'Digital Dispatch',
  totalImpoundCalls: 'Total Impound Calls',
  PieChartCard: {
    title: 'Impounds by Percentage',
  },
};