import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useApp } from "../hooks/useApp";
import { useAssetSettings } from "src/hooks/useUserSettings";

/**
 * Wrapper component that checks for the presense of
 * account.user in the redux store to determine if the user
 * is authenticated, if not redirects to login. Also
 * performs an additional check to confirm user has
 * access to the specified App; if not redirects to
 * 403
 */
function AuthGuard({ children }) {
  const { location } = useHistory();
  const account = useSelector((state) => state.account);
  // Store initial route unauthenticated user visited and pass via react-router state
  const currentApp = useApp();
  const { hasAppAccess } = useAssetSettings();
  if (!account?.user) {
    const prevPath = location?.pathname;
    const prevQueryParams = location?.search;
    const toObject = {
      pathname: '/login',
      state: {
        ...(prevPath && { prevPath }),
        ...(prevQueryParams && { prevQueryParams }),
      },
    };
    return (
      <Redirect
        to={toObject}
      />
    );
  }
  if (!hasAppAccess(currentApp?.appId)) {
    return (
      <Redirect
        to={{
          pathname: '/403',
        }}
      />
    );
  }
  return children;
}

AuthGuard.propTypes = {
  /**
   * Component or renderable content to be protected
   */
  children: PropTypes.any,
};

AuthGuard.defaultProps = {
  children: null,
};

export default AuthGuard;
