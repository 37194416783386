import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import bytesToSize from "src/utils/bytesToSize";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 240,
    maxWidth: "100%",
    width: "100%",
    backgroundSize: "auto",
  },
  placeholder: {
    height: 240,
    backgroundColor: blueGrey[50],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  insertDriveFileIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    fontSize: theme.spacing(6),
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  getAppIcon: {
    marignRight: theme.spacing(1),
  },
  menu: {
    width: 250,
    maxWidth: "100%",
  },
  description: {
    width: "100%",
  },
  title: {
    width: "70%",
  },
}));

/**
 * Component to display the name and size of the passed in file
 * prop; also display a button to generate a download page for
 * the file
 */
function FileCard({ file, className, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadFile = async (fileToDownload) => {
    try {
      if (!fileToDownload.url) throw new Error("File must include URL");
      const a = document.createElement("a");
      a.href = fileToDownload.url;
      a.download = fileToDownload.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      enqueueSnackbar(`Unable to download file: ${e.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {file.mimeType?.includes("image/") ||
      file?.name.match(/.(jpg|jpeg|png|gif)$/i) ? (
        <CardMedia className={classes.media} image={file.url} />
      ) : (
        <div className={classes.placeholder}>
          <InsertDriveFileIcon className={classes.insertDriveFileIcon} />
        </div>
      )}
      <CardContent className={classes.content}>
        <Box className={classes.title}>
          <Tooltip arrow title={file?.name || "File name not found"}>
            <Typography noWrap variant="h5" color="textPrimary">
              {file.name}
            </Typography>
          </Tooltip>
          <Typography variant="subtitle2" color="textPrimary">
            {file?.size ? bytesToSize(file.size) : "Unable to read size"}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button fullWidth onClick={async () => await handleDownloadFile(file)}>
          <GetAppIcon className={classes.getAppIcon} />
          Download
        </Button>
      </CardActions>
    </Card>
  );
}

FileCard.propTypes = {
  /**
   * Class name to be applied to the outermost container element
   */
  className: PropTypes.string,

  /**
   * File object to be displayed on the card
   */
  file: PropTypes.shape({
    mineType: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
};

FileCard.defaultProps = {
  className: "",
  file: null,
};

export default FileCard;
