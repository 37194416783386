import React, { cloneElement } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { QueryBuilder } from "@material-ui/icons";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  none: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 40,
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "center",
  },
}));

/**
 * Component to cover the entire width and height of the wrapping
 * container while displaying a coming soon message
 */
const ComingSoon = ({ title, fontVariant, iconSize, className, Icon }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.none, className)}>
      {Icon ? (
        cloneElement(Icon, {
          style: { width: iconSize, height: iconSize },
        })
      ) : (
        <QueryBuilder style={{ width: iconSize, height: iconSize }} />
      )}
      <Typography
        className={classes.title}
        variant={fontVariant}
        color="textPrimary"
      >
        {title}
      </Typography>
    </Box>
  );
};

ComingSoon.propTypes = {
  /**
   * Custom class applied to the outermost container element
   */
  className: PropTypes.string,
  /**
   * Title of the coming soon page
   */
  title: PropTypes.string.isRequired,
  /**
   * Size variant of the title text that appears
   */
  fontVariant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  /**
   * Width and height of the icon
   */
  iconSize: PropTypes.number,
  /**
   * Custom icon component to use
   */
  Icon: PropTypes.element,
};

ComingSoon.defaultProps = {
  fontVariant: "h3",
  iconSize: 200,
  title: "Coming Soon",
  className: "",
  Icon: null,
};

export default ComingSoon;
