import React, { useState, useRef, useContext, useEffect } from "react";
import { capitalCase } from "change-case";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Settings as SettingsIcon } from "react-feather";
import LanguageSelector from "src/components/LanguageSelector";
import axios from "src/utils/axios";
import useSettings from "src/hooks/useSettings";
import fallBackThemes from "src/constants/defaultThemes";

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 12,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
  label: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    margin: 0,
  },
}));

/**
 * Component meant to be rendered in the TopBar to display
 * the logged in users current notifications. Can change
 * the text direction, responsive font-sizes, the theme and
 * the language here
 */
function Settings() {
  const classes = useStyles();
  
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [allThemes, setAllThemes] = useState(null);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    themeList: settings.themeList,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  /** Fetch the themes availible to this company */
  useEffect(() => {
    axios
      .get("/appTheme")
      .then(({ data }) => {
        setAllThemes(data?.themes);
      })
      .catch((error) => {
        console.error({ error });
        setAllThemes(fallBackThemes);
      });
  }, []);

  return (
    <>
      <Tooltip title="Settings">
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          Settings
        </Typography>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Switch
                checked={values.direction === "rtl"}
                edge="start"
                name="direction"
                onChange={(event) =>
                  handleChange(
                    "direction",
                    event.target.checked ? "rtl" : "ltr"
                  )
                }
              />
            }
            label="RTL"
            className={classes.label}
            labelPlacement="start"
          />
        </Box>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={(event) =>
                  handleChange("responsiveFontSizes", event.target.checked)
                }
              />
            }
            label="Responsive font sizes"
            className={classes.label}
            labelPlacement="start"
          />
        </Box>
        {/* TODO: Change Design */}
        <Box mt={2} display="flex" justifyContent="space-between">
          <Typography variant="body1">Language</Typography>
          <LanguageSelector />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event) => handleChange("theme", event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {allThemes?.map(({ name }) => (
              <option key={name} value={name}>
                {capitalCase(name)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Save Settings
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
