/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { matchPath, useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAssetSettings } from "src/hooks/useUserSettings";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavItem from "./NavItem";
import PoweredBy from "./PoweredBy";
import AvatarWrapper from "./AvatarWrapper";

// pathname passed in as rest
const renderNavItems = ({ items, ...rest }) => (
  <List disablePadding>
    {items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}
  </List>
);

const checkIfCurrentLevelIsRendered = (renderOnPaths, curPath) => {
  // If not set default to display on every route
  if (!renderOnPaths) return true;
  let isRendered = false;
  if (renderOnPaths && renderOnPaths?.length >= 0) {
    renderOnPaths?.forEach((path) => {
      if (path === "/" && path === curPath) isRendered = true;
      return curPath.includes(path)
        ? (isRendered = true)
        : (isRendered = false);
    });
  }
  return isRendered;
};

const checkIfNavItemsRendered2 = ({ items, pathname, renderOnPaths }) => {
  if (!items || items?.length <= 0) return false;
  for (const curItem of items) {
    let finalRenderOnPaths;
    const { renderOnPaths: childItemRenderOnPaths } = curItem;
    childItemRenderOnPaths
      ? (finalRenderOnPaths = childItemRenderOnPaths)
      : (finalRenderOnPaths = renderOnPaths);

    // If its true the navItems for this level have at least 1 item so it can render
    if (checkIfCurrentLevelIsRendered(finalRenderOnPaths, pathname))
      return true;
    // curItem.items doesn't need to be checked render if parent renders anyway
  }
  // If its not rendered or has no more items return false no render
  return false;
};

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth = 0,
  renderOnPaths,
}) => {
  const key = item.title + depth;
  // renderOnPaths param is from topLevel parent nesting these navLinks,
  // if there is a child renderOnPaths property it takes precedence
  let finalRenderOnPaths;
  const { renderOnPaths: childItemRenderOnPaths } = item;
  childItemRenderOnPaths
    ? (finalRenderOnPaths = childItemRenderOnPaths)
    : (finalRenderOnPaths = renderOnPaths);

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    /*
      Bugs:
      #1 - When using items.items to nest your subroutes 
      and you add a renderOnPaths property to any item 
      in items.items it will not respect the granularity
      and only render if its parent item renders
    */
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        renderOnPaths={finalRenderOnPaths}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          renderOnPaths: renderOnPaths,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        externalLink={item.externalLink}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        renderOnPaths={finalRenderOnPaths}
      />
    );
  }

  return acc;
};

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    width: 64,
    height: 64,
  },
  navBarMenu: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100% - 135px)",
    [theme.breakpoints.down("md")]: {
      minHeight: "calc(100% - 225px)",
    },
  },
  footerBox: {
    width: "100%",
    marginTop: "auto",
  },
}));

/**
 * Navbar component that renders a sidenav. Can be passed props
 * to indicate if the navbar should be hidden on mobile.
 * Desktop NavBar is hidden when viewport width is < 600px
 * Mobile NavBar is hidden when the viewport width is > 600px
 * lg: 1280
 * md: 960
 * sm: 600
 * xl: 1920
 * xs: 0
 */

const NavBar = ({ openMobile, onMobileClose, navConfig }) => {
  const classes = useStyles();
  const location = useLocation();
  const { isNSD } = useAssetSettings();
  const { user } = useSelector((state) => state.account);
  const { type } = useSelector((state) => state.application);

  // Run all items in navBarItems through this, if there is any instance
  // of true you need to render the subheader

  useEffect(() => {
    if (openMobile && onMobileClose) onMobileClose();
  }, [location.pathname]);

  if (!type) return null;
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden> */}
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <AvatarWrapper portalType={type} isNSD={isNSD()}>
              <Avatar
                alt={`${user?.firstName} ${user?.lastName} avatar`}
                className={classes.avatar}
                src={user?.avatar}
              />
            </AvatarWrapper>
          </Box>
          <Box mt={2} textAlign="center">
            <Typography variant="h5" color="textPrimary">
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.navBarMenu}>
          <Box p={2}>
            {navConfig.navBarItems.map((config) => {
              return (
                config.items.length > 0 &&
                checkIfNavItemsRendered2({
                  items: config?.items,
                  pathname: location.pathname,
                  renderOnPaths: config?.renderOnPaths,
                }) && (
                  <List
                    key={config.subheader}
                    subheader={
                      config?.subheader &&
                      checkIfCurrentLevelIsRendered(
                        config?.renderOnPaths,
                        location.pathname
                      ) ? (
                        <ListSubheader disableGutters disableSticky>
                          {config?.subheader || ""}
                        </ListSubheader>
                      ) : null
                    }
                  >
                    {renderNavItems({
                      items: config?.items,
                      pathname: location.pathname,
                      renderOnPaths: config?.renderOnPaths,
                    })}
                  </List>
                )
              );
            })}
          </Box>
          <Divider />
          <Box className={classes.footerBox} p={2}>
            {navConfig.navBarMessage ? (
              <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
                <Typography variant="h6" color="textPrimary">
                  {navConfig?.navBarMessage?.header}
                </Typography>
                <Link
                  variant="subtitle1"
                  color="secondary"
                  component={RouterLink}
                  to={navConfig?.navBarMessage?.link}
                >
                  {navConfig?.navBarMessage?.body}
                </Link>
              </Box>
            ) : (
              <PoweredBy />
            )}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  /**
   * Callback that runs when the pathname (URL) changes;
   * it is not passed any arguments
   */
  onMobileClose: PropTypes.func,
  /**
   * Weather or not to display the Navbar on mobile
   * device widths
   */
  openMobile: PropTypes.bool,
  /**
   * navConfig object that determines what links to populate
   * in the NavBar. Comes from the appConfig of the current
   * rendered route.
   *
   * Within one object of items array you can create an other
   * items array (same shape) within it to have collaspable subroutes render
   * in the navBar.
   *
   * renderOnPaths: Is an array of relative links
   *  that you don't want the current nav item to be rendered on.
   *  The check is done by seeing if the current path CONTAINS/INCLUDES
   * the renderOnPath item. So...
   *  ex: ['/admin', '/claims']
   *    The nav item would not render on '/admin' , '/claims' , '/admin/1/2' or any other
   *    /admin or /claims subpath.
   *
   *
   *
   * module: A module identifier you wish to guard the
   * navItem behind, if the user doesn't have access the link
   * is not rendered in the navBar. Leave off for a page
   * anyone can access
   */
  navConfig: PropTypes.shape({
    navBarItems: PropTypes.arrayOf(
      PropTypes.shape({
        subheader: PropTypes.string.isRequired,
        renderOnPaths: PropTypes.arrayOf(PropTypes.string),
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.object,
            href: PropTypes.string.isRequired,
            items: PropTypes.array,
            module: PropTypes.string,
          })
        ),
      })
    ),
    navBarMessage: PropTypes.shape({
      header: PropTypes.string,
      link: PropTypes.string,
      body: PropTypes.string,
    }),
  }),
};

NavBar.defaultProps = {
  onMobileClose: null,
  openMobile: true,
  navConfig: null,
  type: "client",
};

export default NavBar;
