import React from "react";
import DynamicMapCard from "../common/components/DynamicMapCard";
import CanadaMap from "./CanadaMap";

import { useTranslation } from "react-i18next";

export default function CanadaMapCard({ ...rest }) {
  const { t } = useTranslation();

  const adjustedProps = {
    activeLandRegions: rest.activeStates,
    countByLandRegion: rest.countByState.map(({ stateCode, ...rest }) => ({
      landRegionCode: stateCode,
      ...rest,
    })),
    onChangeSelectedLandRegions: rest.onChangeSelectedStates,
  };

  return <DynamicMapCard MapView={CanadaMap} {...adjustedProps} {...rest} />;
}

// Things to pass in
