/* eslint-disable no-param-reassign */
import produce from 'immer';
import find from 'lodash/find';
import {
  GET_ADDRESS_TYPES,
  GET_CONTACT_METHODS,
  GET_PHONE_TYPES,
  GET_SERVICES,
} from 'src/actions/typesActions';

const initialState = {
  phoneTypes: [],
  addressTypes: [],
  dispatchStatuses: [],
  dispatchStates: [],
  dispatchWorkFlowTypes: [],
  otherAddressTypeId: -1,
  contactMethods: [],
  services: [],
  vehicleCategories: [],
  equipment: [],
  roles: []
};

const typesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PHONE_TYPES: {
      const phoneTypes = action.payload;

      return produce(state, (draft) => {
        draft.phoneTypes = phoneTypes;
      });
    }

    case GET_SERVICES: {
      const services = action.payload;

      return produce(state, (draft) => {
        draft.services = services;
      });
    }

    case GET_ADDRESS_TYPES: {
      const addressTypes = action.payload;

      return produce(state, (draft) => {
        draft.otherAddressTypeId = find(addressTypes, { addressType: 'Other' }).addressTypeId;
        draft.addressTypes = addressTypes;
      });
    }

    case GET_CONTACT_METHODS: {
      const contactMethods = action.payload;

      return produce(state, (draft) => {
        draft.contactMethods = contactMethods;
      });
    }

    default: {
      return state;
    }
  }
};

export default typesReducer;
