import { gql } from '@apollo/client';
import each from 'lodash/each';
import BaseService from './baseService';

class ClientService extends BaseService {
  constructor() {
    super();
    this.clientDataMappings = {
      id: {
        name: '$id',
        type: 'Int',
        input: 'Id'
      },
      name: {
        name: '$name',
        type: 'String',
        typeRequire: 'String!',
        input: 'Name'
      },
      billingEmail: {
        name: '$billingEmail',
        type: 'String',
        typeRequire: 'String!',
        input: 'BillingEmail'
      },
      supportEmail: {
        name: '$supportEmail',
        type: 'String',
        typeRequire: 'String!',
        input: 'SupportEmail'
      },
      preferredContactMethodId: {
        name: '$preferredContactMethodId',
        type: 'Int',
        input: 'PreferredContactMethodId'
      },
      emailOptIn: {
        name: '$emailOptIn',
        type: 'Int',
        input: 'EmailOptIn'
      },
      sMSOptIn: {
        name: '$sMSOptIn',
        type: 'Int',
        input: 'SMSOptIn'
      },
      avatar: {
        name: '$avatar',
        type: 'String',
        input: 'Avatar'
      },
      addresses: {
        name: '$addresses',
        type: '[AddressIn]',
        input: 'Addresses'
      },
      vehicles: {
        name: '$vehicles',
        type: '[VehicleIn]',
        input: 'Vehicles'
      },
      phones: {
        name: '$phones',
        type: '[PhoneIn]',
        input: 'Phones'
      },
      mobileNotificationsOptIn: {
        name: '$mobileNotificationsOptIn',
        type: 'Int',
        input: 'MobileNotificationsOptIn'
      },
      phoneOptIn: {
        name: '$phoneOptIn',
        type: 'Int',
        input: 'PhoneOptIn'
      },
      chatEmailOptIn: {
        name: '$chatEmailOptIn',
        type: 'Int',
        input: 'ChatEmailOptIn'
      },
      chatMobileNotificationsOptIn: {
        name: '$chatMobileNotificationsOptIn',
        type: 'Int',
        input: 'ChatMobileNotificationsOptIn'
      },
      chatSMSOptIn: {
        name: '$chatSMSOptIn',
        type: 'Int',
        input: 'ChatSMSOptIn'
      }
    };
    this.clientGql = `
        Id
        Name
        DateCreated
        LastModified
        BillingEmail
        SupportEmail
        PreferredContactMethodId
        EmailOptIn
        MobileNotificationsOptIn
        SMSOptIn
        PhoneOptIn
        ChatEmailOptIn
        ChatMobileNotificationsOptIn
        ChatSMSOptIn
        Avatar
        Addresses{
          Id
          FullAddress
          GoogleLocationId
          Address
          City
          StateProvidence
          PostalCode
          Latitude
          Longitude
          AddressTypeId
        }
        Phones{
          Id
          Phone
          PhoneTypeId
        }
        Vehicles{
          Id
          Year
          Make
          Model
          Vin
          LicensePlate
        }`;
    this.clientActionGql = `
      Success
      Token`;
  }

  buildClientGQL = (data, mutation, required, actionReturned) => {
    const keys = Object.keys(data);
    const client = [];
    const input = [];
    each(keys, (key) => {
      const map = this.clientDataMappings[key];
      if (map && data[key]) {
        client.push(`${map.name}: ${required && map.typeRequire ? map.typeRequire : map.type}`);
        input.push(`${map.input}: ${map.name}`);
      }
    });
    return `
      mutation Client(${client.join(', ')}){
        ${mutation}(
            ${input.join('\n')}
          ) {
            ${actionReturned ? this.clientActionGql : this.clientGql}
        }
    }`;
  };

  deleteClient = (ids) => new Promise((resolve, reject) => {
    const DELETE_CLIENT = gql`
      mutation Client($ids: [Int]!){
        removeClients(
          Ids: $ids
        ){
          Success
          Token
        }
      }
    `;

    this.client
      .mutate({
        mutation: DELETE_CLIENT,
        variables: { ids }
      })
      .then((result) => {
        console.log('deleteClient:result', result);
        if (result.data.removeClients) {
          resolve(result.data.removeClients);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  addClient = (data) => new Promise((resolve, reject) => {
    const NEW_CLIENT = gql`
      ${this.buildClientGQL(data, 'addClient', true)}
    `;

    this.client
      .mutate({
        mutation: NEW_CLIENT,
        variables: data
      })
      .then((result) => {
        console.log('addClient:result', result);
        if (result.data.addClient) {
          resolve(result.data.addClient);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  saveClient = (data) => new Promise((resolve, reject) => {
    const CLIENT = gql`${this.buildClientGQL(data, 'updateClient', false, true)}`;

    this.client
      .mutate({
        mutation: CLIENT,
        variables: data
      })
      .then((result) => {
        console.log('updateClient:result', result);
        if (result.data.updateClient) {
          resolve(result.data.updateClient);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  savePhones = (id, phones) => new Promise((resolve, reject) => {
    const CLIENT_PHONES = gql`
      mutation Client($id: Int!, $phones: [PhoneUpdate]){
        updateClientPhones(
            Id: $id
            Phones: $phones
          ) {
            Success
            Token
            Error
            Client{
                ${this.clientGql}
            }
        }
    }
    `;

    this.client
      .mutate({
        mutation: CLIENT_PHONES,
        variables: { id, phones }
      })
      .then((result) => {
        console.log('savePhones:result', result);
        if (result.data.updateClientPhones) {
          resolve(result.data.updateClientPhones);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  saveAddresses = (id, addresses) => new Promise((resolve, reject) => {
    console.log('saveAddresses: id', id);
    console.log('saveAddresses: addresses', addresses);
    const CLIENT_ADDRESSES = gql`
      mutation Client($id: Int!, $addresses: [AddressUpdate]){
        updateClientAddresses(
            Id: $id
            Addresses: $addresses
          ) {
            Success
            Token
            Error
            Client{
              ${this.clientGql}
            }
        }
    }
    `;

    this.client
      .mutate({
        mutation: CLIENT_ADDRESSES,
        variables: { id, addresses }
      })
      .then((result) => {
        console.log('saveAddresses:result', result);
        if (result.data.updateClientAddresses) {
          resolve(result.data.updateClientAddresses);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getClients = () => new Promise((resolve, reject) => {
    // named this query
    const CLIENTS = gql`
      query Client{
        clients{
            ${this.clientGql}
        }
    }`;

    this.client
      .query({
        query: CLIENTS
      })
      .then((result) => {
        console.log('getClients: result', result);
        if (result.data.clients) {
          resolve(result.data.clients);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  searchClients = (value) => new Promise((resolve, reject) => {
    const CLIENTS = gql`
      query Client($value: String!){
        searchClients(
            Value: $value
          ){
            ${this.clientGql}
        }
    }`;

    this.client
      .query({
        query: CLIENTS,
        variables: { value }
      })
      .then((result) => {
        console.log('getClients: result', result);
        if (result.data.searchClients) {
          resolve(result.data.searchClients);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getClient = (id) => new Promise((resolve, reject) => {
    const CLIENT = gql`
      query Client($id: Int!){
        client(Id: $id){
            ${this.clientGql}
        }
    }`;

    this.client
      .query({
        query: CLIENT,
        variables: { id }
      })
      .then((result) => {
        console.log('getClient: result', result);
        if (result.data.client) {
          resolve(result.data.client);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

const clientService = new ClientService();

export default clientService;
