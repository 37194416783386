import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const Lasso = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M288 0C126.5 0 0 77.31 0 176c0 46.78 29.06 90.62 81.81 123.45 4.54 2.82 9.51 5.58 14.7 8.34C93.28 333.29 117.4 352 144 352a55.73 55.73 0 0 0 19.65-3.58c17.26 15.75 29.07 36.41 28.32 67.77C191.11 451.85 161 480 125.38 480H40a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h84.61c54.33 0 99.47-44.5 99.39-98.83 0-29.05-8.41-51.28-21.06-68.84A464.82 464.82 0 0 0 288 352c161.5 0 288-77.31 288-176S449.5 0 288 0zm0 320a422.74 422.74 0 0 1-96.36-11c-1.86-20.67-22.35-37-47.64-37a53.72 53.72 0 0 0-29.64 8.76c-5.36-2.8-11-5.6-15.64-8.46C55.69 245.52 32 211.33 32 176 32 97.94 149.22 32 288 32s256 65.94 256 144-117.22 144-256 144z',
    [STYLE.REGULAR]: 'M288 0C126.5 0 0 78.48 0 178.67c0 48.33 29.62 93.24 83.44 126.6 4.17 2.59 8.69 5.12 13.44 7.65-5.61 40.89 35.55 73.75 85.65 70.45a82.41 82.41 0 0 1 3.79 27.11c-.63 29.94-26.22 53.52-56.16 53.52H48a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h82.06c57 0 104-46 104.28-103.07a125.05 125.05 0 0 0-6.71-40.52 71.77 71.77 0 0 0 15.22-13.48c14.88 1.42 29.9 2.4 45.15 2.4 161.5 0 288-78.47 288-178.66S449.5 0 288 0zm0 309.33c-11.35 0-22.46-.88-33.55-1.76-7.19-29.44-39.54-51.67-78.45-51.67-20.38 0-38.77 6.28-52.9 16.32-4.9-2.56-10.12-5.11-14.35-7.74C69.56 240.19 48 209.72 48 178.67 48 107.84 157.91 48 288 48s240 59.84 240 130.67-109.91 130.66-240 130.66z',
    [STYLE.SOLID]: 'M288 0C126.5 0 0 79.63 0 181.3c0 49.78 30.22 95.84 85 129.7 3.52 2.17 7.37 4.29 11.3 6.41 0 .89-.33 1.71-.33 2.6 0 35.35 35.82 64 80 64 .69 0 1.33-.15 2-.16a68.71 68.71 0 0 1 2.69 17.9A45.7 45.7 0 0 1 134.94 448H48a16 16 0 0 0-16 15.94v32.12A16 16 0 0 0 48 512h86.94c59.38 0 108.8-47.22 109.76-106.38A135 135 0 0 0 238 360a468.23 468.23 0 0 0 50 2.58c161.5 0 288-79.63 288-181.28S449.5 0 288 0zm0 298.82c-12.73 0-25.23-.81-37.6-2C238.79 273 210 256 176 256a94.36 94.36 0 0 0-41.2 9.4c-5.58-2.86-11.32-5.69-16.05-8.61C98.31 244.17 64 217.57 64 181.3c0-62.61 104.69-117.54 224-117.54s224 54.93 224 117.54-104.69 117.52-224 117.52z'
  };

  return (
    <FontAwesome
      viewBox="0 0 576 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

Lasso.propTypes = {
  type: PropTypes.string
};

export default Lasso;
