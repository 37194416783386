import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Axios from "axios";
import { Delete as RemoveIcon, PlusSquare as AddIcon } from "react-feather";
import clone from "lodash/clone";
import remove from "lodash/remove";
import PropTypes from "prop-types";
import GoogleAddressInput from "./GoogleAddressInput";

const oldDetailsAPIUrl =
  "https://demo-dispatch-now-api.azurewebsites.net/place-details";
const detailsAPIUrl = `/locationIn`;
const axios = Axios.create();

const useStyles = makeStyles((theme) => ({
  root: {},
  formType: { width: "100%", marginBottom: theme.spacing(1) },
  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  formButton: { marginTop: theme.spacing(1) },
  checkBox: { color: theme.palette.success.main },
  loaderText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  locationDetector: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    placeItems: "center",
    background: theme.palette.background.default,
    width: "100%",
    marginTop: theme.spacing(5),
  },
}));

/**
 * Composite component that accepts multiple types of addresses
 * powered by the GoogleAutocomplete location service. After the
 * user selects a autocompleted location the place details are
 * finalized by making an additional API call (needed?)
 */
function AddressInput({
  className,
  addresses,
  singleAddress,
  updateAddresses,
  detectLocation,
  ...rest
}) {
  const classes = useStyles();
  const [newAddress, setNewAddress] = useState(
    addresses?.length ? addresses[0] : {}
  );
  const [newAddressType, setNewAddressType] = useState(null);
  const [collectingDetails, setCollectingDetails] = useState(false);
  const [localAddresses, setLocalAddresses] = useState(clone(addresses) || []);
  const { addressTypes, otherAddressTypeId } = useSelector(
    (state) => state.types
  );

  useEffect(() => {
    if (updateAddresses) updateAddresses(localAddresses);
  }, [localAddresses]);

  // TODO: Ask if this is necessary / used; in particular the first
  // Axios call
  const collectAddressDetails = (addressIn) => {
    if (addressIn) {
      setCollectingDetails(true);
      const url = `${detailsAPIUrl}?code=sro23zoFSvBu01GzFkvpAQ1AAdVMg7l82otZT6Ajad/DAZQCnwy2bw==&address=${addressIn.description.replace(
        /\s/g,
        "+"
      )}`;
      // Example places URL to get additional details
      // https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=all&key=AIzaSyAavJOGxVd6S9r_yJeZkZyafLqQwOi2Im8
      axios
        .get(url)
        .then((result) => {
          const addressData = { ...result.data.results[0]?.result };
          let newLocation = {
            addressTypeId: newAddressType || otherAddressTypeId,
            googleLocationId: addressData.place_id,
            address: `${addressData.address_components[0].long_name} ${addressData.address_components[1].long_name}`,
            city: addressData.address_components[2].long_name,
            stateProvidence: addressData.address_components[4].short_name,
            postalCode: addressData.address_components[6].long_name,
            fullAddress: addressData.formatted_address,
            latitude: addressData.geometry.location.lat,
            longitude: addressData.geometry.location.lng,
          };
          setNewAddress(newLocation);
          if (singleAddress) {
            setLocalAddresses([newLocation]);
          }

          setCollectingDetails(false);
        })
        .catch((error) => {
          console.log("error", error);
          setCollectingDetails(false);
          console.error(error);
        });
    } else {
      setCollectingDetails(false);
    }
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!singleAddress &&
            localAddresses.map((address, addressIndex) => {
              return (
                <Box key={`address${addressIndex}`}>
                  <TextField
                    className={clsx(classes.formInput, classes.formType)}
                    InputLabelProps={{ shrink: true }}
                    placeholder={"Loading..."}
                    label="Type"
                    name="preferredContactMethodId"
                    onChange={(event) => {
                      let newLocalAddresses = clone(localAddresses);
                      newLocalAddresses[addressIndex] = {
                        ...newLocalAddresses[addressIndex],
                        addressTypeId: parseInt(event.target.value),
                      };
                      setLocalAddresses(newLocalAddresses);
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={address.addressTypeId || 1}
                    variant="outlined"
                  >
                    {addressTypes.map((addressType) => (
                      <option key={addressType.id} value={addressType.id}>
                        {addressType.type}
                      </option>
                    ))}
                  </TextField>
                  <GoogleAddressInput
                    className={classes.formInput}
                    locationChange={(newLocation) => {
                      // console.log('newLocation', newLocation);
                    }}
                    currentLocation={address.fullAddress}
                  />
                  <IconButton
                    onClick={() => {
                      let newLocalAddresses = clone(localAddresses);
                      remove(newLocalAddresses, (o, i) => {
                        if (i === addressIndex) {
                          return true;
                        }
                        return false;
                      });
                      setLocalAddresses(newLocalAddresses);
                    }}
                    className={classes.formButton}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>
              );
            })}
          <Box>
            {!singleAddress && (
              <TextField
                className={clsx(classes.formInput, classes.formType)}
                InputLabelProps={{ shrink: true }}
                placeholder={"Loading..."}
                label="Type"
                name="preferredContactMethodId"
                onChange={(event) => {
                  setNewAddressType(parseInt(event.target.value));
                }}
                select
                SelectProps={{ native: true }}
                value={newAddressType || 1}
                variant="outlined"
              >
                {addressTypes.map((addressType) => (
                  <option key={addressType.id} value={addressType.id}>
                    {addressType.type}
                  </option>
                ))}
              </TextField>
            )}
            <GoogleAddressInput
              currentLocation={newAddress.fullAddress}
              className={classes.formInput}
              locationChange={collectAddressDetails}
              detectLocation={detectLocation}
            />
            {!collectingDetails && !singleAddress && (
              <IconButton
                onClick={() => {
                  setLocalAddresses((localAddresses) =>
                    localAddresses.concat([newAddress])
                  );
                  setNewAddress({});
                  setNewAddressType(null);
                }}
                className={classes.formButton}
              >
                <AddIcon />
              </IconButton>
            )}
            {collectingDetails && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                className={classes.locationDetector}
              >
                <Typography className={classes.loaderText} variant="h4">
                  Gathering Location Details...
                </Typography>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

AddressInput.propTypes = {
  /**
   * Class name to apply to the outermost element container
   */
  className: PropTypes.string,

  /**
   * Boolean indicating weather or not this should render a
   * single input for an addresss; or multiple inputs.
   * TODO: REMOVE if you need a single address input use
   * GoogleAddressInput.js
   */
  singleAddress: PropTypes.bool,

  /**
   * Callback that is invoked when the add (+) button is clicked. It
   * is passed one argument an array of location objects that were
   * entered so far
   */
  updateAddresses: PropTypes.func,

  /**
   * Array of address objects to already be predisplay/filled out
   * in the rendered inputs.
   * See https://developers.google.com/maps/documentation/places/web-service/details
   * for address object shape
   */
  addresses: PropTypes.arrayOf(PropTypes.object),

  /**
   * Boolean indicating weather or not to detect the current
   * users location and pre-fill the input; currentLocation
   * must also be null in order for this work
   */
  detectLocation: PropTypes.bool,
};

AddressInput.defaultProps = {
  className: "",
  singleAddress: false,
  updateAddresses: (addresses) => console.log({ addresses }),
  addresses: null,
};

export default AddressInput;
