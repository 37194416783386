import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Warning as WarningIcon,
  Person as CustomerIcon,
} from "@material-ui/icons";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import throttle from "lodash/throttle";
import find from "lodash/find";
import customerService from "../services/customerService";

const useStyles = makeStyles((theme) => ({
  root: {},
  formType: { width: 200 },
  formInput: { margin: theme.spacing(1) },
  formButton: { margin: theme.spacing(1) },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

/**
 * Autocomplete (combination select and input) component that
 * utilizes the Customer GraphQL service which calls the searchCustomers
 * method and fills in the dropdown as necessary
 */
function CustomerInput({ className, customer, customerChange, ...rest }) {
  const classes = useStyles();
  const [value, setValue] = useState(customer || null);
  const [inputVal, setInputVal] = useState(customer ? `${customer.email}` : "");
  const isMountedRef = useIsMountedRef();
  const [options, setOptions] = useState([]);
  const theme = useTheme();
  const [error, setError] = useState("");

  useEffect(() => {
    if (value) {
      const cust = find(options, { id: value.id });
      if (cust && customerChange) customerChange(cust);
    } else {
      if (customerChange) customerChange(null);
    }
  }, [inputVal]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        customerService
          .searchCustomers(request)
          .then(callback)
          .catch(({ message }) => setError(message));
      }, 200),
    []
  );

  useEffect(() => {
    if (error) setError("");
    let active = true;
    if (inputVal === "" || !inputVal) {
      setOptions([]);
      return undefined;
    }
    fetch(inputVal, (results) => {
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputVal, fetch]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" width="100%">
            <Autocomplete
              getOptionSelected={(option, value) => value.id === option.id}
              noOptionsText={
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flexStart"
                  alignItems="center"
                >
                  {error ? (
                    <>
                      <WarningIcon fontSize="large" />
                      <Typography style={{ marginLeft: theme.spacing(2) }}>
                        {error}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {inputVal ? (
                        <>
                          <CircularProgress />
                          <Typography style={{ marginLeft: theme.spacing(2) }}>
                            Searching for customers
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography style={{ marginLeft: theme.spacing(2) }}>
                            No results found
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </Box>
              }
              id="combo-box-demo"
              style={{ width: "100%" }}
              options={options}
              getOptionLabel={(option) => {
                if (option && option.firstName) {
                  return `${option.firstName} ${option.lastName} - E: ${option.email}`;
                }
                return "";
              }}
              filterOptions={(x) => x}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputVal(newInputValue);
              }}
              renderOption={(option, { inputValue }) => {
                const matches = match(
                  `${option.firstName} ${option.lastName}`,
                  inputValue
                );
                const parts = parse(
                  `${option.firstName} ${option.lastName}`,
                  matches
                );

                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <CustomerIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {part.text}
                        </span>
                      ))}
                      <Typography variant="body2" color="textSecondary">
                        {`${option.email}`}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  className={classes.textField}
                  {...params}
                  label="Customer"
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

CustomerInput.propTypes = {
  /**
   * Class name you wish to apply on root container of this component
   */
  className: PropTypes.string,
  /**
   * Callback that is invoked when a new customer is choosen
   * is passed the choosen Customer (if exists)
   */
  customerChange: PropTypes.func,
  /**
   * Starting value the select starts on; Customer objects
   * have more fields but only email is needed for this prop
   */
  customer: PropTypes.shape({
    email: PropTypes.string,
  }),
};

CustomerInput.defaultProps = {
  className: "",
  customerChange: () => console.log("selected customer changed"),
  customer: null,
};

export default CustomerInput;
