import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { IconButton, Tooltip, Typography, SvgIcon } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import { Users as UsersIcon } from "react-feather";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  likedButton: {
    color: red[600],
  },
  subscribersIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "initial",
    },
  },
  subscriberText: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "initial",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "60%",
    },
  },
}));

/**
 * Reactions component to be displayed under a post / any other
 * UI that can be interacted with by a user; renders any combination
 * of the 4 elements; like counter, rating , share button and
 * a user subscriber list
 */
function Reactions({
  className,
  likeObj,
  ratingObj,
  shareObj,
  userSubscribersObj,
  ...rest
}) {
  const classes = useStyles();
  const {
    likes: likesIn,
    liked: likedIn,
    onLike,
    order: likeOrder = "initial",
  } = likeObj || {};
  const {
    size = "small",
    rating = 3,
    order: ratingOrder = "initial",
  } = ratingObj || {};
  const { onShare, order: shareOrder = "initial" } = shareObj || {};
  const { subscribersString = "", order: subscriberOrder = "initial" } =
    userSubscribersObj || {};

  const [liked, setLiked] = useState(likedIn || false);
  const [likes, setLikes] = useState(likesIn || 0);

  const handleLike = () => {
    if (onLike) onLike(likes, true);
    setLiked(true);
    setLikes((prevLikes) => prevLikes + 1);
  };

  const handleUnlike = () => {
    if (onLike) onLike(likes, false);
    setLiked(false);
    setLikes((prevLikes) => prevLikes - 1);
  };

  const handleShare = (event) => {
    if (onShare) onShare(event);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {likeObj && (
        <div className={classes.container} stlye={{ order: likeOrder }}>
          {liked ? (
            <Tooltip title="Unlike">
              <IconButton
                className={classes.likedButton}
                onClick={handleUnlike}
              >
                <FavoriteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Like">
              <IconButton onClick={handleLike}>
                <FavoriteBorderIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Typography color="textSecondary" variant="h6">
            {likes}
          </Typography>
        </div>
      )}

      {userSubscribersObj && (
        <div className={classes.container} style={{ order: subscriberOrder }}>
          <SvgIcon
            fontSize="small"
            color="action"
            className={classes.subscribersIcon}
          >
            <UsersIcon />
          </SvgIcon>
          <Typography
            className={classes.subscriberText}
            variant="subtitle2"
            color="textSecondary"
          >
            {subscribersString}
          </Typography>
        </div>
      )}

      {shareObj && (
        <div style={{ order: shareOrder }}>
          <IconButton onClick={handleShare}>
            <ShareIcon fontSize="small" />
          </IconButton>
        </div>
      )}

      {ratingObj && (
        <div style={{ order: ratingOrder }}>
          <Rating value={rating} size={size} readOnly />
        </div>
      )}
    </div>
  );
}

Reactions.propTypes = {
  /**
   * Object describing configuration for the like counter;
   * onLike callback is passed 2 arguments; the total # of
   * likes before the button was pressed and a boolean
   * indicating if the current click is to like the post or dislike it
   */
  likeObj: PropTypes.shape({
    likes: PropTypes.number,
    liked: PropTypes.bool,
    onLike: PropTypes.func,
    order: PropTypes.number,
  }),
  /**
   * Object describing configuration for the rating counter;
   */
  ratingObj: PropTypes.shape({
    size: PropTypes.oneOf(["small", "medium", "large"]),
    rating: PropTypes.number,
    order: PropTypes.number,
  }),
  /**
   * Object describing configuartion for the share button;
   * onShare callback is passed 1 argument; the event object
   * propagated by the click on the share icon
   */
  shareObj: PropTypes.shape({
    onShare: PropTypes.func,
    order: PropTypes.number,
  }),
  /**
   * Object describing configuration for the subscribers
   * icon
   */
  userSubscribersObj: PropTypes.shape({
    subscribersString: PropTypes.string,
    order: PropTypes.number,
  }),
  /**
   * Class to be applied to the outmost container element
   */
  className: PropTypes.string,
};

Reactions.defaultProps = {
  className: "",
  likeObj: null,
  ratingObj: null,
  shareObj: null,
  userSubscribersObj: null,
};

export default Reactions;
