import React, { useRef, useState, useReducer } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import FormDialog from "src/components/FormDialog";
import INPUT_FIELD_TYPE from "src/constants/inputFieldType";
import { useFetch } from "src/hooks/useFetch";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "src/utils/axios";

const useStyles = makeStyles((theme) => ({
  /* Styles applied to the group's label elements. */
  groupLabel: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
  },
  /* Styles applied to the group's ul elements. */
  groupUl: {
    padding: 0,
    "& .option": {
      paddingLeft: 24,
    },
  },
  groupText: {
    color: "#546e7a",
    fontSize: "0.875rem",
    boxSizing: "border-box",
    listStyle: "none",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
  },
}));

/**
 * Component to render dialog that displays and edits all
 * the agent codes associated with a user
 */
const UserAgentsDialog = ({ onClose, onExited, selectedUser, open }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef(null);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [groupBy, setGroupBy] = useState(null);

  const {
    response: userAssignedAgentsResponse,
    isLoading: isUserAssignedAgentsResponseLoading,
    clearResponseCache,
  } = useFetch(open && `/users/${selectedUser?.id}/agents`);

  const { response: agentsResponse, isLoading: isAgentsResponseLoading } =
    useFetch(open && "/agents");

  const handleSave = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setSubmitting(true);
      await axios.put(`users/${selectedUser.id}/agents`, {
        agentCodes: values.agentCodes,
      });
      clearResponseCache();
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      enqueueSnackbar("User agents saved successfully", { variant: "success" });
      onClose();
    } catch (error) {
      setStatus({ success: false });
      setErrors({ submit: error.password });
      setSubmitting(false);
      enqueueSnackbar("Unable to save user agents", { variant: "error" });
    }
  };

  const agents = agentsResponse?.data?.agents || [];
  const agentCodeToAgentMap = agents?.reduce(
    (acc, agent) => ({ ...acc, [agent.code]: agent }),
    {}
  );

  return (
    <>
      <FormDialog
        isLoading={
          isUserAssignedAgentsResponseLoading || isAgentsResponseLoading
        }
        size="xs"
        open={open}
        onClose={onClose}
        onExited={(event) => {
          setGroupBy(null);
          onExited(event);
        }}
        title="Select Agent Codes"
        subTitle="Assign agent codes"
        formSettings={{
          onChange: () => {
            forceUpdate();
          },
          innerRef: ref,
          onSubmit: handleSave,
          fields: [
            {
              label: "Group By",
              name: "groupBy",
              type: INPUT_FIELD_TYPE.SELECT,
              options: [
                { value: "state", label: "State" },
                { value: "district", label: "District" },
                { value: "officeCode", label: "Office Code" },
              ],
              onChange: (event) => {
                const groupBy = event.target.value;
                ref.current.setValues({
                  ...ref.current.values,
                  groupBy,
                });
                setGroupBy(groupBy);
              },
            },
            {
              label: "Agent Codes",
              name: "agentCodes",
              type: INPUT_FIELD_TYPE.AUTOCOMPLETE_MULTI_SELECT,
              options: agents
                ?.sort((a, b) => {
                  if (!groupBy) {
                    return a["code"] - b["code"];
                  }
                  return -b[groupBy].localeCompare(a[groupBy]);
                })
                .map((agent) => agent.code),
              ...(groupBy && {
                groupBy: (option) => agentCodeToAgentMap[option][groupBy],
              }),
              getOptionLabel: (option) => {
                return `${option} - ${agentCodeToAgentMap[option].name}`;
              },
              filterOptions: createFilterOptions({
                stringify: (str) => {
                  return `${str} ${agentCodeToAgentMap[str].name} ${agentCodeToAgentMap[str][groupBy]}`;
                },
              }),
              renderOption: (option) => {
                return (
                  <Typography noWrap>
                    {option} - {agentCodeToAgentMap[option].name}
                  </Typography>
                );
              },
              renderGroup: (params) => {
                const areAllAgentsInGroupSelected = (group) => {
                  const selectedAgentCodes = new Set(
                    ref.current.values.agentCodes
                  );
                  const stateAgentCodes = agents
                    ?.filter((agent) => agent[groupBy] === group)
                    .map((val) => val.code);
                  return stateAgentCodes.every((agentCode) =>
                    selectedAgentCodes.has(agentCode)
                  );
                };

                const handleClickGroup = (group) => {
                  const selectedAgentCodes = ref.current.values.agentCodes;

                  let agentCodes = selectedAgentCodes.filter(
                    (agent) => agentCodeToAgentMap[agent][groupBy] !== group
                  );
                  if (!areAllAgentsInGroupSelected(group)) {
                    // check all agent codes in group
                    agentCodes = [
                      ...agentCodes,
                      ...agents
                        ?.filter((agent) => agent[groupBy] === group)
                        .map((agent) => agent.code),
                    ];
                  }

                  ref.current.setValues({
                    ...ref.current.values,
                    agentCodes,
                  });
                };

                return (
                  <li key={params.key}>
                    <ListSubheader
                      className={classes.groupLabel}
                      component="div"
                    >
                      <FormControlLabel
                        onClick={(event) => {
                          event.preventDefault();
                          handleClickGroup(params.group);
                        }}
                        control={
                          <Checkbox
                            checked={areAllAgentsInGroupSelected(params.group)}
                            name={`toggle${params.group}`}
                            color="primary"
                            size="small"
                          />
                        }
                        label={
                          <Typography className={classes.groupText} noWrap>
                            {params.group}
                          </Typography>
                        }
                      />
                    </ListSubheader>
                    <ul className={classes.groupUl}>{params.children}</ul>
                  </li>
                );
              },
            },
          ],
          initialValues: {
            agentCodes: userAssignedAgentsResponse?.data?.agents.map(
              (agent) => agent.code
            ),
          },
          submitButton: {
            text: "Save",
          },
        }}
      />
    </>
  );
};

UserAgentsDialog.propTypes = {
  /**
   * Weather or not the dialog is open
   */
  open: PropTypes.bool.isRequired,

  /**
   * Callback to run when the dialog cancel button is clicked;
   * takes no arguments
   */
  onClose: PropTypes.func,

  /**
   * Callback to run when the dialog is completely exited off
   * the screen; takes no arguments
   */
  onExited: PropTypes.func,

  /**
   * Selected user object representing the user whose
   * agents you wish to fetch
   */
  selectedUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    createdAt: PropTypes.string,
    admin: PropTypes.bool,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
};

UserAgentsDialog.defaultProps = {
  onClose: () => {},
  onExited: () => {},
  selectedUser: {},
};

export default UserAgentsDialog;
