export default {
  "actions": "Comportamiento",
  "agentCodes": "Códigos de Agente",
  "allRoles": "Todos los Roles",
  "admin": "Administrador",
  "avatar": "Avatar",
  "name.firstName": "Primer Nombre",
  "name.lastName": "Apellido",
  "email": "Correo Electrónico",
  "edit": "Editar",
  "roles": "Roles",
  "dateAdded": "Fecha Agregada",
  "userRoles": "Roles del Usuario",
  "sendEmail": "Enviar Correo Electrónico",
  "resetPassword": "Restablecer la Contraseña",
  "role": "el oficio",
  "users": "Usuarios",
  "user": "Usuario",
  "search": "Buscar",
  "searchUsers": "Buscar usuarios",
  "createdAt": "Creado en",
  "required": "Requerido",
  "emailAddress": "Dirección de Correo Electrónico",
  "password": "Contraseña"
};