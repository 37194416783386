import { contactMethodsService } from 'src/services';
import {
  phoneTypesService,
  addressTypesService,
  serviceService
} from 'src/services';

export const GET_PHONE_TYPES = '@mail/get-phone-types';
export const GET_ADDRESS_TYPES = '@mail/get-address-types';
export const GET_CONTACT_METHODS = '@mail/get-contact-methods';
export const GET_SERVICES = '@mail/get-services';

export function getPhoneTypes() {
  const request = phoneTypesService.getPhoneTypes();

  return dispatch => {
    request.then(response => {
      return dispatch({
        type: GET_PHONE_TYPES,
        payload: response
      });
    });
  };
}

export function getAddressTypes() {
  const request = addressTypesService.getAddressTypes();

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_ADDRESS_TYPES,
        payload: response
      })
    );
  };
}

export function getServices() {
  const request = serviceService.getServices();

  return dispatch => {
    request.then(response => {
      const servicesWithServiceTypeField = response?.map(({serviceKey, ...rest}) => ({serviceType: serviceKey, ...rest}))
      return dispatch({
        type: GET_SERVICES,
        payload: servicesWithServiceTypeField,
      })
  });
  };
}

export function getContactMethods() {
  const request = contactMethodsService.getContactMethods();

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_CONTACT_METHODS,
        payload: response
      })
    );
  };
}
