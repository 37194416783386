import React, { useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import LoadingButton from "./LoadingButton";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  docviewer: {
    fontSize: theme.typography.body1,
    color: theme.palette.text.primary,
    height: "100%",
    minHeight: "450px",
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
  },
}));

/**
 * Component to display most file extensions directly
 * in the browser with viwer controls
 * (https://www.npmjs.com/package/react-doc-viewer).
 * Can render an upload button that accepts an aynchronous
 * callback that accepts the array of docs as an argument
 * SUPPORTED FILES OF TYPE:
 * bmp, doc, docx, htm, html, jpg, jpeg, pdf (use PDF viewer for better experience),
 * png, ppt, pptx, tiff, txt, xls, xlsx
 *
 * FIX: When using this with AzureBlobStorage the HEAD request it
 * sends (as opposed to a GET request used by PDF-Viewer)
 *
 */
function MiscDocumentViewer({
  docs,
  className,
  withUploadButton,
  onUpload,
  viewerConfig,
}) {
  const classes = useStyles();
  const [documentUploadPending, setDocumentUploadPending] = useState(false);

  const onUploadButtonClick = async (event) => {
    if (!onUpload) return;
    setDocumentUploadPending(true);
    const response = await onUpload(docs);
    setDocumentUploadPending(false);
  };
  return (
    <Box className={classes.root}>
      {withUploadButton && (
        <LoadingButton
          onClick={onUploadButtonClick}
          loading={documentUploadPending}
          startIcon={<CloudUploadIcon />}
        >
          Upload Docs
        </LoadingButton>
      )}
      <DocViewer
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
          },
          ...viewerConfig,
        }}
        className={clsx(className, classes.docviewer)}
        pluginRenderers={DocViewerRenderers}
        documents={docs}
      />
    </Box>
  );
}

MiscDocumentViewer.propTypes = {
  /**
   * Array of documents with a uri property (local or hosted)
   */
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Class name to be applied directly to the document viewer
   */
  className: PropTypes.string,
  /**
   * Weather or not to render the upload button
   */
  withUploadButton: PropTypes.bool,
  /**
   * Callback that is called when the upload button is clicked.
   * It receives 1 argument; the array of docs. This function
   * is awaited so it is expected to be asynchronous
   */
  onUpload: PropTypes.func,

  /**
   * Config to hide or show file names and headers generated by the
   * document viewer
   * https://www.npmjs.com/package/react-doc-viewer#iheaderconfig
   */
  viewerConfig: PropTypes.shape({
    header: PropTypes.shape({
      disableHeader: PropTypes.bool,
      disableFileName: PropTypes.bool,
      retainURLParams: PropTypes.bool,
      overrideComponent: PropTypes.bool,
    }),
  }),
};

MiscDocumentViewer.defaultProps = {
  docs: null,
  className: "",
  withUploadButton: false,
  onUpload: null,
  viewerConfig: null,
};

export default MiscDocumentViewer;
