import find from 'lodash/find';
import { parseCookie } from 'src/utils/cookie';
import jwtDecode from 'jwt-decode';
import TENANT from 'src/constants/tenant';
import { isNationSafeDriversEmail } from 'src/utils/email';

// Azure B2C AD User Flow names
const EXTERNAL_USER_FLOW_NAME = 'B2C_1_SignIn';
const TURO_USER_FLOW_NAME = 'B2C_1_TURO_SIGNUP_SIGNIN';

// Login URIs
const turoLoginUrl = `https://nationsafedriversconsumers.b2clogin.com/nationsafedriversconsumers.onmicrosoft.com/${TURO_USER_FLOW_NAME}`;
const externalLoginUrl = `https://nationsafedriversconsumers.b2clogin.com/nationsafedriversconsumers.onmicrosoft.com/${EXTERNAL_USER_FLOW_NAME}`;
const internalLoginUrl = `https://login.microsoftonline.com/${TENANT.AD}`;

// Logout URIs
const internalLogoutUrl = `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${encodeURIComponent(
  `${window.origin}/login`
)}`;
const externalLogoutUrl = `https://nationsafedriversconsumers.b2clogin.com/nationsafedriversconsumers.onmicrosoft.com/${EXTERNAL_USER_FLOW_NAME}/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(
  window.origin
)}`;

const turoLogoutUrl = `https://nationsafedriversconsumers.b2clogin.com/nationsafedriversconsumers.onmicrosoft.com/${TURO_USER_FLOW_NAME}/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(
  window.origin
)}`;

export const getCompanyCode = () => {
  const { 'nsd.token': token } = parseCookie(document.cookie);
  if (!token) {
    return null;
  }

  const decoded = jwtDecode(token);
  const companyCode = find(
    decoded.userCompanyCodes,
    (company) =>
      company.includes(process.env.REACT_APP_COMPANY_CODE) ||
      company.includes('nsd')
  );
  return companyCode;
};

/**
 * @typedef {Object} AuthConfig
 * @property {string} loginUrl - The login URI of the current user depending on the domain name of the current site
 * @property {string} logoutUrl - The logout URI of the current user depending on the domain name of the current site
 * @property {('internal'| 'external' | 'turo')} loginType - An additional piece of information send along with POST /login to help common-services determine
 * which endpoint to send the authorization code to
 */

/**
 * Reads the known URIs associated with a client that has SSO integration off an
 * environment variable to determine what authorization endpoints/flow should be
 * used the OAuth 2.0 code authorization flow
 *
 * (Split on ',' char)
 * VW_ASSOCIATED_CLIENT_URIS=https://audi-dev.nationsafedrivers.com,https://audi-stg.nationsafedrivers.com, etc...
 * @param {email} string - No email means that input element was not rendered on the loginForm component, this
 * only occurs on internal login flows (NSD users)
 * @returns {AuthConfig} authConfig
 */
export const getLoginFlowEndpoints = (email) => {
  const isNSDUser = isNationSafeDriversEmail(email);
  if (!email || isNSDUser)
    return {
      loginUrl: internalLoginUrl,
      logoutUrl: internalLogoutUrl,
      loginType: 'internal',
    };

  const currentDomain = window.origin;
  const authConfig = {};
  const turoDomains =
    process.env.REACT_APP_TURO_ASSOCIATED_CLIENT_URIS?.split(',') || [];

  const isTuroDomain = turoDomains.includes(currentDomain);

  if (isTuroDomain) {
    authConfig.loginUrl = turoLoginUrl;
    authConfig.logoutUrl = turoLogoutUrl;
    authConfig.loginType = 'turo';
  } else {
    authConfig.loginUrl = externalLoginUrl;
    authConfig.logoutUrl = externalLogoutUrl;
    authConfig.loginType = 'external';
  }
  return authConfig;
};
