import React from "react";
import PropTypes from 'prop-types';
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import PORTAL_TYPE from "src/constants/portalType";

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer'
  }
}));

function AvatarWrapper({ portalType, isNSD, children }) {
  const classes = useStyles();

  if (portalType === PORTAL_TYPE.EMPLOYEE || isNSD) {
    return (
      <RouterLink
        className={classes.link}
        to={{
          pathname: 'https://nationsafedrivers-my.sharepoint.com/person.aspx'
        }}
        target="_blank"
      >
        {children}
      </RouterLink>
    )
  }

  return <React.Fragment>{children}</React.Fragment>
}

AvatarWrapper.propTypes = {
  portalType: PropTypes.oneOf([
    PORTAL_TYPE.CLIENT, 
    PORTAL_TYPE.EMPLOYEE
  ]).isRequired,
  isNSD: PropTypes.bool.isRequired,
  children: PropTypes.elementType
};

export default AvatarWrapper;
