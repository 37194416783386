import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
}));

/**
 * Component to display a interactive Google Map that fits to its
 * parent elements dimensions. Children of this component are rendered as
 * markers on the map (use position absolute)
 */
const GoogleMap = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};

GoogleMap.propTypes = {
  /**
   * The children elements to be rendered over the google map;
   * use position absolute and z-index to display elements
   */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
