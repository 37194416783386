import { axios, authAxios } from '@nsd/fe';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from 'src/reducers';
import { getClientCompanyCode } from 'src/utils/clientUtils';

const ENABLE_REDUX_LOGGER = false;
const loggerMiddleware = createLogger();

// // Add axios middleware. Used to add agent code header
const axiosMiddleware = () => {
  return next => action => {
    const companyCode = getClientCompanyCode();

    axios.defaults.headers.common['Company-Code'] = companyCode;
    axios.defaults.headers.common['Host-Site'] = companyCode;
    authAxios.defaults.headers.common['Company-Code'] = companyCode;
    authAxios.defaults.headers.common['Host-Site'] = companyCode;


    return next(action);
  };
};

export function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware, axiosMiddleware];

  if (ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware);
  }

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  return createStore(rootReducer, preloadedState, composedEnhancers);
}

export const store = configureStore();
