import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PropTypes from "prop-types";
import map from "lodash/map";
import clientService from "../services/clientService";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * Mutli select input with autocomplete (as user types); fetches
 * a list and displays a list of clients with checkboxes next to
 * the options. When the select is updated updateClientList
 * is invoked with the list of selected client ids as the argument
 */
function ClientMutliSelect({
  className,
  updateClientList,
  selectedList,
  placeholder,
  label,
  ...rest
}) {
  const [clients, setClients] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    clientService.getClients().then((clientList) => {
      setClients(clientList);
    });
  }, []);

  useEffect(() => {
    if (updateClientList) {
      updateClientList(map(value, "id"));
    }
  }, [value]);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={clients}
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      className={className}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </>
      )}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
      {...rest}
    />
  );
}

ClientMutliSelect.propTypes = {
  /**
   * Class name to apply to the outer most container element
   */
  className: PropTypes.string,

  /**
   * Callback that is invoked when the list of selected clients
   * is changed. It is passed 1 argument; an id of string arrays
   * corresponding to the selected clients in the dropdown
   */
  updateClientList: PropTypes.func,

  /**
   * Array of client objects to pass in as a prop if you
   * want to have preselcted values.
   * See ../../services/clientService.js for object shape
   */
  selectedList: PropTypes.arrayOf(PropTypes.object),
  /**
   * Placeholder for rendered input
   */
  placeholder: PropTypes.string,
  /**
   * Label for rendered input
   */
  label: PropTypes.string,
};

ClientMutliSelect.defaultProps = {
  className: "",
  updateClientList: (selectedClients) => {},
  selectedList: null,
};

export default ClientMutliSelect;
