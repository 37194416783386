export default {
  "operational": "Operacional",
  "dispatches": "Despachos",
  "dispatchCount": "Recuento de Despacho",
  "dispatchCount_joined": "RecuentoDeDespacho",
  "totalDispatchesCompletedByDayMonth": "Despachos Totales Completados por Día/Mes",
  "completedImpoundTowsByWeekOfMonth": "Remolques de Depósito Completados por Semana del Mes",
  "allImpoundTowsMustBeCompletedToBeCounted": "Todos los remolques incautados deben completarse para ser contados.",
  "completedImpoundAverageMilesPerTowPerDayByWeekOfMonth": "Consigna Completa Millas Promedio por Remolque por Día por Semana del Mes",
  "impoundTows": "Incautar Remolques",
  "avgETAByDayMonthCompleted": "Promedio de Hora Estimada de Llegada (EDL) por Día/Mes (Completado)",
  "avgMilesPerTowByDayMonthCompleted": "Promedio de Millas por Remolque (MPR) por Día/Mes (completado)",
  "completedLongTowsAsAPercentageOfTotalTows": "Dedos Largos Completados como % del Total de Remolques (Remolques> = 100 Millas)",
  "percentIsCalulatedByTwoWayTowsPlusTotalDispatches": "El porcentaje se calcula mediante remolques bidireccionales ÷ despachos totales.",
  "aTowWithLessThanOrEqualTo100MilesIsALongTow": "Un remolque con> = 100 millas es un remolque largo.",
  "percentIsCalculatedByLongTowsPlusTotalTows": "El porcentaje se calcula por remolques largos ÷ remolques totales.",
  "milesPerImpoundTow": "Millas/Remolque Incautado",
  "averageMilesCompletedImpoundTowAreShown": "Se muestra el promedio de millas/remolques confiscados completados.",
  "completedTwoWayTowsAsAPercentageOfTotalDispatches": "Remolques Bidireccionales Completados (RBC) como % de los Despachos Totales",
};