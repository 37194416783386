import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import find from "lodash/find";
import PropTypes from "prop-types";
import CircularLoader from "./CircularLoader";
import Popper from "@material-ui/core/Popper";
import useScript from "../hooks/useScript";

const NAVIGATOR_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  popper: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    placeItems: "center",
    background: theme.palette.background.default,
    width: "100%",
    marginTop: theme.spacing(5),
  },
  loaderText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

/**
 * A dropdown / autocomplete component for autocompleting addresses via a
 * Google Maps service API and window.navigator
 */
function GoogleAddressInput({
  locationChange,
  className,
  currentLocation,
  detectLocation,
  ...rest
}) {
  const classes = useStyles();
  const [status] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
  );
  const [value, setValue] = useState(currentLocation || null);
  const [inputValue, setInputValue] = useState(currentLocation || "");
  const [options, setOptions] = useState([]);
  const autocompleteService = useRef(null);
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false);
  const id = open ? "location-popover" : undefined;
  const popoverAnchor = useRef(null);
  const setAutocompleteRef = useCallback((service) => {
    if (service) autocompleteService.current = service;
  }, []);
  const loaded = status === "ready";

  // Uses coordinates to get exact starting location
  useEffect(() => {
    if (!loaded) return undefined;
    if (
      typeof window !== "undefined" &&
      "navigator" in window &&
      window.google &&
      !currentLocation &&
      detectLocation
    ) {
      setLoadingCurrentLocation(true);
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          let geocoder = new window.google.maps.Geocoder();
          const formattedCoordinates = new window.google.maps.LatLng(
            latitude,
            longitude
          );
          geocoder.geocode(
            {
              location: formattedCoordinates,
            },
            (resultArr) => {
              if (!resultArr.length) return;
              const { formatted_address, ...rest } = resultArr[0];

              const locationObj = {
                ...rest,
                description: formatted_address,
                structured_formatting: {
                  main_text: formatted_address,
                  main_text_matched_substrings: [
                    {
                      length: formatted_address.length,
                      offset: 0,
                    },
                  ],
                },
              };
              setValue(locationObj);
              setLoadingCurrentLocation(false);
            }
          );
        },
        (err) => console.error(err),
        NAVIGATOR_OPTIONS
      );
    }
  }, [loaded]);

  useEffect(() => {
    if (!currentLocation) {
      setValue(null);
      setInputValue("");
    }
  }, [currentLocation]);

  /* 
     useMemo calls fn and returns result memoized
     useCallback doesn't call fn just memoizes definition
  */
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    const location = find(options, { description: inputValue });
    if (location && locationChange) {
      locationChange(location);
    }
  }, [inputValue]);

  useEffect(() => {
    let active = true;
    setLoadingCurrentLocation(false);
    if (
      !autocompleteService.current &&
      window.google &&
      window.google.maps &&
      window.google.maps.places
    ) {
      setAutocompleteRef(new window.google.maps.places.AutocompleteService());
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  if (!process.env.REACT_APP_GOOGLE_API_KEY) return null;
  return (
    <div className={classes.root}>
      <Autocomplete
        disabled={loadingCurrentLocation}
        className={className}
        style={{ width: "100%" }}
        id="google-map-demo"
        aria-describedby={id}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        ref={popoverAnchor}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add a location"
            variant="outlined"
            fullWidth
          />
        )}
        renderOption={(option) => {
          if (typeof option !== "object") return null;
          const matches =
            option?.structured_formatting?.main_text_matched_substrings;

          const parts = parse(
            option?.structured_formatting?.main_text,
            matches?.map((match) => [
              match?.offset,
              match?.offset + match?.length,
            ])
          );
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts?.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <Popper
        id={id}
        open={loadingCurrentLocation}
        anchorEl={popoverAnchor.current}
        onClose={() => setLoadingCurrentLocation(false)}
        className={classes.popper}
        disablePortal
      >
        <Typography className={classes.loaderText} variant="h4">
          Detecting current location...
        </Typography>
        <CircularLoader size={40} />
      </Popper>
    </div>
  );
}

GoogleAddressInput.propTypes = {
  /**
   * Class name you wish to apply to Autocomplete form/dropdown
   */
  className: PropTypes.string,
  /**
   * Current location of the user as a string (no special format e.g Chicago, IL or New York).
   * If nothing is passed for this prop and detectLocation is true,
   *  the component will fetch your location
   *
   */
  currentLocation: PropTypes.string,
  /**
   * Boolean indicating weather or not to detect the current
   * users location and pre-fill the input; currentLocation
   * must also be null in order for this work
   */
  detectLocation: PropTypes.bool,
  /**
   * Callback to fire when the user types enters text in the input area
   */
  locationChange: PropTypes.func,
};

GoogleAddressInput.defaultProps = {
  className: "",
  currentLocation: "",
  locationChange: null,
  detectLocation: false,
};

export default GoogleAddressInput;
