import { useState, useEffect } from "react";

const getOnLineStatus = () =>
  typeof window.navigator !== "undefined" &&
  typeof window.navigator.onLine === "boolean"
    ? window.navigator.onLine
    : true;

/* 
    Simple hook using window.navigator to determine the
    online status
*/
const useOnline = () => {
  const [status, setStatus] = useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return status;
};

export default useOnline;
