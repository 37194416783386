import LOCAL_STORAGE_NAMESPACE from 'src/constants/localStorage/namespace';
import DEV_LOCAL_STORAGE_KEY from 'src/constants/localStorage/dev';
import {
  clearAllLocalStorageNamespace,
  saveToLocalStorage
} from './localStorageCache';

export const getFromUrlQueryParams = (key, namespace = null) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(namespace ? `${namespace}.${key}` : key);
};

// load all dev query params into local storage
// (example ?mock gets saved in local storage as dev.mock = true)
export const loadDevQueryParamsIntoLocalStorage = () => {
  const searchParams = new URLSearchParams(window.location.search);

  let clearAppData = false;
  searchParams.forEach((val, key) => {
    if (key.startsWith(`${LOCAL_STORAGE_NAMESPACE.DEV}.`)) {
      clearAppData = true;
    }
  });

  if (clearAppData === true) {
    clearAllLocalStorageNamespace(LOCAL_STORAGE_NAMESPACE.APP_DATA);
    window.history.replaceState(
      {},
      '',
      window.location.origin + window.location.pathname
    );
    window.location.replace('/');
  }

  searchParams.forEach((val, key) => {
    if (key.startsWith(`${LOCAL_STORAGE_NAMESPACE.DEV}.`)) {
      saveToLocalStorage(key, val);
    }
  });

  if (
    searchParams.get(
      `${LOCAL_STORAGE_NAMESPACE.DEV}.${DEV_LOCAL_STORAGE_KEY.CLEAR}`
    ) !== null
  ) {
    clearAllLocalStorageNamespace(LOCAL_STORAGE_NAMESPACE.DEV);
  }
};
