import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import LockIcon from "@material-ui/icons/Lock";
import Page from "src/components/Page";
import Logo from "src/components/Logo";
import LoginForm from "./LoginForm";
const QORE_PRODUCT_WALLPAPER_URL = 'https://nsdstaticasset.blob.core.windows.net/assets/Qore_Website_Image.png';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    height: 553,
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

/**
 * The main component for logging a user into the application. Accepts
 * a type prop (client or employee to determine) if to enable login through
 * a microsoft login service or through Azure AD. Link or links can be used
 * to pass in additional links (with their corresponding views) to the LoginView.
 * (e.g. Want to add a register or forgot password link)
 */
function LoginView({
  type,
  links,
  onSubmitSuccess,
  logo,
  logoAlt,
  formJSX,
  width,
  height,
  className,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { alt, src: clientCompanyLogo } = theme?.logo?.login || {};

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth="md">
        <Box mb={8} display="flex" alignItems="center">
          <RouterLink to="/">
            {clientCompanyLogo && !logo ? (
              <Logo
                altText={alt}
                image={clientCompanyLogo}
                width={width}
                height={height}
                className={className}
              />
            ) : (
              <Logo
                mainIcon
                image={logo}
                altText={logoAlt}
                width={width}
                height={height}
                className={className}
              />
            )}
          </RouterLink>
          {/* Doesn't make sense here since it will send back to /login */}
          {/* <Button component={RouterLink} to="/" className={classes.backButton}>
            Back to home
          </Button> */}
        </Box>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Typography variant="h2" color="textPrimary">
              Sign in
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Sign in on the internal platform
            </Typography>
            <Box mt={2}>
              <Alert severity="info">
                <div>
                  Use your <b>email</b> and <b>password</b>
                </div>
              </Alert>
            </Box>
            <Box mt={3}>
              <LoginForm type={type} onSubmitSuccess={onSubmitSuccess} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            {links?.map((link, linkIdx) => (
              <React.Fragment key={`${link}-${linkIdx}`}>
                <Link
                  component={RouterLink}
                  to={link.to}
                  variant="body2"
                  color="textSecondary"
                >
                  {link.text}
                </Link>
                <Box flexGrow={1} />
              </React.Fragment>
            ))}
          </CardContent>
          {formJSX ? (
            formJSX
          ) : (
            <CardMedia
              className={classes.media}
              image={QORE_PRODUCT_WALLPAPER_URL}
              title="Cover"
            >
            </CardMedia>
          )}
        </Card>
      </Container>
    </Page>
  );
}

LoginView.propTypes = {
  /**
   * Which login form to create (employees login in through MS service, clients through Azure AD)
   */
  type: PropTypes.oneOf(["client", "employee"]).isRequired,
  /**
   * Additional links you wish to render on the login form; make sure you have created
   * the linked routes (if leading to a relative component) first in the appConfig
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  /**
   * Callback that runs after a user successfully logs in;
   * TODO: Currently cannot access hook functions here as they are
   * not run/evaluated in the context of react
   */
  onSubmitSuccess: PropTypes.func,
  /**
   * Sets the logo that appears on the login screen. By default should look into the
   * current theme applied and attempt to pull the logo out of that
   */
  logo: PropTypes.string,
  /**
   * Alt text for the logo image passed in
   */
  logoAlt: PropTypes.string,
  /**
   * Sets the JSX that appears next to the login form (image or text related to client company) to allow
   * further customization. If not will default to NSD stlying. Corresponds to the <CardMedia> component from material
   * UI. Accepts an image
   * https://v4.mui.com/api/card-media/
   */
  formJSX: PropTypes.element,
};

LoginView.defaultProps = {
  type: "",
  links: null,
  onSubmitSuccess: () => {},
  logo: null,
  logoAlt: null,
  formJSX: null,
};

export default LoginView;
