import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import FormDialog from 'src/components/FormDialog';
import { upperCaseFirstLetter, plural } from 'src/utils/string';
import { useFetch } from 'src/hooks/useFetch';
import { INPUT_FIELD_TYPE_VALUES } from 'src/constants/inputFieldType';

/**
 * Dialog component that primarily used witin the CrubTablePage;
 * creates a dialog form that allows you to update and or
 * create any specified resource (roles, users, companies etc..)
 * Renders a Form dialog with Formik and validation
 */
const CreateUpdateDialog = ({
  onClose,
  onSubmit, // Optional
  onExited,
  open,
  fields,
  model,
  url,
  params, // Need
  validationSchema,
  selected,
  size,
  title,
  subTitle,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const upperCaseModel = upperCaseFirstLetter(model);
  const pluralizedModel = plural(model);
  // Converts to truthy or falsey
  const verb = !!selected ? 'update' : 'create';
  const upperCaseVerb = upperCaseFirstLetter(verb);
  const { response: rolesResponse, isLoading: isRolesResponseLoading } =
    useFetch(open && model === 'user' && verb === 'create' && '/roles');

  const handleUpsert = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let method = 'post';
      let reqUrl = url;
      let vals = values;
      if (!!selected) {
        const { id, ...valuesWithoutId } = values;
        method = 'put';
        id ? (reqUrl = `${url}/${id}`) : (reqUrl = `${url}/${resourceId}`);
        vals = valuesWithoutId;
      }
      // vals var will already include roleIds array needed on the 'user' model,
      // POST /users to common-services is now modified to create a user and attach roles with 1 network request
      const { data } = await axios[method](
        reqUrl,
        { [model]: vals },
        { params }
      );
      setSubmitting(true);
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      enqueueSnackbar(`${upperCaseModel} ${verb}d successfully`, {
        variant: 'success',
      });

      /* 
        For all POST operations the key is the pluralized version;
        e.g. POST (/suites will return a payload like {suites: {}})
        Add check here for the method used, if POST look for pluralized version.
        PUT operations don't return a payload (204 code sent back) but you can
        send back the updated form values to sync to local state 
        Caveat: 
        POST or PUT /users returns the created user payload under a singular key 
        (e.g. "user" as opposed to "users")
      */
      if (model === 'user' && method === 'post') {
        onSubmit && (await onSubmit(data?.[model]));
      } else {
        method === 'post' && data?.[pluralizedModel]
          ? await onSubmit(data?.[pluralizedModel])
          : await onSubmit(values);
      }
      onClose();
    } catch (error) {
      console.error({ error });
      setStatus({ success: false });
      setErrors({ submit: error.password });
      setSubmitting(false);
      enqueueSnackbar(
        `Unable to ${verb} ${model}: \n
      ${error?.response?.data?.message ?? ''}`,
        { variant: 'error' }
      );
    }
  };

  return (
    <FormDialog
      size={size}
      open={open}
      onClose={onClose}
      onExited={onExited}
      title={title ?? `${upperCaseVerb} ${upperCaseModel}`}
      subTitle={subTitle ?? `Enter the details below to ${verb} ${model}`}
      formSettings={{
        onSubmit: handleUpsert,
        validationSchema,
        fields,
        initialValues: selected,
        submitButton: {
          text: `${upperCaseVerb} ${upperCaseModel}`,
        },
      }}
    />
  );
};

CreateUpdateDialog.propTypes = {
  /**
   * Callback that triggers when the form Dialog component is
   * closed; it is not passed any arguments
   */
  onClose: PropTypes.func,
  /**
   * Callback that triggers when the form Dialog is submitted
   * successfully. It is passed 1 argument; the create or updated
   * resource that was retrieved from the network response. Usually
   * an async function so it is awaited on after the network request is successfully
   * completed
   */
  onSubmit: PropTypes.func,
  /**
   * Callback that triggers after the Dialog has been closed
   * and is finished exiting; it is not passed any arguments
   */
  onExited: PropTypes.func,
  /**
   * Weather or not the dialog is visible
   */
  open: PropTypes.bool.isRequired,
  /**
   *
   */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      validation: PropTypes.object,
      type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
      size: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
      }),
    })
  ),
  /**
   * Which model you are operating the update/create dialog
   * for (e.g. role, suite, module, user)
   */
  model: PropTypes.string,
  /**
   * Base url to use to make the update and create requests
   */
  url: PropTypes.string,
  /**
   * Additional parameters you wish to pass along with the
   * create or update request
   */
  params: PropTypes.object,
  /**
   * If the update verb is used you need a higher component in
   * the tree (usually the parent of CrudTablePage) that has a
   * selected state to indicate when a specified resource
   * is clicked/focused on. Only when this is not null
   * will the update URL be constructed (need id to
   * update one resource)
   */
  selected: PropTypes.object,
  /**
   * Size of the Form Dialog component that is rendered
   */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
};

CreateUpdateDialog.defaultProps = {
  onClose: () => { },
  onExited: () => { },
  onSubmit: () => { },
};

export default CreateUpdateDialog;
