const CdnAppIcon =
  "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/liveChatAppIcon.png";

const appConfig = {
  appId: "liveChat-d59fad9e-e810-45ba-99ab-ad2b5c5cdc1d",
  appName: "Live Chat",
  site: "livechat-qore",
  icon: CdnAppIcon,
};

export default appConfig;
