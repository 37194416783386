import React, { useCallback, useEffect, useMemo, useState } from "react";
import i18next from "i18next";
import { Button, Tooltip } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import useDefaultLanguage from "src/hooks/useDefaultLanguage";
import clsx from "clsx";
import PropTypes from "prop-types";

const usFlag =
  "https://nsdstaticasset.blob.core.windows.net/assets/Nsd_FE_Assets/us.svg";
const mxFlag =
  "https://nsdstaticasset.blob.core.windows.net/assets/Nsd_FE_Assets/mx.svg";
const caFlag =
  "https://nsdstaticasset.blob.core.windows.net/assets/Nsd_FE_Assets/ca.svg";

/**
 * @see https://github.com/i18next/i18next-browser-languageDetector
 */
const FALLBACK_LANGUAGE_CODE = "en-US";
const useStyles = makeStyles((theme) => ({
  container: {
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  button: {
    fontWeight: "bold",
    textTransform: "capitalize",
    borderRadius: "50%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.tertiary.main,
    padding: 0,
    minWidth: 0,
    width: "2rem",
    height: "2rem",
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      transition: "backgroundColor 0.5 ease",
    },
    "&:last-child": {
      marginRight: 0,
    },
    "&:disabled": {
      backgroundColor: grey[400],
    },
    borderColor: "transparent",
  },
  flag: {
    width: "1.5rem",
    height: "1.5rem",
  },
  center: {
    justifyContent: "center",
    display: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));

/**
 * Component to be placed in the TopBar/settings to enable
 * internationalization and language changes. Currently checks
 * for default language code through the useDefaultLanguage hook
 * (looks in localStorage).
 *
 * NOTE: If using this component please instantiate your own instance/setup
 * of i18n with your own JSON files higher up the tree until the internationalization
 * strategy is decided (don't use usei18n hook as it has no translations
 * in it yet)
 * https://react.i18next.com/getting-started
 */
function LanguageSelector({
  languageCodes = ["en-US", "fr", "es"],
  className,
  ...rest
}) {
  const classes = useStyles();
  const { getCachedLanguageCode, setCachedLanguageCode } = useDefaultLanguage();
  const DEFAULT_LANGUAGE_CODE = useMemo(() => getCachedLanguageCode(), []);

  const [selectedLanguageCode, _setSelectedLanguageCode] = useState(
    DEFAULT_LANGUAGE_CODE
  );

  /**
   * Shaves off any language code variants (i.e. en-US) and uses the main
   * version (i.e. "en").
   *
   * @param {string} languageCode
   * @return {string} Altered version without "-variant" suffix
   */
  const getNormalizedLanguageCode = useCallback(
    (languageCode) => languageCode.split("-")[0],
    []
  );

  const handleSetSelectedLanguageCode = useCallback((languageCode) => {
    _setSelectedLanguageCode(languageCode);
  }, []);

  const getLanguageFlag = useCallback((languageCode) => {
    switch (getNormalizedLanguageCode(languageCode)) {
      case "en":
        return { src: usFlag, alt: "English (USA)" };
      case "es":
        return { src: mxFlag, alt: "Spanish (Mexico)" };

      case "fr":
        return { src: caFlag, alt: "French (Canada)" };
      default:
        break;
    }
  }, []);

  const { i18n } = useTranslation();

  // Automatically change language based on selected language code
  useEffect(() => {
    if (selectedLanguageCode !== i18next.language) {
      i18n.changeLanguage(selectedLanguageCode);
    }

    setCachedLanguageCode(selectedLanguageCode);

    // Reload the page if the language changes
    //
    // NOTE: This fixes an issue where certain page elements (i.e. left
    // sidebar) didn't get dynamically translated
    if (DEFAULT_LANGUAGE_CODE !== selectedLanguageCode)
      window.location.reload();
  }, [i18n, selectedLanguageCode, DEFAULT_LANGUAGE_CODE]);

  return (
    <div {...rest} className={clsx(classes.container, className)}>
      {languageCodes.map((languageCode, idx) => {
        const flagConfig = getLanguageFlag(languageCode);
        return (
          <React.Fragment key={languageCode}>
            <Tooltip
              title={
                <div className={classes.center}>
                  <img
                    className={classes.flag}
                    src={flagConfig.src}
                    alt={flagConfig.alt}
                  />{" "}
                  <p>{flagConfig.alt}</p>
                </div>
              }
              arrow
            >
              <span>
                <Button
                  className={classes.button}
                  onClick={() => handleSetSelectedLanguageCode(languageCode)}
                  disabled={selectedLanguageCode === languageCode}
                >
                  {getNormalizedLanguageCode(languageCode)}
                </Button>
              </span>
            </Tooltip>
            {/* {idx < languageCodes.length - 1 && (
              <Divider orientation="vertical" flexItem />
            )} */}
          </React.Fragment>
        );
      })}
    </div>
  );
}

LanguageSelector.propTypes = {
  /**
   *  Class name to apply to the outermost container
   */
  className: PropTypes.string,
  /**
   * Array of strings that represent the available languages
   * that can be switched to; you must have the corresponding
   * JSON translation files for that language to enable it
   */
  lagnaugeCodes: PropTypes.array,
};

LanguageSelector.defaultProps = {
  className: "",
  languageCodes: ["en", "fr", "es"],
};

export default LanguageSelector;
