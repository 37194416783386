import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { Business as BusinessIcon } from "@material-ui/icons";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import filter from "lodash/filter";

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    backgroundColor: theme.palette.primary.contrastText,
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

/**
 * Component meant to be used in the TopBar component; renders
 * a Autocomplete with a list of "data source" options that trigger
 * the custom onChange callback when clicked. Can be used to additional
 * options to the TopBar
 */

const DataContext = ({
  onChange,
  options,
  enableDataContextOverride,
  customJSX,
  value,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [localFilter, setLocalFilter] = useState("");
  const customContent =
    typeof customJSX === "function" ? customJSX() : customJSX;
  if (enableDataContextOverride)
    return <div className={classes.root}>{customContent}</div>;

  const standardRender = (option, { inputValue }) => {
    const matches = match(`${option.name}`, inputValue);
    const parts = parse(`${option.name}`, matches);

    return (
      <Grid container alignItems="center">
        <Grid item>
          <BusinessIcon />
        </Grid>
        <Grid item style={{ paddingLeft: 5 }}>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="textSecondary">
            {`${option.secondLine || ""}`}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        noOptionsText={
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flexStart"
            alignItems="center"
          >
            <CircularProgress />
            <Typography style={{ marginLeft: theme.spacing(2) }}>
              No matching options
            </Typography>
          </Box>
        }
        id="data-context-options"
        size={"small"}
        style={{
          width: "100%",
          minWidth: 200,
          backgroundColor: theme.palette.primary.contrastText,
          borderRadius: 5,
        }}
        options={options}
        getOptionLabel={(option) => `${option.name}`}
        filterOptions={(x) => {
          const newValues = filter(x, (xval) => {
            if (localFilter) {
              return (
                xval?.name?.toLowerCase().indexOf(localFilter.toLowerCase()) >=
                  0 ||
                xval?.secondLine
                  ?.toLowerCase()
                  .indexOf(localFilter.toLowerCase()) >= 0
              );
            }
            return true;
          });
          return newValues;
        }}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onInputChange={(event) => {
          setLocalFilter(event?.target?.value);
        }}
        onChange={onChange}
        renderOption={standardRender}
        renderInput={(params) => (
          <TextField fullWidth {...params} variant="outlined" />
        )}
      />
    </div>
  );
};

DataContext.propTypes = {
  /**
   * Callback that runs when the dataContext menu has a new option
   * selected. It receives 2 argument the generated event object (from Autocomplete),
   * and the selected DataContextObject from options
   */
  onChange: PropTypes.func,
  /**
   * Starting value of the Autocomplete component
   */
  value: PropTypes.any,
  /**
   * Used to render a series of additional data sources/options
   * if you wish to render more misc data in the TopBar
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      value: PropTypes.any,
      name: PropTypes.string,
      secondLine: PropTypes.string,
    })
  ),
  /**
   * enableDataContextOverride: Flag indicating weather or not you wish to override the default
   * behavior of DataContext (creating an AutoComplete from dataContextOptions array )
   * to just pass in a function that returns JSX or JSX. If this flag is set
   * onDataContextChange and dataContextOptions props will be ignored and
   * customJSX prop will be used
   */
  enableDataContextOverride: PropTypes.bool,
  /**
   *
   *  A callback or JSX element that you wish to have rendered with the DataContext
   *  section of TopBar only if you set enableDataContextOverride prop to true.
   */
  customJSX: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default DataContext;
