import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import FirebaseContext from 'src/context/FirebaseContext';
import {
  createSystemAlertMessages,
  createSystemNotifications,
} from 'src/utils/generateFirebaseAlerts';

export default function useMessagingDebug(numAlerts = 0, numNotifications = 0) {
  const { FirebaseCrud } = useContext(FirebaseContext);
  const { user } = useSelector((state) => state.account);
  useEffect(() => {
    console.info('----------------------------------------\n');
    console.info({ alertsPath: FirebaseCrud.getAlertsPath() });
    console.info(
      { notificationsPath: FirebaseCrud.getNotificationsPath() },
      '\n'
    );
    console.info({ companyCode: FirebaseCrud.companyCode });
    console.info('----------------------------------------\n');
  }, [FirebaseCrud]);

  useEffect(() => {
    const createNotificationsAsync = async () => {
      await createSystemNotifications(numNotifications, FirebaseCrud);
    };
    const createAlertAsync = async () => {
      await createSystemAlertMessages(numAlerts, FirebaseCrud);
    };
    if (user && numAlerts !== 0) createAlertAsync();
    if (user && numNotifications !== 0) createNotificationsAsync();
  }, [user, numAlerts, FirebaseCrud, numNotifications]);
}
