import React, { useEffect, useMemo, useState } from "react";
import { Card, CardContent, Grid, Typography, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Restore as RestoreIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import DataSourceButton from "src/components/DataSourceButton";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  cardHeader: {
    flexGrow: 1,
  },
  lastUpdatedContainer: {
    textAlign: "right",
  },
  lastUpdated: {
    alignItems: "center",
    display: "inline-flex",
  },
  lastUpdatedIcon: {
    fontSize: 17,
    marginRight: 4,
  },
  totalCount: {
    fontSize: 15,
  },
  topRightDetails: {
    display: "flex",
    justifyContent: "flex-end",
  },
  clearSelectionButton: {
    marginRight: 10,
  },
}));

export default function DynamicMapCard({
  singleModelEntity = i18next.t("state"),
  legendLandRegionsTitle = i18next.t("states"),
  activeLandRegions,
  countByLandRegion,
  total,
  lastUpdated,
  onChangeSelectedLandRegions,
  dataSourceButtonSettings,
  model,
  dataMap,
  title,
  subTitle,
  onOpenDataSourceDialog = () => {},
  MapView,
}) {
  const { t } = useTranslation();

  /**
   * key: landRegionCode
   * value: count
   *
   * @type {Object.<landRegionCode: string, count: number>}
   */
  const landRegionToCountMap = useMemo(
    () =>
      countByLandRegion?.reduce((acc, { landRegionCode, countNumber }) => {
        acc[landRegionCode] = countNumber;
        return acc;
      }, {}),
    [countByLandRegion]
  );

  const classes = useStyles();
  const [selectedLandRegions, setSelectedLandRegions] =
    useState(activeLandRegions);

  useEffect(() => {
    setSelectedLandRegions(activeLandRegions);
  }, [activeLandRegions]);

  const handleChangeSelectedLandRegions = (landRegions) => {
    setSelectedLandRegions(landRegions);
    onChangeSelectedLandRegions(landRegions);
  };

  const handleClearSelectedLandRegions = () => {
    handleChangeSelectedLandRegions(null);
  };

  return (
    <Card>
      <CardContent>
        <div className={classes.cardHeader}>
          <Grid container>
            <Grid item xs>
              <Typography variant="h4" color="textPrimary">
                <div>
                  {title ??
                    `${t("total")} ${model} ${t(
                      "completedBy_fragment"
                    )} ${singleModelEntity}`}
                </div>
                <Chip
                  className={classes.totalCount}
                  color="secondary"
                  size="medium"
                  label={
                    subTitle ??
                    `${total.toLocaleString()} ${t("total")} ${model}`
                  }
                />
              </Typography>
            </Grid>
            <Grid className={classes.lastUpdatedContainer} item xs>
              <div className={classes.topRightDetails}>
                <Chip
                  onClick={handleClearSelectedLandRegions}
                  variant="outlined"
                  color="primary"
                  size="small"
                  clickable
                  label="Clear Selection"
                  className={classes.clearSelectionButton}
                />
                {dataSourceButtonSettings?.display && (
                  <DataSourceButton
                    onOpenDataSourceDialog={onOpenDataSourceDialog}
                  />
                )}
                {lastUpdated && (
                  <Typography
                    color="textSecondary"
                    variant="h6"
                    align="right"
                    className={classes.lastUpdated}
                  >
                    <RestoreIcon
                      className={classes.lastUpdatedIcon}
                      fontSize="small"
                    />
                    {t("lastUpdated")} {formatDistanceToNow(lastUpdated)}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <MapView
          landRegionToCountMap={landRegionToCountMap}
          legendLandRegionsTitle={legendLandRegionsTitle}
          selectedLandRegions={selectedLandRegions}
          onChangeSelectedLandRegions={handleChangeSelectedLandRegions}
          countByLandRegion={countByLandRegion}
          dataMap={dataMap}
        />
      </CardContent>
    </Card>
  );
}

DynamicMapCard.propTypes = {
  /**
   * i.e. "State" or "Province"
   **/
  singleModelEntity: PropTypes.string,

  /** i.e. "Claims, Profit etc..." */
  model: PropTypes.string,

  /**
   * Title displayed in legend above smaller land region squares.
   *
   * i.e. "States" or "Provinces" */
  legendLandRegionsTitle: PropTypes.string,

  /** Array of state/province codes you want pre-selected when
   * the map loads
   */
  activeLandRegions: PropTypes.arrayOf(PropTypes.string),

  /** An object with state / provinceCode and countNumber properties */
  countByLandRegion: PropTypes.arrayOf(
    PropTypes.shape({
      // NOTE: landRegionCode is not required because one index may represent
      // the total count of all of the land regions

      landRegionCode: PropTypes.string,

      countNumber: PropTypes.number.isRequired,
    })
  ),

  /** Total number of given model */
  total: PropTypes.number,

  /** Date format for date-fns (just a normal Date object  or number (the unix time)) */
  lastUpdated: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),

  /**
   * Called with the selected land regions, on change, passes
   * the selected state/province code as a single arguement
   */
  onChangeSelectedLandRegions: PropTypes.func.isRequired,

  /** Called when user wishes to open data source dialog */
  onOpenDataSourceDialog: PropTypes.func,

  /** Button settings for data source */
  dataSourceButtonSettings: PropTypes.object,

  /**
   * Map configuration object that sets the legend title
   * and includes a function called valueFormatter which accepts a number as an argument
   * and returns a formated currency string (with $ appended)
   * (meant to indicate usd currency)
   */
  dataMap: PropTypes.shape({
    legend: PropTypes.string.isRequired,
    valueFormatter: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * Title to appear on the upper left hand side of the screen
   */
  title: PropTypes.string,
  /**
   * Appears below title; use for total profits or calculating
   * total values
   */
  subTitle: PropTypes.string,

  /** @type {React.Component}
   * one of the CanadaMap, UsaMap, or MexicoMap components
   */
  MapView: PropTypes.elementType.isRequired,
};
