const AZURE_CLIENT_ID = {
  /** Nation safe drivers portal in Azure AD; need to change to .env reference
   * NOTE: These are the harded coded app registrations which caused all the
   * apps redirect uris to end up in the same. To make the move over process smoother
   * these hardcoded values will still be used if the env vars are not properly set.
   */
  // AD: 'fb6dd162-b85e-48e3-9c64-ba557f629f8a',
  // B2C: '073b27bb-780e-41f5-a1a1-9990e4c0f323'
  AD:
    process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID ||
    "fb6dd162-b85e-48e3-9c64-ba557f629f8a",
  B2C:
    process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_B2C_CLIENT_ID ||
    "073b27bb-780e-41f5-a1a1-9990e4c0f323",
};
console.log({
  clientADId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID,
});
console.log({
  clientB2CId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_B2C_CLIENT_ID,
});
export default AZURE_CLIENT_ID;
