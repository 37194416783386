const CdnAppIcon = "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/insuranceQoreAppIcon.png"

const appConfig = {
  appId: 'insurance-26108cd7-53f2-4f06-b2db-5030d86a08c3',
  appName: 'Insurance',
  site: 'insurance-qore',
  icon: CdnAppIcon
};

export default appConfig;
