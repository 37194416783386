import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  dataSourceButton: {
    marginRight: 10,
  },
}));

/**
 * Component that renders a small chip/button in that is
 * clickable and fires a prop callback. To be used to
 * bring up dialogs or extra information or to be used
 * in a multiSelect input
 */
const DataSourceButton = ({ className, onOpenDataSourceDialog }) => {
  const classes = useStyles();

  return (
    <Chip
      onClick={onOpenDataSourceDialog}
      variant="outlined"
      color="primary"
      size="small"
      clickable
      label="Data"
      className={clsx(className, classes.dataSourceButton)}
    />
  );
};

DataSourceButton.propTypes = {
  /**
   * Callback that runs when the button is clicked. It
   * is passed 1 argument; the generated event object
   */
  onOpenDataSourceDialog: PropTypes.func,
  /**
   * Classname to apply to the rendered button
   */
  className: PropTypes.string,
};

DataSourceButton.defaultProps = {
  onOpenDataSourceDialog: () => {},
  className: "",
};

export default DataSourceButton;
