import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Edit as EditIcon } from "@material-ui/icons";
import {
  ArrowRight as MoreIcon,
  Eye as ViewerIcon,
  Paperclip as AttachmentsIcon,
} from "react-feather";
import getInitials from "../utils/getInitials";
import { DISPOSITION_TYPES } from "../constants/index";

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 200,
    backgroundColor: theme.palette.background.dark,
  },
  likedButton: {
    color: red[600],
  },
  subscribersIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  reviewerDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start ",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  claimDetails: {
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&__reviewerName": {
      textAlign: "left",
      width: "4rem",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
      },
    },
  },
}));

/**
 * Component that displays the details of the passed in claim object prop
 * @component
 */
function ClaimsCard({ claim, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();

  const getAmountApproved = () => {
    if (claim.claimDisposition === DISPOSITION_TYPES.APPROVED) {
      return `$${claim.claimApprovedAmount}`;
    }
    if (claim.claimDisposition === DISPOSITION_TYPES.DENIED) {
      return `$0.00`;
    }
    return "TBD";
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Link
              color="textPrimary"
              component={RouterLink}
              to="#"
              variant="h5"
            >
              {claim.claimNumber}
            </Link>
            <Box flexGrow={1} />
            <Typography variant="body2" color="textSecondary">
              Updated {formatDistanceToNow(claim.lastUpdateDate)}
            </Typography>
          </Box>
        }
      />
      <Divider />
      <Box className={classes.claimDetails}>
        <div className={classes.reviewerDetails}>
          <Avatar alt="Author" src={claim.avatar}>
            {getInitials(claim.reviewer ? claim.reviewer.name : "Un Assigned")}
          </Avatar>
          {claim.reviewer && (
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                className={`${classes.claimDetails}__reviewerName`}
              >
                Assigned to{" "}
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="#"
                  variant="h6"
                >
                  {claim.reviewer.name}
                </Link>
              </Typography>
            </Box>
          )}
        </div>
        {!claim.reviewer && (
          <Box>
            <Typography variant="body2" color="textSecondary">
              Unassigned
            </Typography>
          </Box>
        )}
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ textAlign: "left" }}
        >
          Amount Claimed: ${claim.claimAmount}
          <br />
          Amount Approved: {getAmountApproved()}
        </Typography>
      </Box>
      <Box
        pb={2}
        px={3}
        style={{
          display: "-webkit-box",
          margin: "0 auto",
          WebkitLineClamp: "3",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          height: 60,
        }}
      >
        {(
          <Typography
            color="textSecondary"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: claim.disablementDescription }}
          ></Typography>
        ) || (
          <Typography color="textSecondary" variant="body2">
            {claim.claimType}
          </Typography>
        )}
      </Box>
      <Box py={2} px={3}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              Status
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {claim.claimStatus || "Open"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              Claim Type
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {claim.claimType}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              Disposition
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {claim.claimDisposition || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2} pl={2} pr={3} display="flex" alignItems="center">
        <SvgIcon
          fontSize="small"
          color="action"
          className={classes.subscribersIcon}
        >
          <ViewerIcon />
        </SvgIcon>
        <Typography variant="subtitle2" color="textSecondary">
          {claim.viewers || "0"}
        </Typography>
        <SvgIcon
          fontSize="small"
          color="action"
          className={classes.subscribersIcon}
        >
          <AttachmentsIcon />
        </SvgIcon>
        <Typography variant="subtitle2" color="textSecondary">
          {claim.attachments || "0"}
        </Typography>
        <Box flexGrow={1} />
        {claim.claimDisposition === "Pre-Approved" && (
          <IconButton
            variant={"outlined"}
            onClick={() => {
              history.push(`/claims/create/${claim.id}`);
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton
          variant={"outlined"}
          onClick={() => {
            history.push(`/claims/${claim.id}`);
          }}
        >
          <MoreIcon />
        </IconButton>
      </Box>
    </Card>
  );
}

ClaimsCard.propTypes = {
  /**
   * Class name you wish to apply to the container around the Card
   */
  className: PropTypes.string,
  /**
   * Claim object with appropriate details to display on claim card
   */
  claim: PropTypes.shape({
    claimDisposition: PropTypes.string,
    claimNumber: PropTypes.number,
    // Date
    lastUpdateDate: PropTypes.object,
    avatar: PropTypes.string,
    reviewer: PropTypes.shape({
      name: PropTypes.string,
    }),
    claimAmount: PropTypes.number,
    claimType: PropTypes.string,
    disablementDescription: PropTypes.string,
    claimStatus: PropTypes.string,
    viewers: PropTypes.number,
    attachments: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
};

ClaimsCard.defaultProps = {
  className: "",
  claim: {
    claimDisposition: "",
    claimNumber: 1,
    lastUpdateDate: new Date(),
    avatar:
      "https://www.cdbradshaw.com/wp-content/uploads/2021/07/generic-avatar.jpg",
    reviewer: {
      name: "Test",
    },
    claimAmount: 100,
    claimType: "",
    disablementDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    claimStatus: "",
    viewers: 1,
    attachments: 1,
    id: 11111,
  },
};

export default ClaimsCard;
