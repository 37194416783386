import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Box, Button, Paper, Portal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useSettings from "../hooks/useSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 420,
    position: "fixed",
    top: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: "none",
    zIndex: 2000,
    padding: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
  },
}));

/**
 * Notification for letting the user know when their settings
 * have automatically changed. The popup remains until the
 * Switch link is clicked, or the close button is hit, both
 * which will set the user's aknowledgement in a cookie
 */
function SettingsNotification({ settingsPathFunc }) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const { saveSettings } = useSettings();

  const handleSwitch = () => {
    Cookies.set("settingsUpdated", "true");
    setOpen(false);
  };

  const handleClose = () => {
    Cookies.set("settingsUpdated", "true");
    setOpen(false);
  };

  useEffect(() => {
    const settingsUpdated = Cookies.get("settingsUpdated");
    if (!settingsUpdated) setOpen(true);
  }, []);

  if (!isOpen) return null;

  return (
    <Portal>
      <Paper className={classes.root} elevation={3}>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          Settings Updated
        </Typography>
        <Typography variant="body2" color="textSecondary">
          We automatically updated your settings. You change the settings any
          time from your dashboard settings.
        </Typography>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button onClick={handleClose}>Close</Button>
          <div
            onClick={async (e) => {
              await settingsPathFunc();
            }}
            className={classes.link}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSwitch}
            >
              Switch
            </Button>
          </div>
        </Box>
      </Paper>
    </Portal>
  );
}

SettingsNotification.propTypes = {
  /**
   * Function that runs when the "Switch" button of the settings
   * notification is clicked; should open the settings page.
   * If its a separate route you should history.push() in the function
   * If its a DOM element to click/open settings widget it should trigger that event
   *
   */
  settingsPathFunc: PropTypes.func.isRequired,
};

SettingsNotification.defaultProps = {
  settingsPath: "/settings",
};

export default SettingsNotification;
