import React from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";

/**
 * Customized version of Material UI's Switch component;
 * can receive any prop Switch receives; see full list here
 * https://v4.mui.com/api/switch/
 */
const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const AntSwitch = ({ className, ...rest }) => (
  <StyledSwitch className={className} {...rest} />
);

AntSwitch.propTypes = {
  /**
   * Class name to apply to the Switch component
   */
  className: PropTypes.string,
};

AntSwitch.defaultProps = {
  className: "",
};

export default AntSwitch;
