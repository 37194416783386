export const GET_APPLICATIONS = '@applications/get-applications';

export function getApplications() {
  const applications = [];

  return (dispatch) => {
    dispatch({
      type: GET_APPLICATIONS,
      payload: { applications }
    });
  };
}
