import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { Tool as ServiceIcon } from "react-feather";
import Typography from "@material-ui/core/Typography";
import throttle from "lodash/throttle";
import flatMap from "lodash/flatMap";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import servicesService from "../services/servicesService";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@material-ui/icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {},
  formType: { width: 200 },
  formInput: { margin: theme.spacing(1) },
  formButton: { margin: theme.spacing(1) },
  icon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

/**
 * Multi-select dropdown component that fetches a list
 * of services
 */
function ServiceInput({
  className,
  service,
  serviceChange,
  serviceFilter,
  checkBoxOptions,
  mobileOnly,
  forConcierge,
  ...rest
}) {
  const classes = useStyles();
  const [value, setValue] = useState(service || []);
  const [inputVal, setInputVal] = useState(service ? `${service.service}` : "");
  const [options, setOptions] = useState([]);
  const theme = useTheme();

  const filterServices = (services) => {
    services = orderBy(services, ["service"], ["asc"]);
    services = filter(
      services,
      (s) => flatMap(value, "id").indexOf(parseInt(s.id)) < 0
    );
    if (!serviceFilter || serviceFilter.length === 0) {
      if (mobileOnly) {
        return filter(
          services,
          (s) => s.service.toLowerCase().indexOf("mobile") >= 0
        );
      } else if (forConcierge) {
        return filter(
          services,
          (s) => s.service.toLowerCase().indexOf("tow") >= 0
        );
      }
      return services;
    }

    // Only one option can be checked at a time (?)
    if (mobileOnly) {
      return filter(
        services,
        (s) =>
          serviceFilter.indexOf(s.id) >= 0 &&
          s.service.toLowerCase().indexOf("mobile") >= 0
      );
    } else if (forConcierge) {
      return filter(
        services,
        (s) =>
          serviceFilter.indexOf(s.id) >= 0 &&
          s.service.toLowerCase().indexOf("tow") >= 0
      );
    }

    return filter(services, (s) => serviceFilter.indexOf(s.id) >= 0);
  };

  useEffect(() => {
    if (value && serviceChange) {
      serviceChange(value);
    }
  }, [inputVal]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        servicesService.searchServices(request).then(callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;
    if ((inputVal === "" || !inputVal) && !checkBoxOptions) {
      setOptions([]);
      return undefined;
    }

    fetch(inputVal, (results) => {
      if (active) {
        let newOptions = [];
        if (results) newOptions = filterServices([...results]);
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputVal, fetch]);

  const checkBoxRender = (option, { selected }) => (
    <>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option.service}
    </>
  );

  const standardRender = (option, { inputValue }) => {
    const matches = match(`${option.service}`, inputValue);
    const parts = parse(`${option.service}`, matches);

    return (
      <Grid container alignItems="center">
        <Grid item>
          <ServiceIcon className={classes.icon} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="textSecondary">
            {`${option.serviceKey}`}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" width="100%">
            <Autocomplete
              multiple
              noOptionsText={
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flexStart"
                  alignItems="center"
                >
                  <CircularProgress />
                  <Typography style={{ marginLeft: theme.spacing(2) }}>
                    Searching for services
                  </Typography>
                </Box>
              }
              id="combo-box-demo"
              style={{ width: "100%" }}
              options={options}
              getOptionLabel={(option) => {
                return `${option.serviceKey} - ${option.service}`;
              }}
              filterOptions={(x) => x}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              disableCloseOnSelect={checkBoxOptions}
              onChange={(_, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
              }}
              onInputChange={(_, newInputValue) => {
                setInputVal(newInputValue);
              }}
              renderOption={checkBoxOptions ? checkBoxRender : standardRender}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Service"
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

ServiceInput.propTypes = {
  /**
   * Class name to be applied to the outermost container
   * element
   */
  className: PropTypes.string,
  /**
   * Weather or not to render a checkbox or a SVG icon
   */
  checkBoxOptions: PropTypes.bool,
  /**
   * Render only services with the string "mobile" in the name
   * If this is true forConcierge applied, even if true
   */
  mobileOnly: PropTypes.bool,
  /**
   * Render only services with the string "tow" in the name
   */
  forConcierge: PropTypes.bool,
  /**
   * Callback that runs when a new service is selected.
   * Receives one parameter an array of service objects
   * that have been selected
   */
  serviceChange: PropTypes.func,
  /**
   * Array of service ids that you want to include when
   * searching
   */
  serviceFilter: PropTypes.arrayOf(PropTypes.number),
  /**
   *  Service object to appear as the intial selected
   * value
   */
  service: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      serviceKey: PropTypes.string,
      service: PropTypes.string,
    })
  ),
};

ServiceInput.defaultProps = {
  className: "",
  checkBoxOptions: false,
  mobileOnly: false,
  forConcierge: false,
  serviceChange: null,
  serviceFilter: null,
  service: null,
};

export default ServiceInput;
