import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAssetSettings } from '@nsd/fe';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavItem from './NavItem';
import { PORTAL_TYPE } from '@nsd/fe';
import { getUserCompany } from 'src/utils/user';
import { TRANSLATION } from 'src/constants/translationKey';
import { useTranslation } from 'react-i18next';

// pathname passed in as rest
const renderNavItems = ({ items, ...rest }) => (
  <List disablePadding>
    {items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth = 0 }) => {
  const key = item.title + depth;
  const { excludedPaths } = item;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    // If there are nested items repeat
    acc.push(
      <NavItem
        id={`nav-link-${key}`}
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        excludedPaths={excludedPaths}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        id={`nav-link-${key}`}
        depth={depth}
        href={item.href}
        externalLink={item.externalLink}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        excludedPaths={excludedPaths}
      />,
    );
  }

  return acc;
};

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  navBarMenu: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100% - 225px)',
    },
  },
  footerBox: {
    width: '100%',
    marginTop: 'auto',
  },
}));

/**
 * Navbar component that renders a sidenav. Can be passed props
 * to indicate if the navbar should be hidden on mobile.
 * Desktop NavBar is hidden when viewport width is < 600px
 * Mobile NavBar is hidden when the viewport width is > 600px
 * lg: 1280
 * md: 960
 * sm: 600
 * xl: 1920
 * xs: 0
 */

const NavBar = ({ openMobile, onMobileClose, navConfig }) => {
  const { t } = useTranslation(TRANSLATION);

  const classes = useStyles();
  const location = useLocation();
  const { isNSD } = useAssetSettings();
  const { user } = useSelector((state) => state.account);
  const { type } = useSelector((state) => state.application);

  useEffect(() => {
    if (openMobile && onMobileClose) onMobileClose();
    // eslint-disable-next-line
  }, [location.pathname]);

  if (!type) return null;

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink
              {...(type === PORTAL_TYPE.EMPLOYEE || isNSD()
                  ? {
                    to: {
                      pathname:
                        'https://nationsafedrivers-my.sharepoint.com/person.aspx',
                    },
                    target: '_blank',
                  }
                  : {
                    to: '/profile',
                  }
              )}
            >
              <Avatar
                alt={`${user?.firstName} ${user?.lastName} avatar`}
                className={classes.avatar}
                src={user?.avatar}
                data-test="user-avatar"
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Typography
              variant="h5"
              color="textPrimary"
              data-test="user-name"
            >
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              data-test="user-company"
            >
              {getUserCompany(user).name}
            </Typography>
          </Box>
        </Box>
        <Divider/>
        <Box className={classes.navBarMenu}>
          <Box p={2}>
            {navConfig.navBarItems.map((config) => {
              return (
                config.items.length > 0 && (
                  <List
                    data-test={`nav-list-${config.subheader}`}
                    key={config.subheader}
                    subheader={
                      <ListSubheader
                        data-test={`nav-list-subheader-${config.subheader}`}
                        disableGutters
                        disableSticky
                      >
                        {t(config.subheader)}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: config.items,
                      pathname: location.pathname,
                    })}
                  </List>
                )
              );
            })}
          </Box>
          <Divider/>
          {/* <Box className={classes.footerBox} p={2}>
            {navConfig.navBarMessage ? (
              <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
                <Typography variant="h6" color="textPrimary">
                  {navConfig?.navBarMessage?.header}
                </Typography>
                <Link
                  variant="subtitle1"
                  color="secondary"
                  component={RouterLink}
                  to={navConfig?.navBarMessage?.link}
                >
                  {navConfig?.navBarMessage?.body}
                </Link>
              </Box>
            ) : (
              <PoweredBy />
            )}
          </Box> */}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  return (
    <>
      <Hidden lgUp>
        <Drawer
          data-test="mobile-drawer"
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          data-test="desktop-drawer"
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  /**
   * Callback that runs when the pathname (URL) changes;
   * it is not passed any arguments
   */
  onMobileClose: PropTypes.func,
  /**
   * Weather or not to display the Navbar on mobile
   * device widths
   */
  openMobile: PropTypes.bool,
  /**
   * navConfig object that determines what links to populate
   * in the NavBar. Comes from the appConfig of the current
   * rendered route.
   *
   * Within one object of items array you can create an other
   * items array (same shape) within it to have collaspable subroutes render
   * in the navBar.
   *
   * excludedPaths: Is an array of relative links
   *  ex: ['/', 'admin/path1'] that you don't want the
   *  current nav item to be rendered on.
   *
   * module: A module identifier you wish to guard the
   * navItem behind, if the user doesn't have access the link
   * is not rendered in the navBar. Leave off for a page
   * anyone can access
   */
  navConfig: PropTypes.shape({
    navBarItems: PropTypes.arrayOf(
      PropTypes.shape({
        subheader: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.object,
            href: PropTypes.string.isRequired,
            items: PropTypes.array,
            excludedPaths: PropTypes.arrayOf(PropTypes.string),
            module: PropTypes.string,
          }),
        ),
      }),
    ),
    navBarMessage: PropTypes.shape({
      header: PropTypes.string,
      link: PropTypes.string,
      body: PropTypes.string,
    }),
  }),
};

NavBar.defaultProps = {
  onMobileClose: null,
  openMobile: true,
  navConfig: null,
  type: 'client',
};

export default NavBar;
