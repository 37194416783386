import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { adjustWidthForLocale } from 'src/utils/style';

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(2),
    width: adjustWidthForLocale(320, 390),
  },
}));

/**
 * Generic dropdown component that reveals a menu (children prop)
 * when a specified icon is clicked (tooltip optional)
 */
const DropdownMenu = forwardRef(
  (
    {
      title,
      tooltip,
      Icon,
      IconButtonProps,
      PopoverProps,
      onOpen,
      onClose,
      children,
    },
    dropdownMenuRef
  ) => {
    const classes = useStyles();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    useImperativeHandle(dropdownMenuRef, () => ({
      close: () => {
        setOpen(false);
      },
    }));

    const handleOpen = () => {
      setOpen(true);
      onOpen();
    };

    const handleClose = () => {
      setOpen(false);
      onClose();
    };

    const iconButton = (
      <span>
        <IconButton
          onClick={handleOpen}
          ref={ref}
          {...IconButtonProps}

        >
          {Icon}
        </IconButton>
      </span>
    );

    const { className, ...restPopoverProps } = PopoverProps;

    return (
      <>
        {tooltip ? <Tooltip title={title}>{iconButton}</Tooltip> : iconButton}
        <Popover
          classes={{ paper: clsx(classes.popover, className) }}
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          {...restPopoverProps}
        >
          <Typography variant="h4" align="center" color="textPrimary">
            {title}
          </Typography>
          <Box mt={2}>{children}</Box>
        </Popover>
      </>
    );
  }
);

DropdownMenu.propTypes = {
  /**
   * String title of the tooltip which also appears at the top of the dropdown menu
   */
  title: PropTypes.string,
  /**
   * Boolean indicaing if the tooltip should render
   */
  tooltip: PropTypes.bool,
  /**
   * A Component or DOM node to represent the icon that when
   * clicked brings up the menu
   */
  Icon: PropTypes.element,
  /**
   * Props to be passed to the button component wrapped around
   * the icon https://v4.mui.com/api/icon-button/#iconbutton-api
   */
  IconButtonProps: PropTypes.shape({
    children: PropTypes.node,
    clases: PropTypes.object,
    disabled: PropTypes.bool,
    disableFocusRipple: PropTypes.bool,
    edge: PropTypes.oneOf(['end', 'start']),
    size: PropTypes.oneOf(['small', 'medium']),
    sx: PropTypes.object,
    color: PropTypes.oneOf(['inherit', 'default', 'primary', 'secondary']),
  }),
  /**
   * Props to be passed to the Popover dropdown component.
   * https://v4.mui.com/api/popover/
   */
  PopoverProps: PropTypes.shape({
    open: PropTypes.bool,
    action: PropTypes.element,
    anchorEl: PropTypes.element,
    anchorOrigin: PropTypes.shape({
      horizontal: PropTypes.oneOf(['left', 'center', 'right']),
      vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    }),
    anchorPosition: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
    }),
    anchorReference: PropTypes.oneOf(['anchorEl', 'anchorPosition', 'none']),
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    container: PropTypes.element,
    elevation: PropTypes.number,
    marginThreshold: PropTypes.number,
    onClose: PropTypes.func,
    paperProps: PropTypes.object,
    TransitionComponent: PropTypes.any,
    TransitionProps: PropTypes.object,
    transitionDuration: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        appear: PropTypes.number,
        enter: PropTypes.number,
        exit: PropTypes.number,
      }),
    ]),
  }),
  /**
   * Callback to run after the menu is clicked open
   */
  onOpen: PropTypes.func,
  /**
   * Callback to run after the menu is closed (outside click closes it)
   */
  onClose: PropTypes.func,
  /**
   * Content (Component, DOM node) that you wish to be displayed
   * in the dropdown menu (usually a list)
   */
  children: PropTypes.element,
  /**
   * Ref passed in to be attached to the outputted Popover
   * component
   */
  dropdownMenuRef: PropTypes.any,
};

DropdownMenu.defaultProps = {
  title: 'File List',
  tooltip: true,
  Icon: null,
  IconButtonProps: {
    color: 'primary',
  },
  onOpen: () => { },
  onClose: () => { },
  PopoverProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  },
  dropdownMenuRef: null,
};

DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;