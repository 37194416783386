import React, { useCallback, useMemo, createElement } from "react";
import SVGLandRegion from "./SVGLandRegion";
import PropTypes from "prop-types";
import { scaleQuantize, extent } from "d3";

SVGInnerMap.propTypes = {
  svgEl: PropTypes.instanceOf(SVGElement).isRequired,
  classes: PropTypes.object.isRequired,
  landRegionToCountMap: PropTypes.object,
  landRegionsWithCount: PropTypes.arrayOf(
    PropTypes.shape({
      landRegionCode: PropTypes.string.isRequired,
      textOverlay: PropTypes.shape({
        transform: PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
        }).isRequired,
        rect: PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
        }),
        countNumber: PropTypes.number,
      }).isRequired,
    })
  ).isRequired,
  colorScale: PropTypes.arrayOf(PropTypes.string).isRequired,
  onLandRegionEnter: PropTypes.func.isRequired,
  onLandRegionLeave: PropTypes.func.isRequired,
  onLandRegionClick: PropTypes.func.isRequired,
  selectedLandRegions: PropTypes.arrayOf(PropTypes.string),
  disabledLandRegions: PropTypes.arrayOf(PropTypes.string),
  dataMap: PropTypes.object,
  innerMapOffsetX: PropTypes.number,
  innerMapOffsetY: PropTypes.number,
  landRegionCountOffsetY: PropTypes.number,
  innerMapScaling: PropTypes.number,
};

export default function SVGInnerMap({
  svgEl,
  classes,
  landRegionToCountMap,
  landRegionsWithCount,
  colorScale,
  onLandRegionEnter,
  onLandRegionLeave,
  onLandRegionClick,
  selectedLandRegions,
  disabledLandRegions,
  dataMap,
  innerMapOffsetX,
  innerMapOffsetY,
  landRegionCountOffsetY,
  innerMapScaling,
}) {
  /**
   * @param {Object} landRegionsWithCount
   * @param {string} landRegionCode
   * @return {Object}
   */
  const getLandRegionObjectWithCode = useCallback(
    (landRegionsWithCount, landRegionCode) => {
      return landRegionsWithCount.find(
        ({ landRegionCode: predicate }) => predicate === landRegionCode
      );
    },
    []
  );

  /**
   * Replaces raw HTML/DOM attributes with JSX-formatted equivalents, based
   * on the internal replacements list.
   *
   * @param {string} str
   * @return {string}
   */
  const makeJSXAttributeReplacements = useCallback((str) => {
    const replacements = [
      ["xmlns:xlink", "xmlnsXlink"],
      ["clip-path", "clipPath"],
      ["xlink:href", "xlinkHref"],
    ];

    for (const [prev, next] of replacements) {
      str = str.replaceAll(prev, next);
    }

    return str;
  }, []);

  /**
   * @param {DOMElement} domElement The DOMElement to parse
   * @return {Object} An object representing the converted JSX attributes
   * of the original DOM element
   */
  const makeJSXAttributes = useCallback(
    (domElement) =>
      Object.fromEntries(
        [...domElement.attributes].map((attr) => [
          makeJSXAttributeReplacements(attr.name),
          attr.value,
        ])
      ),
    [makeJSXAttributeReplacements]
  );

  /**
   * @see https://github.com/d3/d3-scale
   * @type {function}
   **/
  const makeColor = useMemo(
    () =>
      scaleQuantize(extent(Object.values(landRegionToCountMap)), colorScale),
    []
  );

  /** @type {string[]} */
  const landRegionCodes = useMemo(
    () =>
      Object.values(landRegionsWithCount).map(
        ({ landRegionCode }) => landRegionCode
      ),
    [landRegionsWithCount]
  );

  return (
    <svg
      {...makeJSXAttributes(svgEl)}
      className={classes.font}
      width="100%"
      height="100%"
      x={innerMapOffsetX}
      y={innerMapOffsetY}
    >
      <g
        className={classes.map}
        style={{
          transform: `scale(${parseFloat(innerMapScaling)})`,
        }}
      >
        {
          /**
           * @type {DOMElement[]}
           */
          [...svgEl.children].map((node, idx) => {
            const nodeId = node.getAttribute("id");

            // Determine if this DOMElement represents a state / province
            if (landRegionCodes.includes(nodeId)) {
              const { textOverlay, countNumber } = getLandRegionObjectWithCode(
                landRegionsWithCount,
                nodeId
              );
              const landRegionCode = nodeId;

              return (
                <SVGLandRegion
                  key={landRegionCode}
                  makeColor={makeColor}
                  classes={classes}
                  landRegionCode={landRegionCode}
                  landRegionCountOffsetY={landRegionCountOffsetY}
                  selected={selectedLandRegions.includes(landRegionCode)}
                  textOverlay={textOverlay}
                  attrs={makeJSXAttributes(node)}
                  onMouseEnter={(landRegionCode) => {
                    onLandRegionEnter(landRegionCode);
                  }}
                  onMouseLeave={(landRegionCode) => {
                    onLandRegionLeave(landRegionCode);
                  }}
                  onClick={(landRegionCode) => {
                    onLandRegionClick(landRegionCode);
                  }}
                  countNumber={countNumber}
                  staticChildren={({ ...args }) => {
                    return [...node.children].map((childNode, idx) =>
                      createElement(childNode.tagName, {
                        key: idx,
                        ...args,
                        ...makeJSXAttributes(childNode),
                        dangerouslySetInnerHTML: {
                          __html: makeJSXAttributeReplacements(
                            childNode.innerHTML
                          ),
                        },
                      })
                    );
                  }}
                  disabled={disabledLandRegions.includes(landRegionCode)}
                  dataMap={dataMap}
                />
              );
            } else {
              // Disable title overlay (i.e. "Map of Canada")
              // NOTE: (jh) Titles can be added to individual
              // landmarks, and need a <title>title</title> (title
              // as an HTML attribute does not work for that)
              if (node.tagName === "title") {
                return null;
              }

              const attributes = makeJSXAttributes(node);

              // For meta-data; non-provinces
              return createElement(node.tagName, {
                key: idx,
                ...attributes,
                // We don't need to map sub-nodes of what we're not
                // directly manipulating, so we can just render the
                // original HTML, with string replacements for what
                // have been needed with JSX
                dangerouslySetInnerHTML: {
                  __html: makeJSXAttributeReplacements(node.innerHTML),
                },
              });
            }
          })
        }
      </g>
    </svg>
  );
}
