export const SUPPORTED_DOC_TYPES = {
  PDFVIEWER: ["pdf"],
  DOCVIEWER: [
    "doc",
    "docx",
    "htm",
    "html",
    "ppt",
    "pttx",
    "tiff",
    "txt",
    "xls",
    "xlsx",
  ],
  IMAGEVIWER: ["bmp", "webp", "jpg", "jpeg", "png"],
  AUDIOPLAYER: ["mp3", "wav"],
  VIDEOPLAYER: ["mp3", "mp4", "wav"],
};
