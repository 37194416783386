import React from 'react';
import PropTypes from 'prop-types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import format from 'date-fns/format';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
    },
  },
  date: {
    marginLeft: 6,
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  commentDate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeIcon: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  bubble: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: grey[200],
    padding: theme.spacing(2),
  },
  avatarContainer: {
    margin: 'auto 0',
  },
}));

/**
 * Component representing a singular comment; to be used with the Comments
 * component to list out an array of comments
 */

const Comment = ({ className, comment, simpleDate, bubbleStyle, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const combinedClasses = false
    ? clsx(classes.root, className, classes.bubble)
    : clsx(classes.root, className);


  return (
    <ListItem className={combinedClasses} alignItems="flex-start" {...rest}>
      <ListItemAvatar className={classes.avatarContainer}>
        <Avatar alt={comment.author.displayName} src={comment.author.avatar} />
      </ListItemAvatar>
      <ListItemText
        className={classes.bubble}
        primary={
          <div className={classes.commentHeader}>
            <p>{comment.author.displayName}</p>
            <div className={classes.commentDate}>
              {matches && (
                <>
                  <AccessTimeIcon className={classes.timeIcon} />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={classes.date}
                  >
                    {simpleDate && comment?.createdAt
                      ? formatDistanceToNow(comment.createdAt)
                      : format(comment.createdAt, 'MM/dd/yyyy K:m bbb')}
                  </Typography>
                </>
              )}
            </div>
          </div>
        }
        secondary={
          <>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            ></Typography>
            {comment.message}
          </>
        }
      />
    </ListItem>
  );
};

Comment.propTypes = {
  /**
   * Class name you wish to apply to each individual comment
   */
  className: PropTypes.string,
  /**
   * Object representing a comment information to be displayed; avatar maybe
   * a valid image URL or a Node/Icon component
   */
  comment: PropTypes.shape({
    createdDate: PropTypes.number,
    author: PropTypes.shape({
      displayName: PropTypes.string,
      avatar: PropTypes.string,
    }),
    message: PropTypes.string,
    id: PropTypes.number,
  }),
  /**
   * Boolean indicating if you want the displayed date to be
   * simplified or a full date
   */
  simpleDate: PropTypes.bool,
  /**
   * Boolean indicating if you want the comments to have a bubble
   * style
   */
  bubbleStyle: PropTypes.bool,
};

Comment.defaultProps = {
  className: '',
  comment: {
    createdAt: Date.now(),
    author: {
      displayName: 'sample user',
      avatar: '/static/images/avatars/1.jpg',
    },
    message: 'This is a sample comment! ',
    id: 1234243422,
  },
  simpleDate: true,
  bubbleStyle: true,
};

export default Comment;
