import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Typography,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { TRANSITION_COMPONENT_TYPES } from '../constants/index';
import LoadingButton from './LoadingButton';
const CollapseTransition = lazy(() => import('@material-ui/core/Collapse'));
const FadeTransition = lazy(() => import('@material-ui/core/Fade'));
const GrowTransition = lazy(() => import('@material-ui/core/Grow'));
const SlideTransition = lazy(() => import('@material-ui/core/Slide'));
const ZoomTransition = lazy(() => import('@material-ui/core/Zoom'));


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
  },
  dialogTitle: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    color: theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
  },
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: '1.25em',
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogText: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem',
    },
  },
  closeButton: {
    padding: theme.spacing(1),
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

/**
 * Modal component that displays confirmation text and a message that
 * must be exited or confirmed. Use to add an extra layer of protection
 * to a destructive action such as account deletion or password changes.
 */
const ConfirmationDialog = ({
  title,
  className,
  onClose,
  onConfirm,
  maxWidth,
  transitionType,
  subTitle,
  open,
  ConfirmIcon,
  onCancel,
  onExited,
  confirmText,
  cancelText,
  renderExitButton,
  dialogStyles,
  disableEscapeKey,
  ...rest
}) => {
  const internalClasses = useStyles();
  const [loading, setLoading] = useState(false);
  const {
    dialogTitleContainerClassName,
    dialogTitleTextClassName,
    dialogContentContainerClassName,
    dialogContentTextClassName,
    dialogConfirmButtonClassName,
    dialogCancelButtonClassName,
  } = dialogStyles || {};

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  };

  const getTransition = () => {
    switch (transitionType) {
      case TRANSITION_COMPONENT_TYPES.GROW:
        return GrowTransition;
      case TRANSITION_COMPONENT_TYPES.COLLAPSE:
        return CollapseTransition;
      case TRANSITION_COMPONENT_TYPES.ZOOM:
        return ZoomTransition;
      case TRANSITION_COMPONENT_TYPES.FADE:
        return FadeTransition;
      case TRANSITION_COMPONENT_TYPES.SLIDE:
        return SlideTransition;
      default:
        return FadeTransition;
    }
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      className={className}
      open={open}
      onClose={onClose}
      aria-describedby="modal-dialog-description"
      aria-labelledby="modal-dialog-title"
      TransitionComponent={getTransition()}
      disableEscapeKeyDown
      TransitionProps={{
        onExited,
      }}
      onKeyDown={disableEscapeKey ? null : async (e) => {
        // Only fire if type is undefined (not any other falsely value), meaning an input or select element is not
        // in focus
        const targetElementType = e.target.type;
        if (e.key === 'Escape' && targetElementType === undefined) {
          await handleConfirm();
        }
      }}
      {...rest}
    >
      <DialogTitle
        id="modal-dialog-title"
        disableTypography
        className={dialogTitleContainerClassName || internalClasses.root}
      >
        <Typography className={dialogTitleTextClassName || internalClasses.dialogTitle}>{title}</Typography>
        {onClose && renderExitButton ? (
          <IconButton
            aria-label="close"
            className={internalClasses.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers className={dialogContentContainerClassName || internalClasses.dialogContent}>
        <DialogContentText
          id="modal-dialog-description"
          className={dialogContentTextClassName || internalClasses.dialogText}
        >
          {subTitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" className={dialogCancelButtonClassName}>
          {cancelText}
        </Button>
        <LoadingButton
          className={dialogConfirmButtonClassName}
          variant="contained"
          color="secondary"
          startIcon={ConfirmIcon ?? <DeleteIcon />}
          onClick={handleConfirm}
          loading={loading}
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  /**
   * Class name you wish to apply to the container around Modal
   */
  className: PropTypes.string,
  /**
   * Text to appear on the confirmation button
   */
  confirmText: PropTypes.string,
  /**
   * Text to appear in center of modal below Alarm icon
   */
  subTitle: PropTypes.string,
  /**
   * Callback to run when either the area outside the modal
   * or the close button is clicked. Accepts 2 parameters; event object
   * and a reason string (e.g. "escapeKeyDown" or "backdropClick").
   */
  onClose: PropTypes.func,
  /**
   * Callback to run when the confirm button of the modal is clicked.
   * Accepts 1 parameter; the generated event object
   */
  onConfirm: PropTypes.func,
  /**
   * Boolean (usually state variable) to control the visibility of
   * the modal
   */
  open: PropTypes.bool,
  /**
   * Text to appear in the title section of the modal
   */
  title: PropTypes.string,
  /**
   * String value choosen from enum ('xs','sm','md','lg','xl') to
   * indicate how wide the modal should grow to
   */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  /**
   * String value choosen from enum ('fade','grow','collapse','zoom', 'slide') to
   * specify the type of transition when the modal enters the viewport
   */
  transitionType: PropTypes.oneOf([
    'collapse',
    'fade',
    'grow',
    'slide',
    'zoom',
  ]),
  /**
   * Text to appear in the title section of the modal
   */
  ConfirmIcon: PropTypes.element,
  /**
   * Text to appear in the title section of the modal
   */
  onCancel: PropTypes.func,
  /**
   * Text to appear in the button of the cancel action
   */
  cancelText: PropTypes.string,
  /**
   * Callback fired before the "exiting" status is applied.
   */
  onExited: PropTypes.func,
  /**
   * If the 'X' icon to close the dialog should be rendered
   */
  renderExitButton: PropTypes.bool,
  /**
   * Classes to override styles of the individual components
   * that make up the dialog
   */
  dialogStyles: PropTypes.shape({
    dialogTitleContainerClassName: PropTypes.string,
    dialogTitleTextClassName: PropTypes.string,
    dialogContentContainerClassName: PropTypes.string,
    dialogContentTextClassName: PropTypes.string,
    dialogConfirmButtonClassName: PropTypes.string,
    dialogCancelButtonClassName: PropTypes.string,
  }),
  /**
   * If enabled the user cannot exit the modal via the escape key
   */
  disableEscapeKey: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  confirmText: 'Confirming...',
  onClose: () => { },
  onConfirm: () => { },
  onCancel: () => { },
  open: true,
  className: '',
  ConfirmIcon: null,
  subTitle: 'Generic modal message goes here',
  title: 'Subscribe',
  maxWidth: 'lg',
  transitionType: 'fade',
  renderExitButton: true,
  dialogStyles: null,
  disableEscapeKey: false,
};

export default ConfirmationDialog;
