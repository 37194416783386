import { gql } from '@apollo/client';
import each from 'lodash/each';
import Axios from 'axios';
import BaseService from './baseService';
import 'react-phone-input-2/lib/material.css';

const axios = Axios.create();

class UserService extends BaseService {
  constructor() {
    super();
    this.userDataMappings = {
      id: {
        name: '$id',
        type: 'Int',
        input: 'Id'
      },
      firstName: {
        name: '$firstName',
        type: 'String',
        typeRequire: 'String!',
        input: 'FirstName'
      },
      lastName: {
        name: '$lastName',
        type: 'String',
        typeRequire: 'String!',
        input: 'LastName'
      },
      email: {
        name: '$email',
        type: 'String',
        typeRequire: 'String!',
        input: 'Email'
      },
      title: {
        name: '$title',
        type: 'String',
        input: 'Title'
      },
      preferredContactMethodId: {
        name: '$preferredContactMethodId',
        type: 'Int',
        typeRequire: 'Int!',
        input: 'PreferredContactMethodId'
      },
      roleId: {
        name: '$roleId',
        type: 'Int',
        typeRequire: 'Int!',
        input: 'RoleId'
      },
      password: {
        name: '$password',
        type: 'String',
        input: 'Password'
      },
      emailOptIn: {
        name: '$emailOptIn',
        type: 'Int',
        input: 'EmailOptIn'
      },
      mobileNotificationsOptIn: {
        name: '$mobileNotificationsOptIn',
        type: 'Int',
        input: 'MobileNotificationsOptIn'
      },
      sMSOptIn: {
        name: '$sMSOptIn',
        type: 'Int',
        input: 'SMSOptIn'
      },
      phoneOptIn: {
        name: '$phoneOptIn',
        type: 'Int',
        input: 'PhoneOptIn'
      },
      chatEmailOptIn: {
        name: '$chatEmailOptIn',
        type: 'Int',
        input: 'ChatEmailOptIn'
      },
      chatMobileNotificationsOptIn: {
        name: '$chatMobileNotificationsOptIn',
        type: 'Int',
        input: 'ChatMobileNotificationsOptIn'
      },
      chatSMSOptIn: {
        name: '$chatSMSOptIn',
        type: 'Int',
        input: 'ChatSMSOptIn'
      },
      avatar: {
        name: '$avatar',
        type: 'String',
        input: 'Avatar'
      },
      addresses: {
        name: '$addresses',
        type: '[AddressIn]',
        input: 'Addresses'
      },
      phones: {
        name: '$phones',
        type: '[PhoneIn]',
        input: 'Phones'
      }
    };
    this.userGql = `
      Id
      FirstName
      LastName
      Email
      Title
      PreferredContactMethod
      Role
      EmailOptIn
      MobileNotificationsOptIn
      SMSOptIn
      PhoneOptIn
      ChatEmailOptIn
      ChatMobileNotificationsOptIn
      ChatSMSOptIn
      Avatar
      Addresses{
        Id
        FullAddress
        City
        StateProvidence
        PostalCode
        AddressTypeId
      }
      Phones{
        Id
        Phone
        PhoneTypeId
      }`;
    this.userActionGql = `
      Success
      Token`;
  }

  sendRegistrationEmail = (user) => new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_BASE_STANDARD_URL}/customer-notifications${process.env.REACT_APP_BASE_STANDARD_KEY ? `?code=${process.env.REACT_APP_BASE_STANDARD_KEY}&avatar=invite` : '?type=invite'}`;
    const postData = {
      email: user.email,
      user,
      url: `${process.env.REACT_APP_WEB_URL}/user-registration`,
      subject: 'Dispatch Qore Account Verification'
    };
    console.log('url', url);
    console.log('postData', postData);
    axios.post(
      url,
      postData
    )
      .then((emailResult) => {
        resolve(emailResult);
      })
      .catch((emailError) => {
        reject(emailError);
      });
  });

  buildUserGQL = (data, mutation, required, actionReturned) => {
    const keys = Object.keys(data);
    const user = [];
    const input = [];
    each(keys, (key) => {
      const map = this.userDataMappings[key];
      if (map && data[key]) {
        user.push(`${map.name}: ${required && map.typeRequire ? map.typeRequire : map.type}`);
        input.push(`${map.input}: ${map.name}`);
      }
    });

    return `
      mutation User(${user.join(', ')}){
        ${mutation}(
            ${input.join('\n')}
          ) {
            ${actionReturned ? this.userActionGql : this.userGql}
          }
      }`;
  };

  saveUser = (data) => new Promise((resolve, reject) => {
    console.log('userService saveUser');
    try {
      console.log(this.buildUserGQL(data, 'updateUser', false, false));
      const USER = gql`${this.buildUserGQL(data, 'updateUser', false, false)}`;

      this.client
        .mutate({
          mutation: USER,
          variables: data
        })
        .then((result) => {
          console.log('userService saveUser:result', result);
          if (result.data.updateUser) {
            resolve(result.data.updateUser);
          } else {
            reject(result.data.error);
          }
        })
        .catch((error) => {
          console.log('error', error);
          reject(error);
        });
    } catch (e) {
      console.log('userService saveUser:e', e);
      reject(e);
    }
  });

  savePhones = (id, phones) => new Promise((resolve, reject) => {
    const USER_PHONES = gql`
      mutation User($id: Int!, $phones: [PhoneUpdate]){
        updateUserPhones(
            Id: $id
            Phones: $phones
          ) {
            Success
            Token
            Error
            User{
                ${this.userGql}
            }
        }
    }
    `;

    this.client
      .mutate({
        mutation: USER_PHONES,
        variables: { id, phones }
      })
      .then((result) => {
        console.log('savePhones:result', result);
        if (result.data.updateUserPhones) {
          resolve(result.data.updateUserPhones);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  saveAddresses = (id, addresses) => new Promise((resolve, reject) => {
    const USER_ADDRESSES = gql`
      mutation User($id: Int!, $addresses: [AddressUpdate]){
        updateUserAddresses(
            Id: $id
            Addresses: $addresses
          ) {
            Success
            Token
            Error
            User{
               ${this.userGql}
            }
        }
    }
    `;

    this.client
      .mutate({
        mutation: USER_ADDRESSES,
        variables: { id, addresses }
      })
      .then((result) => {
        console.log('saveAddresses:result', result);
        if (result.data.updateUserAddresses) {
          resolve(result.data.updateUserAddresses);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  addUser = (data) => new Promise((resolve, reject) => {
    const NEW_CUSTOMER = gql`
      ${this.buildUserGQL(data, 'addUser', true)}
    `;

    this.client
      .mutate({
        mutation: NEW_CUSTOMER,
        variables: data
      })
      .then((result) => {
        console.log('addUser:result', result);
        if (result.data.addUser) {
          this.sendRegistrationEmail(result.data.addUser)
            .then((emailResult) => {
              console.log('emailResult', emailResult);
              resolve(result.data.addUser);
            })
            .catch((emailError) => {
              console.log('emailError', emailError);
              reject(emailError);
            });
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getUsers = () => new Promise((resolve, reject) => {
    const GET_USERS = gql`
      query{
        users{
          ${this.userGql}
        }
      }
    `;

    this.client
      .query({
        query: GET_USERS
      })
      .then((result) => {
        console.log('getUsers:result', result);
        if (result.data.users) {
          resolve(result.data.users);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getUser = (id) => new Promise((resolve, reject) => {
    console.log('getUser: id', id);
    const GET_USERS = gql`
      query User($id:Int!){
        user(Id:$id){
          ${this.userGql}
        }
      }
    `;

    this.client
      .query({
        query: GET_USERS,
        variables: { id }
      })
      .then((result) => {
        console.log('getUsers:result', result);
        if (result.data.user) {
          resolve(result.data.user);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  deleteUser = (ids) => new Promise((resolve, reject) => {
    const DELETE_CUSTOMER = gql`
      mutation User($ids: [Int]!){
        removeUsers(
          Ids: $ids
        ){
          Success
          Token
        }
      }
    `;

    this.client
      .mutate({
        mutation: DELETE_CUSTOMER,
        variables: { ids }
      })
      .then((result) => {
        console.log('deleteUser:result', result);
        if (result.data.removeUsers) {
          resolve(result.data.removeUsers);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

const userService = new UserService();

export default userService;
