import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from './Form';
import { INPUT_FIELD_TYPE_VALUES } from 'src/constants/inputFieldType';

/**
 * Composite component that renders a custom form within a
 * dialog to be displayed on the screen
 */
const FormDialog = ({
  className,
  title,
  subTitle,
  open,
  onClose,
  onExited,
  size,
  formSettings,
  isLoading,
  children,
}) => {
  const formikRef = useRef();
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth={size}
      onClose={onClose}
      TransitionProps={{
        onExited,
      }}
      open={open}
      className={className}
      onKeyDown={async (e) => {
        // Only fire if type is undefined (not any other falsely value), meaning an input or select element is not
        // in focus
        const targetElementType = e.target.type;
        if (
          e.key === 'Enter' &&
          formikRef.current &&
          targetElementType === undefined
        ) {
          formikRef.current.handleSubmit(e);
          await formikRef.current.setSubmitting(false);
        }
      }}
    >
      <DialogTitle>
        <Typography
          align="center"
          component="div"
          gutterBottom
          variant="h3"
          color="textPrimary"
        >
          {title}
        </Typography>
        <Typography
          component="div"
          align="center"
          variant="subtitle2"
          color="textSecondary"
        >
          {subTitle}
        </Typography>
      </DialogTitle>
      {formSettings ? (
        <Form
          isLoading={isLoading}
          onClose={onClose}
          isFormDialog
          formikRef={formikRef}
          {...formSettings}
        />
      ) : (
        children
      )}
    </Dialog>
  );
};

FormDialog.propTypes = {
  /**
   * Class name to apply to the outermost container element
   */
  className: PropTypes.string,
  /**
   * Title of the Dialog
   */
  title: PropTypes.string,
  /**
   * Subtitle of the Dialog
   */
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Boolean indicating if the Dialog is open / rendered
   */
  open: PropTypes.bool.isRequired,
  /**
   * Callback function that runs when the cancel button
   * is clicked. Is invoked with 1 parameter; the generated
   * event object from the button click
   */
  onClose: PropTypes.func,
  /**
   * Callback function to run after the dialog has been closed
   */
  onExited: PropTypes.func,
  /**
   * Size enum for the maxWidth of the Dialog
   */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  /**
   * Form settings to render a complete form; see Form component
   * for more details
   */
  formSettings: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
        size: PropTypes.shape({
          xs: PropTypes.number,
          sm: PropTypes.number,
          md: PropTypes.number,
          lg: PropTypes.number,
          xl: PropTypes.number,
        }),
      })
    ),
    dialogContent: PropTypes.element,
    initialValues: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    validationSchema: PropTypes.object,
    isLoading: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  /**
   * Boolean indicating if some loading is taking place; usually
   * connected to state
   */
  isLoading: PropTypes.bool,
  /**
   * If you want to render your element instead of a custom Form
   * pass in children
   */
  children: PropTypes.any,
};

FormDialog.defaultProps = {
  onClose: () => { },
  onExited: () => { },
  size: 'md',
  className: '',
  title: 'New Customer Form',
  subTitle: 'Please enter your information',
  open: true,
  formSettings: null,
  isLoading: false,
  children: null,
};

export default FormDialog;
