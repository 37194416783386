import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import PageWithBreadCrumbs from 'src/components/PageWithBreadCrumbs';
import TabTable from 'src/components/tables/TabTable';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import NewButton from 'src/components/buttons/NewButton';
import AddExistingButton from 'src/components/buttons/AddExistingButton';
import TooltipActions from 'src/components/crud/TooltipActions';
import { Edit as EditIcon, Times as TimesIcon } from '../../icons/fontawesome';
import * as Yup from 'yup';
import { upperCaseFirstLetter, plural } from 'src/utils/string';
import CreateUpdateDialog from './CreateUpdateDialog';
import { INPUT_FIELD_TYPE_VALUES } from 'src/constants/inputFieldType';

/**
 *  Component to create a page of a table given
 * a url, a model (roles, suites, users etc...). The majority
 * of the row creation and fetching is done by TabTable
 * component; this just renders some dialogs (create, delete)
 * sets up state, creates breadcrumbs, sets up validation
 * and passes the table settings props down to TabTable
 * -----------------------------------------------------
 *
 * Quirks/ Notes:
 *
 * a) Can also take in tabs config object in tableSettings prop
 *
 * b) Cannot use custom company filter on users unless returned with user response;
 * meaning it is only possible if server prop is enabled
 *
 * c) client side (server = false prop) must be rendered
 *  with standard pagination controls, if you attempt to
 * use the loadMoreButton = true prop all data is
 * immediately loadded into the table without respecting row
 * limit; if you want client side pagination you must omit the loadMoreButton
 * prop
 *
 * d) Currently pagination through the server side is only supported for users
 * (nsd-api-common-services) (potentially Companies endpoints needs
 * to implement this as well when there are many companies)
 *
 * e) loadMoreButton is only for the server=true prop and is only rendered
 * if skiptoken is not null; skiptoken is null on first data pull
 * and is returned by the intial request.
 *
 * f) Server side filtering works in production/dev but as
 * of now has not been mocked
 *
 * g) skiptoken is used only by the server implementation
 * and holds a number; the id of the last record fetched
 * into your table, it holds a cursor to let us know where
 * to continue reading data.
 * In the event skiptoken is false, the pagination is
 * at the end
 *
 * h) Table auto refreshes data for the current URL when any CRUD operation
 * is performed
 */
const CrudTablePage = ({
  actions,
  tableSettings: tblSettings,
  inputFields,
  selected,
  setSelected,
  model,
  url,
  params,
  createUpdateParams,
  modalSize,
  inputSize,
  pageHeaderSettings,
  children,
  onSubmit,
  addExistingSettings,
}) => {
  const tblRef = useRef(null);
  const tableRef = tblSettings.ref ?? tblRef;
  const { enqueueSnackbar } = useSnackbar();
  const [isAddExistingDialogOpen, setIsAddExistingDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const upperCaseModel = upperCaseFirstLetter(model);
  const upperCasePluralModel = upperCaseFirstLetter(plural(model));

  const handleCreate = async () => {
    await tableRef.current.refresh();
  };

  const handleClickEdit = (model) => (event) => {
    event.stopPropagation();
    setSelected(model);
    setIsCreateDialogOpen(true);
  };

  const handleClickDelete = (targetModel) => (event) => {
    event.stopPropagation();
    setSelected(targetModel);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${url}/${selected.id}`, {
        params: createUpdateParams
      });
      enqueueSnackbar(`${upperCaseModel} has been archived`, {
        variant: 'success',
      });
      tableRef.current.moveToFirstPage();
      await tableRef.current.refresh();
      setIsDeleteDialogOpen(false);
    } catch (e) {
      enqueueSnackbar(`Unable to delete ${model}`, { variant: 'error' });
    }
  };

  const handleCancelDelete = () => setIsDeleteDialogOpen(false);

  const actionMap = (actions || []).reduce(
    (acc, cur) => ({ ...acc, [cur]: true }),
    {}
  );
  let { tableRowDisplay } = tblSettings;
  const { tableRows, ...restTableSettings } = tblSettings;
  if (tableRows) {
    tableRowDisplay = (model) => {
      const tableRowsOutput = tableRows(model);
      // Note:  Actions must be in the last index position
      const actions = tableRowsOutput.pop();
      let tooltipActions = [];
      if (actionMap.update) {
        tooltipActions = [
          ...tooltipActions,
          {
            title: 'Edit',
            onClick: handleClickEdit(model),
            Icon: <EditIcon />,
          },
        ];
      }

      tooltipActions = [...tooltipActions, ...actions];
      if (actionMap.delete) {
        tooltipActions = [
          ...tooltipActions,
          {
            title: `Delete ${upperCaseModel}`,
            onClick: handleClickDelete(model),
            Icon: <TimesIcon />,
          },
        ];
      }
      return [...tableRowsOutput, <TooltipActions actions={tooltipActions} />];
    };
  }
  const tableSettings = {
    ref: tableRef,
    title: upperCasePluralModel,
    url,
    params,
    size: 'small',
    tableRowDisplay,
    ...restTableSettings,
  };

  const createValidationSchema = {};
  const createInputs = inputFields.map((inputField) => {
    const { validation, size, ...restInputField } = inputField;
    createValidationSchema[restInputField.name] = validation;
    return { ...restInputField, size: size ?? inputSize };
  });

  const addExistingValidationSchema = {};
  const addExistingInputs = addExistingSettings?.inputFields?.map((inputField) => {
    const { validation, size, ...restInputField } = inputField;
    createValidationSchema[restInputField.name] = validation;
    return { ...restInputField, size: size ?? inputSize };
  });

  return (
    <PageWithBreadCrumbs
      title={upperCasePluralModel}
      {...pageHeaderSettings}
      button={(<>
        {actionMap.create && (
          <NewButton
            title={upperCaseModel}
            onClick={() => setIsCreateDialogOpen(true)}
          />
        )}
        {actionMap.addExisting && (
          <AddExistingButton
            title={upperCaseModel}
            onClick={() => setIsAddExistingDialogOpen(true)}
          />
        )}
      </>)}

    >
      {children}
      {actionMap.create && (
        <CreateUpdateDialog
          size={modalSize ?? 'xs'}
          validationSchema={Yup.object().shape(createValidationSchema)}
          model={model}
          url={url}
          params={{ ...params, ...createUpdateParams }}
          open={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onExited={() => setSelected(null)}
          onSubmit={async (createdResourceData) => {
            await onSubmit(createdResourceData);
            await handleCreate();
          }}
          fields={createInputs}
          selected={selected}
        />
      )}
      {actionMap.addExisting && (
        <CreateUpdateDialog
          size={modalSize ?? 'xs'}
          validationSchema={Yup.object().shape(addExistingValidationSchema)}
          model={model}
          title={addExistingSettings.title}
          subTitle={addExistingSettings.subTitle}
          url={addExistingSettings.url}
          params={{ ...params, ...createUpdateParams }}
          open={isAddExistingDialogOpen}
          onClose={() => setIsAddExistingDialogOpen(false)}
          onExited={() => setSelected(null)}
          onSubmit={async (createdResourceData) => {
            await Promise.all([
              onSubmit(createdResourceData),
              handleCreate(),
            ]);
          }}
          fields={addExistingInputs}
          selected={selected}
        />
      )}
      {actionMap.delete && (
        <ConfirmationDialog
          title={`Delete ${upperCaseModel}`}
          subTitle={`Are you sure you want to delete this ${model}? You won't be able to undo this action.`}
          confirmText={`Delete ${upperCaseModel}`}
          cancelText="Don't Delete"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          onClose={handleCancelDelete}
          onExited={() => setSelected(null)}
          open={isDeleteDialogOpen}
        />
      )}
      <TabTable {...tableSettings} />
    </PageWithBreadCrumbs>
  );
};

CrudTablePage.propTypes = {
  /**
   * Function that will be passed as a prop into CreateUpdateDialog as onSubmit. This
   * will return the created object data of a successful POST call (PUT and DELETE return 204s).
   * This function is run then the tableref refresh is called to hydrate the table. This function
   * is awaited in CreateUpdateDialog so using an async function is supported
   */
  onSubmit: PropTypes.func,
  /**
   * Which actions you want to be able to perform on the table
   * data which is synced up through network calls.
   * "create" renders a button in the PageHeader next to
   * the breadcrumbs; its input fields are generated through
   * the inputFields props
   * "delete" renders in the actions column and is an 'x' icon
   * clicking brings up the delete dialog
   * "update" renders in the actions column and is a 'pencil' icon
   * clicking brings up the update dialog
   *
   * Could also be boolean, false (no actions rendered)
   */
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  /**
   * Settings object to customize anything about the generated
   * table (how rows are built, table headers, what to show
   * with no results, how to sort, what happens when a row is clicked
   * etc...)
   *
   *  ------------------------
   *
   *  ref Prop:
   *
   *
   *  1) getSelectedCustomFilter: (filterName) => filterObject  (if name matches)
   *
   *  2) moveToFirstPage: () => void  (Sets pagnation to page 1)
   *
   *  3) refresh: () => Promise void (clears response cache and refetches table data)
   *
   *  4) setSelectedCustomFilter: (customFilterName, newValue) => void
   *  ( goes to first page of pagnation and changes an existing custom filter
   *   to a new value)
   *
   * ------------------------
   *
   * tableRows:
   *
   * Callback that accepts an object of your selected resource
   * (a user object, a role object etc..) and returns a
   * array representing rows that has a length AND order
   * that MUST match the tableHeadDisplay array.
   * E.g. If the first item in tableHeadDisplay is Avatar
   * the first item in the returned array must be for the avatar
   * column.
   *
   *  The items in the returned array can be any
   * renderable value (not object or array) EXCEPT for
   * the last index which is always an array of action icons
   * to iterate over and build with the shape
   * {title: PropTypes.string, onClick: PropTypes.fn
   * (passed in the object used to render rows), Icon: PropTypes.element}
   *
   * ------------------------
   *
   * tableHeadDisplay
   *
   * Shape of returned array is
   *     { width: "3%", text: "Avatar" },
   *     { width: "12%", text: "First Name" },
   *     { width: "12%", text: "Last Name" },
   *     { width: "15%", text: "Email" },
   *     { width: "10%", text: "Roles" },
   *     { width: "16%", text: "Date Added" },
   *     "Actions",
   *
   *  Can be an object or just a string (uses auto width)
   *
   * ________________________
   *
   * searchSettings:
   *
   * queryFields is an array of fields found in your
   * choosen model/resource (user has firstName etc...)
   * that will be searched when text is typed in the searchbar
   *
   * placeholder can be a function or string
   * If it's a function it will be given a count (# of records)
   * value by TabTable as an argument which you can use
   * to return/construct a new placeholder string
   *
   * ------------------------
   *
   * sortOptions:
   *
   * An array of options to be rendered in the sort select;
   * the format of the value key within the objects should be
   * one of the following enum values
   *
   * ------------------------
   *
   * onRowClick:
   *
   * Callback that receives the selected resource (from the
   * row that was clicked); here you can do a history.push
   * to continue to deeper levels of navigation (if enabled)
   *
   *
   * ------------------------
   *
   * customFilters:
   *
   * Each object in this array is rendered as a select
   * and must include options; rendered next to the search
   * bar above the table header. If pass server=false as a prop
   * the filtering is done client side and you MUST PROVIDE
   * a filter callback in each of the customFilter objects.
   * filter: (filterName, tableRowData) => tableRowData?.companyType === filterName
   * The filter callback accepts 2 arguments; the current filtername and the tableRowData
   * (object 1 instance of fetched data)
   *
   * Autocomplete version of customFilter automically has options
   * grouped alphabetically
   */
  tableSettings: PropTypes.shape({
    ref: PropTypes.any,
    server: PropTypes.bool,
    idKey: PropTypes.string,
    loadMoreButton: PropTypes.bool,
    idKey: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium']),
    tableHeadDisplay: PropTypes.func,
    tableRows: PropTypes.func,
    noResultsSettings: PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    }),
    searchSettings: PropTypes.shape({
      placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      queryFields: PropTypes.arrayOf(PropTypes.string),
    }),
    sortOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOf([
          'name|asc',
          'name|desc',
          'createdAt|desc',
          'createdAt|asc',
          'id|asc',
          'id|desc',
        ]).isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    cursorOnRowHover: PropTypes.bool,
    onClickRow: PropTypes.func,
    customFilters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        width: PropTypes.string,
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.oneOf(['all']),
              // When using js Date() object
              PropTypes.object,
            ]),
            label: PropTypes.string.isRequired,
          })
        ),
        onChange: PropTypes.func,
        filter: PropTypes.func,
        isAutocomplete: PropTypes.bool,
        type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
      })
    ),
  }),
  /**
   * Array of inputs to be rendered in the create dialog
   * (if specified in actions prop).
   * Validation is done through Formik and Yup, validation
   * has this format e.g. Yup.string().max(100).required()
   */
  inputFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        })
      ),
      validation: PropTypes.object,
    })
  ),
  /**
   * Used to specific the width for the creation/update form
   * attached to CrudTablePage. The breakpoints correspond to
   * different device widths.
   * https://v4.mui.com/customization/breakpoints/#breakpoints
   *
   * A row has a total number of 12 columns so by passing in a config of
   * xs: 12, all the inputs will be full-width for all device widths.
   */
  inputSize: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  /**
   * An object representing the selected resource you will be
   * performing the operation (delete, edit) on. It is set
   * when any of the action icons are clicked
   */
  selected: PropTypes.object,
  /**
   * Callback that accepts an object; usually a state var setter
   * that is passed from a parent
   */
  setSelected: PropTypes.func,
  /**
   * Which model (e.g. roles, users, suites, modules etc..)
   * of data should this table fetch; this affects the
   * title displayed, the text on the create button and what
   *  endpoint is fetched (e.g. url + pluralWord(model) is how the endpoint is
   * constructued).
   *
   */
  model: PropTypes.string,
  /**
   * Url to fetch; axios instance internally used already
   * has the environment variable REACT_APP_API as the baseUrl
   * so this is any route on that base site
   * (e.g. REACT_APP_API= https://localhost:7071/api/ + url
   *  is how the endpoint is constructed)
   */
  url: PropTypes.string,
  /**
   * Parameters that you wish to send along with every
   * request (on create, read,  delete and update)
   */
  params: PropTypes.object,
  /**
   * Parameters that you wish to send along with only update
   * requests
   */
  createUpdateParams: PropTypes.object,
  /**
   * Size of all the create dialog displayed
   */
  modalSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  /**
   * To be given to each object in the inputFields array
   * to determine the size of the inputs displayed in the
   * dialog using the grid system of Material UI
   * https://v4.mui.com/components/grid/#grid. This is only
   * applied to objects in the inputFields array without a size
   * key
   */
  inputSize: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  /**
   * Props to pass into the PageHeader component to generate
   * the breadcrumbs and weather or not to render a select
   * and/or a button in the header next to the breadcrumbs.
   * If you are using 'create' as one of your actions
   * your button prop will be overwritten and CrudTablePage
   * will automatically create the button
   */
  pageHeaderSettings: PropTypes.shape({
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        href: PropTypes.string,
        resourceName: PropTypes.string,
      })
    ),
    children: PropTypes.any,
    title: PropTypes.string,
    button: PropTypes.element,
    headerMenuSettings: PropTypes.shape({
      settings: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['single', 'multi']).isRequired,
          value: PropTypes.string,
          className: PropTypes.string,
          label: PropTypes.string.isRequired,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
              Icon: PropTypes.object,
            })
          ),
          changeCallback: PropTypes.func,
          Icon: PropTypes.object,
        })
      ),
    }),
  }),
  /**
   * Children to be rendered below the breadcrumbs header
   */
  children: PropTypes.any,

  /**
 * Object to support table labels/text in different languages if desired,
 * for react-i18next useTranslation hook pass in your translated string (e.g. t('something'))
 */
  translationStrings: PropTypes.shape({
    downloadButtonText: PropTypes.string,
    sortByText: PropTypes.string,
    deleteButtonText: PropTypes.string,
    editButtonText: PropTypes.string,
    rowsPerPageText: PropTypes.string,
    ofNRowsText: PropTypes.string,
    moreThanNRowsText: PropTypes.string,
    loadMoreButtonText: PropTypes.string,
  })
};

CrudTablePage.defaultProps = {
  actions: ['create', 'update', 'delete'],
  selected: {},
  setSelected: () => { },
  tableSettings: null,
  inputFields: null,
  url: null,
  model: 'Role',
  params: null,
  createUpdateParams: null,
  modalSize: 'lg',
  inputSize: { xs: 6 },
  pageHeaderSettings: null,
  onSubmit: () => { },
  translationStrings: {
    downloadButtonText: "Download",
    sortByText: "Sort By",
    deleteButtonText: "Delete",
    editButtonText: "Edit",
    rowsPerPageText: "Rows per Page",
    ofNRowsText: "of",
    moreThanNRowsText: "more",
    loadMoreButtonText: "Load More"
  }
};

export default CrudTablePage;
