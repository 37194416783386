import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { logout } from '@nsd/fe';
import { useTranslation } from 'react-i18next';
import addressTypesService from 'src/services/addressTypesService';
import contactMethodsService from 'src/services/contactMethodsService';
import phoneTypesService from 'src/services/phoneTypesService';
import serviceService from 'src/services/servicesService';
import useAgents from 'src/hooks/useAgents';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

/**
 * Component meant to be used in TopBar; renders the logged-in
 * user's name and avatar and displays a logout and Edit profile
 * button
 */
function Account() {
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { removeCachedAgents } = useAgents();

  const gqlServices = [
    addressTypesService,
    contactMethodsService,
    phoneTypesService,
    serviceService,
  ];

  const handleLogout = async () => {
    try {
      gqlServices.forEach(service => {
        service.clearStore();
      });
      handleClose();
      removeCachedAgents();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        id="topbar-account-box"
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          id="topbar-account"
          alt="User"
          className={classes.avatar}
          src={account?.user?.avatar}
        />
        <Hidden smDown>
          <Typography id="account-first-name-last-name" variant="h6">
            {`${account?.user?.firstName} ${account?.user?.lastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        id="topbar-account-menu"
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem id="menu-item-logout" onClick={handleLogout}>
          {t('TopBar.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}

Account.propTypes = {
  /**
   * Used to determine how to style the navbar
   */
  type: PropTypes.oneOf(['client', 'employee']),
};

export default Account;
