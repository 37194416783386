import CACHE_TIME from "src/constants/cacheTime";

/**
 * This service implements caching of data
 * received by our APIs to give our requests
 * a performance boost. CacheService should
 * be used in data fetching functions that
 * directly call our APIs. 
 */
class CacheService {
  #cache = {};

  /**
   * @return {any}
   */
  getFromCache(key) {
    const cacheKey = JSON.stringify(key);
    return this.#cache[cacheKey];
  }

  /**
   * In addition to writing data to the
   * cache, this method will also delete
   * cached data after an amount of time
   * specified by the `cacheTime` param.
   * @param {any}     key
   * @param {any}     value
   * @param {number}  cacheTime
   * @return {void}
   */
  saveToCache(
    key, 
    value, 
    cacheTime = CACHE_TIME.FIVE_MIN
  ) 
  {
    const cacheKey = JSON.stringify(key);
    this.#cache =  {
      ...this.#cache,
      ...{ [cacheKey]: value }
    };

    if (cacheTime) {
      setTimeout(
        () => this.removeFromCache(cacheKey), 
        cacheTime
      );
    }
  }

  /**
   * @private
   * @param {string} key
   * @return {void}  
   */
  removeFromCache(key) {
    const { 
      [key]: _, 
      ...cacheAfterKeyRemove 
    } = this.#cache;

    this.#cache = cacheAfterKeyRemove;
  }
}

const cacheService = new CacheService();

export default cacheService;
