import { lazy } from 'react';
import { AuthGuard } from '@nsd/fe';
import { Search as SearchIcon } from 'react-feather';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { appIcon } from '../appIcon';

const MODULES = {
  DOCUMENTS: 'documents-4af23cfd-23b0-4ad4-9755-a71de8865322'
};

const appConfig = {
  appId: 'documents-ce087a5a-79d2-4832-8cf1-fad9da5153ae',
  appName: 'documents.documents',
  priority: 4,
  path: 'documents',
  icon: appIcon.documents,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: 'search',
      view: lazy(() => import('src/views/DocumentsSearchView/index'))
    }
  ],
  topBarConfig: {
    contacts: false,
    search: false,
    notifications: true,
    settings: true
  },
  navConfig: {
    navBarItems: [
      {
        subheader: 'documents.documents',
        items: [
          {
            title: 'documents.documentSearch',
            href: 'search',
            icon: SearchIcon,
            module: MODULES.DOCUMENTS
          }
        ]
      }
    ]
  }
};

export default appConfig;
