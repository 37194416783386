import { lazy } from 'react';
import { AuthGuard } from '@nsd/fe';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { appIcon } from '../appIcon';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@material-ui/icons';


const MODULES = {
  TURO_CLIENT_DASHBOARD: 'analytics-1a6d0bfb-d422-43e0-8db9-b518d41239e4',
};

const appConfig = {
  appId: 'reports-6f0f70f8-3ba0-4918-a8fe-520b3f7a6ba9',
  appName: 'claims.reports',
  priority: 1,
  path: 'reports',
  icon: appIcon.reportsQoreApp,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: 'reports',
      view: lazy(() => import('src/views/ReportsDocumentsView/index')),
    },
    {
      exact: true,
      path: 'embeddedReports',
      view: lazy(() => import('src/views/EmbeddedReports/index')),
    },
  ],
  topBarConfig: {
    contacts: false,
    search: false,
    notifications: true,
    settings: true,
  },
  navConfig: {
    navBarItems: [
      {
        subheader: 'claims.reports',
        items: [
          {
            title: 'claims.reports',
            icon: DescriptionOutlinedIcon,
            href: 'reports',
          },
          {
            title: 'Embedded Reports',
            icon: DescriptionOutlinedIcon,
            href: 'embeddedReports',
            module: MODULES.TURO_CLIENT_DASHBOARD,
          },
        ],
      },
    ],
  },
};

export default appConfig;
