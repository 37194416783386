const CdnAppIcon = "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/providerQoreAppIcon.png";

const appConfig = {
  appId: 'providerQore-bea69903-f904-43ef-809b-93679d96c4b7',
  appName: 'Provider',
  site: 'provider-qore',
  icon: CdnAppIcon
};

export default appConfig;
