import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import usePrevious from "src/hooks/usePrevious";
import isEqual from "lodash/isEqual";

export const FIRST_PAGE = 1;

/**
 * Higher order component meant to be wrapped in TabTable component;
 * responsible for constructing the pagination state object and creating
 * the state using your selected custom filters.
 * Sort options come from the sortOptions of CrudTablePage
 */
export default (Table) => {
  const TableWithPagination = ({
    rowsPerPage,
    paginationSettings,
    ...rest
  }) => {
    // if using load more button, don't send page number
    const { sortOptions, sortSettings, loadMoreButton, customFilters } = rest;
    const orderOptions = sortSettings?.options ?? sortOptions;
    const filters = rest?.customFilters?.reduce(
      (acc, { name, options, value }) => ({ ...acc, [name]: value ?? options?.[0]?.value }),
      {}
    );

    const paginationState = useState({
      limit: rowsPerPage,
      filters,
      ...(loadMoreButton && { skiptoken: null }),
      ...(!loadMoreButton && { page: FIRST_PAGE }),
    });

    const prevOrderOptions = usePrevious(orderOptions);
    useEffect(() => {
      if (!isEqual(orderOptions, prevOrderOptions)) {
        const [_, setPaginationState] = paginationState;
        setPaginationState((prevPagination) => ({
          ...prevPagination,
          sort: orderOptions ? orderOptions[0].value : null,
        }));
      }
    }, [orderOptions, prevOrderOptions, paginationState]);

    return (
      <Table
        paginationState={paginationState}
        paginationSettings={{
          paginate: loadMoreButton ? false : true,
          ...paginationSettings,
        }}
        {...rest}
      />
    );
  };

  TableWithPagination.propTypes = {
    /**
     * How many table rows per page to display
     */
    rowsPerPage: PropTypes.number,

    /**
     * Pagination settings object passed in as prop from
     * TabTable
     */
    paginationSettings: PropTypes.shape({
      paginate: PropTypes.bool,
    }),
  };

  TableWithPagination.defaultProps = {
    rowsPerPage: 25,
    paginationSettings: null,
  };

  return TableWithPagination;
};
