import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { IconButton, Grid, Slider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon,
  MoreVert as MoreVertIcon,
  GetApp as GetAppIcon,
} from "@material-ui/icons";
import { secondsToHoursMinutesSeconds } from "src/utils/time";
import { useSnackbar } from "notistack";
import CircularLoader from "../CircularLoader";
import DropdownButton from "../DropdownButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 30,
    height: 60,
    width: 320,
    maxWidth: "100%",
    boxShadow: theme.shadows[1],
    padding: 8,
  },
  icon: {
    width: 20,
    height: 20,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0.2s, opacity 0.3s ease-out",
  },
  visible: {
    visibility: "visible",
    opacity: 1,
    transition: "visibility 0.1s, opacity 0.1s linear",
  },
  trackPositionRail: {
    marginTop: -1,
    height: 4,
    borderRadius: 2,
  },
  volume: {
    display: "flex",
    alignItems: "center",
    borderRadius: 25,
    cursor: "pointer",
    position: "absolute",
    right: 0,
    width: 44,
    height: 44,
    padding: 15,
    transition: "width 0.1s ease-out",
    "&:hover": {
      transition: "width 0.2s ease-in, background 0.1s",
      width: "80%",
      background: "#cad0d4",
      "& > span": {
        visibility: "visible",
      },
    },
  },
  volumeSlider: {
    marginRight: 30,
    marginLeft: 5,
  },
  trackPositionSlider: {
    marginRight: 45,
  },
  trackPositionContainer: {
    position: "relative",
    marginRight: -10,
  },
  volumeIcon: {
    position: "absolute",
    right: 13,
  },
}));

/**
 *  Custom audio player that takes a audio file link
 */
const AudioPlayer = ({ className, src }) => {
  const classes = useStyles();
  const [audio] = useState(new Audio(src));
  const [audioPlayer, setAudioPlayer] = useState({
    isLoading: true,
    trackPositionHovered: false,
    volumeSliderHovered: false,
    isPlaying: false,
    totalTime: null,
    currentTime: null,
    isMuted: false,
    currentVolume: 1,
  });

  const setTrackPositionHovered = (newVal) => {
    setAudioPlayer((oldAudioPlayer) => ({
      ...oldAudioPlayer,
      trackPositionHovered: newVal,
    }));
  };

  const setVolumeSliderHovered = (newVal) => {
    setAudioPlayer((oldAudioPlayer) => ({
      ...oldAudioPlayer,
      volumeSliderHovered: newVal,
    }));
  };

  const setIsPlaying = (newVal) => {
    setAudioPlayer((oldAudioPlayer) => ({
      ...oldAudioPlayer,
      isPlaying: newVal,
    }));
  };

  const displayTrackPositionThumb = () => setTrackPositionHovered(true);
  const hideTrackPositionThumb = () => setTrackPositionHovered(false);

  const displayVolumeSliderThumb = () => setVolumeSliderHovered(true);
  const hideVolumeSliderThumb = () => setVolumeSliderHovered(false);

  const handleChangeThumbPosition = (_, newPosition) => {
    if (audioPlayer.isPlaying) {
      setAudioPlayer((oldAudioPlayer) => ({
        ...oldAudioPlayer,
        wasPlayingBeforeThumbPositionChange: true,
      }));
    }
    audio.pause();
    audio.currentTime = (audioPlayer.totalTime * newPosition) / 100;
  };

  const handleChangeThumbPositionCommitted = () => {
    if (audioPlayer.wasPlayingBeforeThumbPositionChange) {
      audio.play();
      setAudioPlayer((oldAudioPlayer) => ({
        ...oldAudioPlayer,
        wasPlayingBeforeThumbPositionChange: false,
      }));
    }
  };

  const toggleVolume = () => (audio.muted = !audio.muted);

  const downloadAudio = () => {
    const a = document.createElement("a");
    a.href = src;
    a.download = "CallRecording.mp3";
    a.click();
  };

  const handleChangeVolumeThumbPosition = (_, newPosition) => {
    audio.volume = newPosition / 100;
    audio.muted = false;
  };

  const handleTogglePlayAndPause = () => {
    if (audioPlayer.isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  const handleClickMoreOptionMenuItem = (val) => {
    if (val === "download") {
      downloadAudio();
    }
  };

  const PlayPauseIcon = audioPlayer.isPlaying ? PauseIcon : PlayArrowIcon;
  const VolumeIcon =
    audioPlayer.isMuted || audioPlayer.currentVolume === 0
      ? VolumeOffIcon
      : VolumeUpIcon;

  const { enqueueSnackbar } = useSnackbar();

  /* Define functions to enable removing event listeners */
  const onErrorEvent = () => {
    setAudioPlayer((oldAudioPlayer) => ({
      ...oldAudioPlayer,
      isLoading: false,
    }));
    enqueueSnackbar("Unable to play recording", { variant: "error" });
  };
  const onPlayEvent = () => setIsPlaying(true);
  const onPauseEvent = () => setIsPlaying(false);
  const onEndEvent = () => setIsPlaying(false);
  const onLoadDataEvent = () =>
    setAudioPlayer((oldAudioPlayer) => ({
      ...oldAudioPlayer,
      isLoading: false,
      totalTime: audio.duration,
      currentTime: audio.currentTime,
    }));
  const onTimeUpdateEvent = () =>
    setAudioPlayer((oldAudioPlayer) => ({
      ...oldAudioPlayer,
      currentTime: audio.currentTime,
    }));

  const onVolumeChangeEvent = () =>
    setAudioPlayer((oldAudioPlayer) => ({
      ...oldAudioPlayer,
      currentVolume: audio.volume,
      isMuted: audio.muted,
    }));

  useEffect(() => {
    audio.addEventListener("error", onErrorEvent);
    audio.addEventListener("play", onPlayEvent);
    audio.addEventListener("pause", onPauseEvent);
    audio.addEventListener("ended", onEndEvent);
    audio.addEventListener("loadeddata", onLoadDataEvent);
    audio.addEventListener("timeupdate", onTimeUpdateEvent);
    audio.addEventListener("volumechange", onVolumeChangeEvent);
    return () => {
      audio.pause();
      audio.removeEventListener("error", onErrorEvent);
      audio.removeEventListener("play", onPlayEvent);
      audio.removeEventListener("pause", onPauseEvent);
      audio.removeEventListener("ended", onEndEvent);
      audio.removeEventListener("loadeddata", onLoadDataEvent);
      audio.removeEventListener("timeupdate", onTimeUpdateEvent);
      audio.removeEventListener("volumechange", onVolumeChangeEvent);
    };
  }, []);

  if (audioPlayer.isLoading) return <CircularLoader />;

  return (
    <div className={clsx(classes.root, className, classes.flexCenter)}>
      <Grid container spacing={1}>
        <Grid item className={classes.flexCenter}>
          <IconButton
            onClick={handleTogglePlayAndPause}
            aria-label="play/pause"
          >
            <PlayPauseIcon className={classes.icon} />
          </IconButton>
        </Grid>
        <Grid item className={classes.flexCenter}>
          <Typography align="center" variant="body2" color="textPrimary">
            {secondsToHoursMinutesSeconds(audioPlayer.currentTime)} /{" "}
            {secondsToHoursMinutesSeconds(audioPlayer.totalTime)}
          </Typography>
        </Grid>
        <Grid
          item
          xs
          className={clsx(classes.flexCenter, classes.trackPositionContainer)}
        >
          <Slider
            value={100 * (audioPlayer.currentTime / audioPlayer.totalTime)}
            className={classes.trackPositionSlider}
            classes={{
              thumb: clsx({
                [classes.hidden]: !audioPlayer.trackPositionHovered,
                [classes.visible]: audioPlayer.trackPositionHovered,
              }),
              rail: classes.trackPositionRail,
              track: classes.trackPositionRail,
            }}
            onChangeCommitted={handleChangeThumbPositionCommitted}
            onChange={handleChangeThumbPosition}
            onMouseEnter={displayTrackPositionThumb}
            onMouseLeave={hideTrackPositionThumb}
            aria-labelledby="position"
          />
          <div className={classes.volume}>
            <Slider
              value={audioPlayer.isMuted ? 0 : 100 * audioPlayer.currentVolume}
              className={classes.volumeSlider}
              classes={{
                thumb: clsx({
                  [classes.hidden]: !audioPlayer.volumeSliderHovered,
                  [classes.visible]: audioPlayer.volumeSliderHovered,
                }),
                rail: classes.trackPositionRail,
                track: classes.trackPositionRail,
              }}
              onChange={handleChangeVolumeThumbPosition}
              onMouseEnter={displayVolumeSliderThumb}
              onMouseLeave={hideVolumeSliderThumb}
              aria-labelledby="volume"
            />
            <VolumeIcon
              className={clsx(classes.volumeIcon, classes.icon)}
              onClick={toggleVolume}
            />
          </div>
        </Grid>
        <Grid item className={classes.flexCenter}>
          <DropdownButton
            Icon={<MoreVertIcon fontSize="small" />}
            dropDownList={[
              { value: "download", text: "Download", Icon: <GetAppIcon /> },
            ]}
            onClickListItem={handleClickMoreOptionMenuItem}
            MenuProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

AudioPlayer.propTypes = {
  /**
   * Custom class to apply to the outermost container
   * element
   */
  className: PropTypes.string,
  /**
   * Source URL to audio file
   */
  src: PropTypes.string,
};

AudioPlayer.defaultProps = {
  className: "",
  src: "",
};

export default AudioPlayer;
