import React, { forwardRef } from "react";
import Chart from "react-apexcharts";
import CircularLoader from "src/components/CircularLoader";
import { Card, CardContent, Typography, Chip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DropdownButton from "src/components/DropdownButton";
import NoResults from "src/components/NoResults";
import withAsync from "./withAsync";
import withFullScreenToggle from "./withFullScreenToggle";
import { branch, compose } from "src/utils";

const useStyles = makeStyles(() => ({
  cardContent: {
    position: "relative",
  },
  chartTitle: {
    flexGrow: 1,
  },
  chart: {},
  cardHeader: {
    display: "flex",
  },
  moreButton: {
    marginRight: 5,
  },
}));

/**
 * Component that is combined with 2 HOCs (WithAsync; to fetch
 * chart data, withFullScreen to add fullscreen functionality) to
 * display a chart and its associated data on a card with the
 * option to switch between multiple charts
 */
const ChartCard = ({
  src,
  isLoading,
  response,
  activeChartType,
  onChangeChartType,
  chartTypes,
  chartProps,
  createChart,
  chartHeight,
  cardAction,
  fullScreenButton,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const chartTypesMap = chartTypes?.reduce((acc, { type, ...rest }) => {
    acc[type] = { ...rest };
    return acc;
  }, {});

  const moreCardAction = (
    <DropdownButton
      className={classes.moreButton}
      size="small"
      Button={
        <Chip
          variant="outlined"
          color="primary"
          size="small"
          clickable
          label="More Charts"
        />
      }
      dropDownList={chartTypes}
      onClickListItem={(chartType) => {
        onChangeChartType(chartType);
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
    />
  );

  if (!createChart) return;

  let content;
  if (isLoading) {
    content = <CircularLoader />;
  } else if (!response) {
    content = <NoResults iconSize={140} />;
  } else {
    const {
      data: { [activeChartType]: chartData },
    } = response;
    if (chartData) {
      content = (
        <Chart
          type={activeChartType || "line"}
          height={chartHeight}
          {...createChart(
            theme,
            chartData,
            chartTypesMap[activeChartType],
            chartProps
          )}
        />
      );
    }
  }

  const { text: activeChartTypeText, info: activeChartTypeInfo } =
    chartTypesMap[activeChartType];

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <div className={classes.cardHeader}>
          <Typography
            variant="h4"
            color="textPrimary"
            className={classes.chartTitle}
          >
            {activeChartTypeText}
          </Typography>
          {cardAction}
          {moreCardAction}
          {fullScreenButton}
        </div>
        <div
          style={{ height: chartHeight === "100%" ? "90vh" : chartHeight }}
          className={classes.chart}
        >
          {content}
        </div>
        {activeChartTypeInfo && (
          <Typography color="textSecondary" variant="h6">
            {activeChartTypeInfo}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

ChartCard.propTypes = {
  /**
   * Config object to determine where the data will be
   * fetched from along with a params object of key-val
   * pairs
   */
  src: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.object,
  }),
  /**
   * While true a circular loader is rendered in the chart
   */
  isLoading: PropTypes.bool,
  /**
   * The response from the fetched data; only used if the
   * src prop is passed in; this prop is generated by the
   * withAysnc HOC
   */
  response: PropTypes.object,
  /**
   * String for the type of chart you are currently rendering.
   * https://apexcharts.com/docs/options/chart/type/
   */
  activeChartType: PropTypes.oneOf([
    "line",
    "area",
    "bar",
    "radar",
    "histogram",
    "pie",
    "donut",
    "radialBar",
    "scatter",
    "bubble",
    "heatmap",
    "candlestick",
  ]),
  /**
   * Callback that runs when the dropdown menu has a new
   * option clicked. It receives 1 argument; the value key
   * from the selected chartTypes array that rendered the
   * option in the dropdown
   */
  onChangeChartType: PropTypes.func,
  /**
   * Any additonal elements you wish to render above the
   * CardChart as part of the actions section
   */
  cardAction: PropTypes.element,
  /**
   * Additional props/ configuration object that is passed into
   * the createChart() function to generate the final config. It
   * can contain any of the valid props for react-apex-charts Chart
   * component.
   * type is already specified through activeChartType
   * and series is generated from the data fetched.
   * Series shape changes depending on what chart type
   * you render; see docs (also for options object)
   * https://www.npmjs.com/package/react-apexcharts
   */
  chartProps: PropTypes.shape({
    series: PropTypes.array,
    type: PropTypes.oneOf([
      "line",
      "area",
      "bar",
      "radar",
      "histogram",
      "pie",
      "donut",
      "radialBar",
      "scatter",
      "bubble",
      "heatmap",
      "candlestick",
    ]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.object,
  }),
  /**
   * Array of objects representing the possible types of
   * charts that can be displayed and switched via an dropdown
   * menu in the CardChart actions, can also hold configuration
   * for that specified chart (see options ). One of this objects
   * is passed in to createChart as the 3rd argument
   * https://apexcharts.com/docs/options/
   * https://apexcharts.com/docs/options/xaxis/
   * https://apexcharts.com/docs/options/yaxis/
   */
  chartTypes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      info: PropTypes.string,
      type: PropTypes.string,
      yaxis: {
        label: PropTypes.string.isRequired,
      },
      xaxis: PropTypes.object,
      yaxis: PropTypes.object,
    })
  ),
  /**
   * Callback function that is invoked to create the final
   * configuration for react-apex-charts Chart component. Accepts
   * 4 arguments;
   * theme -> The theme object applied
   * chartData -> The fetched data from the withAsync HOC
   * chartTypeDetails -> The details for the currently selected
   * chart; the corresponding object from the chartTypes array
   * chartProps -> Any additional chart props passed in
   */
  createChart: PropTypes.func.isRequired,
  /**
   * Desired height of the chart as a number (px) or a string
   * (vh, % etc..)
   */
  chartHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The element to render as the fullscreen button
   */
  fullScreenButton: PropTypes.element,
};

ChartCard.defaultProps = {
  onChangeChartType: () => {},
  chartHeight: 300,
  fullScreenButton: null,
  createChart: null,
  chartTypes: [],
  chartProps: null,
  cardAction: null,
  onChangeChartType: () => {},
  activeChartType: "line",
  src: null,
};

// load chart using async endpoint if src is a prop
const withAsyncDataLoad = branch((props) => props.src !== undefined, withAsync);

// add toggle full screen button
const withFullScreen = branch(
  (props) => props.fullScreenButton !== false,
  withFullScreenToggle
);

const ChartCardWithDataSource = compose(
  withAsyncDataLoad,
  withFullScreen
)(ChartCard);

export default forwardRef((props, ref) => {
  return <ChartCardWithDataSource {...props} forwardref={ref} />;
});
