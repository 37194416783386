import esDocumentStrings from './documents';
import esDispatchStrings from './dispatch';
import esBillingStrings from './billing';
import esClaimsStrings from './claims';
import esInsuranceStrings from './insurance';

export default {
  agents: 'Agentes',
  brands: 'Marcas',
  agentCodes: 'Códigos de Agente',
  brandCodes: 'Códigos de marca',
  allAgents: 'Todos los Agentes',
  allBrands: 'Todas las marcas',
  manyAgents: 'Muchos Agentes',
  multipleBrands: 'Múltiples marcas',
  apps: 'Aplicaciones',
  settings: 'Ajustes',
  settings_languages: 'Idiomas',
  settings_responsiveFontSizes: 'Tamaños de Fuente Receptivos',
  settings_rtl: 'DAI',
  settings_saveSettings: 'Guardar Ajustes',
  settings_theme: 'Tema',
  settings_updated: 'Ajustes Actualizan',
  settings_updated_note:
    'Actualizamos automáticamente su configuración. Puede cambiar la configuración en cualquier momento desde la configuración de su panel de control.',
  logout: 'Cerrar Sesión',
  support: {
    support: 'Apoyo',
    requestFeature: 'Solicitar una característica',
    reportBug: 'Reportar un error',
    topic: 'Tema',
    description: 'Descripción',
    addScreenshot: 'Agregar una captura de pantalla (opcional)',
    towOrder: 'Orden de Remolque',
    dispatchReporting: 'Informes de despacho',
    documents: esDocumentStrings.documents,
    reports: esDispatchStrings.reports,
    billing: esBillingStrings.billing,
    claims: esClaimsStrings.claims,
    insurance: esInsuranceStrings.insurance,
    thankYouForYourFeedback: '¡Gracias por tus comentarios!',
    weAppreciateYourPartnership: 'Agradecemos su colaboración',
    couldNotSubmitRequest: 'Desafortunadamente, no pudimos enviar su solicitud.',
  },
};