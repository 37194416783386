import React, { useRef } from "react";
import DropdownMenu from "src/components/DropdownMenu";
import { Link, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import AppsIcon from "@material-ui/icons/Apps";
import { getAllApps } from "src/utils/routes";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useAssetSettings } from "src/hooks/useUserSettings";
import { getUrl } from "src/utils/env";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
    textAlign: "center",
    borderRadius: "3px",
    padding: "10px 7px 7px 7px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  appIcon: {
    width: "50px",
  },
  appItemText: {
    whiteSpace: "pre-wrap",
    lineHeight: "16px",
  },
}));

const alphabetizeApps = (apps) => {
  return apps.sort((a, b) => a.appName.localeCompare(b.appName));
};

/**
 * Component meant to be used in the TopBar component of
 * the Dashboard Layout view. Renders a clickable dropdown menu
 * of all apps available to the logged in user
 */
const Apps = ({ apps }) => {
  const classes = useStyles();
  const { hasAppAccess } = useAssetSettings();
  const menuRef = useRef();
  const handleCloseDropDownMenu = () => menuRef.current.close();
  const { type } = useSelector((state) => state.application);
  /*  
    getAllApps filters by priority field by default to help generate routes
    Add a sort here to alphabetize the displayed apps.
    For NSD employees (QORE apps) skip the app filter to give complete access
    For Clients actually run the apps through the associated filter
   */
  const allApps = alphabetizeApps(getAllApps(apps, type));

  if (!type) return null;
  return (
    <DropdownMenu
      ref={menuRef}
      Icon={
        <SvgIcon fontSize="small">
          <AppsIcon />
        </SvgIcon>
      }
      IconButtonProps={{ color: "inherit" }}
      PopoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }}
      title="Apps"
      tooltip
    >
      <Grid container spacing={3}>
        {allApps
          .filter((app) => hasAppAccess(app.appId))
          .map((app) => {
            const img = new Image();
            img.src = app.icon;
            return (
              <Grid key={app.appId} item xs={4}>
                <Link
                  color="textPrimary"
                  display="block"
                  underline="none"
                  noWrap
                  {...(app.path
                    ? {
                      to: `/${app.path}`,
                      component: RouterLink,
                    }
                    : {
                      onClick: (e) => {
                        window.location = getUrl(app.site);
                        handleCloseDropDownMenu(e);
                      },
                    })}
                  variant="h6"
                  className={classes.link}
                >
                  <Box>
                    <img
                      alt={app.name}
                      className={classes.appIcon}
                      src={app?.icon}
                    />
                  </Box>
                  <Box className={classes.appItemText}>{app.appName}</Box>
                </Link>
              </Grid>
            );
          })}
      </Grid>
    </DropdownMenu>
  );
};

Apps.propTypes = {
  /**
   * Array of app objects that the logged in user has access to
   */
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      appId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      site: PropTypes.string,
    })
  ),
  /**
   * Type of user that is currently logged in
   */
  // type: PropTypes.oneOf(["employee", "client"]),
};

Apps.defaultProps = {
  type: "client",
  apps: null,
};

export default Apps;
