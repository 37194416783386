import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  landRegionTitle: {
    textAnchor: "middle",
    fill: "#000000",
  },
  legend: {
    overflow: "visible",
    display: "block",
  },
  scale: {
    textAnchor: "middle",
  },
  title: {
    fill: "currentColor",
    textAnchor: "start",
  },
}));

/**
 * IMPORTANT: This React.Component is only suited for use inside of SVG
 * elements with the viewBox configured to "30 0 650 400".
 */
const CountryMapLegend = ({
  colorScale,
  title,
  landRegionsTitle = i18next.t("states"),
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  let scaleProps = {
    height: 30,
    width: 150,
    rectHeight: 6,
    xPos: 390,
    yPos: 20,
  };
  scaleProps = {
    ...scaleProps,
    rectWidth: scaleProps.width / colorScale.length,
  };
  const legendGradient = colorScale.map((fill, i) => ({
    fill,
    x: i * scaleProps.rectWidth,
  }));
  return (
    <>
      <g>
        <text
          className={classes.landRegionTitle}
          transform="translate(634, 34)"
        >
          {landRegionsTitle}
        </text>
      </g>
      <g transform={`translate(${scaleProps.xPos},${scaleProps.yPos})`}>
        <svg
          width={scaleProps.width}
          height={scaleProps.height}
          viewBox={`0,0,${scaleProps.width},${scaleProps.height}`}
          className={classes.legend}
        >
          <g>
            {legendGradient.map(({ x, fill }) => (
              <rect
                key={fill}
                x={x}
                y="18"
                width={scaleProps.rectWidth}
                height={scaleProps.rectHeight}
                fill={fill}
              />
            ))}
          </g>
          <g transform="translate(0,28)" className={classes.scale}>
            {legendGradient.slice(1).map(({ x, fill }, ind) => {
              return (
                <g key={fill} transform={`translate(${x + 0.5},0)`}>
                  <line stroke="currentColor" y2="-1" y1="-10"></line>
                  {(ind === 0 || ind === legendGradient.length - 2) && (
                    <text
                      fill="currentColor"
                      y={scaleProps.rectHeight - 2}
                      dy="0.60em"
                    >
                      {ind === 0 ? t("least") : t("most")}
                    </text>
                  )}
                </g>
              );
            })}
            <text className={classes.title} x="0" y="-16">
              {title}
            </text>
          </g>
        </svg>
      </g>
    </>
  );
};

CountryMapLegend.propTypes = {
  colorScale: PropTypes.array,
  title: PropTypes.string,
};

export default CountryMapLegend;
