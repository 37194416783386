import React, { useState, useEffect } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: theme.palette.secondary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.secondary.main,
    },
  },
}));

/**
 * Nav item to be used exclusively within the Navbar component
 * to generate a list of nav links; depth is used to determine
 * how much to indent nested nav items
 *
 * module can also be destructured if needed...
 */
function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  externalLink,
  // If null navLink will be rendered on every path
  renderOnPaths = null,
  ...rest
}) {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(openProp);
  const [isRouteVisible, setIsRouteVisible] = useState(true);
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  let paddingLeft = 8;
  if (depth > 0) paddingLeft = 32 + 8 * depth;
  const style = { paddingLeft };

  /*    
    Path / is a special case; when this is found in the 
    renderOnPaths array it does an exact match or else
    it would render on every other subpath
  */
  useEffect(() => {
    if (renderOnPaths && renderOnPaths?.length >= 0) {
      renderOnPaths?.forEach((path) => {
        switch (path) {
          // Exact match only for home route '/'
          case "/":
            path === location.pathname
              ? setIsRouteVisible(true)
              : setIsRouteVisible(false);
            break;
          // Every other route
          default:
            location.pathname.includes(path)
              ? setIsRouteVisible(true)
              : setIsRouteVisible(false);
            break;
        }
      });
    }
  }, [location.pathname]);

  if (!isRouteVisible) return null;
  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? (
            <ExpandLessIcon size="small" color="inherit" />
          ) : (
            <ExpandMoreIcon size="small" color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  /**
   * The nested NavItems within it
   * Should be automatically
   * generated by reduceChildRoutes (in NavBar.js)
   */
  children: PropTypes.node,
  /**
   * Class to apply to the outermost element container
   */
  className: PropTypes.string,
  /**
   * Used to determine indenetation levels (padding-left)
   * if the current NavItem is nested. Should be automatically
   * generated by reduceChildRoutes (in NavBar.js)
   */
  depth: PropTypes.number.isRequired,
  /**
   * Link to another page when NavItem is clicked
   */
  href: PropTypes.string,
  /**
   * An icon component (as a Object) to display next to the
   * NavBar item
   */
  icon: PropTypes.object,
  /**
   * A React component that can be rendered to display
   * additional information
   */
  info: PropTypes.object,
  /**
   * Weather or not to display the menu item;
   * Should be automatically generated
   * by reduceChildRoutes (in NavBar.js)
   */
  open: PropTypes.bool,
  /**
   * Name of the rendered NavItem
   */
  title: PropTypes.string.isRequired,
  /**
   * Non - relative link to to the specified NavItem
   * (e.g. When the href is "/test" , externalLink should be
   * https://someTopLevelDomain.com/test, a full path)
   *
   * NOTE: Currently not useed
   */
  externalLink: PropTypes.string,

  /**
   * renderOnPaths: Is an array of relative links
   * that you don't want the current nav item to be rendered on.
   * The check is done by seeing if the current path CONTAINS/INCLUDES
   * the renderOnPath item. So...
   *    ex: ['/admin', '/claims']
   *    The nav item would not render on '/admin' , '/claims' , '/admin/1/2' or any other
   *    /admin or /claims subpath.
   */
  renderOnPaths: PropTypes.arrayOf(PropTypes.string),
};

NavItem.defaultProps = {
  children: null,
  className: "",
  depth: 0,
  href: null,
  icon: null,
  info: null,
  open: true,
  title: "Item Name",
  externalLink: null,
  renderOnPaths: null,
};

export default NavItem;
