import React from "react";
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const ImpoundCar = (props) => {
    return (
      <SvgIcon {...props} viewBox="0 0 512 512">
        <path d="m112.85 14.42v478.28h26.471v-478.28h-26.471zm130 0v478.28h26.471v-478.28h-26.471zm134 0v478.28h26.471v-478.28h-26.471zm-207.07 49.58c-9.7681 0-19.238 1.788-28.049 5.0898v59.553c5.5626-10.212 16.193-16.643 28.049-16.643h71.211v-48h-71.211zm101.52 0v48h70.918c13.13 0 24.78 7.8708 29.68 20.051l2.9394 7.3027v-68.389c-10.067-4.5004-21.139-6.9648-32.619-6.9648h-70.918zm-160.31 25.758c-6.4552 6.9927-11.729 15.194-15.42 24.361l-31.359 77.881h-52.201c-7.81 0-13.541 7.3402-11.641 14.91l6 24a12.01 12.01 0 0 0 11.641 9.0898h11.311c-8.28 14.16-13.32 30.41-13.32 47.99v136.01c0 13.25 10.75 24 24 24h16c13.25 0 24-10.75 24-24v-24h30.99v-48h-30.99c-8.82 0-16-7.18-16-16v-48c0-26.131 20.986-47.434 46.99-47.975v-150.27zm294.16 4.8789v145.64c24.055 2.579 42.848 22.992 42.848 47.721v48c0 8.82-7.18 16-16 16h-26.848v48h26.848v24c0 13.25 10.75 24 24 24h16c13.25 0 24-10.75 24-24v-136c0-17.59-5.0406-33.84-13.311-47.99h11.311c5.51 0 10.311-3.7498 11.641-9.0898l6-24c1.89-7.58-3.8404-14.91-11.65-14.91h-52.211l-31.359-77.879c-2.8626-7.119-6.6858-13.651-11.268-19.494zm-263.42 97.354v48.01h99.26v-48.01h-99.26zm129.57 0v48.01h103.54v-48.01h-103.54zm103.54 73.82c-18.034 6.591-38.84 29.77-38.84 46.039 0 16.28 20.804 16.413 38.84 16.088v-62.127zm-233.11 1.9844v60.201c16.799 0.12667 34.27-1.2304 34.27-16.146 0-14.907-17.471-35.62-34.27-44.055zm-30.74 0.31836c-9.3711 5.2264-14.99 15.103-14.99 27.787s5.6191 22.559 14.99 27.785v-55.572zm294.16 2.8125v49.953c6.8587-5.5998 10.848-14.293 10.848-24.979 0-10.68-3.989-19.373-10.848-24.975zm-263.42 81.074v48h99.26v-48h-99.26zm129.57 0v48h103.54v-48h-103.54z"/>
      </SvgIcon>
    );
};

ImpoundCar.propTypes = {
    type: PropTypes.string
};

export default ImpoundCar;
