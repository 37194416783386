import { gql } from '@apollo/client';
import each from 'lodash/each';
import BaseService from './baseService';

class DriverService extends BaseService {
  constructor() {
    super();
    this.driverDataMappings = {
      id: {
        name: '$id',
        type: 'Int',
        input: 'Id'
      },
      firstName: {
        name: '$firstName',
        type: 'String',
        typeRequire: 'String!',
        input: 'FirstName'
      },
      lastName: {
        name: '$lastName',
        type: 'String',
        typeRequire: 'String!',
        input: 'LastName'
      },
      email: {
        name: '$email',
        type: 'String',
        typeRequire: 'String!',
        input: 'Email'
      },
      providerId: {
        name: '$providerId',
        type: 'Int',
        typeRequire: 'Int!',
        input: 'ProviderId'
      },
      avatar: {
        name: '$avatar',
        type: 'String',
        input: 'Avatar'
      },
      addresses: {
        name: '$addresses',
        type: '[AddressIn]',
        input: 'Addresses'
      },
      phones: {
        name: '$phones',
        type: '[PhoneIn]',
        input: 'Phones'
      }
    };
    this.driverGql = `
      Id
      FirstName
      LastName
      Email
      Dispatches{
        Id
        DateCreated
        LastModified
        DateTime
        Customer{
          Id
          FirstName
          LastName
          DateCreated
          LastModified
          Email
          PreferredContactMethodId
          EmailOptIn
          MobileNotificationsOptIn
          SMSOptIn
          PhoneOptIn
          ChatEmailOptIn
          ChatMobileNotificationsOptIn
          ChatSMSOptIn
          Avatar
          Addresses{
            Id
            FullAddress
            GoogleLocationId
            Address
            City
            StateProvidence
            PostalCode
            Latitude
            Longitude
            AddressTypeId
          }
          Phones{
            Id
            Phone
            PhoneTypeId
          }
        }
        DispatchStatus
        DispatchService
        DispatchState
        VehicleMake
        VehicleModel
        VehicleYear
        VehicleVin
        Driver{
          Id
          FirstName
          LastName
          Email
          Avatar
          ProviderId
          Provider
          ProviderAvatar
          LastModified
          DateCreated
          Phones{
            Id
            Phone
            PhoneTypeId
          }
        }
        PickUpLocation{
          Id
          FullAddress
          GoogleLocationId
          Address
          City
          StateProvidence
          PostalCode
          Latitude
          Longitude
          AddressTypeId
        }
        DropOffLocation{
          Id
          FullAddress
          GoogleLocationId
          Address
          City
          StateProvidence
          PostalCode
          Latitude
          Longitude
          AddressTypeId
        }
      }
      ClockedIn
      Avatar
      ProviderId
      Provider
      ProviderAvatar
      LastModified
      DateCreated
      Phones{
        Id
        Phone
        PhoneTypeId
      }`;
    this.driverActionGql = `
      Success
      Token`;
  }

  buildDriverGQL = (data, mutation, required, actionReturned) => {
    const keys = Object.keys(data);
    const driver = [];
    const input = [];
    each(keys, (key) => {
      const map = this.driverDataMappings[key];
      if (map && data[key]) {
        driver.push(`${map.name}: ${required && map.typeRequire ? map.typeRequire : map.type}`);
        input.push(`${map.input}: ${map.name}`);
      }
    });
    return `
      mutation Driver(${driver.join(', ')}){
        ${mutation || 'updateDriver'}(
            ${input.join('\n')}
          ) {
            ${actionReturned ? this.driverActionGql : this.driverGql}
        }
    }`;
  };

  deleteDriver = (ids) => new Promise((resolve, reject) => {
    const DELETE_SERVICE = gql`
      mutation Driver($ids: [Int]!){
        removeDrivers(
          Ids: $ids
        ){
          Success
          Token
        }
      }
    `;

    this.client
      .mutate({
        mutation: DELETE_SERVICE,
        variables: { ids }
      })
      .then((result) => {
        if (result.data.removeDrivers) {
          resolve(result.data.removeDrivers);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  addDriver = (data) => new Promise((resolve, reject) => {

    const NEW_SERVICE = gql`
      ${this.buildDriverGQL(data, 'addDriver', true)}
    `;


    this.client
      .mutate({
        mutation: NEW_SERVICE,
        variables: data
      })
      .then((result) => {
        if (result.data.addDriver) {
          resolve(result.data.addDriver);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  saveDriver = (data) => new Promise((resolve, reject) => {
    const SERVICE = gql`${this.buildDriverGQL(data, 'updateDriver', false, true)}`;

    this.client
      .mutate({
        mutation: SERVICE,
        variables: data
      })
      .then((result) => {
        if (result.data.updateDriver) {
          resolve(result.data.updateDriver);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getDrivers = () => new Promise((resolve, reject) => {
    const SERVICES = gql`
      query {
        drivers{
            ${this.driverGql}
        }
    }`;

    this.client
      .query({
        query: SERVICES
      })
      .then((result) => {
        if (result.data.drivers) {
          resolve(result.data.drivers);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  searchDrivers = (value) => new Promise((resolve, reject) => {
    const CUSTOMERS = gql`
      query Driver($value: String!){
        searchDrivers(
            Value: $value
          ){
            ${this.driverGql}
        }
    }`;

    this.client
      .query({
        query: CUSTOMERS,
        variables: { value }
      })
      .then((result) => {
        if (result.data.searchDrivers) {
          resolve(result.data.searchDrivers);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getDriver = (id) => new Promise((resolve, reject) => {
    const SERVICE = gql`
      query Driver($id: Int!){
        driver(Id: $id){
            ${this.driverGql}
        }
    }`;

    this.client
      .query({
        query: SERVICE,
        variables: { id }
      })
      .then((result) => {
        if (result.data.driver) {
          resolve(result.data.driver);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

const driverService = new DriverService();

export default driverService;
