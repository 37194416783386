import httpRequestHandler from 'src/utils/httpRequestHandler';

// const api = process.env.REACT_APP_API || 'https://api.nationsafedrivers.com/nsd-api-consumer-portal/v1';

class TowOrderService {
  setAxiosInterceptors = ({ onLogout }) => {
    httpRequestHandler.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      httpRequestHandler.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete httpRequestHandler.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  
  saveTowOrder = async (towOrderInformation) => {
    const { data } = await httpRequestHandler.post(`/tow-order/create`, towOrderInformation);
    return data;
  };

  getTowOrders = async () => {
    const { data } = await httpRequestHandler.get(`/tow-order`);

    return data;
  };

  

}

const towOrderService = new TowOrderService();
towOrderService.setSession(towOrderService.getAccessToken());

export default towOrderService;
