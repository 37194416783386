import React, { Suspense, useContext } from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import FirebaseContext from "src/context/FirebaseContext";
import clsx from "clsx";
import { advanceAlertQueue } from "../actions/systemAlertActions";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    zIndex: theme.zIndex.modal,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  absolutePosition: {
    position: "absolute",
  },
  loading: {
    position: "relative",
    left: 0,
    height: "8px",
    zIndex: `${Number(theme.zIndex.modal) + 10}`,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  barColorPrimary: {
    backgroundColor: grey[200],
  },
  actionButton: {
    cursor: "pointer",
    marginRight: theme.spacing(3),
  },
  AlertPositionTop: {
    top: "63px",
    left: "0",
  },
  AlertPositionBottom: {
    bottom: "-1%",
    left: "0",
  },
  loadingPositionTop: {
    top: "63px",
  },
  loadingPositionBottom: {
    bottom: "8%",
    position: "absolute",
  },
}));

function SystemAlert() {
  const alerts = useSelector((state) => state?.alerts);
  const { FirebaseCrud } = useContext(FirebaseContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  // Will mark current notification as read
  // Will update queue
  // Will dispatch a new alert if necessary
  const onClose = async (e) => {
    e.preventDefault();
    await dispatch(advanceAlertQueue(FirebaseCrud));
  };

  const {
    open,
    alertsQueue,
    actionButtonContent: ActionButtonContent,
    startIcon,
    variant,
    position,
    transitionComponent: TransitionComponent,
    currentAlert,
  } = alerts || {};
  const alertPositionClass =
    position === "top" ? classes.AlertPositionTop : classes.AlertPositionBottom;

  if (!currentAlert || !alertsQueue.length) return null;

  return (
    <Suspense fallback={<p>testing</p>}>
      <TransitionComponent in={open || false}>
        {currentAlert?.pageLink?.includes("http") ? (
          <a
            href={currentAlert?.pageLink || "#"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Alert
              className={clsx(
                alertPositionClass,
                classes.absolutePosition,
                classes.root
              )}
              onClose={onClose}
              severity={currentAlert.severity}
              variant={variant}
              action={
                <ActionButtonContent
                  onClick={onClose}
                  tabIndex="0"
                  onKeyPress={(event) => {
                    event.preventDefault();
                    if (event.key !== "Enter") return;
                    onClose();
                  }}
                  className={classes.actionButton}
                />
              }
              icon={startIcon}
            >
              <AlertTitle>{`${currentAlert?.from}`}</AlertTitle>
              {currentAlert?.text}
            </Alert>
          </a>
        ) : (
          <Link to={currentAlert?.pageLink || "/#"}>
            <Alert
              className={clsx(
                alertPositionClass,
                classes.absolutePosition,
                classes.root
              )}
              onClose={onClose}
              severity={currentAlert.severity}
              variant={variant}
              action={
                <ActionButtonContent
                  onClick={onClose}
                  tabIndex="0"
                  onKeyPress={(event) => {
                    event.preventDefault();
                    if (event.key !== "Enter") return;
                    onClose();
                  }}
                  className={classes.actionButton}
                />
              }
              icon={startIcon}
            >
              <AlertTitle>{`${currentAlert?.from}`}</AlertTitle>
              {currentAlert?.text}
            </Alert>
          </Link>
        )}
      </TransitionComponent>
    </Suspense>
  );
}

export default SystemAlert;
