const LOCAL_STORAGE_LANGUAGE_CODE_KEY = "i18nextLng";
/**
 * Hook to get an object with 2 functions; a getter and
 * setter to get/set the default language code from
 * localStorage
 * @returns {Object} returns object with getter and setter for
 * default_language_code key in local storage
 */
const useDefaultLanguage = () => ({
  getCachedLanguageCode: () =>
    window.localStorage?.getItem(LOCAL_STORAGE_LANGUAGE_CODE_KEY),
  setCachedLanguageCode: (defaultLanguageCode) =>
    window.localStorage?.setItem(
      LOCAL_STORAGE_LANGUAGE_CODE_KEY,
      defaultLanguageCode
    ),
});

export default useDefaultLanguage;
