import React from 'react';
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useAccount from 'src/hooks/useAccount';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: '100%',
    maxHeight: 500,
    height: 'auto',
  },
  logout: {
    marginLeft: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
}));

/**
 * Error component that takes an image , strings describing the message
 * and a button to construct a error container/view when combined
 * with a Page component
 */
const Error = ({
  header,
  message,
  imgSrc,
  imgAlt,
  button,
  renderLogoutButton,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { signOut } = useAccount();
  return (
    <Container maxWidth="lg">
      <Typography
        align="center"
        variant={mobileDevice ? 'h4' : 'h2'}
        color="textPrimary"
      >
        {header}
      </Typography>
      <Typography align="center" variant="subtitle2" color="textSecondary">
        {message}
      </Typography>
      <Box mt={6} display="flex" justifyContent="center">
        <img alt={imgAlt} className={classes.image} src={imgSrc} />
      </Box>
      <Box mt={6} className={classes.buttonContainer}>
        {button}
        {/* Logout Button */}
        {renderLogoutButton && (
          <Button
            color="secondary"
            component={RouterLink}
            variant="outlined"
            onClick={signOut}
          >
            Logout
          </Button>
        )}
      </Box>
    </Container>
  );
};

Error.propTypes = {
  /**
   * Title of the error message
   */
  header: PropTypes.string,
  /**
   * Description of error message
   */
  message: PropTypes.string,
  /**
   * Src to the image to be displayed with the error
   */
  imgSrc: PropTypes.string,
  /**
   * Alt text of error image
   */
  imgAlt: PropTypes.string,
  /**
   * Component or element to render, usually a button to redirect to another
   * page
   */
  button: PropTypes.element,
  /**
   * Weather or not to render a logout button on this error page
   */
  renderLogoutButton: PropTypes.bool,
};

Error.defaultProps = {
  header: 'An error has occured...',
  message: 'Please retype your password.',
  imgSrc:
    'https://nsdstaticasset.blob.core.windows.net/assets/Platform_Status_Images/403.png',
  imgAlt: 'Access denied icon',
  title: 'Title of error page',
  renderLogoutButton: true,
};

export default Error;
