import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const Tire = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M256 0C114.62 0 0 114.62 0 256s114.62 256 256 256 256-114.62 256-256S397.38 0 256 0zm0 480C132.49 480 32 379.51 32 256S132.49 32 256 32s224 100.49 224 224-100.49 224-224 224zm0-384c-88.37 0-160 71.63-160 160s71.63 160 160 160 160-71.63 160-160S344.37 96 256 96zm0 32c22.34 0 43.04 6.26 61.34 16.36l-37.57 51.71c-7.37-2.93-15.35-4.65-23.77-4.65s-16.39 1.72-23.77 4.65l-37.57-51.71c18.3-10.1 39-16.36 61.34-16.36zM128 256c0-36.74 15.77-69.67 40.64-93.03l37.82 52.06c-9.25 11.18-15.04 25.33-15.04 40.97 0 1.38.32 2.67.41 4.03l-61.4 19.95c-1.49-7.79-2.43-15.77-2.43-23.98zm112 126.38a127.631 127.631 0 0 1-99.45-72.05l61.03-19.83c8.68 13.66 22.32 23.71 38.42 27.84v64.04zm-16-126.4c0-17.67 14.33-32 32-32s32 14.33 32 32-14.33 32-32 32-32-14.32-32-32zm48 126.4v-64.04c16.1-4.13 29.74-14.17 38.42-27.84l61.03 19.83A127.631 127.631 0 0 1 272 382.38zm48.17-122.35c.09-1.36.41-2.65.41-4.03 0-15.64-5.79-29.79-15.04-40.97l37.82-52.06C368.23 186.33 384 219.26 384 256c0 8.21-.94 16.19-2.42 23.97l-61.41-19.94z',
    [STYLE.REGULAR]: 'M256 84c-94.99 0-172 77.01-172 172s77.01 172 172 172 172-77.01 172-172S350.99 84 256 84zm0 48c18.58 0 36.05 4.4 51.89 11.75l-26.66 36.7c-7.97-2.66-16.35-4.45-25.22-4.45s-17.25 1.79-25.22 4.45l-26.66-36.7C219.95 136.4 237.42 132 256 132zM133.47 270.56c-.58-4.84-1.47-9.58-1.47-14.56 0-32.48 12.83-61.85 33.34-83.98l26.55 36.55C182.03 221.87 176 238.17 176 256c0 .25.07.47.07.72l-42.6 13.84zM232 377.57c-36.13-7.12-66.23-30.21-83.72-61.35l42.71-13.88c9.96 13.94 24.31 24.31 41.01 29.59v45.64zM256 288c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm24 89.57v-45.64c16.7-5.28 31.04-15.64 41.01-29.59l42.71 13.88c-17.49 31.15-47.59 54.23-83.72 61.35zm55.93-120.85c0-.25.07-.47.07-.72 0-17.83-6.03-34.13-15.89-47.43l26.55-36.55C367.17 194.15 380 223.52 380 256c0 4.99-.9 9.73-1.47 14.56l-42.6-13.84zM256 0C114.62 0 0 114.62 0 256s114.62 256 256 256 256-114.62 256-256S397.38 0 256 0zm0 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208z',
    [STYLE.SOLID]: 'M256 0C114.62 0 0 114.62 0 256s114.62 256 256 256 256-114.62 256-256S397.38 0 256 0zm0 448c-105.87 0-192-86.13-192-192S150.13 64 256 64s192 86.13 192 192-86.13 192-192 192zm-80-192c0-17.82 6.03-34.12 15.88-47.41l-48.23-66.38C114.27 171.22 96 211.45 96 256c0 8.87 1.22 17.42 2.61 25.89l77.46-25.17c0-.24-.07-.47-.07-.72zm15 46.36l-77.42 25.15c22.86 45.4 66.32 78.17 118.42 86.07v-81.65c-16.69-5.28-31.04-15.64-41-29.57zM256 176c8.87 0 17.25 1.79 25.22 4.45l47.93-65.97C307.14 103.06 282.51 96 256 96s-51.14 7.06-73.15 18.48l47.93 65.97c7.97-2.66 16.35-4.45 25.22-4.45zm112.35-33.79l-48.23 66.38C329.97 221.88 336 238.18 336 256c0 .25-.07.48-.07.73l77.46 25.17c1.39-8.48 2.61-17.02 2.61-25.89 0-44.56-18.27-84.79-47.65-113.8zM280 331.93v81.65c52.1-7.9 95.55-40.67 118.42-86.07L321 302.36c-9.96 13.93-24.31 24.29-41 29.57zM256 224c-17.67 0-32 14.33-32 32s14.33 32 32 32 32-14.33 32-32-14.33-32-32-32z'
  };

  return (
    <FontAwesome
      viewBox="0 0 512 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

Tire.propTypes = {
  type: PropTypes.string
};

export default Tire;
