export default {
  totalCalls: 'Total Calls',
  dispatchCalls: 'Dispatch Calls',
  dispatched: 'Dispatched',
  notDispatched: 'Not Dispatched',
  callsByPercent: 'Calls By Percentage',
  cash: 'Cash',
  goneOnArrival: 'Gone on Arrival',
  cancelled: 'Cancelled',
  callMessaging: 'Call Messaging',
  callRecording: 'Call Recording',
  owner: 'Owner',
  year: 'Year',
  make: 'Make',
  model: 'Model',
  vin_abbreviation: 'VIN',
  color: 'Color',
  serviceType: 'Service Type',
  breakdownReason: 'Breakdown Reason',
  noDispatchesFound: 'No Dispatches Found',
  noImpoundsFound: 'No Impounds Found',
  'noDispatches.message':
    'Please try another time range or select different agents.',
  new: 'New',
  accepted: 'Accepted',
  inRoute: 'In Route',
  onScene: 'On Scene',
  towInProgress: 'Tow In Progress',
  destinationArrival: 'Destination Arrival',
  complete: 'Complete',
  digitalDispatch: 'Digital Dispatch',
};