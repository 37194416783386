import { gql } from '@apollo/client';
import BaseService from './baseService';

class AddressTypesService extends BaseService {
  constructor(uri) {
    super(uri);
    this.addressTypeGql = `
      addressTypeId
      addressType
    `;
  }

  getAddressTypes = () =>
    new Promise((resolve, reject) => {
      const ADDRESS_TYPES = gql`
      query {
        addressTypes{
            ${this.addressTypeGql}
        }
      }
    `;

      this.client
        .query({
          fetchPolicy: 'no-cache',
          query: ADDRESS_TYPES
        })
        .then(result => {
          if (result.data.addressTypes) {
            resolve(result.data.addressTypes);
          } else {
            reject(result.data.error);
          }
        })
        .catch(error => {
          console.log('error', error);
          reject(error);
        });
    });
}

const addressTypesService = new AddressTypesService(
  `${process.env.REACT_APP_PROVIDER_API}/graphql`
);

export default addressTypesService;
