export default {
  totalRoadsideCalls: 'Total Roadside Calls',
  accepted: 'Accepted',
  inRoute: 'In Route',
  cashCall: 'Cash Call',
  goa: 'GOA',
  onScene: 'On Scene',
  towInProgress: 'Tow In Progress',
  destinationArrival: 'Destination Arrival',
  complete: 'Complete',
  cancelled: 'Cancelled',
  new: 'New',
  PieChartCard: {
    title: 'Roadside by Percentage',
  },
  ErrorPage: {
    title: 'No Roadside Calls Found',
    message: 'Please try another time range or select different agents.',
  },
};
