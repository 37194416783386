export default {
  "operational": "Opérationnel",
  "dispatches": "Dépêches",
  "dispatchCount": "Nombre d'Envois",
  "dispatchCount_joined": "Nombre_d'Envois",
  "totalDispatchesCompletedByDayMonth": "Total des Expéditions Terminées par Jour/Mois",
  "completedImpoundTowsByWeekOfMonth": "Remorquages Terminés par Semaine du Mois",
  "allImpoundTowsMustBeCompletedToBeCounted": "Tous les traits de fourrière doivent être terminés pour être comptés.",
  "completedImpoundAverageMilesPerTowPerDayByWeekOfMonth": "Fourrière Terminée Moyenne de Miles par Remorquage par Jour par Semaine du Mois",
  "impoundTows": "Remorques de Fourrière",
  "avgETAByDayMonthCompleted": "Heure d'Arrivée Estimée Moyenne (HAEM) par Jour/Mois (Terminé)",
  "avgMilesPerTowByDayMonthCompleted": "Moyenne des Milles par Remorquage (MPR) par Jour/Mois (Terminé)",
  "completedLongTowsAsAPercentageOfTotalTows": "Longs orteils terminés en % du total des remorquages (remorquages >= 100 milles)",
  "percentIsCalulatedByTwoWayTowsPlusTotalDispatches": "Le pourcentage est calculé en fonction des remorquages bidirectionnels ÷ des expéditions totales.",
  "aTowWithLessThanOrEqualTo100MilesIsALongTow": "Un remorquage avec >= 100 milles est un long remorquage.",
  "percentIsCalculatedByLongTowsPlusTotalTows": "Le pourcentage est calculé par les longs traits le total des traits.",
  "milesPerImpoundTow": "Miles/Remorquage en Fourrière",
  "averageMilesCompletedImpoundTowAreShown": "Le nombre moyen de kilomètres/travaux de fourrière effectués est indiqué.",
  "completedTwoWayTowsAsAPercentageOfTotalDispatches": "Remorquages Bidirectionnels Terminés (RBT) en % des Expéditions Totales",
};