import React, { memo, useRef, useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  menu: {
    minWidth: 256,
    maxWidth: '100%',
  },
}));

/**
 * More comprehensive dropdown component (may remove DropdownMenu)
 * that allows you to create a button with a dropdown menu
 */
const DropdownButton = ({
  className,
  Button,
  Icon,
  checkboxes,
  closeOnSelectListItem,
  dropDownList: dropDownListIn,
  onClickListItem,
  onClose,
  onOpen,
  tooltip,
  MenuProps,
  allowMultiSelect,
  ...props
}) => {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [dropDownList, setDropdownList] = useState(dropDownListIn || []);
  const handleMenuOpen = () => {
    setOpenMenu(true);
    onOpen();
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
    onClose();
  };

  const handleClickListItem = (value, dropDownItemIdx) => {
    if (closeOnSelectListItem === true) setOpenMenu(false);
    const clickedItemNewSelectValue = !dropDownList[dropDownItemIdx].selected;
    const copy = dropDownList.map(({ selected, ...rest}, idx) => {
    let newSelectValue;
    if(allowMultiSelect){
      newSelectValue = selected;
    }else {
      newSelectValue = false;
    }
    return {
        ...rest,
        selected: idx == dropDownItemIdx ? clickedItemNewSelectValue : newSelectValue,
        };
    });
    setDropdownList(copy);
    onClickListItem(value);
  };


  const buttonProps = {
    ...props,
    className: clsx(className),
    onClick: handleMenuOpen,
    ref: moreRef,
  };

  const clickableElement = Button ? (
    cloneElement(Button, buttonProps)
  ) : (
    <IconButton {...buttonProps}>{Icon}</IconButton>
  );

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>{clickableElement}</Tooltip>
      ) : (
        clickableElement
      )}
      <Menu
        getContentAnchorEl={null}
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{ className: classes.menu }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...MenuProps}
      >
        {dropDownList.map((dropDownItem, dropDownItemIdx) => (
          <MenuItem
            key={dropDownItemIdx + dropDownItem.value}
            selected={dropDownItem.selected}
            // Disable clicking on selected item if multi select is not allowed
            disabled={!allowMultiSelect && dropDownItem.selected }
            onClick={() => {
              handleClickListItem(dropDownItem.value, dropDownItemIdx);
            }}
          >
            {checkboxes && <Checkbox checked={dropDownItem.selected} />}
            {dropDownItem.Icon && (
              <ListItemIcon>{dropDownItem.Icon}</ListItemIcon>
            )}
            <ListItemText primary={dropDownItem.text} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

DropdownButton.propTypes = {
  /**
   * The component or element you wish to use for the button,
   * if null an Icon button will be made using the Icon prop.
   * Must pass in at least one of the props Button or Icon
   */
  Button: PropTypes.element,
  /**
   * The component or element you wish to use for the Icon
   * button if the Button prop is null.
   */
  Icon: PropTypes.element,
  /**
   * Weather or not to close the list when an item from
   * the dropdown is selected
   */
  closeOnSelectListItem: PropTypes.bool,
  /**
   * Custom class to apply around the outermost element container
   */
  className: PropTypes.string,
  /**
   * Weather or not to render a checkbox next to the dropdown
   * items
   */
  checkboxes: PropTypes.bool,
  /**
   * Array of objects with information to render each of
   * the dropdown items
   */
  dropDownList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      selected: PropTypes.bool,
      Icon: PropTypes.element,
      text: PropTypes.string,
    })
  ),
  /**
   * Callback that fires when an item in the dropdown is
   * clicked. Receives one argument ; the selected options
   * value
   *
   */
  onClickListItem: PropTypes.func,
  /**
   * Callback to run when dropdown is closed. Receives no
   * arguments
   */
  onClose: PropTypes.func,
  /**
   * Callback to run when dropdown is opened. Receives no
   * arguments
   */
  onOpen: PropTypes.func,
  /**
   * Tooltip to appear when the dropdown button is hovered
   */
  tooltip: PropTypes.string,
  /**
   * Props that can be passed into the Material UI V4 Menu
   * component
   * https://v4.mui.com/api/menu/
   */
  MenuProps: PropTypes.object,

  /**
   * Whether or not multiple items in the rendered dropdown menu
   * to be selected
   */
  allowMultiSelect: PropTypes.bool,
};

DropdownButton.defaultProps = {
  closeOnSelectListItem: true,
  Button: null,
  Icon: null,
  tooltip: 'Tooltip',
  checkboxes: false,
  className: '',
  onClickListItem: () => {},
  onClose: () => {},
  onOpen: () => {},
  dropDownList: [],
  MenuProps: {},
  allowMultiSelect: true,
};

export default memo(DropdownButton);
