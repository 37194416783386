import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PlusCircle as PlusCircleIcon } from "react-feather";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * Button to indicate a new resource is will be created when
 * clicked
 */
const NewButton = ({ title, onClick, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      color="secondary"
      variant="contained"
      className={clsx(classes.action, className)}
      onClick={onClick}
      {...rest}
    >
      <SvgIcon fontSize="small" className={classes.actionIcon}>
        <PlusCircleIcon />
      </SvgIcon>
      New {title}
    </Button>
  );
};

NewButton.propTypes = {
  /**
   * Text of the button
   */
  title: PropTypes.string.isRequired,

  /**
   * Callback function that fires when button is clicked. Passed
   * one argument; the event object generated
   */
  onClick: PropTypes.func,

  /**
   * Class name to apply custom styles to the button
   */
  className: PropTypes.string,
};

NewButton.defaultProps = {
  onClick: () => {},
};

export default NewButton;
