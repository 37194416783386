const CdnAppIcon = "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/adminQoreAppIcon.png";

const appConfig = {
  appId: "admin-cf196b57-0774-4a2c-bff0-04a4f44d7f0d",
  appName: "Admin",
  site: "admin-qore",
  icon: CdnAppIcon,
};

export default appConfig;
