import frAdminStrings from './admin';
import frAdminUserStrings from './AdminUsersView';
import frBillingStrings from './billing';
import frBottomBarStrings from './BottomBar';
import frCancelDispatchFormStrings from './CancelDispatchForm';
import frClaimsViewStrings from './ClaimsView';
import frClaimsStrings from './claims';
import frClaimsOperationalViewStrings from './ClaimsOperationalView';
import frClaimSearchViewStrings from './ClaimSearchView';
import frCommonStrings from './common';
import frDispatchStrings from './dispatch';
import frDispatchNetworkMapViewStrings from './DispatchNetworkMapView';
import frDispatchOperationalStrings from './DispatchOperationalView';
import frDispatchCallSearchViewStrings from './DispatchCallSearchView';
import frDispatchCallsViewStrings from './DispatchCallsView';
import frInsuranceOperationalViewStrings from './InsuranceOperationalView';
import frDispatchTowOrderViewStrings from './DispatchTowOrderView';
import frDocumentStrings from './documents';
import frInsuranceStrings from './insurance';
import frTopBarStrings from './TopBar';
import frImpoundCallsViewStrings from './ImpoundCallsView';
import frRoadsideCallsViewStrings from './RoadsideCallsView';
/**
 * This file is used to provide translation strings for the 
 * French language. 
 * 
 * Translation strings are grouped by the context in which
 * they are used. Keys ending in a `View` correspond to module
 * views seen in the `src/views` directory. The `common` key 
 * contains strings shared by different modules. The other
 * lowercase keys correspond to apps such as dispatch and their 
 * strings are used in the appConfig objects within `src/apps`. 
 * 
 * Some of the apps don't have a separate file containing 
 * translation strings for their views (e.g. billing). 
 * The reason for this is that these views are very simple 
 * in terms of text content, so we've put all of the relevant 
 * translations into a single file (e.g. `frBillingStrings`). 
 */
export default {
  AdminUsersView: frAdminUserStrings,
  ClaimsView: frClaimsViewStrings,
  ClaimsOperationalView: frClaimsOperationalViewStrings,
  ClaimSearchView: frClaimSearchViewStrings,
  CancelDispatchForm: frCancelDispatchFormStrings,
  BottomBar: frBottomBarStrings,
  TopBar: frTopBarStrings,
  admin: frAdminStrings,
  billing: frBillingStrings,
  claims: frClaimsStrings,
  common: frCommonStrings,
  dispatch: frDispatchStrings,
  documents: frDocumentStrings,
  DispatchNetworkMapView: frDispatchNetworkMapViewStrings,
  DispatchOperationalView: frDispatchOperationalStrings,
  DispatchCallSearchView: frDispatchCallSearchViewStrings,
  DispatchCallsView: frDispatchCallsViewStrings,
  InsuranceOperationalView: frInsuranceOperationalViewStrings,
  DispatchTowOrderView: frDispatchTowOrderViewStrings,
  insurance: frInsuranceStrings,
  ImpoundCallsView: frImpoundCallsViewStrings,
  RoadsideCallsView: frRoadsideCallsViewStrings,
};