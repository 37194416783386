import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const BoxAlt = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zm-87-112l50.8 96H286.1l-12-96h86.8zM192 192h64v64h-64v-64zm49.9-128l12 96h-59.8l12-96h35.8zM87.1 64h86.8l-12 96H36.3l50.8-96zM32 448s.1-181.1.1-256H160v64c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32v-64h127.9c0 74.9.1 256 .1 256H32z',
    [STYLE.REGULAR]: 'M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zm-97.1-96l42.8 80H278.4l-24-80h96.4zM97.2 80h96.4l-24 80H54.4l42.8-80zM48 432c0-42.3.1-157.9.1-224H160v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16v-64h111.9c0 66.1 0 181.8.1 224H48z',
    [STYLE.SOLID]: 'M446.7 160c.4-.5.5-.7.9-1.2L391.3 53.9C386.9 40.8 374.7 32 360.9 32H256l32 128h158.7zM160 160l32-128H87.1c-13.8 0-26 8.8-30.4 21.9L.4 158.8c.4.5.5.7.9 1.2H160zm128 32v80c0 8.8-7.2 16-16 16h-96c-8.8 0-16-7.2-16-16v-80H0v256c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V192H288z'
  };

  return (
    <FontAwesome
      viewBox="0 0 448 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

BoxAlt.propTypes = {
  type: PropTypes.string
};

export default BoxAlt;
