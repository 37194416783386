import { useSelector } from "react-redux";
import find from "lodash/find";

export const useAssetSettings = () => {
  const { appSettings: settings, user } = useSelector((state) => state.account);

  const isNSD = () =>
    !!(user?.companies && find(user.companies, { code: "nsd" }) !== undefined);
  const hasSuiteAccess = (suiteIdentifier) =>
    settings?.suites?.[suiteIdentifier]?.hasAccess || (user?.admin && isNSD());
  const hasAppAccess = (appIdentifier) =>
    settings?.apps?.[appIdentifier]?.hasAccess || (user?.admin && isNSD());
  const hasModuleAccess = (moduleIdentifier) =>
    settings?.modules?.[moduleIdentifier]?.hasAccess ||
    (user?.admin && isNSD());
  const hasFunctionAccess = (functionIdentifier) =>
    settings?.functions?.[functionIdentifier]?.hasAccess ||
    (user?.admin && isNSD());
  const hasFeatureAccess = (featureIdentifier) =>
    settings?.features?.[featureIdentifier]?.hasAccess ||
    (user?.admin && isNSD());

  return {
    settings,
    isNSD,
    hasSuiteAccess,
    hasAppAccess,
    hasModuleAccess,
    hasFunctionAccess,
    hasFeatureAccess,
  };
};
