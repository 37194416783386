import translations from './ClaimsView';

export default {
  "amount": "Monto",
  "billing": "Facturación",
  "billingMonth": "Mes de Facturación",
  "billingPeriod": "Período de Facturación",
  "invoices": "Facturas",
  "invoiceNumber": "Factura #",
  "status": translations.status,
  "document": "Documento",
  "allDocuments": "Todos los Documentos",
  "cancellationStatement": "Declaración de Cancelación",
  "claimInvoice": "Reclamar Factura",
  "printedCancellationCheck": "Cheque de Cancelación Impreso",
};