import { lazy } from 'react';
import { AuthGuard } from '@nsd/fe';
import {
  DollarSign as DollarSignIcon,
  BarChart as BarChartIcon,
  Search as SearchIcon,
  Clipboard as ClipboardIcon, // TODO- this is a potentially a temp icon; we can build something more permanent after MVP
  FilePlus as FilePlusIcon, // TODO- this is a potentially a temp icon; we can build something more permanent after MVP
  BookOpen as BookIcon,
  Globe as GlobeIcon,
} from 'react-feather';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { appIcon } from '../appIcon';

const MODULES = {
  CLAIMS: 'claims-4b634fbc-9f81-4ce8-8c18-9c0b26753f90',
  DEALER_REGISTRATION: 'dealerRegistration-302fb9cf-c83d-41ea-9de2-d8c26a441091',
  DEALER_ADD_PRODUCTS: 'addDealerProducts-a844caba-7a66-4e0e-b0ba-b79e4b4f3cc8',
  DEALER_SUBMISSIONS: 'dealerSubmissions-a9de62de-7c93-4465-a472-355086107f6a',
  COMPANIES: 'companies-5b28c16c-cd8e-48f5-b3f0-d19966928fa0',
};

const appConfig = {
  appId: 'claims-9930570c-81f3-44fb-b0df-70e16ed66a48',
  appName: 'claims.claims',
  priority: 7,
  path: 'claims',
  icon: appIcon.claimsQoreApp,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: 'reports',
      view: lazy(() => import('src/views/ClaimsView/index')),
    },
    {
      exact: true,
      path: 'map',
      view: lazy(() => import('src/views/ClaimsOperationalView/index')),
    },
    {
      exact: true,
      path: 'search',
      view: lazy(() => import('src/views/ClaimSearchView/index')),
    },
    {
      exact: true,
      path: 'dealer-registration',
      view: lazy(() => import('src/views/ClaimsDealerRegistrationView/index')),
    },
    {
      exact: true,
      path: 'dealer-add-products',
      view: lazy(() => import('src/views/ClaimsDealerAddProductsView/index')),
    },
    {
      exact: true,
      path: 'dealer-submissions',
      view: lazy(() => import('src/views/ClaimsDealerSubmissionsView/index')),
    },
    {
      exact: true,
      path: 'companies',
      view: lazy(() => import('src/views/ClaimsCompaniesView/index')),
    },
  ],
  topBarConfig: {
    contacts: false,
    search: false,
    notifications: true,
    settings: true,
  },
  navConfig: {
    navBarItems: [
      {
        subheader: 'claims.reports',
        items: [
          {
            title: 'claims.claims',
            icon: DollarSignIcon,
            href: 'reports',
            // module: MODULES.CLAIMS_REPORTS,
          },
          {
            title: 'claims.operationalReports',
            icon: BarChartIcon,
            href: 'map',
          },
        ],
      },
      {
        subheader: 'claims.claims',
        items: [
          {
            title: 'claims.claimSearch',
            href: 'search',
            icon: SearchIcon,
          },
        ],
      },
      {
        subheader: 'claims.companies',
        items: [
          {
            title: 'claims.companies',
            icon: GlobeIcon,
            href: 'companies',
            module: MODULES.COMPANIES,
          },
        ],
      },
      {
        subheader: 'claims.dealerRegistration',
        items: [
          {
            title: 'claims.dealerRegistration',
            href: 'dealer-registration',
            icon: ClipboardIcon,
            module: MODULES.DEALER_REGISTRATION,
          },
          {
            title: 'claims.addProducts',
            href: 'dealer-add-products',
            icon: FilePlusIcon,
            module: MODULES.DEALER_ADD_PRODUCTS,
          },
          {
            title: 'claims.dealerSubmissions',
            href: 'dealer-submissions',
            icon: BookIcon,
            module: MODULES.DEALER_SUBMISSIONS,
          },
        ],
      },
    ],
  },
};

export default appConfig;
