import dayjs from 'dayjs';
import esLocale from 'dayjs/locale/es';
import enLocale from 'dayjs/locale/en';
import frLocale from 'dayjs/locale/fr';
import frcaLocale from 'dayjs/locale/fr-ca';
import { LANGUAGE_CODES } from 'src/constants/languageCodes';


const localeConfigMap = {
  // enLocale doesn't supply an ordinal
  // function by default. This is added
  // for compatibility
  [LANGUAGE_CODES.enUS]: {
    ...enLocale,
    ordinal: (n) => n,
  },
	[LANGUAGE_CODES.es]: esLocale,
	[LANGUAGE_CODES.fr]: frLocale,
  [LANGUAGE_CODES.frCA]: frcaLocale,
};

/**
 * @param {string} languageCode - The lanaguage code in the users local storage
 * @returns {string} The base language code with no locale that we support if any
 * locales are attached, expect for en which gets parsed into en-US
 */
const getBaseLanguage = (languageCode) => {
  if(languageCode.includes('en')) return 'en-US';
  if(languageCode.includes('es')) return 'es';
  if(languageCode.includes('fr')) return 'fr';
  return 'en-US';
};

export const getCurrentLanguage = () => {
  const currentBaseLanguage = getBaseLanguage(window.localStorage?.getItem('i18nextLng'));
  window.localStorage?.setItem('i18nextLng', currentBaseLanguage);
	return currentBaseLanguage; 
};

/**
 * Load a locale config object from dayjs that matches the
 * locale parameter. 
 * 
 * This is used to provide locale functionality for dates.
 * @param {string} locale 
 * @returns {object | never}
 */
export const loadLocale = (locale=getCurrentLanguage()) => 
   localeConfigMap[locale] ?? localeConfigMap[LANGUAGE_CODES.enUS];  


/**
 * This is used to properly format timestamps
 * according to the site language
 * @param {any} date 
 * @param {string} localeFormat // e.g. L, LL
 * @param {object} locale // locale config object
 * @returns {string} // the formatted date
 */
export const localizeDate = (
  date,
  localeFormat,
  locale = loadLocale()
) => {
  return (
    dayjs(date)
      .locale(locale)
      .format(localeFormat)
  );
};
