import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const MapMarkedAlt = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M560 160c-2 0-4 .4-6 1.2L384 224l-10.3-3.6C397 185.5 416 149.2 416 123 416 55 358.7 0 288 0S160 55.1 160 123c0 11.8 4 25.8 10.4 40.6L20.1 216C8 220.8 0 232.6 0 245.7V496c0 9.2 7.5 16 16 16 2 0 4-.4 6-1.2L192 448l172 60.7c13 4.3 27 4.4 40 .2L555.9 456c12.2-4.9 20.1-16.6 20.1-29.7V176c0-9.2-7.5-16-16-16zM176 419.8L31.9 473l-1.3-226.9L176 195.6zM288 32c52.9 0 96 40.8 96 91 0 27-38.1 88.9-96 156.8-57.9-67.9-96-129.8-96-156.8 0-50.2 43.1-91 96-91zm80 444.2l-160-56.5V228.8c24.4 35.3 52.1 68 67.7 85.7 3.2 3.7 7.8 5.5 12.3 5.5s9-1.8 12.3-5.5c12.8-14.5 33.7-39.1 54.3-66.9l13.4 4.7zm32 .2V252.2L544.1 199l1.3 226.9zM312 128c0-13.3-10.8-24-24-24s-24 10.7-24 24c0 13.2 10.8 24 24 24s24-10.7 24-24z',
    [STYLE.REGULAR]: 'M344 126c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 13.2 10.7 24 24 24s24-10.8 24-24zm-24 226c5 0 10-2 13.5-6.1 35.3-40 127.3-150.1 127.3-210.6C460.8 60.6 397.8 0 320 0S179.2 60.6 179.2 135.3c0 60.4 92 170.6 127.3 210.6C310 350 315 352 320 352zm0-304c51.2 0 92.8 39.2 92.8 87.3 0 21.4-31.8 79.1-92.8 152.6-61-73.5-92.8-131.2-92.8-152.6 0-48.1 41.6-87.3 92.8-87.3zm240 112c-2 0-4 .4-6 1.2l-73.5 27.2c-8.2 20.4-20.2 42-34.2 63.8L528 222v193l-128 44.5V316.3c-13.7 17.3-27.9 34.3-42.5 50.8-1.7 1.9-3.6 3.5-5.5 5.1v81.4l-128-45.2v-113c-18.1-24.1-34.8-48.8-48-72.8v180.2l-.6.2L48 450V257l123.6-43c-8-15.4-14.1-30.3-18.3-44.5L20.1 216C8 220.8 0 232.6 0 245.7V496c0 9.2 7.5 16 16 16 2 0 4-.4 6-1.2L192 448l172 60.7c13 4.3 27 4.4 40 .2L555.9 456c12.2-4.9 20.1-16.6 20.1-29.7V176c0-9.2-7.5-16-16-16z',
    [STYLE.SOLID]: 'M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z'
  };

  return (
    <FontAwesome
      viewBox="0 0 576 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

MapMarkedAlt.propTypes = {
  type: PropTypes.string
};

export default MapMarkedAlt;
