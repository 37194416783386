import React, { Suspense } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Card, CardHeader, Divider, CardContent } from "@material-ui/core";
import { makeStyles, useTheme, alpha } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import PerfectScrollbar from "react-perfect-scrollbar";
import CircularLoader from "src/components/CircularLoader";
import ChartComponent from "react-chartjs-2";
import MoreIcon from "@material-ui/icons/MoreVert";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import withPrintAndDownload from "./withPrintAndDownload";
import DropdownButton from "./DropdownButton";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  actionsContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  card: {
    maxHeight: "350px",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "initial",
    },
  },
}));

/*
 * Generic Chart component using chartjs3 and a react wrapper.
 * Specify the type to get a different chart, pass in bool
 * to indicate if you want it rendered on a card. data prop
 * depends on which chart you want to make (see props). Comes
 * with the actions to download and print each Chart
 */
const Chart = ({
  className,
  type,
  card,
  data: dataProp,
  labels,
  description,
  chartJsRef,
  getElementsAtEvent,
  pointBackgroundColor,
  borderColor,
  mergeOptions,
  chartTitle,
  downloadRef,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleGetElementsAtEvent = (elements) =>
    getElementsAtEvent && getElementsAtEvent(elements);

  const onActionsMenuClick = (value) =>
    value === "Download"
      ? downloadRef.current.download()
      : downloadRef.current.print();

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.2));
    gradient.addColorStop(0.9, "rgba(255,255,255,0)");
    gradient.addColorStop(1, "rgba(255,255,255,0)");
    return {
      datasets: [
        {
          // deep clone because chart.js modifies data object
          data: cloneDeep(dataProp),
          backgroundColor: gradient,
          borderColor: borderColor ?? theme.palette.secondary.main,
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointRadius: 6,
          pointHitRadius: 10,
          pointBackgroundColor:
            pointBackgroundColor ?? theme.palette.secondary.main,
          label: chartTitle || "Chart",
        },
      ],
      labels,
    };
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxis: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
        },
      },
      yAxis: {
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider,
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
          maxTicksLimit: 7,
          callback: (value) => (value > 0 ? `${value}` : value),
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        position: "average",
        intersect: false,
        caretSize: 10,
        padding: 20,
        borderWidth: 1,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.default,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        footerColor: theme.palette.text.secondary,
        callbacks: {
          title: () => {},
          label: (context) => `${description || ""} - ${context.label}`,
        },
      },
    },
  };

  const options = merge(defaultOptions, mergeOptions);
  const defaultCardActions = (
    <Box className={classes.actionsContainer}>
      <DropdownButton
        Icon={<MoreIcon />}
        checkboxes={false}
        tooltip="Options"
        onClickListItem={onActionsMenuClick}
        dropDownList={[
          {
            value: "Download",
            selected: false,
            Icon: <GetAppIcon />,
            text: "Download",
          },
          {
            value: "Print",
            selected: false,
            Icon: <PrintIcon />,
            text: "Print",
          },
        ]}
      />
    </Box>
  );

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Suspense fallback={<CircularLoader />}>
        {card ? (
          <Card className={classes.card}>
            <CardHeader action={defaultCardActions} title={chartTitle} />
            <Divider />
            <CardContent className={classes.card}>
              <PerfectScrollbar>
                <Box>
                  <ChartComponent
                    type={type}
                    data={data}
                    options={options}
                    ref={chartJsRef}
                    getElementsAtEvent={handleGetElementsAtEvent}
                  />
                </Box>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        ) : (
          <>
            <ChartComponent
              type={type}
              data={data}
              options={options}
              ref={chartJsRef}
              getElementsAtEvent={handleGetElementsAtEvent}
            />
            {defaultCardActions}
          </>
        )}
      </Suspense>
    </div>
  );
};

Chart.propTypes = {
  /**
   * Title of the chart; also appears as downloaded file name
   */
  chartTitle: PropTypes.string,
  /**
   * Class name to apply to the outermost container element
   */
  className: PropTypes.string,
  /**
   * Weather or not to render the chart in a card view
   */
  card: PropTypes.bool,
  /**
   * Which type of chart you wish to render
   * https://www.npmjs.com/package/react-chartjs-2
   *
   */
  type: PropTypes.oneOf([
    "line",
    "bar",
    "radar",
    "doughnut",
    "polarArea",
    "pie",
    "scatter",
  ]),
  /**
   * Data for the supplied labels; data index corresponds to
   * label index in labels array. Every other chart but
   * scatter just takes an array of numbers. For scatter charts
   * you need to structure data as an array of objects with x and
   * y coordinates
   */
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    ])
  ),
  /**
   * Array of labels with indices corresponding to the indicies
   * in the data prop array
   */
  labels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Short description of the chart
   */
  description: PropTypes.string,
  /**
   * Forwarded ref from an HOC which is automatically
   * passed in
   */
  chartJsRef: PropTypes.any,
  /**
   * Callback function that runs when a point on the chart
   * is clicked. Receives one argument; the element object.
   * (or elements array if more than one point is touched)
   */
  getElementsAtEvent: PropTypes.func,
  /**
   * Rest of the chart options that can be customized by the
   * user; see the react-chartjs-2 docs for more details
   * https://www.npmjs.com/package/react-chartjs-2
   */
  mergeOptions: PropTypes.object,
  /**
   * Background color for the points on the chart
   */
  pointBackgroundColor: PropTypes.string,
  /**
   * Border color for the chart canvas
   */
  borderColor: PropTypes.string,
};

Chart.defaultProps = {
  borderColor: "#000000",
  pointBackgroundColor: "#00ff",
  mergeOptions: null,
  getElementsAtEvent: (elements) => console.log({ elements }),
  chartJsRef: null,
  description: "Description",
  labels: [],
  data: [],
  type: "line",
  card: false,
  className: "",
  chartTitle: "Sample Title",
};

export default withPrintAndDownload(Chart);
