import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import throttle from "lodash/throttle";
import find from "lodash/find";
import each from "lodash/each";
import { Person as DriverIcon } from "@material-ui/icons";
import driverService from "../services/driverService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  formType: { width: 200 },
  formInput: { margin: theme.spacing(1) },
  formButton: { margin: theme.spacing(1) },
  icon: {
    marginLeft: theme.spacing(2),
  },
}));

function DriverInput({ className, driver, driverChange, ...rest }) {
  const classes = useStyles();
  const { driverStatuses } = useSelector((state) => state.driverStatus);
  const [value, setValue] = useState(driver || null);
  const [searchError, setSearchError] = useState(null);
  const [inputVal, setInputVal] = useState(driver ? `${driver.email}` : "");
  const [options, setOptions] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (value) {
      const driver = find(options, { id: value.id });
      if (driver && driverChange) {
        driverChange(driver);
      }
    }
  }, [inputVal]);

  // Callback to forward fetch to a service call
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        driverService
          .searchDrivers(request)
          .then(callback)
          .catch((e) => {
            setSearchError(e?.message || "Service could not be reached");
          });
      }, 200),
    []
  );

  /*
    When input changes fetch new driver details and set
    clockedIn based on driverStatuses array from redux store.
    Set as new options in the select
  */
  useEffect(() => {
    let active = true;
    if (inputVal === "" || !inputVal) {
      setOptions([]);
      setSearchError(null);
      return undefined;
    }

    fetch(inputVal, (results) => {
      if (active) {
        let newOptions = [];
        each(results, (d) => {
          const dStatus = find(driverStatuses, { id: d.id });
          if (dStatus) {
            d.clockedIn = dStatus.clockedIn ? 1 : 0;
          }
        });

        if (results) {
          newOptions = [...results];
        }

        results.length <= 0
          ? setSearchError("Could not find specified driver")
          : setSearchError(null);

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputVal, fetch]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" width="100%">
            <Autocomplete
              noOptionsText={
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flexStart"
                  alignItems="center"
                >
                  {!searchError && <CircularProgress />}
                  <Typography style={{ marginLeft: theme.spacing(2) }}>
                    {searchError ?? "Searching for drivers"}
                  </Typography>
                </Box>
              }
              id="combo-box-demo"
              style={{ width: "100%" }}
              options={options}
              getOptionLabel={(option) => {
                return `${option.firstName} ${option.lastName}`;
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputVal(newInputValue);
              }}
              renderOption={(option, { inputValue }) => {
                const matches = match(
                  `${option.firstName} ${option.lastName}`,
                  inputValue
                );
                const parts = parse(
                  `${option.firstName} ${option.lastName}`,
                  matches
                );
                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <DriverIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {part.text}
                        </span>
                      ))}
                      <Typography variant="body2" color="textSecondary">
                        {option.clockedIn ? "Clocked In" : "Clocked Out"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Driver"
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

DriverInput.propTypes = {
  /**
   * Class name you wish to apply to the container around Input
   */
  className: PropTypes.string,
  /**
   * Callback to run after inputText has changed; uses the selected value from the
   * dropdown and compares it the list of options to find the choosen driver. That
   * choosen driven is passed as a parameter
   */
  driverChange: PropTypes.func,
  /**
   * Driver that is currently logged in; intially set from value pulled out of
   * redux store
   */
  driver: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

DriverInput.defaultProps = {
  className: "",
  driverChange: null,
  driver: {
    email: "driver123@gmail.com",
    id: 123456,
    firstName: "Driver",
    lastName: "Test",
  },
};

export default DriverInput;
