import axios from '../utils/axios';
import authService from '../services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export const login = (loginAndGetUser) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const { user, settings, roles, clientQoreCompanyCode } =
        await loginAndGetUser();

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user,
          appSettings: settings,
          roles,
          clientQoreCompanyCode,
        },
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
};

export function loginWithMicrosoft(email, prevPath, prevQueryParams) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      await authService.loginWithMicrosoft(email, prevPath, prevQueryParams);
    } catch (error) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          error,
        },
      });
      throw error;
    }
  };
}

/**
 * @param {string} code
 * @param {string} azureTenantId
 * @param {('internal'| 'external' | 'turo')} loginType
 * @returns {Promise<void>}
 */
export const loginWithCode = (code, azureTenantId, loginType) => {
  return login(async () =>
    authService.loginWithCode(code, azureTenantId, loginType)
  );
};

export function setUserData(user, settings, roles, clientQoreCompanyCode) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
        appSettings: settings,
        roles,
        clientQoreCompanyCode,
      },
    });
}

export function logout() {
  return async (dispatch) => {
    await authService.logout();
    dispatch({
      type: LOGOUT,
    });
  };
}

export function updateProfile(update) {
  const request = axios.post('/api/account/profile', { update });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data,
      })
    );
  };
}
