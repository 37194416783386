export default {
  'claims': 'Claims',
  'operationalReports': 'Operational Reports',
  'reports': 'Reports',
  'claimSearch': 'Claim Search',
  'dealerRegistration': 'Dealer Registration',
  'addProducts': 'Add Products',
  'registerDealer': 'Register Dealer',
  'dealerSubmissions': 'Dealer Submissions',
  'companies': 'Companies',
};