import { gql } from '@apollo/client';
import BaseService from './baseService';

class ContactMethodsService extends BaseService {
  constructor() {
    super();
    this.contactMethodGql = `
      PreferredContactMethodId
      Method
    `;
  }

  getContactMethods = () => new Promise((resolve, reject) => {
    const CONTACT_METHODS = gql`
      query {
        contactMethods{
            ${this.contactMethodGql}
        }
      }
    `;

    this.client
      .query({
        query: CONTACT_METHODS
      })
      .then((result) => {
        if (result.data.contactMethods) {
          resolve(result.data.contactMethods);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

const contactMethodsService = new ContactMethodsService();

export default contactMethodsService;
