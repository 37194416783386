import React, { cloneElement, isValidElement, lazy } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  icon: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.h3,
  },
}));

/**
 * Component to show display a small no results view for a page when a search yields
 * nothing.
 */
const NoResults = ({
  className,
  title,
  fontVariant,
  iconSize,
  icon,
  iconColor,
  ...rest
}) => {
  const classes = useStyles();
  const Icon = icon ?? lazy(() => import("@material-ui/icons/Block"));

  const iconProps = {
    className: clsx(classes.icon, icon?.props?.className),
    style: { width: iconSize, height: iconSize },
  };

  return (
    <Box className={clsx(classes.root, className)}>
      {isValidElement(Icon) ? (
        cloneElement(Icon, iconProps)
      ) : (
        <Icon {...iconProps} color={iconColor} />
      )}
      <Typography variant={fontVariant} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

NoResults.propTypes = {
  /**
   * Class name you wish to apply to the main container around the component
   */
  className: PropTypes.string,
  /**
   * Text to appear on the component
   */
  title: PropTypes.string,
  /**
   * String choosen from enum that determines how large the text is (h1 - h6)
   */
  fontVariant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  /**
   * Number (in px) for the width and height of the icon
   */
  iconSize: PropTypes.number,
  /**
   * Node or Component to be displayed above the title text; defaults to a
   * not-allowed icon
   */
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  /**
   * Color for the icon
   */
  iconColor: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "action",
    "error",
    "disabled",
  ]),
};

NoResults.defaultProps = {
  fontVariant: "h3",
  iconSize: 200,
  title: "No results found",
  icon: null,
  iconColor: "primary",
};

export default NoResults;
