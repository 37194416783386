import  React, { createContext } from 'react';
import PropTypes from 'prop-types';

const AppContext = createContext(null);

export const AppProvider = ({ app, children }) => (
  <AppContext.Provider
    value={app}
  >
    {children}
  </AppContext.Provider>
);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  app: PropTypes.object.isRequired
};

export default AppContext;
