import React from 'react';
import setDisplayName from './setDisplayName'
import wrapDisplayName from './wrapDisplayName'

const identity = Component => Component
/**
 * Accepts a test function and two higher-order components. The test function is passed the props
 * from the owner. If it returns true, the `left` higher-order component is applied to
 * `BaseComponent`; otherwise, the `right` higher-order component is applied. If the `right` is
 * not supplied, it will by default render the wrapped component
 * 
 * Former recompose utility. 
 * Taken from: https://github.com/acdlite/recompose/blob/master/src/packages/recompose/branch.js
 * See the original Recompose docs: https://github.com/acdlite/recompose/blob/HEAD/docs/API.md#branch
 * 
 * @param {*} test 
 * @param {*} left 
 * @param {*} right 
 * @returns 
 */
const branch = (test, left, right = identity) => BaseComponent => {
  const LeftElement = left(BaseComponent);
  const RightElement = right(BaseComponent);

  const Branch = props => {
    return test(props) ? <LeftElement {...props} /> : <RightElement {...props} />;
  }

  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(BaseComponent, 'branch'))(Branch)
  }
  return Branch
}

export default branch;
