import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function LanguageSupportErrorFallback() {
  return (
    <Alert variant="outlined" severity="error">
      <AlertTitle>Error</AlertTitle>
      Language value found is unsupported, please refresh the page to set the language code back to en-US
    </Alert>
  );
}
