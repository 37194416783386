/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles(() => ({
  marker: {
    cursor: "pointer",
    width: 200,
    color: "red",
    top: 0,
    left: -100,
    position: "absolute",
    zIndex: 2,
  },
}));

/*
 * Component to be used to mark a location on a GoogleMap
 * and display information for that specified location
 */
const Marker = ({ className, infoWindow, onClick }) => {
  const classes = useStyles();
  return (
    <>
      {infoWindow || null}
      <RoomIcon onClick={onClick} className={clsx(classes.marker, className)} />
    </>
  );
};

Marker.propTypes = {
  /*
   * Callback to be fired when the marker is clicked. It
   * receives one argument; the generated event object
   */
  onClick: PropTypes.func,
  /*
   * The InfoWindow component that is displayed along with
   * the Marker component. Used to display the images /
   * text describing a point on the map
   */
  infoWindow: PropTypes.element,
};

Marker.defaultProps = {
  onClick: () => {},
  infoWindow: <div></div>,
};

export default Marker;
