import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import axios from "src/utils/axios";
import FormDialog from "src/components/FormDialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {
  SlidersH as SlidersHIcon,
  Sync as SyncIcon,
} from "src/icons/fontawesome";
import * as Yup from "yup";
import ResetPasswordDialogSettings from "./ResetPasswordDialogSettingsOld";

const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(1, "Must be at least 1 character")
    .max(100)
    .required("Password is required"),
});

const generatePasswordSettings = [
  {
    title: "Letters",
    key: "letters",
    initialNumberOfType: 9,
    options: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  },
  {
    title: "Numbers",
    key: "numbers",
    initialNumberOfType: 2,
    options: "0123456789",
  },
  {
    title: "Special Chars",
    key: "specialCharacters",
    initialNumberOfType: 1,
    options: "!@#$%^&*=-_",
  },
];

/**
 * Dialog component meant to be displayed when icons on
 * the CrudTablePage are clicked. Brings up a dialog
 * with the option to generate a random password and settings to
 * tailor the specific number of characters in the generated password.
 * Submits a PUT request to '/users/:id' to reset password
 */
const ResetPasswordDialog = ({ open, onClose, onExited, selectedUser }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef();
  const resetPasswordRef = useRef();

  const [isPasswordGenerateSettingsOpen, setIsPasswordGenerateSettingsOpen] =
    useState(false);

  const fillPasswordInputWithNewPassword = useCallback(
    () =>
      formikRef.current.setFieldValue(
        "password",
        resetPasswordRef?.current?.generateRandomPassword()
      ),
    [resetPasswordRef?.current?.generateRandomPassword]
  );

  useEffect(() => {
    if (formikRef.current && formikRef.current?.values?.password !== "")
      fillPasswordInputWithNewPassword();
  }, [
    resetPasswordRef?.current?.generateRandomPassword,
    fillPasswordInputWithNewPassword,
  ]);

  const handleResetPassword = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setSubmitting(true);
      await axios.put(`users/${selectedUser.id}`, {
        user: { password: values.password },
      });
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      enqueueSnackbar("Password reset successfully", { variant: "success" });
      onClose();
    } catch (error) {
      setStatus({ success: false });
      setErrors({ submit: error.password });
      setSubmitting(false);
      enqueueSnackbar("Unable to reset password", { variant: "error" });
    }
  };

  const handleOpenPasswordGenerateSettings = () =>
    setIsPasswordGenerateSettingsOpen((prevState) => !prevState);

  return (
    <FormDialog
      size="xs"
      open={open}
      onClose={onClose}
      onExited={onExited}
      title="Reset Password"
      subTitle="Enter a new password or generate a random password"
      formSettings={{
        onSubmit: handleResetPassword,
        validationSchema: resetPasswordValidationSchema,
        initialValues: {
          password: "",
        },
        fields: [
          {
            label: "Password",
            name: "password",
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={fillPasswordInputWithNewPassword}>
                    <SyncIcon type="light" />
                  </IconButton>
                  <IconButton
                    onClick={handleOpenPasswordGenerateSettings}
                    edge="end"
                  >
                    <SlidersHIcon type="light" />
                  </IconButton>
                </InputAdornment>
              ),
            },
          },
        ],
        dialogContent: (
          <ResetPasswordDialogSettings
            open={isPasswordGenerateSettingsOpen}
            settings={generatePasswordSettings}
            ref={resetPasswordRef}
          />
        ),
        submitButton: {
          text: "Reset Password",
        },
        innerRef: formikRef,
      }}
    />
  );
};

ResetPasswordDialog.propTypes = {
  /**
   * Weather or not the dialog is open
   */
  open: PropTypes.bool.isRequired,

  /**
   * Callback to run when the dialog cancel button is clicked;
   * takes no arguments
   */
  onClose: PropTypes.func,

  /**
   * Callback to run when the dialog is completely exited off
   * the screen; takes no arguments
   */
  onExited: PropTypes.func,

  /**
   * Selected user object representing the user whose
   * password will get reset; you just need the id
   * for this particular component
   */
  selectedUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    createdAt: PropTypes.string,
    admin: PropTypes.bool,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
};

ResetPasswordDialog.defaultProps = {
  onClose: () => { },
  onExited: () => { },
  selectedUser: {},
  open: false,
};

export default ResetPasswordDialog;
