import React, { useState, useEffect } from "react";
import { Typography, Fade, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "react-avatar-edit";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

const DEFAULT_HEIGHT = 300;
// ms
const DEFAULT_NOTIFICATION_TIME = 1200;
const ICON_STYLES = {
  width: "100%",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  display: "block",
};
const FILE_BYTE_LIMIT = 71680;

// Interpolate props and theme to affect how styling is generated
// https://stackoverflow.com/questions/48879517/passing-props-to-material-ui-style
const useStyles = ({ width, height }) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "flex-start",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        padding: theme.spacing(3),
      },
    },
    preview: {
      // height: "100%",
    },
    previewGroup: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      justifyContent: "center",
      width,
      height,
      [theme.breakpoints.up("sm")]: {
        margin: 0,
      },
    },
    alert: {
      width: "100%",
      position: "fixed",
      zIndex: theme.zIndex.snackbar,
      top: 0,
      left: 0,
    },
    closeBtn: {
      paddingRight: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        paddingRight: theme.spacing(6),
      },
      [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(8),
      },
    },
  }));

/**
 * Component that displays a file input area and preview crop
 * area for user uploaded photos
 */
const AvatarEditor = ({ onFileSelect, width, height, ...props }) => {
  const classes = useStyles({ width, height })();
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, DEFAULT_NOTIFICATION_TIME);
    }
    return () => clearTimeout(timer);
  }, [error]);

  const onClose = () => {
    if (onFileSelect) onFileSelect(null);
    setAvatarPreview(null);
  };

  const onCrop = (preview) => {
    if (onFileSelect) onFileSelect(preview);
    setAvatarPreview(preview);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > FILE_BYTE_LIMIT) {
      setError("File is too large to upload");
      elem.target.value = "";
    }
  };

  return (
    <div className={classes.root}>
      <Fade in={!!error}>
        <Alert
          severity="warning"
          variant="filled"
          className={classes.alert}
          action={
            <IconButton
              className={classes.closeBtn}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Fade>
      <Avatar
        width={width}
        height={height}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        src={avatar}
        labelStyle={ICON_STYLES}
      />
      <div className={classes.previewGroup}>
        <Typography
          className={classes.name}
          gutterBottom
          variant="h4"
          color="textPrimary"
        >
          Preview
        </Typography>
        {avatarPreview && (
          <img className={classes.preview} src={avatarPreview} alt="Preview" />
        )}
      </div>
    </div>
  );
};

AvatarEditor.propTypes = {
  /**
   * Callback to run on photo crop with the cropped image, accepts
   * the cropped image as an arguement
   */
  onFileSelect: PropTypes.func,
  /**
   * Width of the the file input area and preview box; by deafault
   * is 50px less than the height
   */
  width: PropTypes.number,
  /**
   * Height of the file input area and preview box,
   */
  height: PropTypes.number,
};

AvatarEditor.defaultProps = {
  onFileSelect: null,
  width: DEFAULT_HEIGHT - 50,
  height: DEFAULT_HEIGHT,
};

export default AvatarEditor;
