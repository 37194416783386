import React from "react";
import { Card, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Close as CloseIcon } from "@material-ui/icons";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  root: {
    width: 200,
    position: "absolute",
    zIndex: 50,
  },
  card: {
    position: "absolute",
    top: 25,
    left: 0,
    display: "inline-block",
  },
  cardContent: {
    paddingTop: 0,
  },
  cardHeader: {
    width: "100%",
  },
}));

/**
 * Component to be used on maps to display an information
 * window overlay
 */

const InfoWindow = ({ open, onClose, title, subheader, content }) => {
  const classes = useStyles();

  if (open === false) return null;

  return (
    <Box className={classes.root}>
      <Card className={classes.card} variant="outlined">
        <CardHeader
          className={classes.cardHeader}
          action={
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          title={title}
          subheader={subheader}
        />
        <CardContent className={classes.cardContent}>{content}</CardContent>
      </Card>
    </Box>
  );
};

InfoWindow.propTypes = {
  /**
   * Callack that runs when the "x" icon is clicked. It
   * receives 1 argument; the generated event
   */
  onClose: PropTypes.func,
  /**
   * Weather or not the window is rendered
   */
  open: PropTypes.bool,
  /**
   * The title of the window
   */
  title: PropTypes.string,
  /**
   * The subheader of the window
   */
  subheader: PropTypes.string,
  /**
   * Main content to render in window (Node or string)
   */
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

InfoWindow.defaultProps = {
  onClose: () => {},
  open: false,
  title: "",
  subheader: "",
  content: "",
};

export default InfoWindow;
