import React, { useMemo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { schemeBlues } from "d3";
import PropTypes from "prop-types";
import useCountryMapLayers from "../../common/hooks/useCountryMapLayers";

const useDynamicMapStyles = makeStyles(() => ({
  deSelectedLandRegion: {
    fill: "#e5eff5",
  },
  defaultCursor: {
    cursor: "default",
  },
  pointer: {
    cursor: "pointer",
  },
  outerSVGFont: {
    fontWeight: 500,
    fontSize: 15,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  landRegionPath: {
    transition: "fill 0.2s ease-out",
    stroke: "#ffffff",
  },
  map: {},
}));

export default function DynamicMap({
  legendLandRegionsTitle,
  onChangeSelectedLandRegions,
  /**
   * @type {string[] | null}
   */
  selectedLandRegions = [],
  dataMap,
  svgUrl,
  useCountryMapStyles,
  landRegionTextOverlayPositions,
  landRegionToCountMap,
  landRegionCountOffsetY = 0,
  innerMapOffsetX = 0,
  innerMapOffsetY = 0,
  innerMapScaling = 1,
}) {
  // merge countNumbers with state paths
  const landRegionsWithCount = landRegionTextOverlayPositions.map(
    (landRegionData) => {
      return {
        ...landRegionData,
        countNumber: landRegionToCountMap[landRegionData.landRegionCode],
      };
    }
  );

  /**
   * All of the state or province codes.
   *
   * @type {string[]}
   */
  const landRegionCodes = Object.values(landRegionsWithCount).map(
    // IMPORTANT: This must be stateCode
    ({ landRegionCode }) => landRegionCode
  );

  const dynamicMapClasses = useDynamicMapStyles();
  const userClasses = useCountryMapStyles();

  /** @type {Object} */
  const classes = useMemo(
    () => ({ ...dynamicMapClasses, ...userClasses }),
    [dynamicMapClasses, userClasses]
  );

  // true when all provinces are selected (null) and you hover over a province
  const [hoveredLandRegion, setHoveredLandRegion] = useState(null);

  const theme = useTheme();
  const colorScale = theme.palette.gradient ?? schemeBlues[9].slice(-6);

  /**
   * Adds a new land region code to the current selection.
   *
   * @param {string} landRegionCode
   */
  const handleChangeSelectedLandRegions = (landRegionCode) => {
    let nextSelectedLandRegions = [];

    if (landRegionCode) {
      if (selectedLandRegions?.includes(landRegionCode)) {
        // Remove it
        nextSelectedLandRegions = selectedLandRegions.filter(
          (predicate) => predicate !== landRegionCode
        );
      } else {
        // Add it
        nextSelectedLandRegions = [
          ...new Set([...(selectedLandRegions || []), landRegionCode]),
        ];
      }
    }

    onChangeSelectedLandRegions(
      // FIXME: (jh) This changing of type to null if there are no selected
      // provinces makes it harder to work with; it should probably be an empty
      // array instead
      nextSelectedLandRegions.length > 0 ? nextSelectedLandRegions : null
    );
  };

  /**
   * If defined, the value is an Object with option keys and "true" values.
   *
   * @type {Object | void}
   **/
  const optionsMap = dataMap?.options?.reduce(
    (acc, cur) => ({ ...acc, [cur]: true }),
    {}
  );

  /** @type {boolean} */
  const areAllLandRegionsSelected = Boolean(
    !selectedLandRegions || !selectedLandRegions.length
  );

  /**
   * The "to-render" variant of the selectedLandRegions.
   *
   * Rules:
   *
   *  - If no land regions are selected, it will fake all of them being
   * selected.
   *
   * - If a land region is hovered, it will fake that region being added to the
   * list of selections.
   *
   * - Otherwise, it will render all of the selected land regions, unmodified.
   *
   * @type {string[]}
   */
  const toRenderSelectedLandRegions =
    areAllLandRegionsSelected && !hoveredLandRegion
      ? landRegionCodes
      : [
          ...new Set(
            [hoveredLandRegion || [], ...(selectedLandRegions || [])].flat()
          ),
        ];

  /**
   * @type {string[]}
   */
  const disabledLandRegionCodes = landRegionCodes.filter(
    (landRegionCode) => optionsMap && !optionsMap?.[landRegionCode]
  );

  /**
   * @type {React.Component | null}
   */
  const SVGLayeredCountryMap = useCountryMapLayers(svgUrl);
  if (!SVGLayeredCountryMap) {
    return null;
  }

  return (
    <SVGLayeredCountryMap
      classes={classes}
      legendTitle={dataMap?.legend}
      legendLandRegionsTitle={legendLandRegionsTitle}
      colorScale={colorScale}
      // FIXME: (jh) Can these two landRegion... props be merged, somehow?
      landRegionToCountMap={landRegionToCountMap}
      landRegionsWithCount={landRegionsWithCount}
      // FIXME: (jh) Rename dataMap?
      dataMap={dataMap}
      selectedLandRegions={toRenderSelectedLandRegions}
      onLandRegionEnter={(landRegionCode) =>
        setHoveredLandRegion(landRegionCode)
      }
      onLandRegionLeave={() => setHoveredLandRegion(null)}
      onLandRegionClick={(landRegionCode) => {
        handleChangeSelectedLandRegions(landRegionCode);
      }}
      disabledLandRegions={disabledLandRegionCodes}
      landRegionCountOffsetY={landRegionCountOffsetY}
      innerMapOffsetX={innerMapOffsetX}
      innerMapOffsetY={innerMapOffsetY}
      innerMapScaling={innerMapScaling}
    />
  );
}

DynamicMap.propTypes = {
  /**
   * Title displayed in legend above smaller land region squares.
   *
   * i.e. "States" or "Provinces" */
  legendLandRegionsTitle: PropTypes.string.isRequired,

  /**
   * Called with the selected land regions, on change, passes
   * the selected state/province code as a single arguement
   */
  onChangeSelectedLandRegions: PropTypes.func.isRequired,

  /** Array of state/province codes you want pre-selected when
   * the map loads
   */
  selectedLandRegions: PropTypes.arrayOf(PropTypes.string),

  /**
   * Map configuration object that sets the legend title
   * and includes a function called valueFormatter which accepts a number as an argument
   * and returns a formated currency string (with $ appended)
   * (meant to indicate usd currency)
   */
  dataMap: PropTypes.shape({
    legend: PropTypes.string.isRequired,
    valueFormatter: PropTypes.func.isRequired,
  }).isRequired,

  /** The URL of the SVG file (i.e. import svgUrl from './map.svg) */
  svgUrl: PropTypes.string.isRequired,

  /** Created from makeStyles command; don't invoke your function just pass it in */
  useCountryMapStyles: PropTypes.func.isRequired,

  /**
   * Shape of array in configuration file to determine where to overlay
   * text on the specific state located on the SVG map
   */
  landRegionTextOverlayPositions: PropTypes.arrayOf(
    PropTypes.shape({
      landRegionCode: PropTypes.string.isRequired,
      textOverlay: PropTypes.shape({
        transform: PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
        }).isRequired,
        rect: PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
        }),
      }).isRequired,
    })
  ).isRequired,

  /**
   * Object with key name corresponding to the displayed
   * map's state/provice, with the value being the count
   * (whatever the unit may be).
   * e.g.
   * const landRegionToCountMap = {  NY: 1, NJ: 2 }
   */
  landRegionToCountMap: PropTypes.object.isRequired,

  /**
   * Vertical spacing between land region code and number text overlays
   * (default = 0)
   **/
  landRegionCountOffsetY: PropTypes.number,

  /**
   * Horizontal spacing of the inner map relative to the outer SVG container
   * (default is 0)
   **/
  innerMapOffsetX: PropTypes.number,

  /**
   * Vertical spacing of the inner map relative to the outer SVG container
   * (default is 0)
   **/
  innerMapOffsetY: PropTypes.number,

  /**
   * Scaling of the inner map in relation to the outer SVG container (default
   * is 1)
   **/
  innerMapScaling: PropTypes.number,
};
