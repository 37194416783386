const usaStates = [
  {
    landRegionCode: 'CA',
    textOverlay: {
      transform: {
        x: 78.93946983258735,
        y: 180.32923935518647
      }
    }
  },
  {
    landRegionCode: 'FL',
    textOverlay: {
      transform: {
        x: 485.4484838262045,
        y: 311.34365159805617
      }
    }
  },
  {
    landRegionCode: 'GA',
    textOverlay: {
      transform: {
        x: 459.2301321411308,
        y: 260.46277634828203
      }
    }
  },
  {
    landRegionCode: 'ID',
    textOverlay: {
      transform: {
        x: 150.9532237795459,
        y: 99.02717196674928
      }
    }
  },
  {
    landRegionCode: 'IL',
    textOverlay: {
      transform: {
        x: 389.8302265061739,
        y: 169.86540379491007
      }
    }
  },
  {
    landRegionCode: 'IA',
    textOverlay: {
      transform: {
        x: 346.6802978364948,
        y: 145.1622426223237
      }
    }
  },
  {
    landRegionCode: 'KY',
    textOverlay: {
      transform: {
        x: 436.8457005427171,
        y: 199.09467444507956
      }
    }
  },
  {
    landRegionCode: 'LA',
    textOverlay: {
      transform: {
        x: 359.8925618780151,
        y: 289.7380810220952
      }
    }
  },
  {
    landRegionCode: 'MI',
    textOverlay: {
      transform: {
        x: 427.04138364820386,
        y: 125.4815364414421
      }
    }
  },
  {
    landRegionCode: 'MN',
    textOverlay: {
      transform: {
        x: 334.92195055123926,
        y: 93.43442396382113
      }
    }
  },
  {
    landRegionCode: 'MO',
    textOverlay: {
      transform: {
        x: 358.4611138827095,
        y: 193.68783135604488
      }
    }
  },
  {
    landRegionCode: 'NY',
    textOverlay: {
      transform: {
        x: 518.5218635757531,
        y: 113.41184648750487
      }
    }
  },
  {
    landRegionCode: 'OR',
    textOverlay: {
      transform: {
        x: 95.73690873339882,
        y: 91.74632049437294
      }
    }
  },
  {
    landRegionCode: 'TN',
    textOverlay: {
      transform: {
        x: 423.47008084060553,
        y: 223.11523125794758
      }
    }
  },
  {
    landRegionCode: 'TX',
    textOverlay: {
      transform: {
        x: 286.1097670837416,
        y: 284.46934001550903
      }
    }
  },
  {
    landRegionCode: 'VA',
    textOverlay: {
      transform: {
        x: 497.6546154230556,
        y: 189.56793453602327
      }
    }
  },
  {
    landRegionCode: 'WI',
    textOverlay: {
      transform: {
        x: 378.14512114840767,
        y: 110.46609072050687
      }
    }
  },
  {
    landRegionCode: 'AK',
    textOverlay: {
      transform: {
        x: 95.65416099214774,
        y: 315.66370090097683
      }
    }
  },
  {
    landRegionCode: 'AZ',
    textOverlay: {
      transform: {
        x: 156.2685836359159,
        y: 234.1638204776313
      }
    }
  },
  {
    landRegionCode: 'AR',
    textOverlay: {
      transform: {
        x: 360.34289033316975,
        y: 239.11537514503124
      }
    }
  },
  {
    landRegionCode: 'CO',
    textOverlay: {
      transform: {
        x: 227.13482205507972,
        y: 180.96282203131278
      }
    }
  },
  {
    landRegionCode: 'IN',
    textOverlay: {
      transform: {
        x: 418.72747456259447,
        y: 169.2803616929733
      }
    }
  },
  {
    landRegionCode: 'NE',
    textOverlay: {
      transform: {
        x: 285.8470914782802,
        y: 151.65553224087628
      }
    }
  },
  {
    landRegionCode: 'NM',
    textOverlay: {
      transform: {
        x: 215.10447724326747,
        y: 240.53357512656157
      }
    }
  },
  {
    landRegionCode: 'NC',
    textOverlay: {
      transform: {
        x: 498.360919984736,
        y: 215.67959710428872
      }
    }
  },
  {
    landRegionCode: 'OH',
    textOverlay: {
      transform: {
        x: 452.2343589342477,
        y: 160.00558912497468
      }
    }
  },
  {
    landRegionCode: 'ME',
    textOverlay: {
      transform: {
        x: 564.4297785704193,
        y: 68.77996295738836
      }
    }
  },
  {
    landRegionCode: 'MS',
    textOverlay: {
      transform: {
        x: 391.634969900851,
        y: 265.9306485865639
      }
    }
  },
  {
    landRegionCode: 'MT',
    textOverlay: {
      transform: {
        x: 201.55534952123472,
        y: 71.47602967518795
      }
    }
  },
  {
    landRegionCode: 'OK',
    textOverlay: {
      transform: {
        x: 306.99304856260767,
        y: 230.59823723599533
      }
    }
  },
  {
    landRegionCode: 'SC',
    textOverlay: {
      transform: {
        x: 484.0911984506378,
        y: 240.03939665984163
      }
    }
  },
  {
    landRegionCode: 'SD',
    textOverlay: {
      transform: {
        x: 283.511499039218,
        y: 113.4600632231455
      }
    }
  },
  {
    landRegionCode: 'UT',
    textOverlay: {
      transform: {
        x: 166.94078282067073,
        y: 168.97894987830827
      }
    }
  },
  {
    landRegionCode: 'WA',
    textOverlay: {
      transform: {
        x: 107.8597811686647,
        y: 48.3835853549256
      }
    }
  },
  {
    landRegionCode: 'WV',
    textOverlay: {
      transform: {
        x: 476.86612444082493,
        y: 180.2176521345572
      }
    }
  },
  {
    landRegionCode: 'WY',
    textOverlay: {
      transform: {
        x: 213.57843695956655,
        y: 126.74502963676738
      }
    }
  },
  {
    landRegionCode: 'AL',
    textOverlay: {
      transform: {
        x: 422.3156871159539,
        y: 262.86569865819956
      }
    }
  },
  {
    landRegionCode: 'ND',
    textOverlay: {
      transform: {
        x: 283.11107422226115,
        y: 74.22420731872981
      }
    }
  },
  {
    landRegionCode: 'PA',
    textOverlay: {
      transform: {
        x: 499.2451216754262,
        y: 144.19617794812635
      }
    }
  },
  {
    landRegionCode: 'KS',
    textOverlay: {
      transform: {
        x: 298.6039839884596,
        y: 192.03051279682825
      }
    }
  },
  {
    landRegionCode: 'NV',
    textOverlay: {
      transform: {
        x: 118.34395087888619,
        y: 159.34561195869944
      }
    }
  },
  {
    landRegionCode: 'HI',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 38
      },
      transform: {
        x: 615,
        y: 55
      }
    }
  },
  {
    landRegionCode: 'VT',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 73
      },
      transform: {
        x: 615,
        y: 90
      }
    }
  },
  {
    landRegionCode: 'NH',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 108
      },
      transform: {
        x: 615,
        y: 125
      }
    }
  },
  {
    landRegionCode: 'MD',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 143
      },
      transform: {
        x: 615,
        y: 160
      }
    }
  },
  {
    landRegionCode: 'RI',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 178
      },
      transform: {
        x: 615,
        y: 195
      }
    }
  },
  {
    landRegionCode: 'MA',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 213
      },
      transform: {
        x: 615,
        y: 230
      }
    }
  },
  {
    landRegionCode: 'CT',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 248
      },
      transform: {
        x: 615,
        y: 265
      }
    }
  },
  {
    landRegionCode: 'NJ',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 283
      },
      transform: {
        x: 615,
        y: 300
      }
    }
  },
  {
    landRegionCode: 'DE',
    textOverlay: {
      rect: {
        width: 35,
        height: 35,
        x: 597,
        y: 318
      },
      transform: {
        x: 615,
        y: 335
      }
    }
  }
];

export default usaStates;
