import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const PaintBrush = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M455.59 0c-15.81 0-30.62 6.99-41.93 17.15C195.73 211.82 169.77 216.5 179.98 281.99c-41.52 4.96-78.59 24.05-100.32 81.32-2.68 7.08-9.12 11.38-16.64 11.38-12.67 0-51.85-31.56-63.02-39.19C0 429.45 43.26 512 146 512c117.18 0 152.72-87.75 145.06-145.89 56.9-7.01 97.15-62.51 206.45-266.49C505.2 84.65 512 68.48 512 51.66 512 21.52 484.89 0 455.59 0zM236.52 445.55C216.47 468.41 186.02 480 146 480c-63.78 0-92.29-38.83-104.75-78.69 8.02 3.65 14.98 5.39 21.77 5.39 20.92 0 39.2-12.58 46.56-32.03 6.65-17.52 16.05-53.95 83.76-62.04l65.08 50.62c4.03 30.68-1.25 58.75-21.9 82.3zM469.31 84.5c-118.4 220.96-143.69 245.11-194.08 251.31l-62-48.22c-8.8-56.43-14.8-35.28 221.82-246.64 6.33-5.69 13.81-8.95 20.54-8.95C467.38 32 480 39.9 480 51.66c0 10.58-5.54 22.79-10.69 32.84z',
    [STYLE.REGULAR]: 'M455.59 0c-15.81 0-30.62 6.99-41.93 17.15C195.73 211.82 169.77 216.5 179.98 281.99c-41.52 4.96-78.59 24.05-100.32 81.32-2.68 7.08-9.12 11.38-16.64 11.38-12.67 0-51.85-31.56-63.02-39.19C0 429.45 43.26 512 146 512c117.18 0 152.72-87.75 145.06-145.89 56.9-7.01 97.15-62.51 206.45-266.49C505.2 84.65 512 68.48 512 51.66 512 21.52 484.89 0 455.59 0zM222.08 432.89c-16.24 18.52-41.84 27.91-76.08 27.91-35.97 0-58.6-14.93-72.68-35.65 24.56-3.6 45.23-19.96 54.21-43.67 13.79-36.33 32.61-45.55 58.52-48.65l16.43-1.96 36.06 28.51 1.77 13.41c2.07 15.77-1.46 40.97-18.23 60.1zm62.72-117.6l-16.87 2.08L233 289.75l-2.44-15.64C224.3 233.92 444.24 44.8 456.12 54.57c12.12 9.98-121.27 254.56-171.32 260.72z',
    [STYLE.SOLID]: 'M167.02 309.34c-40.12 2.58-76.53 17.86-97.19 72.3-2.35 6.21-8 9.98-14.59 9.98-11.11 0-45.46-27.67-55.25-34.35C0 439.62 37.93 512 128 512c75.86 0 128-43.77 128-120.19 0-3.11-.65-6.08-.97-9.13l-88.01-73.34zM457.89 0c-15.16 0-29.37 6.71-40.21 16.45C213.27 199.05 192 203.34 192 257.09c0 13.7 3.25 26.76 8.73 38.7l63.82 53.18c7.21 1.8 14.64 3.03 22.39 3.03 62.11 0 98.11-45.47 211.16-256.46 7.38-14.35 13.9-29.85 13.9-45.99C512 20.64 486 0 457.89 0z'
  };

  return (
    <FontAwesome
      viewBox="0 0 512 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

PaintBrush.propTypes = {
  type: PropTypes.string
};

export default PaintBrush;
