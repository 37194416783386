export default {
  pendingDocument: 'Documento Pendiente',
  complete: 'Íntegro',
  escalateToNSD: 'Escalar a NSD',
  dispatched: 'Enviado',
  pendingDispatch: 'Despacho Pendiente',
  cancelled: 'Anulado',
  policeHold: 'Retenciones Policiales',
  newCase: 'Nuevo Caso',
  new: 'Nuevo',
  accepted: 'Aceptado',
  inRoute: 'En Ruta',
  onScene: 'En Escena',
  towInProgress: 'Remolque en Curso',
  destinationArrival: 'Llegada al Destino',
  digitalDispatch: 'Despacho Digital',
  totalImpoundCalls: 'Total de Llamadas de Incautación',
  PieChartCard: {
    title: 'Incautaciones por Porcentaje',
  },
};
