import React from "react";
import PropTypes from "prop-types";
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useApp } from "src/hooks/useApp";
import Page from "./Page";
import PageHeader from "./PageHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

/**
 * Composite component that combines a Page with PageHeader
 * to create a breadcrumb page wrapper for easy navigation
 */
const PageWithBreadCrumbs = ({
  title,
  breadcrumbs,
  children,
  button,
  headerMenuSettings,
}) => {
  const classes = useStyles();
  const currentApp = useApp();
  return (
    <Page title={title}>
      <Container maxWidth={false} className={classes.container}>
        <PageHeader
          title={title}
          breadcrumbs={
            breadcrumbs ?? [
              { title: currentApp.appName, href: currentApp.path },
              { title },
            ]
          }
          button={button}
          headerMenuSettings={headerMenuSettings}
        />
        <Box mt={3}>{children}</Box>
      </Container>
    </Page>
  );
};

PageWithBreadCrumbs.propTypes = {
  /**
   * Array of breadcrumbs to list out; each has a (generic) title,
   * href and resourceName (more specific resource name) properties
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      resourceName: PropTypes.string,
    })
  ),
  /**
   * Content to render below the breadcrumb trail as the
   * main content of the page
   */
  children: PropTypes.any,

  /**
   * Main title (largest text) to be rendered just below
   * the list of breadcrumbs
   */
  title: PropTypes.string,

  /**
   * Component (not object) of the custom button you
   * wish to be rendered across from the breadcrumbs
   */
  button: PropTypes.element,

  /**
   * Object with an array of settings that specifies the props to
   * create the Select or MultiSelect.
   */
  headerMenuSettings: PropTypes.shape({
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(["single", "multi"]).isRequired,
        value: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            Icon: PropTypes.object,
          })
        ),
        changeCallback: PropTypes.func,
        Icon: PropTypes.object,
      })
    ),
  }),
};

PageWithBreadCrumbs.defaultProps = {
  breadcrumbs: [],
  children: null,
  title: "Sample Title",
  button: null,
  headerMenuSettings: null,
};

export default PageWithBreadCrumbs;
