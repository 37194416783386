import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  loginWithCode,
  loginWithMicrosoft,
} from '../../../actions/accountActions';
import PORTAL_TYPE from '../../../constants/portalType';
import { getLoginFlowEndpoints } from '../../../utils/company';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {},
}));


function LoginForm({ className, onSubmitSuccess, type, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef();
  const history = useHistory();
  const prevPath = history.location.state?.prevPath || null;
  const prevQueryParams = history.location.state?.prevQueryParams || null;
  const isMountedRef = useIsMountedRef();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = new URLSearchParams(urlParams.get('state'));
    const intitialPathFromState = state.get('prevPath');
    const initialQueryParamsFromState = state.get('prevQueryParams');
    const azureTenantId = state.get('azureTenantId');
    const email = state.get('email');

    const loginWithMicrosoftCode = async () => {
      try {
        const { loginType } = getLoginFlowEndpoints(email);
        formRef.current.setValues({ email });
        formRef.current.setSubmitting(true);
        await dispatch(loginWithCode(code, azureTenantId, loginType));
        if (intitialPathFromState) {
          history.push({
            pathname: intitialPathFromState,
            search: initialQueryParamsFromState
          });
        }
        if (onSubmitSuccess) await onSubmitSuccess();
      } catch (error) {
        console.error(error);
        formRef.current?.setErrors({
          submit:
            (error?.response && error?.response?.data?.message) ||
            'Unable to login',
        });
      }

      if (isMountedRef.current && formRef.current) {
        formRef.current.setSubmitting(false);
      }
    };

    if (code && formRef.current) {
      loginWithMicrosoftCode();
    }
  }, [isMountedRef, formRef]);

  return (
    <Formik
      innerRef={formRef}
      validateOnBlur={false}
      validateOnChange={false}
      {...(type === PORTAL_TYPE.CLIENT && {
        validationSchema: Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
        }),
      })}
      initialValues={{
        email: '',
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(loginWithMicrosoft(values.email, prevPath, prevQueryParams));
          if (onSubmitSuccess) await onSubmitSuccess();
        } catch (error) {
          const message =
            (error.response && error.response.data.message) ||
            'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          {type === PORTAL_TYPE.CLIENT && (
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              autoFocus
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.email}
              variant="outlined"
              disabled={isSubmitting}
            />
          )}
          <Box mt={2}>
            {isSubmitting && (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignContent={'center'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <CircularProgress style={{ marginRight: 10 }} />{' '}
                <Typography>Logging In...</Typography>
              </Box>
            )}
            {!isSubmitting && (
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {type === PORTAL_TYPE.CLIENT ? (
                  isSubmitting ? (
                    'Logging In...'
                  ) : (
                    'Log In'
                  )
                ) : (
                  <>
                    <img
                      alt="Office-365"
                      src="https://img.icons8.com/ios-filled/48/000000/office-365.png"
                      style={{ width: 35, marginRight: 5 }}
                    />
                    Log In
                  </>
                )}
              </Button>
            )}
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  /**
   * Callback that runs after a user successfully logs in;
   * TODO: Currently cannot access hook functions here as they are
   * not run/evaluated in the context of react; running in the
   * context of the @nsd-fe doesn't allow React hooks to be run in them (?)
   */
  onSubmitSuccess: PropTypes.func,
  /**
   * Type of app this is, passed in from Routes.js
   */
  type: PropTypes.oneOf(['client', 'employee']),
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => { },
};

export default LoginForm;