import DynamicMapCard from "../common/components/DynamicMapCard";
import UsaMap from "./UsaMap";

export default function UsaMapCard({ ...rest }) {
  /**
   * Adapts "state" code usage into "land region" usage to smooth over
   * differences between states, provinces, etc.
   */
  const adjustedProps = {
    activeLandRegions: rest.activeStates,
    countByLandRegion: rest.countByState.map(({ stateCode, ...rest }) => ({
      landRegionCode: stateCode,
      ...rest,
    })),
    onChangeSelectedLandRegions: rest.onChangeSelectedStates,
  };

  return <DynamicMapCard MapView={UsaMap} {...adjustedProps} {...rest} />;
}
