export const QUEUE_NOTIFICATION = '@notifications/queue-notification';
export const UPDATE_NOTIFICATION = '@notifications/update-notification';
export const CLEAR_ALL_NOTIFICATIONS = '@notifications/clear-all-notification';
export const SET_NOTIFICATION_SETTINGS =
  '@notifications/set-notification-settings';
export const CLEAR_ONE_NOTIFICATION = '@notifications/clear-one-notification';

/*
  Notification shape
  {
    to: '', //Leave to blank to send to all users
    from: '',
    read: false,
    text: '',
    //Relative link
    pageLink: '' ,
    severity: ['info' | 'warning' | 'error' | 'success']
  }  
 */

const DEFAULT_STATE = {
  messages: [],
  pulseAnimation: false,
  displayNumberedBadge: true,
};

export const clearAllNotifications = () => ({
  type: CLEAR_ALL_NOTIFICATIONS,
  payload: { ...DEFAULT_STATE },
});

export const clearOneNotification = (docId) => ({
  type: CLEAR_ONE_NOTIFICATION,
  payload: { docId },
});

export const setNotificationSettings = (settingsObject = DEFAULT_STATE) => ({
  type: SET_NOTIFICATION_SETTINGS,
  payload: { ...settingsObject },
});

// plural marks all as read
export const updateNotifications =
  (FirebaseCrudInstance) => async (dispatch, getState) => {
    try {
      await FirebaseCrudInstance.updateAll(
        FirebaseCrudInstance.getNotificationsPath(),
        { read: true },
        true
      );
      const { notifications } = getState();
      const { messages = [] } = notifications;
      const alteredMessages = messages?.map((message) => {
        return {
          ...message,
          read: !message?.read,
        };
      });
      await dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { alteredMessages },
      });
    } catch (err) {
      console.error({ err });
    }
  };

// Pass in single updated notification (e.g. marked one as read)
export const updateNotification =
  (FirebaseCrudInstance, readMessage) => async (dispatch, getState) => {
    try {
      const { notifications } = getState();
      const { messages = [] } = notifications;
      await FirebaseCrudInstance.updateOne(
        FirebaseCrudInstance.getNotificationsPath(),
        readMessage?.docId,
        {
          ...readMessage,
          read: !readMessage?.read,
        }
      );
      const alteredMessages = messages?.map((message) => {
        if (message?.docId === readMessage?.docId) {
          return {
            ...readMessage,
            read: !readMessage?.read,
          };
        }
        return { ...message };
      });
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { messages: alteredMessages },
      });
    } catch (err) {
      console.error({ err });
    }
  };

export const queueNotification = (messages) => ({
  type: QUEUE_NOTIFICATION,
  payload: { messages },
});
