export default {
  claim: 'Claim',
  noClaimsFound: 'No Claims Found',
  "noClaims.message": 'Please try another time range or select different agents.',
  "pleaseSelectDifferentStatusesAdjustersUnderwritersLendersOrProducts": "Please select different statuses, adjusters, underwriters, lenders, or products",
  totalClaims: 'Total Claims',
  processed: 'Processed',
  denied: 'Denied',
  paid: 'Paid',
  open: 'Open',
  closed: 'Closed',
  cwop_abbreviation: 'CWOP',
  "processedClaimsByPercentage": "Processed Claims by Percentage",
  "byPercentage": "By Percentage",
  "pleaseCheckBackLater": "Please check back later",
  "noProcessedClaims": "No Processed Claims",
  "investigation": "Investigation",
  "paymentReview": "Payment Review",
  "denial": "Denial",
  "customerCommunication": "Customer Communication",
  "other": "Other",
  "searchClaims": "Search claims",
  "claimType": "Claim Type",
  "claimAmount": "Claim Amount",
  "dateOfLoss": "Date Of Loss",
  "isDisbursed": "Is Disbursed",
  "created": "Created",
  "createdAt": "Created at",
  "createdBy": "Created by",
  "claimant": "Claimant",
  "claimNumber_hash": "Claim #",
  "type": "Type",
  "requested": "Requested",
  "approved": "Approved",
  "status": "Status",
  "disposition": "Disposition",
  "mobilePhone": "Mobile Phone",
  "shipToPhone": "Ship To Phone",
  "isRegistered": "Is Registered",
  "optInEmail": "Opt-in Email",
  "optInSMS": "Opt-in SMS",
  "policy": "Policy",
  "agentNumber_hash": "Agent #",
  "prNumber_hash": "PR #",
  "transactionDate": "Transaction Date",
  "datePaid": "Date Paid",
  "effectiveDate": "Effective Date",
  "expirationDate": "Expiration Date",
  "term": "Term", 
  "tireRackInvoice": "Tire Rack Invoice",
  "invoiceDate": "Invoice Date",
  "invoiceNumber": "Invoice #",
  "totalPrice": "Total Price",
  "roadHazardId": "Road Hazard Id",
  "vehicle": "Vehicle",
  "vehicleYear": "Vehicle Year",
  "vehicleMake": "Vehicle Make",
  "vehicleModel": "Vehicle Model",
  "tire": "Tire",
  "tireMake": "Tire Make",
  "tireModel": "Tire Model",
  "diameter": "Diameter",
  "consumerPrice": "Consumer Price",
  "tireRackPrice": "Tire Rack Price",
  "quantity": "Quantity",
  "claimLog": "Claim Log",
  "created By": "Created By",
  "updatedAt": "Updated At",
  "updatedBy": "Updated By",
  "reserve": "Reserve",
  "remain": "Remain",
  "remaining": "Remaining",
  "causeOfLoss": "Cause Of Loss",
  "treatyInfo": "Treaty Info",
  "member": "Member",
  "planName": "Plan Name",
  "plan": "Plan",
  "code": "Code",
  "cert": "Cert",
  "fax": "Fax",
  "entryDate": "Entry Date",
  "lossDate": "Loss Date",
  "closedDate": "Closed Date",
  "deniedDate": "Denied Date"
};