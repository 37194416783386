import { lazy } from 'react';
import { AuthGuard } from '@nsd/fe';
import {
  MapPin as MapPinIcon,
  Phone as PhoneCallIcon,
  BarChart as BarChartIcon,
  Search as SearchIcon,
  FileText as TowOrderIcon,
} from 'react-feather';
import {
  ImportantDevices as SubmittedOrdersIcon,
  HowToRegOutlined as RegisterIcon,
} from '@material-ui/icons';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { appIcon } from '../appIcon';

const MODULES = {
  CALLS: 'calls-dd31d5bb-9650-497e-bbc6-c21f913cda9a',
  NETWORK_MAP: 'networkMap-c6579a28-74d0-4dbc-967e-83dea91bc385',
  OPERATIONAL_REPORTS:
    'operationalReports-df4720a6-12d8-469a-81cc-8e1912e85c86',
  TOW_ORDER: 'towOrder-c7977f7f-38b3-4647-a852-8b06dbbad389',
  GENERIC_TOW_ORDER: 'genericTowOrder-ede247aa-e083-419d-b8ef-f0475cea5209',
  TURO_TOW_ORDER: 'turoTowOrder-085dd363-6b0e-411f-9e3d-a0094b44e0f6',
  IMPOUND_GENERIC_TOW_ORDER:
    'genericImpoundOrder-739c110d-f828-4ced-a2bc-bb6a2e9cb535',
  REPO_GENERIC_TOW_ORDER:
    'repossessionOrder-2a550543-1052-496f-b4a5-ef163115bc5e',
  SUBMITTED_ORDERS: 'submittedOrders-73b3d45f-099e-4bef-96a3-b22c57f30bbc',
  IMPOUND_CALLS: 'impounds-b3c460b8-074c-4c9d-bb8f-60f45b63b0ab',
  REGISTERED_CUSTOMERS: 'registeredCustomers-f3366d51-5925-4b68-aeaf-14ac96dc44fa',
  DISPATCH_SEARCH: 'dispatchSearch-68004f8d-59b6-46b9-9c65-1935945ff52c',
  ROADSIDE_CALLS: 'roadsideCalls-28ff5229-f3d4-40b1-93bf-ec495b6f8ebc',
  SANDBOX: 'sandboxForm-84e9cd20-db09-481d-a25c-a493c76a9a05',
  SIXT_IMPOUND_ORDER: 'sixtImpoundOrder-499113a6-8673-4e3a-b371-f6b6197181cf',
};

const appConfig = {
  appId: 'dispatch-d2781de2-3559-4dcf-abd9-33cea0f9d926',
  appName: 'dispatch.dispatch',
  priority: 6,
  path: 'dispatch',
  icon: appIcon.dispatchQoreApp,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: 'map',
      view: lazy(() => import('src/views/DispatchNetworkMapView/index')),
    },
    {
      exact: true,
      path: 'operational',
      view: lazy(() => import('src/views/DispatchOperationalView/index')),
    },
    {
      exact: true,
      path: 'search',
      view: lazy(() => import('src/views/DispatchCallSearchView/index')),
    },
    {
      exact: true,
      path: 'tow-order',
      view: lazy(() => import('src/views/DispatchTowOrderView/index')),
    },
    {
      exact: true,
      path: 'tow-order/create',
      view: lazy(() => import('src/views/DispatchTowOrderView/createTowOrder')),
    },
    {
      exact: true,
      path: 'create-dispatch',
      view: lazy(() => import('src/views/DispatchGenericTowOrderView/index')),
    },
    {
      exact: true,
      path: 'create-dispatch/create',
      view: lazy(() =>
        import('src/views/DispatchGenericTowOrderView/createTowOrder')
      ),
    },
    {
      exact: true,
      path: 'create-turo-dispatch',
      view: lazy(() => import('src/views/DispatchTuroTowOrderView/index')),
    },
    {
      exact: true,
      path: 'create-turo-dispatch/create',
      view: lazy(() =>
        import('src/views/DispatchTuroTowOrderView/createTowOrder')
      ),
    },
    {
      exact: true,
      path: 'create-impound-order',
      view: lazy(() =>
        import('src/views/DispatchGenericImpoundOrderView/index')
      ),
    },
    {
      exact: true,
      path: 'create-impound-order/create',
      view: lazy(() =>
        import('src/views/DispatchGenericImpoundOrderView/createTowOrder')
      ),
    },
    {
      exact: true,
      path: 'create-sixt-impound-order',
      view: lazy(() =>
        import('src/views/DispatchSixtImpoundOrderView/index')
      ),
    },
    {
      exact: true,
      path: 'create-sixt-impound-order/create',
      view: lazy(() =>
        import('src/views/DispatchSixtImpoundOrderView/createTowOrder')
      ),
    },
    {
      exact: true,
      path: 'create-repo-order',
      view: lazy(() =>
        import('src/views/DispatchGenericRepoOrderView/index')
      ),
    },
    {
      exact: true,
      path: 'create-repo-order/create',
      view: lazy(() =>
        import('src/views/DispatchGenericRepoOrderView/createTowOrder')
      ),
    },
    {
      exact: true,
      path: 'submitted-orders',
      view: lazy(() => import('src/views/SubmittedOrdersView/index')),
    },
    {
      exact: true,
      path: 'roadside',
      view: lazy(() => import('src/views/RoadsideView/index')),
    },
    {
      exact: true,
      path: 'impounds',
      view: lazy(() => import('src/views/ImpoundView/index')),
    },
    {
      exact: true,
      path: 'registered-customers',
      view: lazy(() => import('src/views/DispatchRegisteredCustomersView/index')),
    },
    {
      exact: true,
      path: 'roadside-tow',
      view: lazy(() => import('src/views/SandboxForm/index')),
    },
    {
      exact: true,
      path: 'roadside-tow/create',
      view: lazy(() => import('src/views/SandboxForm/createTowOrder')),
    },
  ],
  topBarConfig: {
    contacts: false,
    search: false,
    notifications: true,
    settings: true,
  },
  navConfig: {
    navBarItems: [
      {
        subheader: 'dispatch.reports',
        items: [
          {
            title: 'dispatch.roadsideCalls',
            icon: PhoneCallIcon,
            href: 'roadside',
            module: MODULES.ROADSIDE_CALLS,
          },
          {
            title: 'dispatch.impoundCalls',
            icon: PhoneCallIcon,
            href: 'impounds',
            module: MODULES.IMPOUND_CALLS,
            key: 'dispatch.impoundCalls',
          },
          {
            title: 'dispatch.networkMap',
            icon: MapPinIcon,
            href: 'map',
            module: MODULES.NETWORK_MAP,
          },
          {
            title: 'dispatch.operationalReports',
            icon: BarChartIcon,
            href: 'operational',
            module: MODULES.OPERATIONAL_REPORTS,
          },
          {
            title: 'Roadside Tow Order',
            icon: TowOrderIcon,
            href: 'roadside-tow',
            module: MODULES.SANDBOX,
            key: 'dispatch.sandbox',
          },
          {
            title: 'dispatch.towOrderCox',
            icon: TowOrderIcon,
            href: 'tow-order',
            module: MODULES.TOW_ORDER,
            key: 'dispatch.towOrderCox',
          },
          {
            title: 'dispatch.towOrder',
            icon: TowOrderIcon,
            href: 'create-dispatch',
            module: MODULES.GENERIC_TOW_ORDER,
            key: 'dispatch.towOrderGeneric',
          },
          {
            title: 'dispatch.towOrderTuro',
            icon: TowOrderIcon,
            href: 'create-turo-dispatch',
            module: MODULES.TURO_TOW_ORDER,
            key: 'dispatch.towOrderTuro',
          },
          {
            title: 'dispatch.impoundOrder',
            icon: TowOrderIcon,
            href: 'create-impound-order',
            module: MODULES.IMPOUND_GENERIC_TOW_ORDER,
            key: 'dispatch.impoundOrder',
          },
          {
            title: 'dispatch.sixtImpoundOrder',
            icon: TowOrderIcon,
            href: 'create-sixt-impound-order',
            module: MODULES.SIXT_IMPOUND_ORDER,
            key: 'dispatch.impoundOrder',
          },
          {
            title: 'dispatch.repoOrder',
            icon: TowOrderIcon,
            href: 'create-repo-order',
            module: MODULES.REPO_GENERIC_TOW_ORDER,
            key: 'dispatch.repoOrder',
          },
          {
            title: 'dispatch.submittedOrders',
            icon: SubmittedOrdersIcon,
            href: 'submitted-orders',
            module: MODULES.SUBMITTED_ORDERS,
            key: 'dispatch.submittedOrders',
          },
          {
            title: 'Registered Customers',
            icon: RegisterIcon,
            href: 'registered-customers',
            module: MODULES.REGISTERED_CUSTOMERS,
          },
        ],
      },
      {
        subheader: 'dispatch.dispatches',
        items: [
          {
            title: 'dispatch.dispatchSearch',
            icon: SearchIcon,
            href: 'search',
            module: MODULES.DISPATCH_SEARCH,
          },
        ],
      },
    ],
  },
};

export default appConfig;
