import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Typography,
} from "@material-ui/core";
import Form from "src/components/Form";
import { useDispatch } from "react-redux";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Page from "src/components/Page";
import Logo from "src/components/Logo";
import { loginWithMicrosoft } from "src/actions/accountActions";
import authService from "src/services/authService";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
const headOfTechnology =
  "https://nsdstaticasset.blob.core.windows.net/assets/Nsd_FE_Assets/HeadOfTechnology.jpg";
import axios from "src/utils/axios";
import Axios from "axios";

const instance = Axios.create({
  baseURL: "http://localhost:7071/api",
  "ocp-apim-subscription-key": "96a659b8af2d4aa9ae0928e98dac572f",
  headers: {
    Cookie:
      "ajs_user_id=2b0eb497-8fd9-4edd-981f-0c9aa85dad7a; ajs_anonymous_id=c040dc2b-097a-45f5-b4ae-43d97cab51a5; ajs_group_id=nsd; settingsUpdated=true; consent=true; nsd.token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyQ29tcGFueUNvZGVzIjpbIm5zZCJdLCJ1c2VySWQiOiIyYjBlYjQ5Ny04ZmQ5LTRlZGQtOTgxZi0wYzlhYTg1ZGFkN2EiLCJqdGkiOiIzZDFiYjQ3Ni04N2YzLTRhZWItYmI1MC0yY2JlZjJlMTE3NjYiLCJpYXQiOjE2NDk0NDA1NDYsImV4cCI6MTY0OTUyNjk0NX0.NrCQngh6dVNUKWOBu9I5m1wJP9sWktjidQF7W-hwAcI",
    Origin: "http://localhost:3000",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    height: 553,
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

function ResetPasswordView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const formFields = [
    {
      label: "email",
      name: "email",
      size: {
        xs: 12,
      },
      type: "text",
    },
  ];
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const checkIfEmailIsValidInAzure = async ({ email }) => {
    try {
      setIsFormLoading(true);
      const { data } = await axios.get("/email-valid", {
        params: {
          email,
        },
      });
      const { isEmailValidInAzure } = data;
      isEmailValidInAzure
        ? await onSubmit(email)
        : enqueueSnackbar(
            `The specified email "${email}" doesn't have an associated Azure account`,
            {
              variant: "warning",
            }
          );
    } catch (error) {
      const message =
        (error.response && error.response.data.message) ||
        "Something went wrong";
      console.error({ message });
    } finally {
      setIsFormLoading(false);
    }
  };

  const onSubmit = async (email) => {
    try {
      // loginWithMicroSoft callback returns empty promise; just used to empty
      // user info in redux store
      setIsFormLoading(true);
      // await dispatch(loginWithMicrosoft(email));
      await authService.redirectToPasswordResetPage(email);
    } catch (error) {
      const message =
        (error.response && error.response.data.message) ||
        "Something went wrong";
      console.error({ message });
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth="md">
        <Box mb={8} display="flex" alignItems="center">
          <RouterLink to="/">
            <Logo mainIcon />
          </RouterLink>
          <Button component={RouterLink} to="/" className={classes.backButton}>
            Back to home
          </Button>
        </Box>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockOpenIcon fontSize="large" />
            </Avatar>
            <Typography variant="h2" color="textPrimary">
              Password Reset
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Enter your email to be redirected to the password reset screen
            </Typography>
            <Box mt={2}>
              <Alert severity="info">
                <div>
                  Use your <b>email</b>
                </div>
              </Alert>
            </Box>
            <Box mt={3}>
              <Form
                buttonText="Submit"
                fields={formFields}
                initialValues={{
                  email: "",
                }}
                onSubmit={checkIfEmailIsValidInAzure}
                validationSchema={validationSchema}
                buttonText={"Search"}
                submitButtonWidth={"100%"}
                isLoading={isFormLoading}
              />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
          </CardContent>
          <CardMedia
            className={classes.media}
            image="https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Wallpaper/Qore_Brand_Wallpaper-01.jpg"
            title="Cover"
          >
            <Typography color="inherit" variant="subtitle1">
              The ability to create amazing software, is great. The ability to
              make it so simple that the user can't imagine doing it in a
              different way, is extraordinary.
            </Typography>
            <Box alignItems="center" display="flex" mt={3}>
              <Avatar alt="Head Of Technology" src={headOfTechnology} />
              <Box ml={3}>
                <Typography color="inherit" variant="body1">
                  Trevor Moore
                </Typography>
                <Typography color="inherit" variant="body2">
                  Head of Technology
                </Typography>
              </Box>
            </Box>
          </CardMedia>
        </Card>
      </Container>
    </Page>
  );
}

export default ResetPasswordView;
