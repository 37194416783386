import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Used in place of the react-router-dom Route component.
 * Checks the redux store for a logged in user; only if
 * the user is not logged in will this route render
 * (used for signup , login pages etc...)
 */
function GuestRoute({ component: Component, render, ...rest }) {
  const account = useSelector((state) => state.account);
  if (account?.user) return <Redirect to="/" />;
  return render ? render({ ...rest }) : <Component {...rest} />;
}

GuestRoute.propTypes = {
  /**
   * React component you wish to render if the user is not logged
   * in; all props to GuestRoute are passed to this component
   */
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  /**
   * Render function that returns a component/element to render
   * if the user is not logged; all props to GuestRoute are
   * passed to this component
   */
  render: PropTypes.func,
};

GuestRoute.defaultProps = {
  component: null,
  render: null,
};

export default GuestRoute;
