/* eslint-disable import/prefer-default-export */
export const secondsToHoursMinutesSeconds = (secs) => {
    let hours = parseInt(secs / (60 * 60), 10);
    const mins = secs % (60 * 60);
    let minutes = parseInt(mins / 60, 10);
    let seconds = parseInt(mins % 60, 10);

    seconds = String(seconds).padStart(2, 0);
    let ans = `${minutes}:${seconds}`;
    if (hours > 0) {
        hours = String(hours).padStart(2, 0);
        minutes = String(minutes).padStart(2, 0);
        ans = `${hours}:${minutes}:${seconds}`;
    }

    return ans;
};
