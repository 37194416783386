import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const FileChartPie = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01zM192 192v128h127.99c.01 0 0-.01 0-.02-.01-70.68-57.29-127.97-127.97-127.98H192zm32 37.49c27.22 9.66 48.85 31.28 58.5 58.51H224v-58.51zM176 416c-44.12 0-80-35.89-80-80 0-38.63 27.52-70.95 64-78.38v-32c-54.13 7.85-96 54.11-96 110.38 0 61.75 50.25 112 112 112 56.27 0 102.54-41.87 110.38-96h-32c-7.43 36.47-39.74 64-78.38 64z',
    [STYLE.REGULAR]: 'M369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95zm-176-206.4c-36.52 7.41-64 39.68-64 78.39 0 44.18 35.82 80 80 80 38.7 0 70.97-27.49 78.39-64H160v-94.39zm32-32V320h94.39a80.321 80.321 0 0 0 1.61-16c0-44.18-35.82-80-80-80-5.48 0-10.83.56-16 1.61z',
    [STYLE.SOLID]: 'M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-87.49 302.53c-28.73-11.16-51.87-34.3-63.04-63.03C43.42 298.14 90.91 224.8 160 210.66V352h141.34c-14.14 69.09-87.48 116.58-164.83 86.53zm175.63-118.55c0 .01.01.02 0 .02H192V199.86c0-.01.01 0 .02 0 66.34.01 120.11 53.78 120.12 120.12zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z'
  };

  return (
    <FontAwesome
      viewBox="0 0 384 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

FileChartPie.propTypes = {
  type: PropTypes.string
};

export default FileChartPie;
