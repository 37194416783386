import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const TireFlat = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M256 128c-88.37 0-160 71.63-160 160s71.63 160 160 160 160-71.63 160-160-71.63-160-160-160zm0 32c22.34 0 43.04 6.26 61.34 16.36l-37.57 51.71c-7.37-2.93-15.35-4.65-23.77-4.65s-16.39 1.72-23.77 4.65l-37.57-51.71c18.3-10.1 39-16.36 61.34-16.36zM128 288c0-36.74 15.77-69.67 40.64-93.03l37.82 52.06c-9.25 11.18-15.04 25.33-15.04 40.97 0 1.38.32 2.67.41 4.03l-61.4 19.95c-1.49-7.79-2.43-15.77-2.43-23.98zm112 126.38a127.631 127.631 0 0 1-99.45-72.05l61.03-19.83c8.68 13.66 22.32 23.71 38.42 27.84v64.04zm-16-126.4c0-17.67 14.33-32 32-32s32 14.33 32 32-14.33 32-32 32-32-14.32-32-32zm48 126.4v-64.04c16.1-4.13 29.74-14.17 38.42-27.84l61.03 19.83A127.631 127.631 0 0 1 272 414.38zm48.17-122.35c.09-1.36.41-2.65.41-4.03 0-15.64-5.79-29.79-15.04-40.97l37.82-52.06C368.23 218.33 384 251.26 384 288c0 8.21-.94 16.19-2.42 23.97l-61.41-19.94zm156.59 125.69C499.75 378.83 512 334.39 512 288c0-141.16-114.84-256-256-256S0 146.84 0 288c0 46.39 12.25 90.83 35.24 129.72C14.95 423.32 0 441.95 0 464c0 26.47 21.53 48 48 48h416c26.47 0 48-21.53 48-48 0-22.05-14.95-40.68-35.24-46.28zM464 480H48c-8.84 0-16-7.16-16-16s7.16-16 16-16h48c8.84 0 16-7.16 16-16s-7.16-16-16-16H72.35C46.97 379.72 32 335.64 32 288 32 164.29 132.29 64 256 64s224 100.29 224 224c0 47.64-14.97 91.72-40.35 128H416c-8.84 0-16 7.16-16 16s7.16 16 16 16h48c8.84 0 16 7.16 16 16s-7.16 16-16 16z',
    [STYLE.REGULAR]: 'M0 488.02c0 13.23 10.71 23.94 23.92 23.98h464.16c13.22-.04 23.92-10.76 23.92-23.98 0-13.26-10.74-24-24-24h-20.38C495.6 422.98 512 373.42 512 320c0-141.38-114.62-256-256-256S0 178.62 0 320c0 53.42 16.4 102.98 44.38 144.02H24c-13.26 0-24 10.74-24 24zm232-46.45c-36.13-7.12-66.23-30.21-83.72-61.35l42.71-13.88c9.96 13.94 24.31 24.31 41.01 29.59v45.64zm-55.93-120.85l-42.6 13.84c-.58-4.84-1.47-9.58-1.47-14.56 0-32.48 12.83-61.85 33.34-83.98l26.55 36.55C182.03 285.87 176 302.17 176 320c0 .25.07.47.07.72zM256 352c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm-51.89-144.25c24.13-11.21 61.58-19.6 103.77 0l-26.66 36.7c-22.06-7.37-37.63-4.28-50.45 0l-26.66-36.7zM280 441.57v-45.64c16.7-5.28 31.04-15.64 41.01-29.59l42.71 13.88c-17.49 31.15-47.59 54.23-83.72 61.35zm40.11-169l26.55-36.55C367.17 258.15 380 287.52 380 320c0 4.99-.9 9.73-1.47 14.56l-42.6-13.84c0-.25.07-.47.07-.72 0-17.83-6.03-34.13-15.89-47.43zM256 112c114.69 0 208 93.31 208 208 0 55.89-22.27 106.6-58.27 144.02h-56.12C396.71 433.33 428 380.41 428 320c0-94.99-77.01-172-172-172S84 225.01 84 320c0 60.41 31.29 113.33 78.4 144.02h-56.13C70.27 426.6 48 375.89 48 320c0-114.69 93.31-208 208-208z',
    [STYLE.SOLID]: 'M256 160c-26.51 0-51.14 7.06-73.15 18.48l47.93 65.97c7.97-2.66 16.35-4.45 25.22-4.45s17.25 1.79 25.22 4.45l47.93-65.97C307.14 167.06 282.51 160 256 160zM96 320c0 8.87 1.22 17.42 2.61 25.89l77.46-25.17c0-.25-.07-.48-.07-.73 0-17.82 6.03-34.12 15.88-47.41l-48.23-66.38C114.27 235.22 96 275.45 96 320zm224.12-47.41C329.97 285.88 336 302.18 336 320c0 .25-.07.48-.07.73l77.46 25.17c1.39-8.48 2.61-17.02 2.61-25.89 0-44.55-18.27-84.78-47.65-113.79l-48.23 66.37zM256 288c-17.67 0-32 14.33-32 32s14.33 32 32 32 32-14.33 32-32-14.33-32-32-32zm224 160h-2.61C499.3 410.36 512 366.71 512 320c0-141.38-114.62-256-256-256S0 178.62 0 320c0 46.71 12.7 90.36 34.61 128H32c-17.67 0-32 14.33-32 32s14.33 32 32 32h448c17.67 0 32-14.33 32-32s-14.33-32-32-32zm-81.3 0h-47.79a160.175 160.175 0 0 0 47.51-56.49L321 366.36c-9.96 13.94-24.31 24.29-41 29.57V448h-48v-52.07c-16.69-5.28-31.04-15.64-41-29.57l-77.42 25.15A160.175 160.175 0 0 0 161.09 448H113.3C82.76 413.99 64 369.2 64 320c0-105.87 86.13-192 192-192s192 86.13 192 192c0 49.2-18.76 93.99-49.3 128z'
  };

  return (
    <FontAwesome
      viewBox="0 0 512 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

TireFlat.propTypes = {
  type: PropTypes.string
};

export default TireFlat;
