const average = (arr) => {
    const arrWithOnlyNumbers = arr.filter((entry) => entry !== null);
    return arrWithOnlyNumbers.reduce((a, b) => Number(a) + Number(b), 0) / arrWithOnlyNumbers.length;
};

const fractionDigits = (num, minimumFractionDigits, maximumFractionDigits) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits ?? minimumFractionDigits
    });

    return formatter.format(num);
};

const formatNumber = (num) => {
    return (new Intl.NumberFormat()).format(num);
}

export { 
    average,
    fractionDigits,
    formatNumber
}