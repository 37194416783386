import Axios from 'axios';
import { nsdAPIHeader, getNsdApiBaseUrl } from 'src/utils/axios';

const instance = Axios.create({
    headers: nsdAPIHeader,
    withCredentials: true,
    baseURL: getNsdApiBaseUrl(),
  });

  

export default instance;