import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Page from './Page';
import PropTypes from 'prop-types';
import Error from './Error';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
}));

/**
 * Generic page component to show an error view on the page. The title
 * tag is inserted via React Helmet
 */
const ErrorPage = ({ title, ...rest }) => {
  const classes = useStyles();
  const errorProps = {
    ...rest,
    imgAlt: rest.imgAlt || title,
    header: rest.header || title,
  };
  return (
    <Page className={classes.root} title={title}>
      <Error {...errorProps} />
    </Page>
  );
};

ErrorPage.propTypes = {
  /**
   * Title tag to be inserted within the head of the document
   */
  title: PropTypes.string,
};

ErrorPage.defaultProps = {
  title: 'Error Page',
};

export default ErrorPage;
