import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import TopBar from "src/layouts/DashboardLayout/TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

// This does not have NavBar
// Sometimes props passed down to TopBar that end up on a DOM element
// by accident

function MainLayout({ topBarConfig, apps = [], children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar
        apps={apps}
        onMobileNavOpen={() => {}}
        {...topBarConfig}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
  topBarConfig: PropTypes.object.isRequired,
  apps: PropTypes.array,
};

export default MainLayout;
