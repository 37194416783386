import frDocumentStrings from './documents';
import frDispatchStrings from './dispatch';
import frBillingStrings from './billing';
import frClaimsStrings from './claims';
import frInsuranceStrings from './insurance';

export default {
  agents: 'Agentes',
  brands: 'Marques',
  agentCodes: 'Codes d\'agent',
  brandCodes: 'Codes de marque',
  allAgents: 'Tous les agents',
  allBrands: 'Toutes les marques',
  manyAgents: 'De nombreux agents',
  multipleBrands: 'Plusieurs marques',
  apps: 'Apps',
  settings: 'Paramètres',
  settings_languages: 'Traduction',
  settings_responsiveFontSizes: 'Tailles de police réactives',
  settings_rtl: 'De Droite à Gauche',
  settings_saveSettings: 'Enregistrer les Paramètres',
  settings_theme: 'Thème',
  settings_updated: 'Paramètres mis à Jour',
  settings_updated_note:
    'Nous avons automatiquement mis à jour vos paramètres. Vous modifiez les paramètres à tout moment à partir des paramètres de votre tableau de bord.',
  logout: 'Se déconnecter',
  support: {
    support: 'Soutien',
    requestFeature: 'demander une fonctionnalité',
    reportBug: 'signaler un bug',
    topic: 'Sujet',
    description: 'Description',
    addScreenshot: 'Ajouter une capture d\'écran (facultatif)',
    towOrder: 'Ordre de remorquage',
    dispatchReporting: 'rapports d\'expédition',
    documents: frDocumentStrings.documents,
    reports: frDispatchStrings.reports,
    billing: frBillingStrings.billing,
    claims: frClaimsStrings.claims,
    insurance: frInsuranceStrings.insurance,
    thankYouForYourFeedback: 'Merci pour votre avis!',
    weAppreciateYourPartnership: 'Nous apprécions votre partenariat',
    couldNotSubmitRequest: 'Malheureusement, nous n\'avons pas pu soumettre votre demande.',
  },
};