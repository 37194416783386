export default {
  totalRoadsideCalls: 'Total de llamadas en carretera',
  accepted: 'Aceptado',
  inRoute: 'En ruta',
  cashCall: 'Llamada en efectivo',
  goa: 'GOA',
  onScene: 'En escena',
  towInProgress: 'Remolque en progreso',
  destinationArrival: 'Llegada al destino',
  complete: 'Completo',
  cancelled: 'Cancelado',
  new: 'Nuevo',
  PieChartCard: {
    title: 'Asistencia en carretera por porcentaje',
  },
  ErrorPage: {
    title: 'No se encontraron llamadas de asistencia en carretera',
    message:
      'Por favor, intente en otro rango de tiempo o seleccione agentes diferentes.',
  },
};
