import React, { useRef, useState, memo } from "react";
import PropTypes from "prop-types";
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from "@material-ui/icons/MoreVert";
import GetAppIcon from "@material-ui/icons/GetApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ArchiveIcon from "@material-ui/icons/Archive";

const useStyles = makeStyles(() => ({
  menu: {
    width: 256,
    maxWidth: "100%",
  },
}));

/**
 * A Generic component that displays a tooltip with 4 items;
 * Import , Copy,  Export, and Archive
 */
function GenericMoreButton({ className, listItemCallbacks }) {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleMenuOpen = () => setOpenMenu(true);
  const handleMenuClose = () => setOpenMenu(false);

  return (
    <>
      <Tooltip title="More options" className={className}>
        <IconButton onClick={handleMenuOpen} ref={moreRef}>
          <MoreIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{ className: classes.menu }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => listItemCallbacks.import()}>
          <ListItemIcon>
            <GetAppIcon />
          </ListItemIcon>
          <ListItemText primary="Import" />
        </MenuItem>
        <MenuItem onClick={() => listItemCallbacks.copy()}>
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          <ListItemText primary="Copy" />
        </MenuItem>
        <MenuItem onClick={() => listItemCallbacks.export()}>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText primary="Export" />
        </MenuItem>
        <MenuItem onClick={() => listItemCallbacks.archive()}>
          <ListItemIcon>
            <ArchiveIcon />
          </ListItemIcon>
          <ListItemText primary="Archive" />
        </MenuItem>
      </Menu>
    </>
  );
}

GenericMoreButton.propTypes = {
  /**
   * Class name you wish to apply to the Tooltip section
   */
  className: PropTypes.string,
  /**
   * Object with keys corresponding to the list icons displayed with a callback
   * as the value of each key to be run when the specified icon is clicked
   */
  listItemCallbacks: PropTypes.shape({
    export: PropTypes.func,
    copy: PropTypes.func,
    archive: PropTypes.func,
    import: PropTypes.func,
  }),
};

GenericMoreButton.defaultProps = {
  className: "",
  listItemCallbacks: {
    export: () => console.log("Clicked export item"),
    copy: () => console.log("Clicked copy item"),
    archive: () => console.log("Clicked archive item"),
    import: () => console.log("Clicked import item"),
  },
};

export default memo(GenericMoreButton);
