import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from 'src/actions/accountActions';

const useAgents = () => {
    const dispatch = useDispatch();

    const { user, appSettings, clientQoreCompanyCode } = useSelector(
        state => state.account
    );
    const cachedAgents = JSON.parse(localStorage.getItem('agents'));
    const agents = cachedAgents ?? user?.agents ?? [];

    // Consider undefined property 'active' as being active
    const activeAgents = agents.filter(agent => agent.active ?? true);

    // create map with currently selected agent codes
    const agentCodes = agents.reduce((acc, agent) => {
        acc[agent.code] = agent.active ?? true;
        return acc;
    }, {});

    const [selectedAgentCodes, setSelectedAgentCodes] = useState(agentCodes);

    /**
     * This returns an array of agents that are
     * active. If an agent is not active, it will
     * not appear in the array. This is needed to
     * send the appropriate agents to our API when
     * a request is made.
     */
    const activeAgentCodes =
        agents.reduce((acc, ag) => {
            const agentIsActive = ag.active ?? true;
            if (agentIsActive) {
                acc.push(ag.code);
            }
            return acc;
        }, []);

    const selectedAgentCodesStringified = activeAgentCodes.join(',');

    // update the logged in user's active agentCode until logout
    const setSelectedAgentsAsActiveAgents = async () => {
        const newAgents = agents.map(agent => {
            return { ...agent, active: selectedAgentCodes[agent.code] };
        });

        if (
            Object.values(selectedAgentCodes).filter(isSelected => isSelected)
                .length === 0
        ) {
            throw new Error('Select at least one agent');
        }

        localStorage.setItem('agents', JSON.stringify(newAgents));

        await dispatch(
            setUserData(
                { ...user, agents: newAgents },
                appSettings,
                user.roles,
                clientQoreCompanyCode
            )
        );
    };

    const toggleSelectedAgent = agentCode => () => {
        setSelectedAgentCodes(prevSelectedAgentCodes => {
            return {
                ...prevSelectedAgentCodes,
                [agentCode]: !prevSelectedAgentCodes[agentCode]
            };
        });
    };

    const getAreAllAgentsSelected = agCodes => {
        const selectedAgents = Object.values(agCodes);
        return (
            selectedAgents.filter(selected => selected).length ===
            selectedAgents.length
        );
    };

    const toggleAllSelectedAgents = () => {
        setSelectedAgentCodes(prevSelectedAgentCodes => {
            const areAllAgentsSelected = getAreAllAgentsSelected(
                prevSelectedAgentCodes
            );
            return Object.keys(prevSelectedAgentCodes).reduce(
                (acc, cur) => ({ ...acc, [cur]: !areAllAgentsSelected }),
                {}
            );
        });
    };

    const isAgentCodeSelected = code => selectedAgentCodes[code] === true;

    const turoAgentCodesAreSelected = (codes) => {
        for (let code of codes) {
            if (selectedAgentCodes[code] === true) {
                return true;
            }
        }

        return false;
    }

    const setActiveAgents = async agCodes => {
        if (agCodes.length === 0) {
            throw new Error('Select at least one agent');
        }
        const agCodesMap = agCodes.reduce((acc, agCode) => {
            acc[agCode] = true;
            return acc;
        }, {});
        const newAgents = agents.map(agent => {
            return { ...agent, active: agCodesMap[agent.code] ?? false };
        });
        await dispatch(
            setUserData(
                { ...user, agents: newAgents },
                appSettings,
                user.roles,
                clientQoreCompanyCode
            )
        );
    };

    const removeCachedAgents = () => {
        /**
         * This is used on login and logout 
         * to prevent stale agents from
         * being shown when logging into a
         * different client's portal.
         */
        localStorage.removeItem('agents');
    }

    return {
        agents,
        selectedAgentCodes,
        selectedAgentCodesStringified,
        setSelectedAgentsAsActiveAgents,
        toggleSelectedAgent,
        isAgentCodeSelected,
        setActiveAgents,
        activeAgents,
        areAllAgentsSelected: getAreAllAgentsSelected(selectedAgentCodes),
        toggleAllSelectedAgents,
        removeCachedAgents,
        turoAgentCodesAreSelected
    };
};

export default useAgents;
