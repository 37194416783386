import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Box, Button, Link, Portal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: "none",
    zIndex: theme.zIndex.modal,
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

/**
 * Cookie popup component to get user's consent and set the
 * appropriate consent cookies
 */
function CookiesNotification({
  notificationText,
  confirmText,
  privacyPolicySrc,
  className,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    Cookies.set("consent", "true");
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get("consent") === "true";
    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={clsx(className, classes.root)}>
        <Typography variant="body1" color="inherit">
          {notificationText}{" "}
          <Link
            component="a"
            color="inherit"
            underline="always"
            href={privacyPolicySrc}
            target="_blank"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.action}
          >
            {confirmText}
          </Button>
        </Box>
      </div>
    </Portal>
  );
}

CookiesNotification.propTypes = {
  /**
   * Primary text to be displayed in the pop up notification
   */
  notificationText: PropTypes.string,
  /**
   * Text to appear in the confirmation button
   */
  confirmText: PropTypes.string,
  /**
   * Link to the page containing the company's privacy policy regarding cookies
   */
  privacyPolicySrc: PropTypes.string,
  /**
   * Classname to be apply to the parent container of the cookie modal
   */
  className: PropTypes.string,
};

CookiesNotification.defaultProps = {
  notificationText:
    "We use Cookies to ensure that we give you the best experience on our website. Read our ",
  confirmText: "Agree",
  privacyPolicySrc: "https://devias.io/privacy-policy",
  className: "",
};

export default CookiesNotification;
