// Keep this alphebetized. I use https://alphabetizer.flap.tv/ to do this but feel free to use whatever.
export const SITE = {
  // ABG: 'abg.nationsafedrivers.com',
  // ALLY: 'ally.nationsafedrivers.com',
  // AUDI: 'audi.nationsafedrivers.com',
  CLEARCOVER: 'clearcover.nationsafedrivers.com',
  // CONFIE: 'confie.nationsafedrivers.com',
  // COXAUTO: 'coxauto.nationsafedrivers.com',
  // DISCTIRE: 'disctire.nationsafedrivers.com',
  // GRTCHE: 'grtche.nationsafedrivers.com',
  // MERCURY: 'mercury.nationsafedrivers.com',
  // NGIC: 'ngic.nationsafedrivers.com',
  // NSD_CORPORATE: 'nationsafedrivers.com',
  NSD_DEMO: 'nsddemo.nationsafedrivers.com',
  // NSD_DEMO_AUTO: 'nsddemoauto.nationsafedrivers.com',
  // NSD_DEMO_INSURANCE: 'nsddemoinsurance.nationsafedrivers.com',
  // TR: 'tr.nationsafedrivers.com',
  VOLKSWAGEN: 'volkswagen.nationsafedrivers.com',
  TURO: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8ed921c277-0af9-4963-9ada-49425a6f28f1.nationsafedrivers.com',
  BIRCHWOOD: 'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e3a0aceba-7259-4947-894e-09d868ad0184.nationsafedrivers.com',
};
