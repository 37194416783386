import { gql } from '@apollo/client';
import each from 'lodash/each';
import BaseService from './baseService';

class CustomerService extends BaseService {
  constructor() {
    super();
    this.customerDataMappings = {
      id: {
        name: '$id',
        type: 'Int',
        input: 'Id'
      },
      firstName: {
        name: '$firstName',
        type: 'String',
        typeRequire: 'String!',
        input: 'FirstName'
      },
      lastName: {
        name: '$lastName',
        type: 'String',
        typeRequire: 'String!',
        input: 'LastName'
      },
      email: {
        name: '$email',
        type: 'String',
        typeRequire: 'String!',
        input: 'Email'
      },
      preferredContactMethodId: {
        name: '$preferredContactMethodId',
        type: 'Int',
        input: 'PreferredContactMethodId'
      },
      emailOptIn: {
        name: '$emailOptIn',
        type: 'Int',
        input: 'EmailOptIn'
      },
      sMSOptIn: {
        name: '$sMSOptIn',
        type: 'Int',
        input: 'SMSOptIn'
      },
      avatar: {
        name: '$avatar',
        type: 'String',
        input: 'Avatar'
      },
      addresses: {
        name: '$addresses',
        type: '[AddressIn]',
        input: 'Addresses'
      },
      vehicles: {
        name: '$vehicles',
        type: '[VehicleIn]',
        input: 'Vehicles'
      },
      phones: {
        name: '$phones',
        type: '[PhoneIn]',
        input: 'Phones'
      },
      mobileNotificationsOptIn: {
        name: '$mobileNotificationsOptIn',
        type: 'Int',
        input: 'MobileNotificationsOptIn'
      },
      phoneOptIn: {
        name: '$phoneOptIn',
        type: 'Int',
        input: 'PhoneOptIn'
      },
      chatEmailOptIn: {
        name: '$chatEmailOptIn',
        type: 'Int',
        input: 'ChatEmailOptIn'
      },
      chatMobileNotificationsOptIn: {
        name: '$chatMobileNotificationsOptIn',
        type: 'Int',
        input: 'ChatMobileNotificationsOptIn'
      },
      chatSMSOptIn: {
        name: '$chatSMSOptIn',
        type: 'Int',
        input: 'ChatSMSOptIn'
      }
    };
    this.customerGql = `
        Id
        FirstName
        LastName
        DateCreated
        LastModified
        Email
        PreferredContactMethodId
        EmailOptIn
        MobileNotificationsOptIn
        SMSOptIn
        PhoneOptIn
        ChatEmailOptIn
        ChatMobileNotificationsOptIn
        ChatSMSOptIn
        Avatar
        Addresses{
          Id
          FullAddress
          GoogleLocationId
          Address
          City
          StateProvidence
          PostalCode
          Latitude
          Longitude
          AddressTypeId
        }
        Phones{
          Id
          Phone
          PhoneTypeId
        }
        Vehicles{
          Id
          Year
          Make
          Model
          Vin
          LicensePlate
        }`;
    this.customerActionGql = `
      Success
      Token`;
  }

  buildCustomerGQL = (data, mutation, required, actionReturned) => {
    const keys = Object.keys(data);
    const customer = [];
    const input = [];
    each(keys, (key) => {
      const map = this.customerDataMappings[key];
      if (map && data[key]) {
        customer.push(`${map.name}: ${required && map.typeRequire ? map.typeRequire : map.type}`);
        input.push(`${map.input}: ${map.name}`);
      }
    });
    return `
      mutation Customer(${customer.join(', ')}){
        ${mutation}(
            ${input.join('\n')}
          ) {
            ${actionReturned ? this.customerActionGql : this.customerGql}
        }
    }`;
  };

  deleteCustomer = (ids) => new Promise((resolve, reject) => {
    const DELETE_CUSTOMER = gql`
      mutation Customer($ids: [Int]!){
        removeCustomers(
          Ids: $ids
        ){
          Success
          Token
        }
      }
    `;

    this.client
      .mutate({
        mutation: DELETE_CUSTOMER,
        variables: { ids }
      })
      .then((result) => {
        console.log('deleteCustomer:result', result);
        if (result.data.removeCustomers) {
          resolve(result.data.removeCustomers);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  addCustomer = (data) => new Promise((resolve, reject) => {
    const NEW_CUSTOMER = gql`
      ${this.buildCustomerGQL(data, 'addCustomer', true)}
    `;

    this.client
      .mutate({
        mutation: NEW_CUSTOMER,
        variables: data
      })
      .then((result) => {
        console.log('addCustomer:result', result);
        if (result.data.addCustomer) {
          resolve(result.data.addCustomer);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  saveCustomer = (data) => new Promise((resolve, reject) => {
    const CUSTOMER = gql`${this.buildCustomerGQL(data, 'updateCustomer', false, true)}`;

    this.client
      .mutate({
        mutation: CUSTOMER,
        variables: data
      })
      .then((result) => {
        console.log('updateCustomer:result', result);
        if (result.data.updateCustomer) {
          resolve(result.data.updateCustomer);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  savePhones = (id, phones) => new Promise((resolve, reject) => {
    const CUSTOMER_PHONES = gql`
      mutation Customer($id: Int!, $phones: [PhoneUpdate]){
        updateCustomerPhones(
            Id: $id
            Phones: $phones
          ) {
            Success
            Token
            Error
            Customer{
                ${this.customerGql}
            }
        }
    }
    `;

    this.client
      .mutate({
        mutation: CUSTOMER_PHONES,
        variables: { id, phones }
      })
      .then((result) => {
        console.log('savePhones:result', result);
        if (result.data.updateCustomerPhones) {
          resolve(result.data.updateCustomerPhones);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  saveAddresses = (id, addresses) => new Promise((resolve, reject) => {
    console.log('saveAddresses: id', id);
    console.log('saveAddresses: addresses', addresses);
    const CUSTOMER_ADDRESSES = gql`
      mutation Customer($id: Int!, $addresses: [AddressUpdate]){
        updateCustomerAddresses(
            Id: $id
            Addresses: $addresses
          ) {
            Success
            Token
            Error
            Customer{
              ${this.customerGql}
            }
        }
    }
    `;

    this.client
      .mutate({
        mutation: CUSTOMER_ADDRESSES,
        variables: { id, addresses }
      })
      .then((result) => {
        console.log('saveAddresses:result', result);
        if (result.data.updateCustomerAddresses) {
          resolve(result.data.updateCustomerAddresses);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getCustomers = () => new Promise((resolve, reject) => {
    const CUSTOMERS = gql`
      query {
        customers{
            ${this.customerGql}
        }
    }`;

    this.client
      .query({
        query: CUSTOMERS
      })
      .then((result) => {
        console.log('getCustomers: result', result);
        if (result.data.customers) {
          resolve(result.data.customers);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  searchCustomers = (value) => new Promise((resolve, reject) => {
    const CUSTOMERS = gql`
      query Customer($value: String!){
        searchCustomers(
            Value: $value
          ){
            ${this.customerGql}
        }
    }`;

    this.client
      .query({
        query: CUSTOMERS,
        variables: { value }
      })
      .then((result) => {
        console.log('getCustomers: result', result);
        if (result.data.searchCustomers) {
          resolve(result.data.searchCustomers);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getCustomer = (id) => new Promise((resolve, reject) => {
    const CUSTOMER = gql`
      query Customer($id: Int!){
        customer(Id: $id){
            ${this.customerGql}
        }
    }`;

    this.client
      .query({
        query: CUSTOMER,
        variables: { id }
      })
      .then((result) => {
        if (result.data.customer) {
          resolve(result.data.customer);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

const customerService = new CustomerService();

export default customerService;
