import Cookies from "js-cookie";

// convert cookie object to string
export const createCookie = (cookie) =>
  Object.entries(cookie)
    .reduce(
      (acc, [key, val]) => [...acc, val === true ? key : `${key}=${val}`],
      []
    )
    .join("; ");

// convert cookie string to object
export const parseCookie = (cookie) =>
  cookie.split(";").reduce((res, c) => {
    const [key, val] = c.trim().split("=").map(decodeURIComponent);
    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});

// Cookies must have same attributes set in order to be removed; requires jscookie
export const deleteAllCookiesJSCookie = (neededAttributes = {}) => {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    Cookies.remove(cookieName, neededAttributes);
  });
};

// FIXME: (jh) Refactor into common utility which other components can use
export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};
