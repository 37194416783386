import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoadingBar from "react-top-loading-bar";
import usePrevious from "src/hooks/usePrevious";
import { randomInt } from "src/utils/random";
import { grey } from "@material-ui/core/colors";

/**
 * Loading bar component that simulates random movement
 * of the loading bar over a specified interval; stopping
 * the bar is controlled by a parent component's passed in
 * loading prop; when loading changes from true to false the
 * loading stops, otherwise it continue flashing when it
 * reaches 100% progress.
 * Rest of the Props can be seen here
 * https://www.npmjs.com/package/react-top-loading-bar
 */
const TopLoadingBar = ({ loading, incrementRange, interval, ...rest }) => {
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [incrementMin = 2, incrementMax = 10] = incrementRange;
  const previousLoading = usePrevious(loading);

  useEffect(() => {
    if (previousLoading === loading) return;
    // if loading goes from true to false we're 100% complete
    // if it goes from false to true we're starting
    if (loading === false) {
      setProgress(100);
      clearInterval(intervalId);
      setIntervalId(null);
    } else {
      setProgress(randomInt(20, 30));
      const interId = setInterval(
        () =>
          // increment progress bar length by a random number from 2 to 10
          setProgress(
            (oldProgress) => oldProgress + randomInt(incrementMin, incrementMax)
          ),
        interval
      );
      setIntervalId(interId);
    }
  }, [loading, previousLoading, intervalId]);

  return (
    <LoadingBar
      continuousStart
      height={3}
      color={grey[500]}
      {...rest}
      progress={progress}
    />
  );
};

TopLoadingBar.propTypes = {
  /**
   * Boolean variable prop (likely a state variable)
   * that triggers the loader when true and stops it
   * when changed to false
   */
  loading: PropTypes.bool,
  /**
   * Number in MS for how often you want to the Loading bar
   * to increase/move
   */
  interval: PropTypes.number,
  /**
   * Array of 2 numbers [min, max] which are used to get
   * a random number between the 2 values to increment
   * the progress bar by
   */
  incrementRange: PropTypes.arrayOf(PropTypes.number),
};

TopLoadingBar.defaultProps = {
  loading: false,
  interval: 2000,
  incrementRange: [2, 10],
};

export default TopLoadingBar;
