import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const Router = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M173.93 96.81a8.12 8.12 0 0 0 11.23.07C230.55 55 289.48 32 352 32s121.45 23 166.83 64.88a8.12 8.12 0 0 0 11.23-.07l11.53-11.34a8 8 0 0 0-.11-11.56C490 26.18 423 0 352 0S214 26.18 162.52 73.91a8 8 0 0 0-.11 11.56zM352 112a179.26 179.26 0 0 0-120.38 45.8 8 8 0 0 0-.15 11.64l11.53 11.3a8.21 8.21 0 0 0 11.22.14 148.17 148.17 0 0 1 195.64 0 8.21 8.21 0 0 0 11.22-.14l11.49-11.3a8 8 0 0 0-.15-11.64A179.26 179.26 0 0 0 352 112zm176 208H368v-88a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v88H48a48 48 0 0 0-48 48v96a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48v-96a48 48 0 0 0-48-48zm16 144a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h480a16 16 0 0 1 16 16zm-360-72a24 24 0 1 0 24 24 24 24 0 0 0-24-24zm-80 0a24 24 0 1 0 24 24 24 24 0 0 0-24-24z',
    [STYLE.REGULAR]: 'M528 288H376v-80a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v80H48a48 48 0 0 0-48 48v128a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V336a48 48 0 0 0-48-48zm0 176H48V336h480zm-416-32a32 32 0 1 0-32-32 32 32 0 0 0 32 32zm96 0a32 32 0 1 0-32-32 32 32 0 0 0 32 32zm35.44-296.47a16.44 16.44 0 0 0-1.3 24l11.11 11.37a15.15 15.15 0 0 0 20.53 1.29 122.72 122.72 0 0 1 156.44 0 15.15 15.15 0 0 0 20.53-1.29l11.11-11.37a16.44 16.44 0 0 0-1.3-24 168.83 168.83 0 0 0-217.12 0zm-67.84-28.2c6 6.11 15.39 6.06 21.71.36a230.29 230.29 0 0 1 309.38 0c6.32 5.7 15.75 5.75 21.71-.36L539.47 96a16.41 16.41 0 0 0-1-23.56C487 25.59 421.42 0 352 0S217 25.59 165.48 72.44a16.41 16.41 0 0 0-.95 23.56z',
    [STYLE.SOLID]: 'M528 320H376v-80a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v80H48a48 48 0 0 0-48 48v96a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48v-96a48 48 0 0 0-48-48zM96 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm96 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm-32.59-320.34a15.85 15.85 0 0 0 21.86.64c96.45-86.55 244.24-85.85 341.11.42a16 16 0 0 0 21.84-.63l22.71-22.7a16.11 16.11 0 0 0-.71-23.34C444.81-27.34 259.26-27.34 137.78 82a16.11 16.11 0 0 0-.71 23.34zm46.47 38a16.12 16.12 0 0 0-.86 23.61L227.73 212a15.94 15.94 0 0 0 21.7.78c58.77-49.84 145.8-49.36 205.14.21a16.07 16.07 0 0 0 21.63-.84l22.8-22.8a16.22 16.22 0 0 0-.84-23.63c-83.83-71.91-208.42-71.91-292.28-.06z'
  };

  return (
    <FontAwesome
      viewBox="0 0 576 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

Router.propTypes = {
  type: PropTypes.string
};

export default Router;
