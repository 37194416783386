import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 * Higher Order Component that is wrapped in TabTable when TabTable
 * has the loadMoreButton prop as true. The rest of the props
 * are gathered from the HOC's above this wrapped in TabTable.
 * If tableData is not null it will manipulate the tableData prop
 * to create a new tableData that object that (depending on requestContainsSkipToken)
 * will include both the newly fetched data AND the old data
 * or just the new data.
 * Note: If this is rendered pagnation actions are not
 */
export default (Table) => {
  const TableWithLoadMore = ({
    loadMoreButton,
    tableData,
    requestContainsSkipToken,
    totalCount,
    ...rest
  }) => {
    const [totalNumRows, setTotalNumRows] = useState(null);
    const [loadedTableData, setLoadedTableData] = useState([]);
    // append to table if user clicks load more button, otherwise replace table data
    useEffect(() => {
      if (tableData) {
        setLoadedTableData((prevTableData) => [
          ...(requestContainsSkipToken ? prevTableData : []),
          ...tableData,
        ]);

        if (totalCount) setTotalNumRows(totalCount);
      }
    }, [tableData]);

    return (
      <Table
        tableData={loadedTableData}
        totalCount={totalNumRows}
        loadMoreButton={loadMoreButton}
        {...rest}
      />
    );
  };

  TableWithLoadMore.propTypes = {
    /**
     * Boolean from TabTable that incidates weather or not to
     * wrap TabTable in the withLoadMore HOC; the conditional check for
     * this is done in TabTable so this prop isn't used here
     */
    loadMoreButton: PropTypes.bool,

    /**
     * Array of fetched data (from withAsync) that has the specified resource
     * (fetched with a combination of  model prop and url prop and in TabTable +
     * environment variable called REACT_APP_COMMON_SERVICES_API or your GQL service)
     */
    tableData: PropTypes.array,
  };

  TableWithLoadMore.defaultProps = {
    tableData: null,
  };

  return TableWithLoadMore;
};
