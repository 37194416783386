import React, { useEffect, useMemo, useState } from "react";
import SVGLayeredCountryMap from "../components/SVGLayeredCountryMap";

import axios from "axios";

/**
 * Parses an SVG country map and returns a React SVG component with attached
 * legend, state / province codes, etc.
 *
 * @param {string} svgUrl The URL of the original SVG map to parse
 * @return {React.Component | null}
 */
export default function useCountryMapLayers(svgUrl) {
  const [svgDoc, setSvgDoc] = useState(null);

  // Read svgDoc, parse it w/ DOMParser and set the DOM in the hook's state
  useEffect(() => {
    axios
      .get(svgUrl)
      .then((response) => {
        var parser = new DOMParser();
        var svgDoc = parser.parseFromString(response.data, "image/svg+xml");

        setSvgDoc(svgDoc);
      })
      .catch((err) => {
        throw err;
      });
  }, [svgUrl]);

  /**
   * The render-able React component with attached legend, state / province
   * codes, etc.
   *
   * @type {React.Component | null}
   **/
  // const [SVGLayeredCountryMap, setMapView] = useState(() => () => null);
  // Dynamically constructs the layered output SVGLayeredCountryMap
  const SVGMap = useMemo(() => {
    if (!svgDoc) {
      return null;
    } else {
      const innerSVGEl = svgDoc.children[0];

      return ({ ...rest }) => (
        <SVGLayeredCountryMap innerSVGEl={innerSVGEl} {...rest} />
      );
    }
  }, [svgDoc]);

  return SVGMap;
}
