import axios from 'axios';

/**
 * @param {string} url
 * @return {Promise<string>}
 */
export const getBase64ImageFromUrl = async (url) => {
  const response = await axios.get(url, { responseType: 'arraybuffer' });
  const base64Image = btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  );
  return `data:;base64,${base64Image}`;
};

/**
 * @param {File} file
 * @see https://developer.mozilla.org/en-US/docs/Web/API/File
 * @return {string}
 */
export const getBase64ImageFromFileUpload = async (file) => {
  return new Promise((res) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => res(e.target.result);
    fileReader.readAsDataURL(file);
  });
};
