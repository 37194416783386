// import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/actions/accountActions';
import useCache from 'src/hooks/useCache';
// import { useAssetSettings } from './useUserSettings';

// Where is this used? In apps with providers most likely
const useAccount = () => {
  // const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { clearCache } = useCache();
  const { user } = useSelector((state) => state.account);
  // const { hasAppAccess } = useAssetSettings();

  const signOut = async () => {
    try {
      await dispatch(logout());
      clearCache();
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return { signOut, company: user?.company };
};

export default useAccount;
