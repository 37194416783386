import { useState, useEffect } from "react";

/* Keep track of loaded scripts with context? */

/**
 * Used to load libraries through an external script;
 * keeps track of loading status through state and custom data-* attributes.
 * Prevents reloading already loaded scripts as well
 *
 * @property {Element} existingScript
 *
 * @param {string} src
 * @return {('idle'| 'loading'|'ready'|'error')} string
 */
function useScript(src) {
  // 4 states; idle, loading, ready, error
  const [status, setStatus] = useState(src ? "loading" : "idle");
  useEffect(() => {
    if (!src) {
      setStatus("idle");
      return;
    }
    let existingScript = document.querySelector(`script[src="${src}"]`);

    if (!existingScript) {
      existingScript = document.createElement("script");
      existingScript.src = src;
      existingScript.async = true;
      existingScript.setAttribute("data-status", "loading");
      document.body.appendChild(existingScript);
      // Event listener to change custom attribute
      const setAttributeFromEvent = (event) => {
        const dataStatusUpdate = event.type === "load" ? "ready" : "error";
        existingScript.setAttribute("data-status", dataStatusUpdate);
      };
      existingScript.addEventListener("load", setAttributeFromEvent);
      existingScript.addEventListener("error", setAttributeFromEvent);
    } else {
      //script already exists on DOM
      setStatus(existingScript.getAttribute("data-status"));
    }
    const setStateFromEvent = (event) => {
      setStatus(event.type === "load" ? "ready" : "error");
    };
    existingScript.addEventListener("load", setStateFromEvent);
    existingScript.addEventListener("error", setStateFromEvent);

    return () => {
      if (existingScript) {
        existingScript.removeEventListener("load", setStateFromEvent);
        existingScript.removeEventListener("error", setStateFromEvent);
      }
    };
  }, [src]);
  return [status];
}

export default useScript;
