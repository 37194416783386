import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import PropTypes from "prop-types";
import APPS from "../constants/apps";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    maxWidth: "100%",
    zIndex: theme.zIndex.modal,
  },
  logo: {
    width: 300,
    maxWidth: "100%",
  },
}));

/**
 * Component to be used as fallback prop to Suspense component to
 * show an appropriate loading screen for the current app
 */
function SplashScreen({ appName }) {
  const classes = useStyles();
  const { appInternalName } = useSelector((state) => state.application);
  const [logo, setLogo] = useState(
    "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Logos/Qore_Full_Color_logo-01.png"
  );

  useEffect(() => {
    APPS.forEach((app) => {
      if (
        (app.logo &&
          (location.hostname && location.hostname.indexOf(app.appName)) >= 0) ||
        (appName && appName.indexOf(app.appName) >= 0) ||
        (appInternalName && appInternalName.indexOf(app.appName) >= 0)
      ) {
        setLogo(app.logo);
      }
    });
  }, []);
  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <Logo mainIcon={false} className={classes.logo} image={logo} />
      </Box>
      <CircularProgress />
    </div>
  );
}

SplashScreen.propTypes = {
  /**
   * String representing one of the app names developed by NSD
   */
  appName: PropTypes.oneOf([
    "dispatch-qore",
    "lender-qore",
    "client-qore",
    "provider-qore",
    "claims-qore",
    "contact-qore",
    "insight-qore",
    "sales-qore",
    "marketing-qore",
    "market-qore",
    "developer-qore",
    "pricebook-qore",
    "underwriter-qore",
    "integration-qore",
    "admin-qore",
    "driver-qore",
    "billing-qore",
    "agent-qore",
    "dealer-qore",
    "licensing-qore",
    "ledger-qore",
    "data-qore",
    "notifications-qore",
  ]),
};

SplashScreen.defaultProps = {
  appName: "dispatch-qore",
};

export default SplashScreen;
