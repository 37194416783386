import enDocumentStrings from './documents';
import enDispatchStrings from './dispatch';
import enBillingStrings from './billing';
import enClaimsStrings from './claims';
import enInsuranceStrings from './insurance';

export default {
  agents: 'Agents',
  agentCodes: 'Agent Codes',
  brandCodes: 'Brand Codes',
  allAgents: 'All Agents',
  allBrands: 'All Brands',
  manyAgents: 'Many Agents',
  multipleBrands: 'Multiple Brands',
  apps: 'Apps',
  brands: 'Brands',
  settings: 'Settings',
  settings_languages: 'Languages',
  settings_responsiveFontSizes: 'Responsive font sizes',
  settings_rtl: 'RTL',
  settings_saveSettings: 'Save Settings',
  settings_theme: 'Theme',
  settings_updated: 'Settings Updated',
  settings_updated_note:
    'We automatically updated your settings. You change the settings any time from your dashboard settings.',
  logout: 'Logout',
  support: {
    support: 'Support',
    requestFeature: 'Request a Feature',
    reportBug: 'Report a Bug',
    topic: 'Topic',
    description: 'Description',
    addScreenshot: 'Add a Screenshot (Optional)',
    towOrder: 'Tow Order',
    dispatchReporting: 'Dispatch Reporting',
    documents: enDocumentStrings.documents,
    reports: enDispatchStrings.reports,
    billing: enBillingStrings.billing,
    claims: enClaimsStrings.claims,
    insurance: enInsuranceStrings.insurance,
    thankYouForYourFeedback: 'Thank you for your feedback!',
    weAppreciateYourPartnership: 'We appreciate your partnership',
    couldNotSubmitRequest: 'Unfortunately, we could not submit your request.',
  },
};