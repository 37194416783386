import React, { useRef } from "react";
import { IconButton, Link, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Download as DownloadIcon } from "src/icons/fontawesome";
import TabTable from "src/components/tables/TabTable";
import PropTypes from "prop-types";
import {
  isSameDay,
  isSameMonth,
  parseISO,
  format,
  getYear,
  getMonth,
} from "date-fns";
import axios from "src/utils/axios";
import axiosBase from "axios";
import INPUT_FIELD_TYPE, { INPUT_FIELD_TYPE_VALUES } from "src/constants/inputFieldType";
import bytesToSize from "src/utils/bytesToSize";

const useStyles = makeStyles((theme) => ({
  noDocumentsIcon: {
    marginBottom: 15,
  },
  customFilterInput: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 26,
      marginRight: 0,
      width: "100%",
    },
  },
}));

/**
 * Composite component that leverages the TabTable and its
 * HOC's to create a table specifically used to manage
 * digital resources (files , photos etc...) and filter them
 * by date created with the option to download the file
 */
const DocumentsTable = ({
  dateCreated,
  downloadTooltipText,
  responseKey,
  title,
  baseUrl,
  searchText,
  Icon,
  filters,
  tableSettings,
  datePickerSettings,
  extraCustomFilters,
}) => {
  // const { t } = useTranslation();

  const classes = useStyles();
  const file = useRef(null);
  const tableFilters = {
    search: true,
    date: true,
    sort: true,
    ...filters,
  };

  const tableRef = useRef(null);

  // Possibly add in as column;
  const getFileSize = async (fileUrl) => {
    if (!fileUrl) return;
    const response = await axiosBase.head(fileUrl);
    return bytesToSize(Number(response?.headers?.["content-length"]));
  };

  let customFilters = [];
  if (extraCustomFilters)
    customFilters = [...customFilters, ...extraCustomFilters];

  if (tableFilters.date) {
    customFilters = [
      ...customFilters,
      {
        name: "dateCreated",
        label: "Date Created",
        // This is where the initial value is pulled out of
        // start as current date
        options: [
          {
            value: "",
            label: "today",
          },
        ],
        type: INPUT_FIELD_TYPE.DATE_TIME,
        filter: (newSelectedDate, customFilterObj) => {
          // If no date set return all records
          if (!newSelectedDate) return true;
          const selectedDate = newSelectedDate;
          const fileDate = customFilterObj?.createdAt;
          if (typeof fileDate === "string")
            fileDate = parseISO(customFilterObj?.createdAt);
          return (
            isSameDay(selectedDate, fileDate) &&
            isSameMonth(selectedDate, fileDate)
          );
        },
        datePickerSettings,
      },
    ];
  }

  const sortOptions = tableFilters.sort && [
    {
      value: "name|asc",
      label: "File Name (a-z)",
      // label: t("fileName.aToZ"),
    },
    {
      value: "name|desc",
      label: "File Name (z-a)",
      // label: t("fileName.zToA"),
    },
  ];

  const searchSettings = tableFilters.search && {
    // placeholder: `${t("search")} ${searchText}`,
    placeholder: `search ${searchText}`,
    queryFields: ["name"],
  };

  const handleDownloadDocument = (doc) => {
    const a = document.createElement("a");
    a.href = doc.url;
    a.download = doc.name;
    a.click();
  };

  const handleDownloadDocumentXhr = async (doc) => {
    const response = await axios.get(doc.url, {
      // params: { timezone: dayjs.tz.guess() },
      params: { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
    handleDownloadDocument({
      ...doc,
      url: window.URL.createObjectURL(new Blob([response.data])),
    });
  };

  const defaultTableSettings = {
    idKey: "id",
    title,
    tableHeadDisplay: () => [
      {
        width: "50%",
        text: "Name",
        // text: t("name"),
      },
      ...(dateCreated
        ? [
          {
            width: "45%",
            text: "Date Created",
            // text: t("dateCreated"),
          },
        ]
        : []),
      // "size",
      "actions",
      // t("actions"),
    ],
    tableRowDisplay: (doc) => [
      <Link
        ref={file}
        component="a"
        href={doc.url}
        download={doc.name}
        color="inherit"
      >
        {doc.name}
      </Link>,
      ...(dateCreated ? [dateCreated(doc)] : []),
      <>
        <Tooltip
          title={downloadTooltipText}
        // title={`${t("download")} ${downloadTooltipText}`}
        >
          <IconButton onClick={() => handleDownloadDocument(doc)}>
            <DownloadIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </>,
    ],
    url: `${baseUrl}/${getYear(new Date())}/${getMonth(new Date())}`,
    responseKey,
    searchSettings,
    size: "small",
    sortOptions,
    customFilters,
    rowsPerPage: 5,
    ref: tableRef,
    noResultsSettings: {
      title,
      icon: <Icon type="solid" className={classes.noDocumentsIcon} />,
      iconSize: 170,
    },
  };
  return <TabTable {...defaultTableSettings} {...tableSettings} />;
};

DocumentsTable.propTypes = {
  /**
   * Each object in this array is rendered the specified type
   * and must include options if needed; rendered next to the search
   * bar above the table header. In addition you can pass in any
   * other props associated with your specified type of object.
   * This prop is overwritten to a date picker customFilter
   */
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.oneOf(["all"]),
            // Date() object from js
            PropTypes.object,
          ]),
          label: PropTypes.string.isRequired,
        })
      ),
      onChange: PropTypes.func,
      filter: PropTypes.func,
      isAutocomplete: PropTypes.bool,
      type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
    })
  ),
  /**
   * Callback that is invoked with 1 argument; the createdAt
   * unix time stamp from the returned array of documents. This
   * function is used to create the value for the Date Created
   * column by invoking this function within the tableRowDisplay
   * array.
   */
  dateCreated: PropTypes.func.isRequired,

  /**
   * Text to appear when hovering over the download button
   */
  downloadTooltipText: PropTypes.string,

  /**
   * The key to the data fetched by TabTable; in which key does
   * the data reside ( something like "files")
   */
  responseKey: PropTypes.string,

  /**
   * Title of the table
   */
  title: PropTypes.string,

  /**
   * Base url from where to fetch data (keep in mind TabTable already
   * uses a custom axios instance with a base API url read from
   * the .env file)
   */
  baseUrl: PropTypes.string,

  /**
   * Placeholder text for the search input in the table header
   */
  searchText: PropTypes.string,

  /**
   * Icon object/component you wish to render as the
   * for the NoResults view of the table
   */
  Icon: PropTypes.object,

  /**
   * Object of filters that correspond weather or not to
   * activate the specified feature;
   * date: true -> Renders a date picker in the table head
   * search: true -> Renders a search input in the table head
   * sort : true -> Renders a sort select in the table head
   * (Usually a key on the pagination object in TabTable)
   */
  filters: PropTypes.shape({
    search: PropTypes.bool,
    date: PropTypes.bool,
    sort: PropTypes.bool,
  }),

  /**
   * Settings object to customize anything about the generated
   * table (how rows are built, table headers, what to show
   * with no results, how to sort, what happens when a row is clicked
   * etc...)
   */
  tableSettings: PropTypes.shape({
    ref: PropTypes.any,
    server: PropTypes.bool,
    idKey: PropTypes.string,
    loadMoreButton: PropTypes.bool,
    idKey: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium"]),
    tableHeadDisplay: PropTypes.func,
    tableRows: PropTypes.func,
    noResultsSettings: PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    }),
    searchSettings: PropTypes.shape({
      placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      queryFields: PropTypes.arrayOf(PropTypes.string),
    }),
    sortOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOf([
          "name|asc",
          "name|desc",
          "createdAt|desc",
          "createdAt|asc",
          "id|asc",
          "id|desc",
        ]).isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    cursorOnRowHover: PropTypes.bool,
    onClickRow: PropTypes.func,
    customFilters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.oneOf(["all"]),
            ]),
            label: PropTypes.string.isRequired,
          })
        ).isRequired,
        onChange: PropTypes.func,
        filter: PropTypes.func,
        isAutocomplete: PropTypes.bool,
        type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
      })
    ),
  }),

  /**
   * Extra props to pass into a DatePicker Component for
   * additional customization
   * https://material-ui-pickers.dev/api/DatePicker
   */
  datePickerSettings: PropTypes.object,
  /**
   * Use to pass in any additional custom filters you
   * want to be rendered in the table head, the customFilters
   * prop is overwritten to create a date custom filter, if
   * you need more customFilters add them here
   */
  extraCustomFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.oneOf(["all"]),
          ]),
          label: PropTypes.string.isRequired,
        })
      ).isRequired,
      onChange: PropTypes.func,
      filter: PropTypes.func,
      isAutocomplete: PropTypes.bool,
      type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
    })
  ),
};

DocumentsTable.defaultProps = {
  downloadTooltipText: "Download",
  title: "Downloaded files",
  searchText: "Search",
  Icon: null,
  tableSettings: {
    server: false,
    idKey: "id",
    size: "medium",
  },
  datePickerSettings: {
    openTo: "date",
    inputVariant: "outlined",
  },
  dateCreated: (doc) => {
    return format(doc?.createdAt, "MM/dd/yyyy");
  },
  extraCustomFilters: [],
};

export default DocumentsTable;
