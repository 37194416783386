import enAdminUserStrings from './AdminUsersView';
import enBottomBarStrings from './BottomBar';
import enAdminStrings from './admin';
import enCancelDispatchFormStrings from './CancelDispatchForm';
import enClaimsViewStrings from './ClaimsView';
import enClaimsStrings from './claims';
import enCommonStrings from './common';
import enClaimsOperationalViewStrings from './ClaimsOperationalView';
import enClaimSearchViewStrings from './ClaimSearchView';
import enDispatchStrings from './dispatch';
import enDispatchNetworkMapViewStrings from './DispatchNetworkMapView';
import enDispatchOperationalStrings from './DispatchOperationalView';
import enDispatchCallSearchViewStrings from './DispatchCallSearchView';
import enDispatchCallsViewStrings from './DispatchCallsView';
import enDispatchTowOrderViewStrings from './DispatchTowOrderView';
import enInsuranceStrings from './insurance';
import enInsuranceOperationalViewStrings from './InsuranceOperationalView';
import enDocumentStrings from './documents';
import enImpoundCallsViewStrings from './ImpoundCallsView';
import enRoadsideCallsViewStrings from './RoadsideCallsView';
import enBillingStrings from './billing';
import enTopBarStrings from './TopBar';
import secondaryQuestions from './secondaryQuestions';
/**
 * This file is used to provide translation strings for the
 * English language.
 *
 * Translation strings are grouped by the context in which
 * they are used. Keys ending in a `View` correspond to module
 * views seen in the `src/views` directory. The `common` key
 * contains strings shared by different modules. The other
 * lowercase keys correspond to apps such as dispatch and their
 * strings are used in the appConfig objects within `src/apps`.
 *
 * Some of the apps don't have a separate file containing
 * translation strings for their views (e.g. billing).
 * The reason for this is that these views are very simple
 * in terms of text content, so we've put all of the relevant
 * translations into a single file (e.g. `enBillingStrings`).
 */
export default {
  AdminUsersView: enAdminUserStrings,
  CancelDispatchForm: enCancelDispatchFormStrings,
  ClaimsView: enClaimsViewStrings,
  ClaimsOperationalView: enClaimsOperationalViewStrings,
  ClaimSearchView: enClaimSearchViewStrings,
  BottomBar: enBottomBarStrings,
  TopBar: enTopBarStrings,
  admin: enAdminStrings,
  billing: enBillingStrings,
  claims: enClaimsStrings,
  common: enCommonStrings,
  dispatch: enDispatchStrings,
  documents: enDocumentStrings,
  DispatchNetworkMapView: enDispatchNetworkMapViewStrings,
  DispatchOperationalView: enDispatchOperationalStrings,
  DispatchCallSearchView: enDispatchCallSearchViewStrings,
  DispatchCallsView: enDispatchCallsViewStrings,
  DispatchTowOrderView: enDispatchTowOrderViewStrings,
  insurance: enInsuranceStrings,
  InsuranceOperationalView: enInsuranceOperationalViewStrings,
  secondaryQuestions: secondaryQuestions,
  ImpoundCallsView: enImpoundCallsViewStrings,
  RoadsideCallsView: enRoadsideCallsViewStrings,
};
