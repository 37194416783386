const CdnAppIcon = "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/insightQoreAppIcon.png";


const appConfig = {
  appId: 'reports-6f0f70f8-3ba0-4918-a8fe-520b3f7a6ba9',
  appName: 'Reports',
  site: 'insights-qore',
  icon: CdnAppIcon
};

export default appConfig;
