// convert current url to object
// example { company: ally, environment: dev, domain: .nationsafedrivers.com }
export const parseSiteUrl = (site) => {
  return site.match(/(?<company>\w+)(-(?<environment>dev|stg))?(?<domain>.nationsafedrivers.com)/)?.groups ?? {};
};

// gets the appSettings for either localstorage dev.site or the current site url
export const getDevSiteOrUrlSite = () => {
  return document.location.host;
};

// validated and get current site, default site to nsd.nationsafedrivers.com
export const getHostSite = () => {
  const site = getDevSiteOrUrlSite();
  const { company, domain } = parseSiteUrl(site);
  const hostSite = `${company}${domain}`;
  return hostSite;
};

// if there's no subdomain, return undefined (example nationsafedrivers.com)
export const getSiteCode = (site) => {
  const { company } = parseSiteUrl(site);
  return company;
};

// gets the appSettings for either localstorage dev.site or the current site url
export const getHostSiteCode = () => getSiteCode(getHostSite());

// gets the appSettings for either localstorage dev.site or the current site url
export const getSiteConfig = async () => {
  let siteConfig = {};
  try {
    // ({ default: siteConfig } = await import(`src/sites/${getHostSite()}`));
  } catch (e) {
    // appSettings doesn't exist for current site
    console.error(error);
  }

  return siteConfig;
};

// app, module, feature, function, and site settings for the current site
// give this highest priority, then /settings endpoint, and then hard-coded
// setting defaults
export const getSettingsFromSiteConfig = async () => {
  const siteConfig = (await getSiteConfig()).settings ?? {};
  return siteConfig;
};

export const isNsdSite = getHostSiteCode() === 'nsd';