/* eslint-disable import/prefer-default-export */

export const TRANSITION_COMPONENT_TYPES = {
  COLLAPSE: "collapse",
  FADE: "fade",
  GROW: "grow",
  SLIDE: "slide",
  ZOOM: "zoom",
};

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};

export const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam Gu",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

export const DISPOSITION_TYPES = {
  PENDING_PRE_APPROVAL: "Pending Pre-Approval",
  PRE_APPROVED: "Pre-Approved",
  READY_FOR_PAYMENT: "Ready For Payment",
  NEED_MORE_INFORMATION: "Need More Information",
  APPROVED: "Approved",
  CANCELLED: "Cancelled",
  PAYMENT_PROCESSED: "Payment Processed",
  DENIED: "Denied",
  PAYMENT_SENT: "Payment Sent",
};

export const CLAIM_STATUS_TYPES = {
  OPEN: "Open",
  CLOSED: "Closed",
  IN_PROGRESS: "In Progress",
};

export const BREAKPOINTS = {
  XL: "xl",
  LG: "lg",
  MD: "md",
  SM: "sm",
  XS: "xs",
};

/*
Looks like 
{
  CUSTOMER_PORTAL: getCustomerPortalApiBaseUrl(),
}
*/
export { default as CustomPortalObject } from "./api";
export { default as APPS } from "./apps";
export { default as AZURE_CLIENT_ID } from "./azureClientId";
/*
Looks like 
{
  FIVE_MIN: 5 * 60 * 1000
};
*/
export { default as CACHE_TIME } from "./cacheTime";
export { default as CamelCase } from "./camelCase";
export { default as canadaStateCoverageAreas } from "./canadaStateCoverageAreas";
export { default as canadaStateRegions } from "./canadaStateRegions";
export { default as INPUT_FIELD_TYPE } from "./inputFieldType";
export { default as LOCAL_STORAGE_NAMESPACE } from "./localStorageNamespace";
export { default as PORTAL_TYPE } from "./portalType";
export { default as COMPLEX_PASSWORD_REGEX } from "./pwComplexity";
export { SUPPORTED_DOC_TYPES } from "./supportedDocTypes";
export { default as TENANT } from "./tenant";
export { default as fallBackThemes } from "./defaultThemes";
// export { default as TIRES } from "./tires";
export { default as usStateCoverageAreas } from "./usStateCoverageAreas";
export { default as usStateRegions } from "./usStateRegions";
