import React, { useState } from 'react';
import { 
  Tooltip, 
  IconButton, 
  SvgIcon,
} from '@material-ui/core';
import { HelpOutline as SupportIcon } from '@material-ui/icons';
import SupportDialog from './SupportDialog';

function Support() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true); 
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Support">
        <IconButton 
          color="inherit" 
          onClick={handleOpenDialog} 
        >
          <SvgIcon fontSize="medium">
            <SupportIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      {isDialogOpen && (
        <SupportDialog 
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default Support;
