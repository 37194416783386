const CdnAppIcon =
  "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/pricebookQoreAppIcon.png";

const appConfig = {
  appId: "pricebook-6f0f7048-3ba0-4918-a8fe-520b3f7a6ba9",
  appName: "Pricebook",
  site: "pricebook-qore",
  icon: CdnAppIcon,
};

export default appConfig;
