import  React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "src/utils/axios";
import { useFetch }from "src/hooks/useFetch";

const FilesContext = createContext();

const defaultFiles = {
  filesInStorage: [],
  filesPendingUpload: [],
};

export function FilesProvider({ url, params, fileListIn = defaultFiles, isLoadingIn = true,  children }) {
  const [currentFiles, setCurrentFiles] = useState(fileListIn );
  const [isLoading, setLoading] = useState(isLoadingIn);


  const setFilesPendingUpload = (filesPendingUpload) => {
    setCurrentFiles((files) => {
      return { ...files, filesPendingUpload };
    });
  };

  const setFilesInStorage = (filesInStorage) => {
    setCurrentFiles((files) => {
      return { ...files, filesInStorage };
    });
  };

  const addToFilesInStorage = (filesToAdd) => {
    setCurrentFiles((files) => {
      return {
        ...files,
        filesInStorage: [...files.filesInStorage, ...filesToAdd],
      };
    });
  };

  const handleSetFilesPendingUpload = (newFiles) => {
    setFilesPendingUpload(newFiles);
  };

  // load files already uploaded
  const { response: responseFiles, clearResponseCache } = useFetch(url, {
    ...params,
  });

  const handleUploadFiles = async () => {
    const formData = new FormData();
    (currentFiles?.filesPendingUpload).forEach((filePendingUpload) => {
      formData.append("files[]", filePendingUpload);
    });
    Object.entries(params).forEach((entry) => {
      const [key, val] = entry;
      formData.append(key, val);
    });

    const {
      data: { files: filesAddedToStorage },
    } = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    clearResponseCache();

    handleSetFilesPendingUpload([]);
    addToFilesInStorage(filesAddedToStorage);
  };

  const handleDeleteFile = async (fileId) => {
    await axios.delete(url, { data: { ...params, id: fileId } });

    setFilesInStorage(
      currentFiles.filesInStorage.filter((file) => {
        return file.id !== fileId;
      })
    );
  };

  useEffect(() => {
    const files = responseFiles?.data?.files ?? [];
    if (responseFiles) {
      setFilesInStorage(files);
      setLoading(false);
    }
  }, [responseFiles]);

  return (
    <FilesContext.Provider
      value={{
        isLoadingFiles: isLoading,
        files: currentFiles,
        setFilesPendingUpload: handleSetFilesPendingUpload,
        uploadFiles: handleUploadFiles,
        deleteFile: handleDeleteFile,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
}

FilesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  params: PropTypes.object,
};

FilesProvider.defaultProps = {
  params: {},
};

export const FilesConsumer = FilesContext.Consumer;

export default FilesContext;
