import { gql } from '@apollo/client';
import BaseService from './baseService';
import each from 'lodash/each';

class AddressService extends BaseService {
  constructor() {
    super();
    this.addressMappings = {
      id: {
        name: '$id',
        type: 'Int',
        input: 'Id'
      },
      fullAddress: {
        name: '$fullAddress',
        type: 'String',
        typeRequire: 'String!',
        input: 'FullAddress'
      },
      googleLocationId: {
        name: '$googleLocationId',
        type: 'String',
        typeRequire: 'String!',
        input: 'GoogleLocationId'
      },
      address: {
        name: '$address',
        type: 'String',
        typeRequire: 'String!',
        input: 'Address'
      },
      city: {
        name: '$city',
        type: 'String',
        typeRequire: 'String!',
        input: 'City'
      },
      stateProvidence: {
        name: '$stateProvidence',
        type: 'String',
        typeRequire: 'String!',
        input: 'StateProvidence'
      },
      postalCode: {
        name: '$postalCode',
        type: 'String',
        typeRequire: 'String!',
        input: 'PostalCode'
      },
      latitude: {
        name: '$latitude',
        type: 'Float',
        typeRequire: 'Float!',
        input: 'Latitude'
      },
      longitude: {
        name: '$longitude',
        type: 'Float',
        typeRequire: 'Float!',
        input: 'Longitude'
      },
      addressTypeId: {
        name: '$addressTypeId',
        type: 'Int',
        typeRequire: 'Int!',
        input: 'AddressTypeId'
      }
    };
    this.addressGql = `
      Id
      FullAddress
      GoogleLocationId
      Address
      City
      StateProvidence
      PostalCode
      Latitude
      Longitude
      AddressTypeId
    `;
    this.addressActionGql = `
      Success
      Error`;
  }

  buildAddressGQL = (data, mutation, required, actionReturned) => {
    const keys = Object.keys(data);
    const address = [];
    const input = [];
    each(keys, (key) => {
      const map = this.addressMappings[key];
      if (map && data[key]) {
        address.push(`${map.name}: ${required && map.typeRequire ? map.typeRequire : map.type}`);
        input.push(`${map.input}: ${map.name}`);
      }
    });

    return `
      mutation Address(${address.join(', ')}){
        ${mutation}(
            ${input.join('\n')}
          ) {
            ${actionReturned ? this.addressActionGql : this.addressGql}
          }
      }`;
  };

  addUpdateAddress = (address) => new Promise((resolve, reject) => {
    const ADDRESS_TYPES = gql`
      ${this.buildAddressGQL(address, 'addUpdateAddress', true)}
    `;

    this.client
      .mutate({
        mutation: ADDRESS_TYPES,
        variables: { ...address, addressTypeId: address.addressTypeId || 3 }
      })
      .then((result) => {
        if (result.data.addUpdateAddress) {
          resolve(result.data.addUpdateAddress);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });

  getAddresses = () => new Promise((resolve, reject) => {
    const ADDRESS_TYPES = gql`
      query {
        addresses{
            ${this.addressGql}
        }
      }
    `;

    this.client
      .query({
        query: ADDRESS_TYPES
      })
      .then((result) => {
        if (result.data.addresses) {
          resolve(result.data.addresses);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

const addressService = new AddressService();

export default addressService;
