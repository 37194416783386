import { getHostSiteCode } from '@nsd/fe';

// 1 user can belong to many companies
export const getUserCompany = (user) => {
    // if the user belongs to nsd, return nsd
    const nsdCompany = user?.companies?.find((company) => company.code === 'nsd');
    if (nsdCompany) {
        return nsdCompany;
    }

    // if the user belongs to the current website's company, return that
    const hostSiteCompany = user?.companies?.find((company) => company.code === getHostSiteCode());
    if (hostSiteCompany) {
        return hostSiteCompany;
    }

    // otherwise just return the first company for the user
    return user?.companies?.[0];
}