import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "src/components/Logo";

const useStyles = makeStyles(() => ({
  poweredByText: {
    marginBottom: 5,
  },
}));

const PoweredBy = () => {
  const classes = useStyles();
  return (
    <Box
      p={2}
      borderRadius="borderRadius"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Typography
        className={classes.poweredByText}
        variant="h5"
        color="textPrimary"
      >
        Powered by
      </Typography>
      <Logo
        image="https://nsdstaticasset.blob.core.windows.net/assets/nsd.png"
        width={125}
      />
    </Box>
  );
};

export default PoweredBy;
