export default {
  'callDateTime': 'Date/Heure d\'Appel',
  'calledAt': 'Appelé À',
  'callbackNumber': 'Numéro de Rappel',
  'callbackNumber_hash': 'Rappeler#',
  'callerCity': 'Ville de l\'Appelant',
  'callerFirstName': 'Prénom de l\'Appelant',
  'callId': 'Identifiant d\'Appel',
  'callerLastName': 'Nom de l\'Appelant',
  'callerName': 'Nom de l\'Appelant',
  'callerState': 'État de l\'Appelant',
  'caller': 'Votre Interlocuteur',
  'callerId': 'Identification de l\'Appelant',
  'callBackNumber_hash': 'Rappeler #',
  'policyNumber_hash': 'Politique #',
  'company': 'Société',
  'eta_min_abbreviation': 'ETA (min)',
  'member': 'Membre',
  'mobileTexts': 'Textes Mobiles',
  'editedBy': 'Édité Par',
  'editedDate': 'Date de Modification',
  'vehicle': 'Véhicule',
  'rideshareDescription': 'Destination de Covoiturage',
  'dispatchLocation': 'Lieu d\'Expédition',
  'towToLocation': 'Remorquage Jusqu\'à l\'Emplacement',
  'confirmation': 'Confirmation',
  'mva_number_hash_abbreviation': 'MVA#',
  'stateOrProvince': 'État / Province',
  'vin_abbreviation': 'Vin #',
  'status': 'Statut',
  'personWithVehicle': 'Personne avec Véhicule',
  'completedDate': 'Date de Fin',
  'deliveryLatitude': 'Latitude de Livraison',
  'deliveryLongitude': 'Longitude de Livraison',
  'requestDate': 'Date de la Demande',
  'commentAs': 'Commentez cet appel en tant que',
  'customDateAndTime': 'Date et heure personnalisées',
  'selectCustomDateAndTimeRange': 'Sélectionnez une date et une plage horaire personnalisées',
  'startDateTime': 'Date/heure de Début',
  'endDateTime': 'Date/Heure de Fin',
  'dispatchId': 'Expédition ID',
  'thirdPartyId': 'Tierce personne ID',
  'towServiceDescription': 'Description du service',
};
