import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

/**
 * Hook that uses the available theme's (pulled from theme provider)
 * media query breakpoints to determine the best widths to separate
 * a view into a sidebar and a main page. Also includes a boolean
 * to indicate if the current window's width falls into the "mobile"
 * category
 */
export default function useWindowWidths() {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"), {
    defaultMatches: true,
  });
  const isLg = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.up("xs"), {
    defaultMatches: true,
  });
  let windowWidth = "45%";
  let sideWindowWidth = "55%";
  let isMobile = true;
  if (isXs) {
    windowWidth = "95%";
    sideWindowWidth = "5%";
  }
  if (isSm) {
    windowWidth = "75%";
    sideWindowWidth = "25%";
  }
  if (isMd) {
    windowWidth = "50%";
    sideWindowWidth = "50%";
  }
  if (isLg) {
    windowWidth = "45%";
    sideWindowWidth = "55%";
    isMobile = false;
  }
  if (isXl) {
    windowWidth = "40%";
    sideWindowWidth = "60%";
    isMobile = false;
  }
  return {
    windowWidth,
    sideWindowWidth,
    isMobile,
  };
}
