const GET_APPLICATION_INTERNAL_NAME =
  "@applications/get-application-internal-name";

const SET_APPLICATION_DETAILS = "@applications/set-application-details";

export const setApplicationDetails = (
  type,
  appName,
  appVersion,
  appInternalName
) => ({
  type: SET_APPLICATION_DETAILS,
  payload: {
    type,
    appName,
    appVersion,
    appInternalName,
  },
});
