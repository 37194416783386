const canadaProvinces = [
  {
    // Alberta
    landRegionCode: 'AB',
    textOverlay: {
      transform: {
        x: -12015,
        y: 3875
      }
    }
  },
  {
    // British Columbia
    landRegionCode: 'BC',
    textOverlay: {
      transform: {
        x: -17800,
        y: 440
      }
    }
  },
  {
    // Manitoba
    landRegionCode: 'MB',
    textOverlay: {
      transform: {
        x: -1815,
        y: 5275
      }
    }
  },
  {
    // New Brunswick
    landRegionCode: 'NB',
    textOverlay: {
      rect: {
        width: 4000,
        height: 4000,
        x: 37700,
        y: -13800
      },
      transform: {
        x: 39700,
        y: -12000
      }
    }
  },
  {
    // Newfoundland and Labrador
    landRegionCode: 'NL',
    textOverlay: {
      transform: {
        x: 20500,
        y: 1000
      }
    }
  },
  {
    // Northwest Territories
    landRegionCode: 'NT',
    textOverlay: {
      transform: {
        x: -10815,
        y: -5500
      }
    }
  },
  {
    // Nova Scotia
    landRegionCode: 'NS',
    textOverlay:
      // Sidebar view (too small)
      {
        rect: {
          width: 4000,
          height: 4000,
          x: 37700,
          y: -17900
        },
        transform: {
          x: 39700,
          y: -16000
        }
      }
  },
  {
    // Nunavut
    landRegionCode: 'NU',
    textOverlay: {
      transform: {
        x: -1215,
        y: -4000
      }
    }
  },
  {
    // Ontario
    landRegionCode: 'ON',
    textOverlay: {
      transform: {
        x: 7500,
        y: 10275
      }
    }
  },
  {
    // Prince Edward Island
    landRegionCode: 'PE',
    textOverlay:
      // Sidebar view (too small)
      {
        rect: {
          width: 4000,
          height: 4000,
          x: 37700,
          y: -22000
        },
        transform: {
          x: 39700,
          y: -20100
        }
      }
  },
  {
    // Quebec
    landRegionCode: 'QC',
    textOverlay: {
      transform: {
        x: 15000,
        y: 6000
      }
    }
  },
  {
    // Saskatchewan
    landRegionCode: 'SK',
    textOverlay: {
      transform: {
        x: -7015,
        y: 5875
      }
    }
  },
  {
    // Yukon
    landRegionCode: 'YT',
    textOverlay: {
      transform: {
        x: -18815,
        y: -9000
      }
    }
  }
];

export default canadaProvinces;
