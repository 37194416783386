import jwtDecode from 'jwt-decode';
import AZURE_CLIENT_ID from '../constants/azureClientId';
import { getLoginFlowEndpoints } from '../utils/company';
import { parseCookie } from '../utils/cookie';
import { getHostSiteCode } from '../utils/siteConfig';
import { isNationSafeDriversEmail } from '../utils/email';
import TENANT from '../constants/tenant';
import authAxios from 'src/utils/authAxios';

class AuthService {
  constructor() {
    // Attach interceptors to this instance so the global instance doesn't use it
    // Client-Qore can add their redux axios middleware headers to this as well
    this.#axios = authAxios;
  }

  #axios;

  #authResponseInterceptor;

  #signOut;

  #signOutTimer;

  setSignOut = (signOut) => {
    this.#signOut = signOut;
  };

  signOut = () => {
    if (this.#signOut) {
      this.#signOut();
    }
  };

  // logout if there's a error response during /me; nothing to logout of if POST /login fails
  setAxiosInterceptors = ({ onLogout }) => {
    this.#axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        // If there was an error AND there is a JWT present in cookies
        if (error.response && this.isAuthenticated()) {
          if (onLogout) await onLogout();
        }
        return error;
      }
    );
  };

  // don't logout if there's a 401 response on anything aside from
  // authentication requests
  removeAuthResponseInterceptor = () => {
    this.#axios.interceptors.response.eject(this.#authResponseInterceptor);
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    this.updateSession(accessToken);
  }

  // update session token, clear session token
  // if new one is invalid
  updateSession = (accessToken) => {
    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  };

  createUser = (user) => {
    const agents = user.agents?.map((agent) => ({ ...agent, active: true }));
    return { ...user, agents };
  };

  createSettings = (settings) =>
    Object.entries(settings).reduce(
      (settingsMap, [assetType, assets]) => ({
        ...settingsMap,
        [assetType]: assets.reduce(
          (assetsMap, asset) => ({
            ...assetsMap,
            [asset.identifier]: { hasAccess: asset.hasAccess },
          }),
          {}
        ),
      }),
      {}
    );

  // check for user and token in login/register response, update local storage,
  // and return user
  processLoginResponse = (response) => {
    if (!response.data.user) {
      throw new Error('No user');
    }

    const { user, settings, roles, clientQoreCompanyCode } = response.data;
    return {
      user,
      settings: this.createSettings(settings),
      roles,
      clientQoreCompanyCode,
    };
  };

  registerWithUserNameAndPassword = async (
    userName,
    password,
    firstName,
    lastName
  ) => {
    const response = await this.#axios.post('/register', {
      userName,
      password,
      firstName,
      lastName,
    });
    return this.processLoginResponse(response);
  };

  /**
   * Redirect to appropriate Authorization Code URL from Azure. For B2C (external)
   * users the login, token (used on common-services) and the logout OAuth 2.0 endpoints have the same
   * format with the only difference being that one portion of the URI must include
   * the name of the created User flow within Azure.
   *
   * B2C LOGIN URI FORMAT:
   *  https://nationsafedriversconsumers.b2clogin.com/nationsafedriversconsumers.onmicrosoft.com/YOUR_USER_FLOW_NAME
   * B2C LOGOUT URI FORMAT:
   *  https://nationsafedriversconsumers.b2clogin.com/nationsafedriversconsumers.onmicrosoft.com/YOUR_USER_FLOW_NAME/oauth2/v2.0/logout
   *
   * B2C TOKEN URI FORMAT (used for common-services):
   *  https://nationsafedriversconsumers.b2clogin.com/nationsafedriversconsumers.onmicrosoft.com/YOUR_USER_FLOW_NAME/oauth2/v2.0
   *
   * AD LOGIN URI:
   *  https://login.microsoftonline.com/YOUR_AD_TENANT_ID
   *
   * AD LOGOUT URI:
   *  https://login.microsoftonline.com/common/oauth2/logout
   *
   * AD TOKEN URI:
   *  https://login.microsoftonline.com/YOUR_AD_TENANT_ID/oauth2/v2.0
   *
   * @param {string} email - If this is blank then the FE application is using PORTAL_TYPE.EMPLOYEE (meaning default to internal)
   * @param {string} prevPath - The previous path the unauthenticated user tried to access before being redirected to /login 
   * @param {string} prevQueryParams - The previous query parameters the unauthenticated user attempted to use on prevRoute (if present)
   * @returns {Promise<void>} (triggers redirect to Azure portal)
   */
  loginWithMicrosoft = async (email, prevPath, prevQueryParams) => {
    const isNSDUser = isNationSafeDriversEmail(email);
    const { loginUrl } = getLoginFlowEndpoints(email);

    let authUrlParams = {
      client_id: AZURE_CLIENT_ID.B2C,
      login_hint: email,
      // Always prompt B2C users to login with email + password
      ...(isNSDUser ? {} : { prompt: 'login' }),
      response_type: 'code',
      redirect_uri: `${window.location.origin}/login`,
      response_mode: 'query',
      scope: 'openid offline_access https://graph.microsoft.com/.default',
      // Add intitially visited route
      state: new URLSearchParams({
        azureTenantId: TENANT.B2C,
        ...(prevPath && { prevPath }),
        ...(prevQueryParams && { prevQueryParams }),
        email,
      }).toString(),
    };

    if (!email || isNSDUser) {
      authUrlParams = {
        ...authUrlParams,
        client_id: AZURE_CLIENT_ID.AD,
        state: new URLSearchParams({
          azureTenantId: TENANT.AD,
          ...(prevPath && { prevPath }),
          ...(prevQueryParams && { prevQueryParams }),
          email
        }).toString(),
      };
    }
    window.location.href = `${loginUrl}/oauth2/v2.0/authorize?${new URLSearchParams(
      authUrlParams
    ).toString()}`;
    return;
  };

  /**
   * "loginType" param is used to identify which group of users this login request belongs
   * to because we handle our 3 cases differently on common-services
   *   1. Internal User -> loginType:'internal'
   *   2. Enternal Users -> loginType: 'external'
   *   3. Turo External User -> loginType: 'turo'
   *
   * @param {string} code - Fetch from query params in current URI
   * @param {string} azureTenantId - Passed back from callback after successfully getting Authorization code
   * @param {('internal'| 'external' | 'turo')} loginType
   * @returns {Promise<Object>}
   */
  loginWithCode = async (code, azureTenantId, loginType) => {
    const response = await this.#axios.post('/login', {
      code,
      azureTenantId,
      redirectUri: `${window.location.origin}${window.location.pathname}`,
      loginType,
    });
    return this.processLoginResponse(response);
  };

  loginWithToken = async () => {
    const response = await this.#axios.get('/me');
    const { user, settings, roles, clientQoreCompanyCode } = response.data;
    return {
      user,
      settings: this.createSettings(settings),
      roles,
      clientQoreCompanyCode,
    };
  };

  /**
   * @returns {Promise<undefined>} (triggers redirect to Azure portal)
   * Determines correct logout URI by getting the user email from the POST /logout response body
   * Correctly logouts users by clearing SSO session on Azure servers.
   * B2C Logout URL: https://nationsafedriversconsumers.b2clogin.com/nationsafedriversconsumers.onmicrosoft.com/B2C_1_SignIn/oauth2/v2.0/logout?post_redirect_logout_uri=https%3A%2F%2Fwww.urlencoder.org%2F
   * AD Logout: https://login.microsoftonline.com/common/oauth2/logout (immediate log out of all accounts)
   * (2nd AD Logout): https://login.microsoftonline.com/common/oauth2/v2.0/logout (if multiple accounts lets you choose which one to sign out of)
   */
   logout = async () => {
    // Remove axios interceptors so POST /logout doesn't retry
    this.removeAuthResponseInterceptor();
    const token = this.getAccessToken();
    let decodedToken = null;
    if(token){
      decodedToken = jwtDecode(token);
    }
    const userEmail = decodedToken?.userEmail;
    await this.#axios.post('/logout');
    // Fallback to re-direct to origin
    let logoutUrl = window.origin;
    if(userEmail){
      const authObject = getLoginFlowEndpoints(userEmail);
      logoutUrl = authObject.logoutUrl;
    }
    window.location.href = logoutUrl;
  };

  setSession = (accessToken) => {
    if (accessToken) {
      this.removeAuthResponseInterceptor();
      // update sign out time since we get a new token each request
      // and the new token has an expiration date later than the current
      // token
      this.setLogoutTimer();
    } else {
      document.cookie = 'nsd.token=; Max-Age=-99999999;';
    }
  };

  getAccessToken = () => {
    const { 'nsd.token': token } = parseCookie(document.cookie);
    if (!token) {
      return null;
    }

    return token;
  };

  userHasAccessToCurrentSite = (userCompanyCodes) => {
    const hostSiteCode = getHostSiteCode();

    // all users get access to corporate site
    if (!hostSiteCode) {
      return true;
    }

    // give nsd users access to all sites
    if (this.isLoggedInUserAnNsdEmployee()) {
      return true;
    }

    return (
      userCompanyCodes.includes(hostSiteCode) ||
      userCompanyCodes.some((code) =>
        code.includes(process.env.REACT_APP_COMPANY_CODE)
      )
    );
  };

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    try {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      // make sure token hasn't expired and that the user has access to this site

      return (
        decoded.exp > currentTime &&
        this.userHasAccessToCurrentSite(decoded.userCompanyCodes)
      );
    } catch (error) {
      return false;
    }
  };

  getLoggedInUserId = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }

    const decoded = jwtDecode(accessToken);
    return decoded.id;
  };

  getLoggedInUserCompanyCodes = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }

    const decoded = jwtDecode(accessToken);
    return decoded.userCompanyCodes;
  };

  isLoggedInUserAnNsdEmployee = () =>
    this.getLoggedInUserCompanyCodes()?.includes('nsd');

  // gets jwt token expiration in seconds
  getTokenExpirationTime = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }

    const decoded = jwtDecode(accessToken);
    return decoded.exp;
  };

  getMillisecondsUntilTokenExpires = () => {
    const now = new Date().getTime();
    return 1000 * this.getTokenExpirationTime() - now;
  };

  isAuthenticated = () => this.isValidToken(this.getAccessToken());

  setLogoutTimer = () => {
    clearTimeout(this.#signOutTimer);
    this.#signOutTimer = setTimeout(
      this.#signOut,
      this.getMillisecondsUntilTokenExpires()
    );
  };
}

const authService = new AuthService();

export default authService;
