import axios from "src/utils/axios";

class CommonFileService {
  uploadFile = async (file, fileName, path, container, captchaToken) => {
    let formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(
      `/file?captchaToken=${captchaToken}&siteKey=${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}&fileName=${fileName}&filePath=${path}&container=${container}&registration=1`,
      formData
    );
    return response;
  };

  getDocumentData = async (documentInfo) => {
    const response = await axios.post(
      `/file?action=extract-document-data`,
      documentInfo
    );
    return response;
  };
}

const commonfileService = new CommonFileService();

export default commonfileService;
