/* eslint-disable react/no-array-index-key */
import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayout from 'src/layouts/MainLayout';
import {
  LoadingScreen,
  AppProvider,
  ModuleProvider,
  GuestGuard,
  useAssetSettings,
  createRoute,
  getAllApps,
  getRoutesForAnApp,
  ComingSoonView,
  PORTAL_TYPE,
  Error403View,
  Error404View,
  LoginView,
  NoAppsView,
  NoModulesView,
  SomethingWentWrongView,
  ResetPasswordView,
} from '@nsd/fe';

const renderRoutes = (routes, apps, type) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes?.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const navConfig =
            route.layout && route.navConfig ? route.navConfig : null;
          const topBarConfig =
            route.layout && route.topBarConfig ? route.topBarConfig : null;
          const Component = route.component;

          const renderLayout = (props) => {
            /* Conditionally give type and apps b/c you cannot give those props to a React.Fragment */
            const renderedLayout = (
              <Guard
                {...(route?.guard ? { type } : undefined)}
                {...(route?.guard ? { apps } : undefined)}
              >
                <Layout
                  {...(route?.layout ? { navConfig } : undefined)}
                  {...(route?.layout ? { topBarConfig } : undefined)}
                  {...(route?.layout ? { apps } : undefined)}
                >
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            );

            if (route.appId) {
              return (
                <AppProvider
                  app={{
                    appId: route.appId,
                    appName: route.appName,
                    path: route.path,
                  }}
                >
                  {renderedLayout}
                </AppProvider>
              );
            }

            if (route.module) {
              return (
                <ModuleProvider module={route.module}>
                  {renderedLayout}
                </ModuleProvider>
              );
            }

            return renderedLayout;
          };

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => renderLayout(props)}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

/**
 * @param {Object[]} apps  - An array of app config objects found in the 'src/apps' folder and passed into the
 * Routes component (default export) as a prop.
 * @param {Object[]} loginViewLinks - Additional links you wish to render on the login form; make sure you have created
 *  the linked routes (if leading to a relative component) first in the appConfig.
 * @param {Object[]} additionalRootPages -    Array of additional app config objects to add more root pages (register page, etc...)
 * 
 * loginViewLinks example: 
 *  {
      text: 'Link text',
      to: 'some relative or hosted link),
    }
 *
 * App config object example:
 * {
 *   appId: 'insurance-26108cd7-53f2-4f06-b2db-5030d86a08c3', (used to determine if a logged in user has app access through common-services-api)
 *   appName: 'Insurance', (displayed in TopBar)
 *   site: 'insurance-qore', (Used to determine url)
 *   icon: '/images/something' (relative or hosted link to an image)
 *   priority: 1, (Used when apps are sorted to determine what app is displayed when the user first logs-in)
 *   path: 'admin', (Relative path to where the route should be displayed)
 *   guard: AuthGuard, (Guard component wrapped around the route in case the user needs to be logged in to access it or vice-versa;
 *    e.g. redirect the user from login if they are already logged in)
 *   layout: DashboardLayout, (Which layout to use ; 3 choices MainLayout, DashboardLayout and DocsLayout)
 *   routes: [
 *      {
          exact: true,  (should path be matched exactly; same behavior as the <Route exact={true} /> prop on the Route component from react-router-dom)
          path: 'admin', (Relative path to where the route should be displayed)
          redirect: 'management/account'  (One of 'redirect' or 'view' property must be set. redirect just sends the user to a different
          route if they hit the specified path property.)
        },
        {
          exact: true,  
          path: 'management/users', 
          view: lazy(() => import('src/views/management/UsersView')) (One of 'redirect' or 'view' property must be set. For view
          it is recommended to lazy load a custom component)
        },
 *    ]
 * }
 *
 * NOTE: If and only if the type="employee" the array of apps passed in (even if empty)
 * is combined with the available appConfigs exported in the users' local 'src/apps' path
 * (or from the nsd/fe registry's local 'src/apps' if this component is used from the registry)
 *
 *
 * @returns {ReactElement} - The <Routes type="client" apps={[]} /> component meant to be placed below an instance of a Router from react-router-dom
 */

// Still need to pass in your local apps
const Routes = ({
  apps,
  loginViewLinks,
  additionalRootPages = [],
  // Default to an empty function
  onSubmitSuccess = () => { },
  logoConfig,
}) => {
  const { type } = useSelector((state) => state.application);
  if (type !== PORTAL_TYPE.CLIENT && type !== PORTAL_TYPE.EMPLOYEE) {
    throw new Error('type argument can only be \'client\' or \'employee\'');
  }

  let appRoutes = [];
  const { hasModuleAccess } = useAssetSettings();
  // Remove appConfigs without local paths
  appRoutes = getAllApps(apps, type)
    .filter((app) => app?.path !== undefined)
    .map((app) => getRoutesForAnApp(app, hasModuleAccess));

  const rootPages = [
    {
      exact: true,
      path: '/403',
      view: <Error403View />,
    },
    {
      exact: true,
      path: '/404',
      view: <Error404View />,
    },
    {
      exact: true,
      path: '/noapps',
      view: <NoAppsView />,
    },
    {
      exact: true,
      path: '/nomodules',
      view: <NoModulesView />,
    },
    {
      exact: true,
      path: '/comingsoon',
      view: <ComingSoonView />,
    },
    {
      exact: true,
      path: '/somethingwentwrong',
      view: <SomethingWentWrongView />,
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/login',
      view: () => (
        <LoginView
          type={type}
          links={loginViewLinks || null}
          onSubmitSuccess={onSubmitSuccess}
          {...logoConfig}
        />
      ),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/forgot-password',
      view: () => <ResetPasswordView />,
    },
    ...(additionalRootPages && additionalRootPages),
  ].map((route) => createRoute(route));

  const mainRoutes = [
    {
      exact: true,
      path: '/',
      redirect: '/login',
    },
    {
      redirect: '/404',
    },
  ].map((route) => createRoute(route));

  const routesConfig = [
    ...rootPages,
    ...appRoutes,
    {
      path: '*',
      layout: MainLayout,
      routes: mainRoutes,
    },
  ];
  // This ensures the context is wrapped when Routes are generated
  return (<>{renderRoutes(routesConfig, apps, type)}</>);
};

Routes.propTypes = {
  /**
   * Additional links you wish to render on the login form; make sure you have created
   * the linked routes (if leading to a relative component) first in the appConfig
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  /**
   * Callback that runs after a user successfully logs in;
   * TODO: Currently cannot access hook functions here as they are
   * not run/evaluated in the context of react
   */
  onSubmitSuccess: PropTypes.func,
  /**
   * Object that configures how the login form is displayed. You are able to
   * change the logo displayed and the design of the form element.
   */
  logoConfig: PropTypes.shape({
    /**
     * Sets the logo that appears on the login screen. By default should look into the
     * current theme applied and attempt to pull the logo out of that
     */
    logo: PropTypes.string,
    /**
     * Alt text for the logo image passed in
     */
    logoAlt: PropTypes.string,
    /**
     * Sets the JSX that appears next to the login form (image or text related to client company) to allow
     * further customization. If not will default to NSD stlying. Corresponds to the <CardMedia> component from material
     * UI. Accepts an image
     * https://v4.mui.com/api/card-media/
     */
    formJSX: PropTypes.element,
    /**
     * Width of the logo
     */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Class name generated from makeStyles() hook to further
     * customize logo image
     */
    className: PropTypes.string,
  }),
};

export default Routes;
