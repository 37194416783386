import { getClientCompanyCode } from "src/utils/clientUtils"; 
// const NEWSITECODE = {
//   volkswagen: {
//     development:
//       'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8efafaa122-3dff-4e93-852a-70317a8c149a',
//     staging: null,
//     production: null
//   },
//   audi: {
//     development:
//       'customerPortal-9b2c764c-2832-4519-800a-4107ff4fdd8e54264aee-a5f1-4acd-a399-cc663d6e7c39',
//     stagin: null,
//     production: null
//   }
// };

/**
 * Validated and get current site, default site to nsd.nationsafedrivers.com
 *
 * @return {string}
 */
export const getHostSite = () => { 
  // return `${process.env.REACT_APP_COMPANY_CODE}.nationsafedrivers.com`;
  return `${getClientCompanyCode()}.nationsafedrivers.com`
};

/**
 * @return {boolean}
 */
export const isSite = site => getHostSite() === site;
