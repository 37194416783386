import React from "react";
import CountryMapLegend from '../CountryMapLegend';
import SVGInnerMap from './SVGInnerMap';

import PropTypes from 'prop-types';

SVGLayeredCountryMap.propTypes = {
  innerSVGEl: PropTypes.instanceOf(SVGElement).isRequired,
  classes: PropTypes.object.isRequired,
  landRegionToCountMap: PropTypes.object,
  landRegionsWithCount: PropTypes.arrayOf(
    PropTypes.shape({
      landRegionCode: PropTypes.string.isRequired,
      textOverlay: PropTypes.shape({
        transform: PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        }).isRequired,
        rect: PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        }),
        countNumber: PropTypes.number
      }).isRequired
    })
  ).isRequired,
  onLandRegionEnter: PropTypes.func.isRequired,
  onLandRegionLeave: PropTypes.func.isRequired,
  onLandRegionClick: PropTypes.func.isRequired,
  selectedLandRegions: PropTypes.arrayOf(PropTypes.string),
  disabledLandRegions: PropTypes.arrayOf(PropTypes.string),
  legendTitle: PropTypes.string.isRequired,
  legendLandRegionsTitle: PropTypes.string.isRequired,
  colorScale: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataMap: PropTypes.object,
  innerMapOffsetX: PropTypes.number,
  innerMapOffsetY: PropTypes.number,
  landRegionCountOffsetY: PropTypes.number,
  innerMapScaling: PropTypes.number
};

export default function SVGLayeredCountryMap({
  innerSVGEl,
  classes,

  /** @type {Object} */
  landRegionToCountMap,

  landRegionsWithCount,
  onLandRegionEnter,
  onLandRegionLeave,
  onLandRegionClick,
  selectedLandRegions,
  disabledLandRegions,
  legendTitle,
  legendLandRegionsTitle,

  /** @type {string[]} An array of HEX color codes */
  colorScale,

  dataMap,
  innerMapOffsetX = 0,
  innerMapOffsetY = 0,
  landRegionCountOffsetY = 0,
  innerMapScaling = 1
}) {
  return (
    // Outer SVG (w/ Legend and Title)
    <svg
      // IMPORTANT: The legend component is configured to use this viewBox
      // size, while the InnerMapSVG component will use the viewBox size
      // configured to the original map's SVG
      viewBox="30 0 650 400"
      className={classes.outerSVGFont}
      // Fixes issue where navigating away real fast with cursor could
      // cause map to stay stuck over last land region hovered over
      onMouseLeave={() => onLandRegionLeave(null)}
    >
      <SVGInnerMap
        svgEl={innerSVGEl}
        classes={classes}
        landRegionToCountMap={landRegionToCountMap}
        landRegionsWithCount={landRegionsWithCount}
        colorScale={colorScale}
        onLandRegionEnter={onLandRegionEnter}
        onLandRegionLeave={onLandRegionLeave}
        onLandRegionClick={onLandRegionClick}
        selectedLandRegions={selectedLandRegions}
        disabledLandRegions={disabledLandRegions}
        dataMap={dataMap}
        //
        innerMapOffsetX={innerMapOffsetX}
        innerMapOffsetY={innerMapOffsetY}
        landRegionCountOffsetY={landRegionCountOffsetY}
        innerMapScaling={innerMapScaling}
      />
      <CountryMapLegend
        // key="legend"
        colorScale={colorScale}
        title={legendTitle}
        landRegionsTitle={legendLandRegionsTitle}
      />
    </svg>
  );
}
