import { GraphQLClient } from 'src/components/GraphQL';

class BaseService {
  constructor(uri) {
    const graphQL = new GraphQLClient(uri);
    this.client = graphQL.client;
  }

  clearStore() {
    this.client.clearStore()
  }
}

export default BaseService;
