import React from "react";
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const RideShare = (props) => {
    return (
      <SvgIcon {...props} viewBox="0 0 55.562 55.563">
        <g transform="translate(-50.734 -131.37)">
          <path d="m-95.385 90.377c-0.05292-0.1561-0.20108-0.26723-0.37571-0.26723h-2.9104c-0.17462 0-0.32014 0.11112-0.37571 0.26723l-0.55033 1.5849v2.1167c0 0.14552 0.11906 0.26458 0.26458 0.26458h0.26459c0.14552 0 0.26458-0.11906 0.26458-0.26458v-0.26458h3.175v0.26458c0 0.14552 0.11906 0.26458 0.26458 0.26458h0.26458c0.14552 0 0.26458-0.11906 0.26458-0.26458v-2.1167zm-3.2861 2.6432c-0.2196 0-0.39687-0.17727-0.39687-0.39688 0-0.2196 0.17727-0.39688 0.39687-0.39688s0.39688 0.17727 0.39688 0.39688c0 0.2196-0.17727 0.39688-0.39688 0.39688zm2.9104 0c-0.2196 0-0.39688-0.17727-0.39688-0.39688 0-0.2196 0.17727-0.39688 0.39688-0.39688s0.39688 0.17727 0.39688 0.39688c0 0.2196-0.17727 0.39688-0.39688 0.39688zm-3.3073-1.3229 0.39687-1.1906h2.9104l0.39688 1.1906z" strokeWidth=".26458" />
          <path d="m80.854 147.57a2.4337 2.4337 0 0 1-2.4337 2.4337 2.4337 2.4337 0 0 1-2.4337-2.4337 2.4337 2.4337 0 0 1 2.4337-2.4337 2.4337 2.4337 0 0 1 2.4337 2.4337zm9.6703 1.2088a4.103 4.103 0 0 1-4.103 4.103 4.103 4.103 0 0 1-4.103-4.103 4.103 4.103 0 0 1 4.103-4.103 4.103 4.103 0 0 1 4.103 4.103zm-16.347 0.11513a4.103 4.103 0 0 1-4.103 4.103 4.103 4.103 0 0 1-4.103-4.103 4.103 4.103 0 0 1 4.103-4.103 4.103 4.103 0 0 1 4.103 4.103zm20.038-6.2507c-0.4638-1.3682-1.7623-2.342-3.2928-2.342h-25.508c-1.5305 0-2.8058 0.97387-3.2928 2.342l-4.8232 13.89v18.551c0 1.2754 1.0435 2.3188 2.3189 2.3188h2.3189c1.2754 0 2.3189-1.0435 2.3189-2.3188v-2.3188h27.826v2.3188c0 1.2754 1.0435 2.3188 2.3189 2.3188h2.3189c1.2754 0 2.3189-1.0435 2.3189-2.3188v-18.551zm-28.8 23.165c-1.9247 0-3.4783-1.5537-3.4783-3.4783 0-1.9246 1.5536-3.4783 3.4783-3.4783 1.9247 0 3.4783 1.5536 3.4783 3.4783 0 1.9246-1.5536 3.4783-3.4783 3.4783zm25.508 0c-1.9247 0-3.4783-1.5537-3.4783-3.4783 0-1.9246 1.5536-3.4783 3.4783-3.4783 1.9247 0 3.4783 1.5536 3.4783 3.4783 0 1.9246-1.5536 3.4783-3.4783 3.4783zm-28.986-11.594 3.4783-10.435h25.508l3.4783 10.435z" strokeWidth=".30421" />
        </g>
      </SvgIcon>
    );
};

RideShare.propTypes = {
    type: PropTypes.string
};

export default RideShare;
