import React from 'react';
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

export const STYLE = {
  SOLID: "solid",
  REGULAR: "regular",
  LIGHT: "light",
};

/**
 * Component that converts a included Font Awesome SVG
 * to an SVG Icon component that can be customized with props
 * https://v4.mui.com/api/svg-icon/
 */
const FontAwesome = ({
  className,
  variations,
  viewBox,
  viewBoxes,
  type,
  ...rest
}) => {
  return (
    <SvgIcon
      className={clsx(className)}
      viewBox={viewBox ?? viewBoxes[type]}
      {...rest}
    >
      <path d={variations[type]} />
    </SvgIcon>
  );
};

FontAwesome.propTypes = {
  /**
   * Class name to be applied directly to the SVG icon
   * component
   */
  className: PropTypes.string,
  /**
   * String representing the viewbox property of an SVG
   * Ex: default value is "0 0 24 24"
   */
  viewBox: PropTypes.string,
  /**
   * Object of viewbox strings for each of the SVG variations;
   * solid, regular and light
   */
  viewBoxes: PropTypes.shape({
    solid: PropTypes.string,
    regular: PropTypes.string,
    light: PropTypes.string,
  }),
  /**
   * Object of SVG path strings corresponding to the different
   * types/variations
   */
  variations: PropTypes.shape({
    solid: PropTypes.string,
    regular: PropTypes.string,
    light: PropTypes.string,
  }),
  /**
   * Different variations for each of the SVGs
   */
  type: PropTypes.oneOf(["solid", "regular", "light"]),
};

FontAwesome.defaultProps = {
  type: STYLE.REGULAR,
  className: "",
};

export default FontAwesome;
