const CdnAppIcon = "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/billingQoreAppIcon.png";

const appConfig = {
  appId: 'billing-ee010e41-0436-42f7-b5f3-316df5fe3c13',
  appName: 'Billing',
  site: 'billing-qore',
  icon: CdnAppIcon
};

export default appConfig;
