import React, { useRef, useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Popover,
  Tooltip,
  Typography,
  Badge,
} from '@material-ui/core';
import {
  grey,
  lightBlue,
  red,
  yellow,
  lightGreen,
} from '@material-ui/core/colors';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Bell as BellIcon } from 'react-feather';
import {
  Mail as MailIcon,
  Drafts as DraftsIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import {
  updateNotification,
  updateNotifications,
  clearAllNotifications,
  clearOneNotification,
} from 'src/actions/notificationActions';
import FirebaseContext from 'src/context/FirebaseContext';

const severityMap = {
  info: InfoIcon,
  error: ErrorIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};

const useStyles = (pulseBool) =>
  makeStyles((theme) => ({
    popover: {
      width: 320,
    },
    icon: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    badge: {
      height: 20,
      width: 20,
      borderRadius: 18,
      padding: '0.5rem',
      marginTop: 5,
      marginRight: 5,
      animation: `${pulseBool ? '$pulse 2s ease infinite' : ''}`,
    },
    badgeNoNumber: {
      height: 10,
      width: 10,
      borderRadius: 15,
      padding: '0.4rem',
      marginTop: 7,
      marginRight: 12,
      animation: `${pulseBool ? '$pulse 2s ease infinite' : ''}`,
    },
    read: {
      fontWeight: theme.typography.fontWeightLight,
      background: grey[200],
    },
    notRead: {
      fontWeight: theme.typography.fontWeightBold,
    },
    messageTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    severityIcon: {
      marginLeft: theme.spacing(1),
    },
    info: {
      color: lightBlue[200],
    },
    error: {
      color: red[500],
    },
    warning: {
      color: yellow[300],
    },
    success: {
      color: lightGreen[400],
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: `0 0 0 0 ${alpha(theme.palette.secondary.main, 0.7)}`,
      },

      '70%': {
        transform: 'scale(1)',
        boxShadow: `0 0 0 10px ${alpha(theme.palette.secondary.main, 0)}`,
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: `0 0 0 0 ${alpha(theme.palette.secondary.main, 0)}`,
      },
    },
  }));

/**
 * Component meant to be rendered in the TopBar to display
 * any notifications the user may have received; also gives the option
 * to mark all notifications as read. (Notifications are pulled out
 * of the state.notifications.notifications key for now until the chat
 * system is finalized)
 */
function Notifications() {
  const { FirebaseCrud = null } = useContext(FirebaseContext);
  const {
    messages = [],
    displayNumberedBadge = true,
    pulseAnimation = false,
  } = useSelector((state) => state?.notifications || {});

  const badgeVisible = !!messages?.filter((message) => !message.read)?.length;
  const classes = useStyles(pulseAnimation && badgeVisible)();

  const badgeClass = displayNumberedBadge
    ? classes.badge
    : classes.badgeNoNumber;
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const markAllAsRead = async () => {
    if (!FirebaseCrud || !messages || messages?.length <= 0) return;
    await dispatch(updateNotifications(FirebaseCrud));
    await dispatch(clearAllNotifications());
  };

  // Check if value is marked as read; clearOneNotification
  const toggleReadStatus = async (readMessage) => {
    if (!FirebaseCrud || !messages || messages?.length <= 0) return;
    await dispatch(updateNotification(FirebaseCrud, readMessage));
    await dispatch(clearOneNotification(readMessage?.docId));
  };

  return (
    <>
      <Tooltip ref={ref} title="Notifications">
        <Badge
          color="secondary"
          classes={{ badge: badgeClass }}
          variant={displayNumberedBadge ? 'standard' : 'dot'}
          badgeContent={
            messages?.filter((message) => !message.read)?.length || null
          }
          invisible={true}
        >
          <IconButton color="inherit" onClick={handleOpen}>
            <BellIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            Notifications
          </Typography>
        </Box>
        {messages.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {messages.map((message) => {
                // const Icon = iconsMap[notification.type];
                const Icon = severityMap[message?.severity];
                const severityClass = classes?.[message?.severity];
                return message?.pageLink?.includes('http') ? (
                  <a
                    href={message?.pageLink}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItem
                      className={message.read ? classes.read : classes.notRead}
                      divider
                      key={message.docId}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!message?.read) toggleReadStatus(message);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.icon}></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div className={classes.messageTitle}>
                            <Typography>{message?.from}</Typography>
                            <Icon
                              fontSize="small"
                              className={clsx(
                                classes.severityIcon,
                                severityClass
                              )}
                            />
                          </div>
                        }
                        primaryTypographyProps={{
                          variant: 'subtitle2',
                          color: 'textPrimary',
                        }}
                        secondary={message?.text}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleReadStatus(message);
                          }}
                        >
                          {message?.read ? <DraftsIcon /> : <MailIcon />}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </a>
                ) : (
                  <ListItem
                    className={message.read ? classes.read : classes.notRead}
                    component={RouterLink}
                    divider
                    key={message.docId}
                    to={message?.pageLink ?? '#'}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!message?.read) toggleReadStatus(message);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        {/* <SvgIcon fontSize="small">
                        <Icon />
                      </SvgIcon> */}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div className={classes.messageTitle}>
                          <Typography>{message?.from}</Typography>
                          <Icon
                            fontSize="small"
                            className={clsx(
                              classes.severityIcon,
                              severityClass
                            )}
                          />
                        </div>
                      }
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textPrimary',
                      }}
                      secondary={message?.text}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleReadStatus(message);
                        }}
                      >
                        {message?.read ? <DraftsIcon /> : <MailIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button
                onClick={markAllAsRead}
                component={RouterLink}
                size="small"
                to="#"
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
