import { Redirect } from "react-router-dom";
import React, { lazy, isValidElement } from "react";
import NoModulesView from "src/views/errors/NoModulesView";
import sharedApps from "src/apps";
import PORTAL_TYPE from "src/constants/portalType";
import merge from "lodash/merge";
import unionBy from "lodash/unionBy";
import findIndex from "lodash/findIndex";

// return true if path is an external link, or false if it's an internal link
const isExternalLink = (href) => {
  return href.startsWith("http://") || href.startsWith("https://");
};

// prepend app root path to subPath unless it's an absolute path
const getPath = (appRootPath, subPath) => {
  if (Array.isArray(subPath)) {
    return subPath.map((path) => getPath(appRootPath, path));
  }

  if (subPath === "") return `/${appRootPath}`;
  if (subPath === undefined || subPath.startsWith("/")) return subPath;

  return `/${appRootPath}/${subPath}`;
};

// find any navbar links with relative paths and convert them to absolute paths
export const getNavBarItems = (appRootPath, navBarItems = []) =>
  navBarItems.map((navBarItem) => {
    const { href, items } = navBarItem;

    return {
      ...navBarItem,
      ...(href &&
        (isExternalLink(href)
          ? { externalLink: true, href }
          : { href: getPath(appRootPath, href) })),
      ...(items && { items: getNavBarItems(appRootPath, items) || null }),
    };
  });

export const createRoute = (route, appRootPath) => {
  let component;
  if (route.redirect) {
    component = () => <Redirect to={getPath(appRootPath, route.redirect)} />;
  } else if (isValidElement(route.view)) {
    component = () => route.view;
  } else if (typeof route.view === "string") {
    if (appRootPath) {
      // load app view
      component = lazy(() =>
        import(`src/apps/${appRootPath}/views/${route.view}`)
      );
    } else {
      // load global view
      component = lazy(() => import(`src/views/${route.view}`));
    }
  } else {
    component = route.view;
  }

  return {
    ...route,
    ...(route.path !== undefined && { path: getPath(appRootPath, route.path) }),
    ...((route.view !== undefined || route.redirect !== undefined) && {
      component,
    }),
  };
};

export const getRoutesForAnApp = (
  { navConfig, routes, topBarConfig, ...appConfig },
  hasModuleAccess
) => {
  const { path: appRootPath } = appConfig;

  // Set default redirect to first module that the user has access to. If
  // there's no module defined in the app config file then return the first
  // path in the file
  let defaultPath;
  for (let i = 0; i < routes?.length; i++) {
    const route = routes[i];
    const module = route?.module;
    if (typeof module === "undefined" || hasModuleAccess(module)) {
      defaultPath = route?.path;
      if (Array.isArray(defaultPath)) {
        [defaultPath] = defaultPath;
      }

      if (defaultPath) {
        break;
      }
    }
  }

  let defaultMod;
  if (!routes?.some((route) => route.path === "")) {
    defaultMod = {
      exact: true,
      path: "",
    };
    defaultMod = {
      ...defaultMod,
      ...(defaultPath
        ? { redirect: defaultPath }
        : {
            view: (
              <NoModulesView
                {...(routes?.length === 0 && {
                  header: "No Modules Found",
                  message: `This app doesn't have any modules. Please
                  ask a developer to create one.`,
                })}
              />
            ),
          }),
    };
  }

  // redirect to defaultPath (ex views/calls if user goes to views
  // redirect to 404 page if nothing matches
  const routesWithDefaultRedirectAnd404 = [
    ...(defaultMod ? [defaultMod] : []),
    ...(routes ? routes : []),
    {
      redirect: "/404",
    },
  ];

  const appRoutes = routesWithDefaultRedirectAnd404.map((route) =>
    createRoute(route, appRootPath)
  );

  return {
    ...appConfig,
    path: `/${appRootPath}`,
    routes: appRoutes,
    navConfig,
    topBarConfig,
  };
};

// load all apps (with an id) with routes, navigation appSettings, and app configs
// sharedApps needs to be dynamic when merging apps for an employee application
// If client app this step doesn't occur
// Can merge your local appConfig with the one from the registry; may include href
// field on a local appConfig leading it to get purged
const mergeApps = (apps) => {
  let newApps = [];
  unionBy(sharedApps, apps, (e) => {
    const idx = findIndex(newApps, { appId: e.appId });
    if (idx > -1) {
      merge(newApps[idx], e);
    } else {
      newApps.push(e);
    }
  });
  return newApps;
};

export const getAllApps = (apps, type) => {
  try {
    const finalApps =
      PORTAL_TYPE.EMPLOYEE === type ? [...mergeApps(apps)] : [...apps];
    const sortedApps = Object.values(
      finalApps.reduce((acc, cur) => {
        let appConf = cur;
        if (appConf.site) {
          appConf = {
            ...appConf,
            href: `${appConf.site}.nationsafedrivers.com`,
          };
        }
        if (!acc[cur.appId]) acc[cur.appId] = appConf;
        // 0 is lowest; highest priority app determines default view
        if (!acc[cur.appId]?.priority) acc[cur.appId].priority = 0;
        return acc;
      }, {})
    ).sort((a, b) => b.priority - a.priority);
    return sortedApps;
  } catch (e) {
    return [...apps];
  }
};

/* Newer version from client-portal

// load all apps (with an id) with routes, navigation appSettings, and app configs
export const getAllApps = () => {
  const sortedApps = apps.sort((a, b) => a.priority - b.priority);
  return sortedApps;
};
*/
