export default {
  pendingDocument: 'Document en Attente',
  complete: 'Complet',
  escalateToNSD: 'Escalade vers NSD',
  dispatched: 'Expédiés',
  pendingDispatch: 'En Ettente D’expédition',
  cancelled: 'Annulé',
  policeHold: 'Maintien de la Police',
  newCase: 'Nouveau Cas',
  new: 'Nouveau',
  accepted: 'Accepté',
  inRoute: 'En Route',
  onScene: 'Sur les Lieux',
  towInProgress: 'Remorquage en Cours',
  destinationArrival: 'Arrivée à Destination',
  digitalDispatch: 'Expédition Numérique',
  totalImpoundCalls: 'Nombre Total D’appels à la Fourrière',
  PieChartCard: {
    title: 'Mises en Fourrière par Pourcentage',
  },
};
