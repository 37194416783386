import esAdminUserStrings from './AdminUsersView';
import esBottomBarStrings from './BottomBar';
import esAdminStrings from './admin';
import esCancelDispatchFormStrings from './CancelDispatchForm';
import esClaimsViewStrings from './ClaimsView';
import esClaimsStrings from './claims';
import esCommonStrings from './common';
import esClaimsOperationalViewStrings from './ClaimsOperationalView';
import esClaimSearchViewStrings from './ClaimSearchView';
import esDispatchStrings from './dispatch';
import esDispatchNetworkMapViewStrings from './DispatchNetworkMapView';
import esDispatchOperationalStrings from './DispatchOperationalView';
import esDispatchCallSearchViewStrings from './DispatchCallSearchView';
import esDispatchCallsViewStrings from './DispatchCallsView';
import esInsuranceOperationalViewStrings from './InsuranceOperationalView';
import esDispatchTowOrderViewStrings from './DispatchTowOrderView';
import esInsuranceStrings from './insurance';
import esDocumentStrings from './documents';
import esBillingStrings from './billing';
import esTopBarStrings from './TopBar';
import esImpoundCallsViewStrings from './ImpoundCallsView';
import esRoadsideCallsViewStrings from './RoadsideCallsView';

/**
 * This file is used to provide translation strings for the
 * Spanish language.
 *
 * Translation strings are grouped by the context in which
 * they are used. Keys ending in a `View` correspond to module
 * views seen in the `src/views` directory. The `common` key
 * contains strings shared by different modules. The other
 * lowercase keys correspond to apps such as dispatch and their
 * strings are used in the appConfig objects within `src/apps`.
 *
 * Some of the apps don't have a separate file containing
 * translation strings for their views (e.g. billing).
 * The reason for this is that these views are very simple
 * in terms of text content, so we've put all of the relevant
 * translations into a single file (e.g. `esBillingStrings`).
 */
export default {
  AdminUsersView: esAdminUserStrings,
  ClaimsView: esClaimsViewStrings,
  ClaimsOperationalView: esClaimsOperationalViewStrings,
  ClaimSearchView: esClaimSearchViewStrings,
  CancelDispatchForm: esCancelDispatchFormStrings,
  BottomBar: esBottomBarStrings,
  TopBar: esTopBarStrings,
  admin: esAdminStrings,
  billing: esBillingStrings,
  claims: esClaimsStrings,
  common: esCommonStrings,
  dispatch: esDispatchStrings,
  documents: esDocumentStrings,
  DispatchNetworkMapView: esDispatchNetworkMapViewStrings,
  DispatchOperationalView: esDispatchOperationalStrings,
  DispatchCallSearchView: esDispatchCallSearchViewStrings,
  DispatchCallsView: esDispatchCallsViewStrings,
  DispatchTowOrderView: esDispatchTowOrderViewStrings,
  insurance: esInsuranceStrings,
  InsuranceOperationalView: esInsuranceOperationalViewStrings,
  ImpoundCallsView: esImpoundCallsViewStrings,
  RoadsideCallsView: esRoadsideCallsViewStrings,
};
