import React, { memo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { Avatar, Tooltip, Typography, Link } from "@material-ui/core";
import { red, common } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingLeft: 20,
  },
  avatar: {
    border: `3px solid ${theme.palette.background.default}`,
    marginLeft: -20,
    cursor: "pointer",
    "&:hover": {
      zIndex: 2,
    },
  },
  more: {
    backgroundColor: red[600],
    color: common.white,
  },
}));

/**
 * Small component to display a stacked collection of circular
 * avatar images; When clicked the avatar image will link
 * to the users profile page.
 */
function StackAvatars({ avatars, limit, className, ...rest }) {
  const classes = useStyles();
  let lastIndex = 0;

  const avatarNodes = avatars
    .slice(0, limit)
    .map(({ avatarImageLink, profileLink }) => (
      <Tooltip key={++lastIndex} title="View">
        <Link
          component={RouterLink}
          color="textPrimary"
          to={profileLink || "#"}
        >
          <Avatar className={classes.avatar} src={avatarImageLink} />
        </Link>
      </Tooltip>
    ));

  if (avatars.length > limit) {
    avatarNodes.push(
      <Avatar key={++lastIndex} className={clsx(classes.avatar, classes.more)}>
        <Typography color="inherit" variant="subtitle2">
          +{avatars.length - limit}
        </Typography>
      </Avatar>
    );
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {avatarNodes}
    </div>
  );
}

StackAvatars.propTypes = {
  /**
   * Array of avatar objects to be displayed. Needs an image
   * link to the avatar image and a link to the user's profile
   * page with that specified avatar
   */
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      avatarImageLink: PropTypes.string,
      profileLink: PropTypes.string,
    })
  ).isRequired,

  /**
   * Class name to apply to the outmost element containing
   * the stacked avatars
   */
  className: PropTypes.string,

  /**
   *  Limit to the number of displayed avatars, if the number
   * of avatars exceeds this number a "+(remaining number)""
   * circle is displayed as the last image on the stack
   */
  limit: PropTypes.number,
};

StackAvatars.defaultProps = {
  avatars: [],
  limit: 3,
  className: "",
};

export default memo(StackAvatars);
