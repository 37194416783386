import React from "react";
import {
  yellow,
  orange,
  cyan,
  pink,
  grey,
  brown,
  amber,
  green,
  teal,
  purple,
} from "@material-ui/core/colors";
import PropTypes from "prop-types";
import ChartCard from "src/components/cardCharts/apex/ChartCard";
import PopoverTable from "../PopoverTable";
import { average, fractionDigits } from "src/utils/math";
import DataSourceButton from "src/components/DataSourceButton";
const createChart = (theme, chartData, chartTypeDetails, chartProps) => {
  const numMonths = chartData.length;
  const { xaxis, yaxis, percent, tooltip, labels } = chartTypeDetails;
  const yaxisTooltipFormatter =
    yaxis?.tooltipFormatter ??
    ((val) => {
      if (val === null) return;
      let yaxistooltipFormat = val;

      if (yaxis.toolTipLabel || yaxis.label) {
        const number = percent ? val : val?.toFixed(0);
        const numberLabel = `${percent ? "%" : ""} ${
          yaxis.toolTipLabel || yaxis.label
        }`;
        yaxistooltipFormat = `${number}${numberLabel}`;
      }

      return yaxistooltipFormat;
    });

  const yaxisLabelFormatter = (val) => {
    if (percent) {
      return `${val}%`;
    }

    return fractionDigits(val, 0, 2);
  };
  const lineColors = [
    yellow[400],
    orange[400],
    cyan[400],
    pink[400],
    grey[400],
    brown[400],
    amber[400],
    green[400],
    teal[400],
    purple[400],
    "#ff5c7c",
    "#1f87e6",
  ].slice(-numMonths);
  const dashArray = [...Array(numMonths).keys()].map((i) =>
    i % 2 === 1 ? 3 : 0
  );
  const chart = {
    type: "line",
    options: {
      chart: {
        animations: {
          enabled: false,
        },
        background: theme.palette.background.paper,
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: false,
      },
      colors: lineColors,
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: theme.palette.divider,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "right",
        labels: {
          colors: theme.palette.text.secondary,
        },
      },
      markers: {
        size: 4,
        strokeColors: lineColors,
        strokeWidth: 0,
        shape: "circle",
        radius: 2,
        hover: {
          size: undefined,
          sizeOffset: 2,
        },
      },
      stroke: {
        width: 3,
        curve: "smooth",
        lineCap: "butt",
        dashArray,
      },
      theme: {
        mode: theme.palette.type,
      },
      tooltip: {
        theme: theme.palette.type,
        x: {
          formatter: (val) => `Day ${val}`,
        },
        y: {
          formatter: yaxisTooltipFormatter,
        },
        ...tooltip,
      },
      xaxis: {
        title: {
          text: "Day",
        },
        axisBorder: {
          color: theme.palette.divider,
        },
        axisTicks: {
          show: true,
          color: theme.palette.divider,
        },
        categories: [...Array(31).keys()].map((i) => i + 1),
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
        ...xaxis,
        ...chartProps?.xaxis,
      },
      yaxis: [
        {
          ...(!yaxis.hide && { title: { text: yaxis.label } }),
          axisBorder: {
            show: true,
            color: theme.palette.divider,
          },
          axisTicks: {
            show: true,
            color: theme.palette.divider,
          },
          labels: {
            formatter: yaxis?.labels?.formatter ?? yaxisLabelFormatter,
            style: {
              colors: theme.palette.text.secondary,
            },
          },
        },
      ],
    },
    series: chartTypeDetails?.series
      ? chartTypeDetails.series(chartData)
      : chartData.map(({ month, data }) => ({ name: month, data })),
  };
  return chart;
};

/**
 * Component that uses ChartCard to render a multi
 * line graph. It does not use the withAysnc HOC to fetch
 * ; you must pass the response object directly as a prop
 */
const OperationalLineChart = ({
  chartTypes,
  isLoading,
  response,
  activeChartType,
  onChangeChartType,
  onOpenDataSourceDialog,
  showDataSourceButton,
  showAveragesButton,
  chartProps,
}) => {
  const activeChartTypeValue = activeChartType ?? chartTypes[0].value;
  const averagesData = response?.data?.[activeChartTypeValue]?.map(
    ({ month, data }) => ({ month, average: fractionDigits(average(data), 2) })
  );
  return (
    <ChartCard
      isLoading={isLoading}
      response={response}
      activeChartType={activeChartTypeValue}
      onChangeChartType={onChangeChartType}
      createChart={createChart}
      chartTypes={chartTypes}
      chartProps={chartProps}
      cardAction={
        <>
          {showDataSourceButton && (
            <DataSourceButton onOpenDataSourceDialog={onOpenDataSourceDialog} />
          )}
          {showAveragesButton && <PopoverTable data={averagesData} />}
        </>
      }
    />
  );
};

OperationalLineChart.propTypes = {
  /**
   * Array of objects representing the possible types of
   * charts that can be displayed and switched via an dropdown
   * menu in the CardChart actions, can also hold configuration
   * for that specified chart (see options ). One of this objects
   * is passed in to createChart as the 3rd argument
   * https://apexcharts.com/docs/options/
   * https://apexcharts.com/docs/options/xaxis/
   * https://apexcharts.com/docs/options/yaxis/
   */
  chartTypes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      info: PropTypes.string,
      type: PropTypes.string,
      yaxis: {
        label: PropTypes.string.isRequired,
      },
      xaxis: PropTypes.object,
      yaxis: PropTypes.object,
    })
  ),
  /**
   * While true a circular loader is rendered in the chart
   */
  isLoading: PropTypes.bool,
  /**
   * The response from the fetched data; only used if the
   * src prop is passed in; this prop is generated by the
   * withAysnc HOC
   */
  response: PropTypes.object,
  /**
   * String for the type of chart you are currently rendering.
   * https://apexcharts.com/docs/options/chart/type/
   */
  activeChartType: PropTypes.oneOf([
    "line",
    "area",
    "bar",
    "radar",
    "histogram",
    "pie",
    "donut",
    "radialBar",
    "scatter",
    "bubble",
    "heatmap",
    "candlestick",
  ]),
  /**
   * Callback that runs when the dropdown menu has a new
   * option clicked. It receives 1 argument; the value key
   * from the selected chartTypes array that rendered the
   * option in the dropdown
   */
  onChangeChartType: PropTypes.func,
  /**
   * Callback that runs when the DataSource Button component
   * is clicked. It receives 1 argument; the generated event
   * object
   */
  onOpenDataSourceDialog: PropTypes.func,
  /**
   * If the data source button is rendered on the ChartCard
   */
  showDataSourceButton: PropTypes.bool,
  /**
   * If the average popover table button is rendered on the ChartCard
   */
  showAveragesButton: PropTypes.bool,
  /**
   * Additional props/ configuration object that is passed into
   * the createChart() function to generate the final config. It
   * can contain any of the valid props for react-apex-charts Chart
   * component.
   * type is already specified through activeChartType
   * and series is generated from the data fetched.
   * Series shape changes depending on what chart type
   * you render; see docs (also for options object)
   * https://www.npmjs.com/package/react-apexcharts
   */
  chartProps: PropTypes.shape({
    series: PropTypes.array,
    type: PropTypes.oneOf([
      "line",
      "area",
      "bar",
      "radar",
      "histogram",
      "pie",
      "donut",
      "radialBar",
      "scatter",
      "bubble",
      "heatmap",
      "candlestick",
    ]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.object,
  }),
};

OperationalLineChart.defaultProps = {
  showAveragesButton: true,
};

export default OperationalLineChart;
