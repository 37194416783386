import React, { useEffect, useState } from "react";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Skeleton from "@material-ui/lab/Skeleton";
import * as Yup from "yup";
import { Typography, Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "src/utils/axios";
import CreateUpdateDialog from "src/components/crud/CreateUpdateDialog";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import { singular, upperCaseFirstLetter } from "src/utils/string";
import NewButton from "src/components/buttons/NewButton";
import { useLocation } from "react-router";
import { useSnackbar } from "notistack";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
} from "@material-ui/icons";
import { INPUT_FIELD_TYPE_VALUES } from 'src/constants/inputFieldType';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  primaryTitle: {
    color:
      theme?.palette?.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2.5, 0),
  },
  treeItem: {
    margin: "1rem 1rem",
    borderRadius: "1px",
    outline: "1px solid black",
  },
  labelText: {
    fontWeight: "inherit",
    marginRight: theme.spacing(1),
    maxWidth: "2rem",
  },
  actionsContainer: {
    display: "flex",
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    display: "flex",
    // flexGrow: 1,
    width: "100%",
    // outline: "1px solid red",
    justifyContent: "space-around",
  },
  topCaption: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },
  description: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "8rem",
  },
  descriptionToolTip: {
    fontSize: "0.7rem",
  },
  createButtonLabel: {
    ".MuiTreeItem-content": {
      background: "red",
      display: "flex",
      justifyContent: "end",
      margin: "0 1rem",
    },
  },
  treeItemHeader: {
    textTransform: "capitalize",
    "&.MuiTreeItem-root > .MuiTreeItem-content": {
      cursor: "default",
    },
    "&.Mui-selected > .MuiTreeItem-content": {
      background: "transparent",
    },
    "&.MuiTreeItem-root > .MuiTreeItem-content:hover": {
      background: "transparent",
    },
    "&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-label": {
      background: "transparent",
    },
    "@media (hover: none)": {
      backgroundColor: "transparent",
    },
    "&.MuiTreeItem-root > .MuiTreeItem-content > .MuiTreeItem-label": {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
      alignItems: "center",
    },
  },
}));

const AssetsTreeView = ({ treeConfig }) => {
  let nodeIndexCounter = 0;
  const [resources, setResources] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedIdentifier, setSelectedIdentifier] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  // Initial fetch of top level resource
  useEffect(() => {
    const fetchInitialData = async () => {
      const [intialConfig] = treeConfig;
      const { fetchEndpoint, fetchResponseKey } = intialConfig;
      // TODO: Need support for adding query params on initial call
      const { data } = await axios.get(fetchEndpoint);
      setResources(
        data?.[fetchResponseKey]?.map((resource) => ({
          ...resource,
          // Going to initially leave in a skelton or loader so the double click issue goes away
          childResources: [{ name: "Loading", resourceName: "Loading" }],
          // Spread in config as well to customize
          ...intialConfig,
        }))
      );
    };
    if (treeConfig?.length && treeConfig?.length >= 0) fetchInitialData();
  }, [location.pathname, treeConfig]);

  /*
    Utility functions
  */
  const getUpperCaseAndSingular = (str) => singular(upperCaseFirstLetter(str));
  const generateInputValidationSchema = (inputs = []) => {
    const validationSchema = {};
    inputs.map((inputField) => {
      const { validation, size, ...restInputField } = inputField;
      validationSchema[restInputField.name] = validation;
      return { ...restInputField, size: size ?? { xs: 6 } };
    });
    return validationSchema;
  };

  /* 
    Click handlers for action icons; calls local state sync functions
  */

  const handleCopyIdentifier = (identifier = "N/A", event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(identifier);
    setSelectedIdentifier(identifier);
    setTimeout(() => {
      setSelectedIdentifier(null);
    }, 600);
  };

  const handleCreate = (createdResource) => {
    let resourceToAdd;
    // Selected asset is either... just top level config (no identifier) OR
    // correct child config with target parent identifier
    const {
      identifier = null,
      parentResourceIdKey = null,
      fetchEndpoint,
    } = selectedAsset;
    resourceToAdd = { ...selectedAsset, ...createdResource };
    // Selected asset always has the correct config, re-fetch from tree
    let correctConfig = treeConfig.find(
      (config) => config?.fetchEndpoint === fetchEndpoint
    );

    // No identifier here means its just at the topLevel
    // Lack of the parentResourceIdKey means top level of tree
    const updatedResources = syncLocalState(
      [...resources],
      parentResourceIdKey ? identifier : null,
      // This is just used to find correct level to search, resourceToAdd
      // already has the correct config in the object
      correctConfig,
      resourceToAdd,
      "create"
    );
    setResources(updatedResources);
  };

  // Created resource is the updated values from the form
  const handleEdit = (createdResource) => {
    // Also still has the config in it?
    const isRoleOrPackageTree =
      treeConfig[0]?.resourceName === "roles" || treeConfig[0]?.resourceName;

    let updatedResources;
    const { identifier = null, fetchEndpoint } = createdResource;
    let correctConfig = treeConfig.find(
      (config) => config?.fetchEndpoint === fetchEndpoint
    );
    if (isRoleOrPackageTree) {
      // Cannot use name as it is edited, use name from selectedResource
      updatedResources = syncLocalState(
        [...resources],
        selectedAsset.name,
        // This is just used to find correct level to search, resourceToAdd
        // already has the correct config in the object
        correctConfig,
        createdResource,
        "edit"
      );
    } else {
      updatedResources = syncLocalState(
        [...resources],
        identifier,
        // This is just used to find correct level to search, resourceToAdd
        // already has the correct config in the object
        correctConfig,
        createdResource,
        "edit"
      );
    }

    setResources(updatedResources);
  };

  const handleDelete = async () => {
    const { fetchEndpoint, id, resourceName, identifier } = selectedAsset;
    let correctConfig = treeConfig.find(
      (config) => config?.fetchEndpoint === fetchEndpoint
    );
    try {
      await axios.delete(`${fetchEndpoint}/${id}`);
      // Target resourceIdentifier needs to be the parent of this resource
      // b/c selectedAsset is the resource to be deleted
      const updatedResources = syncLocalState(
        [...resources],
        identifier,
        correctConfig,
        null,
        "delete"
      );
      setResources(updatedResources);
      enqueueSnackbar(
        `${getUpperCaseAndSingular(resourceName)} has been archived`,
        {
          variant: "success",
        }
      );
      setIsDeleteDialogOpen(false);
    } catch (e) {
      enqueueSnackbar(
        `Unable to delete ${getUpperCaseAndSingular(resourceName)}`,
        { variant: "error" }
      );
    }
  };

  const handleCancelDelete = () => setIsDeleteDialogOpen(false);

  const handleModifyResourceAccess = async (
    accessPayload,
    hasAccess,
    targetResource = null
  ) => {
    // Make the axios call and display snackbar
    try {
      await axios.put(`/featureAssignments`, {
        ...accessPayload,
      });
      // If its a feature you need to update the local state;
      if (targetResource) {
        const resourceArrayCopy = [...resources];
        /**
         * Steps
         * 1) Get functionId and topLevelParentResourceName
         * 2) Flatten and map childResources to function level
         * 3) See where functionId and topLevelParentResourceName matches local vars
         * 4) Within that function alter the childResouces to be the same
         *    but with the specific feature identifer (from targetResource)
         * 5)
         */
        const {
          topLevelParentResourceName: featureTopLevelParent,
          identifier: featureIdentifer,
        } = targetResource;
        let currentLevelResourceName = "";
        let flattenedFeatureLevel;
        while (currentLevelResourceName !== "features") {
          if (flattenedFeatureLevel) {
            flattenedFeatureLevel = flattenedFeatureLevel
              .map(({ childResources }) => {
                return childResources;
              })
              // So its not an array with an array in it
              .flat(1)
              // truthy check
              .filter((item) => item)
              // Remove loading placeholders
              .filter(({ name }) => name !== "Loading");
          } else {
            flattenedFeatureLevel = resourceArrayCopy
              .map(({ childResources }) => {
                return childResources;
              })
              // So its not an array with an array in it
              .flat(1)
              // truthy check
              .filter((item) => item)
              // Remove loading placeholders
              .filter(({ name }) => name !== "Loading");
          }
          const [item] = flattenedFeatureLevel;
          const { resourceName } = item;
          currentLevelResourceName = resourceName;
        }
        // This is the correct function (end of step 3)
        const alteredFeature = flattenedFeatureLevel.find(
          ({ identifier, topLevelParentResourceName }) =>
            identifier === featureIdentifer &&
            topLevelParentResourceName === featureTopLevelParent
        );
        alteredFeature.hasAccess = hasAccess;
        const {
          functionParentIdentifier,
          fetchEndpoint,
          topLevelParentResourceName,
        } = targetResource;
        let childFeatureConfig = treeConfig.find(
          (config) => config?.fetchEndpoint === fetchEndpoint
        );
        const updatedResources = await addChildResources(
          [...resources],
          functionParentIdentifier,
          childFeatureConfig,
          // I need the altered function
          [{ ...alteredFeature }],
          topLevelParentResourceName
        );
        setResources(updatedResources);
      }

      // No need to update local state won't be reflected anywhere
      enqueueSnackbar(
        `Access successfully ${hasAccess ? "added" : "removed"}.`,
        {
          variant: "success",
        }
      );
      setIsDeleteDialogOpen(false);
    } catch (e) {
      console.error({ e });
      enqueueSnackbar(`Error ${hasAccess ? "adding" : "removing"} access `, {
        variant: "error",
      });
    }
  };

  // Used to add or remove access to role or package (can pass in any resource to add access to suite, app etc...)
  const handleClickModifyResourceAccess =
    (targetResource, hasAccess) => async (event) => {
      // I have roleId/packageId and functionId
      event.stopPropagation();
      event.preventDefault();
      // Check first level of treeConfig prop to determine if the topLevel is role or package
      const isRoleTree = treeConfig[0]?.resourceName === "roles";
      // Corresponds to roleId or packageId and roleName or packageName
      const { topLevelParentResourceId, resourceName, id } = targetResource;
      const singularResourceName = singular(resourceName);

      const accessPayload = {
        ...(isRoleTree
          ? { roleId: topLevelParentResourceId }
          : { packageId: topLevelParentResourceId }),
        [singularResourceName]: { id, hasAccess },
      };
      resourceName === "features"
        ? await handleModifyResourceAccess(
          accessPayload,
          hasAccess,
          targetResource
        )
        : await handleModifyResourceAccess(accessPayload, hasAccess, null);
    };

  const handleClickDelete = (targetResource) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedAsset(targetResource);
    setIsDeleteDialogOpen(true);
  };

  const handleClickEdit = (targetResource) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    // strip out the config with JSX cannot be sent through JSON
    // extra fields from config sent are ignored
    var selected = pick(targetResource, [
      "description",
      "id",
      "createdAt",
      "updatedAt",
      "name",
      "identifier",
      "fetchEndpoint",
      "resourceName",
      "inputFields",
      "parentResourceIdKey",
    ]);
    const { resourceName } = selected;
    if (resourceName !== treeConfig[0].resourceName) {
      selected[targetResource?.parentResourceIdKey] =
        targetResource[targetResource?.parentResourceIdKey];
    }
    setSelectedAsset(selected);
    setIsEditDialogOpen(true);
  };

  // !!!!Specifically for nested create operations
  const handleClickCreate = (targetResource) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    let selected;
    const { childResourceUrl, id, identifier } = targetResource;
    setIsCreateDialogOpen(true);
    const childResourceConfig = treeConfig.find(
      (config) => config?.fetchEndpoint === childResourceUrl
    );
    // Put identifier as parent resource that you want to add the
    // created resource to. The childResourceConfig is to
    // correctly display the create modal config
    selected = { ...childResourceConfig, identifier };
    selected[selected?.parentResourceIdKey] = id;
    // Need the parent resources id,
    setSelectedAsset(selected);
  };

  const addChildResources = async (
    resourceArray,
    targetedResourceIdentifier,
    childResourceConfig,
    childResourceData,
    // For roles and packages, to indicate which role or packages resource to expand
    // Not used for (suites, apps etc...)
    parentResourceName = null
  ) => {
    // Matches the childResourceUrl in the parent resource
    const { fetchEndpoint } = childResourceConfig;
    // Check if this level of resources has a childResourceUrl that
    // matches fetchEndpoint. If so this is the correct level to
    // search for the targetedResourceIdentifier and add to its childResources array

    const [firstItem] = resourceArray;
    const { childResourceUrl = null } = firstItem;
    // Check next level of tree
    if (childResourceUrl !== fetchEndpoint) {
      // Filter out the undefined from not expanded branches
      const nextTreeLevel = resourceArray
        .map(({ childResources }) => {
          return childResources;
        })
        // So its not an array with an array in it
        .flat(1)
        // truthy check
        .filter((item) => item)
        // Remove loading placeholders
        .filter(({ name }) => name !== "Loading");
      // Packages and Roles will have duplicate resources (suites,apps etc...)
      // Need to be able to determine the correct "set" of resources to search,
      // as the initial arg pass in resourceArray as only the child resources of
      // of the targeted role/package
      await addChildResources(
        nextTreeLevel,
        targetedResourceIdentifier,
        childResourceConfig,
        childResourceData,
        parentResourceName
      );
    } else {
      // Don't add skeleton loading screens to the final level of the tree
      const isTreeAtFinalLevel =
        treeConfig[treeConfig.length - 1].resourceName ===
        childResourceConfig.resourceName;

      // Search current level and add data with config to childResources
      const parentResource = resourceArray.find(
        ({ identifier, name, topLevelParentResourceName }) => {
          // If parentResourceName param is present this is a nested add on roles or packages.
          // In this case we need the specific roleName or packageName (parentResourceName) AND
          // the targetResourceIdentifer to find the correct resource
          if (!parentResourceName) {
            return (
              // For suites, apps, modules... anything w/ identifier
              identifier === targetedResourceIdentifier ||
              // For roles and packages
              targetedResourceIdentifier === name
            );
          } else {
            // For nested add operations on roles and suites
            return (
              identifier === targetedResourceIdentifier &&
              parentResourceName === topLevelParentResourceName
            );
          }
        }
      );
      // For roles and packages once you add the feature level you need
      // to determine the access for each feature. We can make that call here
      //Ex: GET https://api.nationsafedrivers.com/cs/dev/featureAssignments?functionId=168&roleId=37
      /**
       * Steps:
       * 1) Check if this is the final level of the tree
       * 2) If so pull out functionId from any item in childDataArray
       * 3) Pull out roleId or packageId from parentResource as topLevelParentResourceId
       * 4) Construct the URL and make the call, it will return a payload array
       *    featureAccess: [] // has feature Ids
       * 5) Map over childDataArray and add field called hasAccess (bool) depending on
       *   if that feature id is in the featureAccess arr from step 4
       * 6) Use that field (hasAccess) to determine the uncontrolled state of
       * the switch during this particular re-render
       *
       *
       *  How about flipping the swtich?
       *     -> Do addChildResources
       *      - ChildConfig is features
       *      - childResource Data is the features again
       *      - Will refetch /featureAssignments to determine correct level
       */
      let featureAccessArray;
      if (isTreeAtFinalLevel) {
        const isRoleTree = treeConfig[0]?.resourceName === "roles";
        const [finalLevelItem] = childResourceData;
        const { functionId } = finalLevelItem;
        const { topLevelParentResourceId } = parentResource;
        const determineFeatureAccess = await axios.get(
          `/featureAssignments?functionId=${functionId}&${isRoleTree ? "roleId" : "packageId"
          }=${topLevelParentResourceId}`
        );
        featureAccessArray = determineFeatureAccess?.data?.featureAccess;
      }

      const isNestedLevelAdd =
        parentResource.resourceName !== "roles" ||
        parentResource.resourceName !== "packages";

      // Need to modify the child Resources so it uniquely identifies
      //the particular role/package it belongs to

      // hasAccess only added if...
      // tree is at final level, this is a roles/package tree,
      const isRoleOrPackageTree =
        treeConfig[0]?.resourceName === "roles" ||
        treeConfig[0]?.resourceName === "packages";
      const childDataArray = childResourceData.map((resource) => {
        return {
          ...resource,
          ...childResourceConfig,
          // Add the skeleton loader as the intial child of newly propagated
          // resources
          ...(isTreeAtFinalLevel
            ? {}
            : {
              childResources: [{ name: "Loading", resourceName: "Loading" }],
            }),
          ...(isTreeAtFinalLevel && isRoleOrPackageTree
            ? {
              hasAccess: featureAccessArray.includes(resource.id),
              // Need to refresh the feature
              functionParentIdentifier: parentResource.identifier,
            }
            : {}),
          // First time get from parent resource other times
          // from parentResource
          ...(isNestedLevelAdd
            ? {
              topLevelParentResourceId:
                parentResource.topLevelParentResourceId || parentResource.id,
              topLevelParentResourceName:
                parentResource.topLevelParentResourceName ||
                parentResource.name,
            }
            : {}),
          // Parent id already returned
          // parentResourceId: parentResource?.id,
        };
      });
      // Create key here
      parentResource.childResources = [...childDataArray];
    }
    // Should have updated by now
    return resourceArray;
  };

  const syncLocalState = (
    resourceArray,
    targetedResourceIdentifier,
    childResourceConfig,
    childResourceData = null,
    operation
  ) => {
    const isTreeAtTopLevel =
      treeConfig[0].resourceName === childResourceConfig.resourceName;

    // Top level resource create case
    if (isTreeAtTopLevel && operation === "create") {
      resourceArray.push({
        ...childResourceConfig,
        ...childResourceData,
        childResources: [{ name: "Loading", resourceName: "Loading" }],
      });
      return resourceArray;
    }

    // Top Level delete resource case; selectedAsset state has
    // resource to delete
    if (isTreeAtTopLevel && operation === "delete") {
      const targetDeleteResourceIndex = resourceArray.findIndex(
        ({ identifier = null, name }) =>
          identifier === selectedAsset?.identifier ||
          selectedAsset?.name === name
      );
      resourceArray.splice(targetDeleteResourceIndex, 1);
      return resourceArray;
    }

    const { fetchEndpoint } = childResourceConfig;
    const [firstItem] = resourceArray;
    const {
      childResourceUrl: firstItemChildResourceUrl,
      fetchEndpoint: firstItemFetchEndpoint,
    } = firstItem;

    if (
      (firstItemChildResourceUrl !== fetchEndpoint && operation === "create") ||
      (firstItemFetchEndpoint !== fetchEndpoint && operation === "edit") ||
      (firstItemChildResourceUrl !== fetchEndpoint && operation === "delete")
    ) {
      const nextTreeLevel = resourceArray
        .map(({ childResources }) => {
          return childResources;
        })
        .flat(1)
        .filter((item) => item)
        .filter(({ name }) => name !== "Loading");
      syncLocalState(
        nextTreeLevel,
        targetedResourceIdentifier,
        childResourceConfig,
        childResourceData,
        operation
      );
    } else {
      if (operation === "create") {
        // Specifically for nested resource creates

        // This is the correct level search for the parent to add to
        const parentResource = resourceArray.find(
          ({ identifier, name }) =>
            identifier === targetedResourceIdentifier ||
            targetedResourceIdentifier === name
        );

        const isTreeAtFinalLevel =
          treeConfig[treeConfig.length - 1].resourceName ===
          childResourceConfig.resourceName;

        // childResourceData is an object (the resource to be added)
        const resourceToBeAdded = {
          ...childResourceData,
          ...childResourceConfig,
          ...(isTreeAtFinalLevel
            ? {}
            : {
              childResources: [{ name: "Loading", resourceName: "Loading" }],
            }),
        };
        parentResource.childResources.push(resourceToBeAdded);
      } else if (operation === "edit") {
        const targetEditResource = resourceArray.find(
          ({ identifier = null, name }) =>
            identifier === targetedResourceIdentifier ||
            targetedResourceIdentifier === name
        );
        const isTreeAtFinalLevel =
          treeConfig[treeConfig.length - 1].resourceName ===
          childResourceConfig.resourceName;

        const newlyEditedObject = {
          ...childResourceData,
          ...childResourceConfig,
          // Only add loading skeleton if its not the the last tree level
          // and there are not already child resources
          ...(isTreeAtFinalLevel || targetEditResource.childResources
            ? {}
            : {
              childResources: [{ name: "Loading", resourceName: "Loading" }],
            }),
        };
        Object.assign(targetEditResource, newlyEditedObject);
      } else if (operation === "delete") {
        // Specifically for nested resource deletes
        // At the correct level to find the parent...
        // Lets find the partent identifier
        const targetDeleteResourceParent = resourceArray.find((currentItem) => {
          let correctChildResources = currentItem.childResources.find(
            ({ identifier, name }) =>
              identifier === targetedResourceIdentifier ||
              targetedResourceIdentifier === name
          );
          // This is the correct parent
          return correctChildResources ? true : false;
        });
        const deleteTargetIndex =
          targetDeleteResourceParent.childResources.findIndex(
            ({ identifier, name }) =>
              identifier === targetedResourceIdentifier ||
              targetedResourceIdentifier === name
          );

        targetDeleteResourceParent.childResources.splice(deleteTargetIndex, 1);
        return resourceArray;
      } else {
        throw Error(
          'Please specify one of the 3 values "create", "edit" , or "delete" for the operation parameter '
        );
      }
    }
    // Should have updated by now
    return resourceArray;
  };

  const generateTreeStructure = (nodes, idx) => {
    nodeIndexCounter++;
    const {
      actions,
      Icon,
      childResourceUrl,
      childResourceParams,
      resourceName,
      rowColor = "inherit",
    } = nodes;

    return (
      <React.Fragment
        key={`${nodes?.name}-${resourceName}-${nodeIndexCounter}`}
      >
        {idx === 0 && nodes?.name !== "Loading" && (
          <React.Fragment>
            <TreeItem
              nodeId={String(
                nodes?.name + "-" + resourceName + nodeIndexCounter
              )}
              label={
                <>
                  <Typography variant="h2" className={classes.primaryTitle}>
                    {resourceName}
                  </Typography>{" "}
                  {/* This is exclusively for the top level resource */}
                  {nodes?.resourceName === treeConfig[0].resourceName ? (
                    <NewButton
                      title={singular(resources[0].resourceName)}
                      onClick={() => {
                        setIsCreateDialogOpen(true);
                        // Just spread in the config for top level;
                        // will be merged with the returned payload object
                        setSelectedAsset({ ...treeConfig[0] });
                      }}
                    ></NewButton>
                  ) : null}
                </>
              }
              className={classes.treeItemHeader}
            />
          </React.Fragment>
        )}
        {idx === 0 && nodes?.name === "Loading" ? (
          <Skeleton
            animation="pulse"
            variant="rect"
            height={"3.5rem"}
            style={{ margin: "0.5rem 0.5rem 0.5rem 0.5rem" }}
          />
        ) : (
          <TreeItem
            key={nodes?.id + nodes?.name}
            className={classes.treeItem}
            nodeId={String(nodes?.name + nodeIndexCounter)}
            style={{
              background: rowColor,
            }}
            onClick={async (e) => {
              // Already fetched child resources
              if (!nodes?.childResourceUrl) return;
              // Check if it has resouces already, not the skelton loader
              if (
                nodes?.childResources &&
                nodes?.childResources.length > 0 &&
                nodes?.childResources[0].name !== "Loading"
              )
                return;
              try {
                // Pull required params out of current resource
                let fetchParams = {};
                // Are there params needed for the next level of fetching
                if (nodes?.childResourceParams) {
                  for (const [key, value] of Object.entries(
                    childResourceParams
                  )) {
                    if (nodes?.[value]) fetchParams[key] = nodes?.[value];
                  }
                }
                // Get childResponseKey from searching the config array
                const childResourceConfig = treeConfig.find(
                  (config) => config?.fetchEndpoint === childResourceUrl
                );
                // Afterwards search the config array to find the appropriate
                // next config to spread into the resources
                const { fetchResponseKey, resourceName: childResourceName } =
                  childResourceConfig;
                const { data } = await axios.get(childResourceUrl, {
                  params: { ...fetchParams },
                });
                const rawData = data[fetchResponseKey];
                // If there are no childResources
                if (!rawData || rawData?.length <= 0) {
                  enqueueSnackbar(
                    `${getUpperCaseAndSingular(resourceName)} ${nodes?.name
                    } doesn't have any ${childResourceName}`,
                    {
                      variant: "info",
                    }
                  );
                }

                const resourcesCopy = resources.slice();
                // Take the array of children resources and add it to
                // the childrenResources of the currentNode
                const updatedTreeResources = await addChildResources(
                  resourcesCopy,
                  nodes?.identifier || nodes?.name,
                  childResourceConfig,
                  rawData,
                  nodes?.topLevelParentResourceName
                );
                setResources(updatedTreeResources);
              } catch (error) {
                enqueueSnackbar(
                  `Unable to fetch child resources. Error: ${error?.message}`,
                  {
                    variant: "error",
                  }
                );
                console.error(error);
              }
            }}
            label={
              <div className={classes.labelRoot}>
                {Icon}
                <Typography variant="body2" className={classes.labelText}>
                  {nodes?.name}
                </Typography>
                <div className={classes.infoContainer}>
                  {nodes?.description && (
                    <Tooltip
                      title={nodes?.description}
                      arrow
                      classes={{
                        tooltip: classes.descriptionToolTip,
                      }}
                    >
                      <div className={classes.topCaption}>
                        <Typography variant="h6" color="inherit">
                          Description
                        </Typography>
                        <Typography
                          variant="caption"
                          color="inherit"
                          className={classes.description}
                        >
                          {nodes?.description}
                        </Typography>
                      </div>
                    </Tooltip>
                  )}
                  <div className={classes.topCaption}>
                    <Typography variant="h6" color="inherit">
                      Created At
                    </Typography>
                    <Typography variant="caption" color="inherit">
                      {nodes?.createdAt &&
                        format(parseISO(nodes?.createdAt), "M/d/y h:mm aaa")}
                    </Typography>
                  </div>

                  <div className={classes.topCaption}>
                    <Typography variant="h6" color="inherit">
                      Updated At
                    </Typography>
                    <Typography variant="caption" color="inherit">
                      {nodes?.updatedAt &&
                        format(parseISO(nodes?.updatedAt), "M/d/y h:mm aaa")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.actionsContainer}>
                  {actions({
                    resourceObj: nodes,
                    setSelectedAsset,
                    handleClickDelete,
                    handleCopyIdentifier,
                    selectedIdentifier,
                    handleClickEdit,
                    handleClickCreate,
                    handleClickModifyResourceAccess,
                  })}
                </div>
              </div>
            }
          >
            {Array.isArray(nodes?.childResources)
              ? nodes?.childResources.map((node, idx) => {
                return generateTreeStructure(node, idx);
              })
              : null}
          </TreeItem>
        )}
      </React.Fragment>
    );
  };
  const generateInitialBranches = () => {
    nodeIndexCounter = 0;
    if (resources.length <= 0) return;
    return resources.map((resource, idx) => {
      return generateTreeStructure(resource, idx);
    });
  };

  if (!treeConfig || treeConfig?.length <= 0) return null;

  return (
    <>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        disableSelection
      >
        {generateInitialBranches()}
      </TreeView>
      {isCreateDialogOpen && (
        // use onSubmit callback to sync edit and create state
        <CreateUpdateDialog
          size={"lg"}
          model={singular(selectedAsset?.resourceName)}
          url={selectedAsset?.fetchEndpoint}
          open={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onExited={() => setSelectedAsset(null)}
          validationSchema={Yup.object().shape(
            generateInputValidationSchema(selectedAsset?.inputFields)
          )}
          /* TODO: Change the hardcoded "suite" comparision */
          params={
            singular(selectedAsset?.resourceName) !== "suite"
              ? {
                [selectedAsset["parentResourceIdKey"]]:
                  selectedAsset[selectedAsset?.parentResourceIdKey],
              }
              : {}
          }
          onSubmit={handleCreate}
          fields={selectedAsset?.inputFields}
          selected={null}
        />
      )}
      {isEditDialogOpen && (
        <CreateUpdateDialog
          size={"lg"}
          model={singular(selectedAsset?.resourceName)}
          url={selectedAsset?.fetchEndpoint}
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onExited={() => setSelectedAsset(null)}
          validationSchema={Yup.object().shape(
            generateInputValidationSchema(selectedAsset?.inputFields)
          )}
          // ES6 use obj value as key
          params={
            singular(selectedAsset?.resourceName) === "suite"
              ? {
                [selectedAsset["parentResourceIdKey"]]:
                  selectedAsset[selectedAsset?.parentResourceIdKey],
              }
              : {}
          }
          onSubmit={handleEdit}
          fields={selectedAsset?.inputFields}
          selected={selectedAsset}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          title={`Delete ${getUpperCaseAndSingular(
            selectedAsset?.resourceName
          )} ${selectedAsset?.name} ?`}
          subTitle={`Are you sure you want to delete this ${getUpperCaseAndSingular(
            selectedAsset?.resourceName
          )}? You won't be able to undo this action.`}
          confirmText={`Delete   ${getUpperCaseAndSingular(
            selectedAsset?.resourceName
          )}`}
          cancelText="Don't Delete"
          onConfirm={handleDelete}
          onCancel={handleCancelDelete}
          onClose={handleCancelDelete}
          onExited={() => setSelectedAsset(null)}
          open={isDeleteDialogOpen}
        />
      )}
    </>
  );
};

AssetsTreeView.propTypes = {
  /**
   * Array of configuration objects to config how the Tree
   * expands and what resources it fetches when advancing to
   * the next level down the tree (customize actions, icons, etc..).
   * First item in the config corresponds to the intially fetched resource
   *
   * You must create an entry in the treeConfig array for
   * each level you wish your tree to expand to. For
   * the base example suites->apps->modules->functs->features
   * would require an array of 5 objects.
   *
   * actions: Can be a JSX element or a callback. The callback
   * is invoked with 4 arguments
   *   ({
   *    resourceObj,
   *    setSelectedAsset,
   *    handleClickDelete,
   *    ,
   *  })
   * and should return JSX.
   *
   * Icons: JSX icon to set the icon displayed next
   * to the resource in the tree
   *
   * childResourceUrl: When the current resource tree branch
   * is expaneded what endpoint should the data be fetched from
   *
   * childResourceParams: An object with key names corresponding to
   * the actual key name you send in as query params and the value
   * being a string that corresponds to an  existing key on
   * your current resource (id, createdAt, name, description etc...).
   * The value will be used to pull the request key out of the object
   * and use it query parameters
   *
   *
   * inputFields: Config for generating a Form Modal
   * with a variety of different <input /> elements. Used
   * to populate the fields of the create and edit modals
   * for the current resource
   *    Note: Validation is a Yep object
   *
   * fetchResponseKey: Is what key the data for your current resource is under after
   * successfully completing an axios.get() request. The default is the name of the resource
   * but it may differ API to API
   *
   *
   */
  treeConfig: PropTypes.arrayOf(
    PropTypes.shape({
      fetchEndpoint: PropTypes.string.isRequired,
      fetchResponseKey: PropTypes.string.isRequired,
      resourceName: PropTypes.string.isRequired,
      actions: PropTypes.func,
      Icon: PropTypes.element,
      childResourceUrl: PropTypes.string,
      childResourceParams: PropTypes.object,
      // ex: colors.red[200] also works; its a hex string
      rowColor: PropTypes.string,
      inputFields: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
          options: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
              label: PropTypes.string.isRequired,
              value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            })
          ),
          validation: PropTypes.object,
        })
      ),
    })
  ),
};

export default AssetsTreeView;
