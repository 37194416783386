import React from "react";
import PropTypes from "prop-types";
import { Check as CheckIcon } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import Axios from "axios";
import authService from "../services/authService";

/**
 * Dialog component meant to be displayed when icons on
 * the CrudTablePage are clicked. Brings up a dialog
 * that forces a password change the next time the specified user
 * (selectedUser) logins in.
 * Submits a PUT request to '/resetPassword/:id' to reset password
 * onCancel and onClose have the same functionality (just like delete modal
 * which is an instance of ConfirmationDialog)
 */
const ResetPasswordDialog = ({ open, onClose, onExited, selectedUser }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { firstName, lastName, id, email } = selectedUser || {};

  const handleResetPassword = async () => {
    try {
      if (email) {
        await Axios.put(
          `${process.env.REACT_APP_COMMON_SERVICES_API}/resetPassword?userId=${id}`,
          {},
          {
            headers: {
              "Authorization": `Bearer ${authService.getAccessToken()}`,
              "Ocp-Apim-Subscription-Key": process.env.REACT_APP_NSD_API_KEY,
            },
          }
        );
      }
      enqueueSnackbar(
        `Success: a confirmation email will be sent to "${selectedUser?.email}"`,
        { variant: "success" }
      );
      onClose();
    } catch (error) {
      enqueueSnackbar("Unable to send password change request", {
        variant: "error",
      });
      console.error({ error });
    }
  };

  return (
    <ConfirmationDialog
      confirmText="Send Request"
      onClose={onClose}
      onCancel={onClose}
      onExited={onExited}
      open={open}
      title="Password Change Request"
      subTitle={`User "${firstName} ${lastName}" will be forced to change password on next login attempt`}
      maxWidth="md"
      ConfirmIcon={<CheckIcon />}
      cancelText="Cancel"
      onConfirm={handleResetPassword}
    />
  );
};

ResetPasswordDialog.propTypes = {
  /**
   * Weather or not the dialog is open
   */
  open: PropTypes.bool.isRequired,

  /**
   * Callback to run when the dialog cancel button is clicked;
   * takes no arguments
   */
  onClose: PropTypes.func,

  /**
   * Callback to run when the dialog is completely exited off
   * the screen; takes no arguments
   */
  onExited: PropTypes.func,

  /**
   * Selected user object representing the user whose
   * password will get reset; you just need the id & email
   * for this particular component
   */
  selectedUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    createdAt: PropTypes.string,
    admin: PropTypes.bool,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
};

ResetPasswordDialog.defaultProps = {
  onClose: () => { },
  onExited: () => { },
  selectedUser: {},
  open: false,
};

export default ResetPasswordDialog;
