import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const DEFAULT_SIZE = 60;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.action.loading,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  absolutePosition: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  positionFixed: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

/**
 * Circular loader component
 */
const CircularLoader = ({ className, size, position }) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(
        className,
        classes.root,
        position
          ? position === "absolute"
            ? classes.absolutePosition
            : classes.positionFixed
          : ""
      )}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

CircularLoader.propTypes = {
  /**
   * Class name you wish to apply to the container around Loader
   */
  className: PropTypes.string,
  /**
   * Size of the circle loader (height and width the same)
   */
  size: PropTypes.number,
  /**
   * Weather or not to apply the fixed or absolute position, or neither
   */
  position: PropTypes.oneOf(["absolute", "fixed", ""]),
};

CircularLoader.defaultProps = {
  className: "",
  size: DEFAULT_SIZE,
  position: "",
};

export default CircularLoader;
