const camelcaseKeys = require('camelcase-keys');
const each = require('lodash/each');

const camelCaseArray = function(inArr) {
  let arr = inArr;
  each(arr, (val, key) => {
    if (Array.isArray(val)) {
      arr[key] = camelCaseArray(val);
    } else {
      arr[key] = camelCaseObject(val);
    }
  });
  return arr;
};

const camelCaseObject = function(inObj) {
  let obj = camelcaseKeys(inObj);
  if (obj) {
    let keys = Object.keys(obj);
    each(keys, (key) => {
      if (Array.isArray(obj[key])) {
        obj[key] = camelCaseArray(obj[key]);
      } else if (obj[key] && typeof obj[key] === 'object') {
        obj[key] = camelCaseObject(obj[key]);
      }
    });
  }
  return obj;
};

export default function(obj) {
  if (Array.isArray(obj)) {
    return camelCaseArray(obj);
  } else {
    return camelCaseObject(obj);
  }
}
