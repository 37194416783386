import accountReducer from "src/reducers/accountReducer";
import applicationReducer from "src/reducers/applicationReducer";
import applicationsReducer from "src/reducers/applicationsReducer";
import systemAlertReducer from "src/reducers/systemAlertReducer";
import notificationsReducer from "src/reducers/notificationsReducer";

export const DEFAULT_REDUCERS = {
  account: accountReducer,
  application: applicationReducer,
  alerts: systemAlertReducer,
  notifications: notificationsReducer,
  // This may not be used
  applications: applicationsReducer,
};
