import { Close as CloseIcon } from "@material-ui/icons";

export const SHOW_ALERT = "@alert/show-alert";
export const HIDE_ALERT = "@alert/hide-alert";
export const UPDATE_ALERT_QUEUE = "@alert/update-alert-queue";
export const ADVANCE_ALERT_QUEUE = "@alert/advance-alert-queue";
export const SET_ALERT_SETTINGS = "@alert/set-alert-settings";

/*
  Alert shape
  {
    to: '', //Leave to blank to send to all users
    from: '',
    read: false,
    text: '',
    severity: ['info' | 'warning' | 'error' | 'success'],
    // Relative or absolute link
    pageLink: "/somePlace"
  }  
 */

const DEFAULT_STATE = {
  open: false,
  alertsQueue: [],
  currentAlert: null,
  // Could be an icon or text (any node)
  actionButtonContent: CloseIcon,
  startIcon: null,
  severity: "info",
  // standard or filled
  variant: "filled",
  // top, or bottom
  position: "top",
};

export const setAlertSettings = (settingsObject = DEFAULT_STATE) => ({
  type: SET_ALERT_SETTINGS,
  payload: {
    ...settingsObject,
  },
});

export const showAlert = () => ({
  type: SHOW_ALERT,
  payload: {},
});

export const updateAlertQueue =
  (newAlerts, readDocId) => async (dispatch, getState) => {
    // Combine them and filter any duplicates or any marked as read
    const { currentAlert } = getState().alerts;
    const newQueue = [...getState().alerts?.alertsQueue, ...newAlerts];
    let temp;
    if (readDocId) {
      // Remove from array
      temp = newQueue.filter((alert, index) => alert?.docId !== readDocId);
    } else {
      temp = newQueue;
    }
    // Filter duplicates; issue keeps duplicate where read = false
    // Then remove any read = true
    const final = temp
      .filter(
        (alert, index, arr) =>
          index === arr.findIndex((t) => t.docId === alert.docId)
      )
      .filter((alert) => alert?.read === false);

    dispatch({
      type: UPDATE_ALERT_QUEUE,
      payload: { newAlerts: [...final] },
    });

    if (!currentAlert && final.length)
      dispatch(showAlert({ ...DEFAULT_STATE }));
  };

export const clearAlert = () => ({ type: HIDE_ALERT });

/*
  Alert should only be marked as read when cleared; 
  either through autoHide or through the user's click.
  Since our Firebase interactions are done through a class
  instance in a Provider we must pass in the instance as well

  Can you dispatch other actions from a thunk action? Yes
  https://github.com/reduxjs/redux/issues/1738
*/

export const advanceAlertQueue =
  (firebaseCrud) => async (dispatch, getState) => {
    const { alerts } = getState();
    const currentAlert = alerts?.currentAlert;
    dispatch(clearAlert());
    if (!currentAlert) return;
    // Mark as read and let reducer purge the duplicates and
    // alerts marked read
    const { docId } = currentAlert;
    const updatedArray = [...alerts?.alertsQueue];
    const readAlert = { ...currentAlert, read: true };
    updatedArray[0] = readAlert;
    await dispatch(updateAlertQueue(updatedArray, docId));
    await firebaseCrud.updateOne(firebaseCrud.getAlertsPath(), docId, {
      ...readAlert,
    });
  };
