import React from "react";
import PropTypes from "prop-types";
import { List, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Comment from "./Comment";

const useStyles = (maxWidth, fullWidth) =>
  makeStyles((theme) => ({
    root: {
      margin: "0 auto",
      width: fullWidth ? "100%" : "auto",
      [theme.breakpoints.up(maxWidth)]: {
        width: "100%",
        maxWidth: theme.breakpoints.values[maxWidth],
      },
    },
  }));

/**
 * Component to display a list of comments. Maps comments from props
 * and outputs a Comment component for each
 */
const Comments = ({
  comments,
  maxWidth,
  fullWidth,
  divider,
  simpleDate,
  bubbleStyle,
}) => {
  const classes = useStyles(maxWidth, fullWidth)();
  return (
    <List className={classes.root}>
      {comments.map((comment, idx) => {
        return idx !== comments.length - 1 && divider ? (
          <React.Fragment key={comment.id}>
            <Comment
              comment={comment}
              simpleDate={simpleDate}
              bubbleStyle={bubbleStyle}
            />
            <Divider></Divider>
          </React.Fragment>
        ) : (
          <Comment comment={comment} key={comment.id} />
        );
      })}
    </List>
  );
};

Comments.propTypes = {
  /**
   * Array of comments to be mapped over and displayed in each <Comment/> component
   */
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      createdDate: PropTypes.number,
      author: PropTypes.shape({
        displayName: PropTypes.string,
        avatar: PropTypes.string,
      }),
      message: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  /**
   * Boolean indicating weather or not you for the comment section to take up its full
   * available width
   */
  fullWidth: PropTypes.bool,
  /**
   * Type to set the max-width of the List (a ul) component containing all the comments
   */
  maxWidth: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]),
  /**
   * Boolean to indicate if each comments should have a dividing
   * line separating them
   */
  divider: PropTypes.bool,
  /**
   * Class name to be passed as a prop to each singlular Comment
   * component to customize it
   */
  singleCommentClassName: PropTypes.string,
  /**
   * Boolean indicating if you want the displayed date to be
   * simplified or a full date
   */
  simpleDate: PropTypes.bool,
  /**
   * Boolean indicating if you want the comments to have a bubble
   * style
   */
  bubbleStyle: PropTypes.bool,
};

Comments.defaultProps = {
  comments: [],
  maxWidth: "xl",
  fullWidth: true,
  divider: true,
  singleCommentClassName: "",
  simpleDate: true,
  bubbleStyle: true,
};

export default Comments;
