import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

/**
 * Custom Microsoft logo made into an SVG component
 */
const MicrosoftLogo = ({ className, title, ...rest }) => {
  return (
    <SvgIcon
      className={className}
      titleAccess={title}
      viewBox="0 0 21 21"
      {...rest}
    >
      <path fill="#f35325" d="M0 0h10v10H0z" />
      <path fill="#81bc06" d="M11 0h10v10H11z" />
      <path fill="#05a6f0" d="M0 11h10v10H0z" />
      <path fill="#ffba08" d="M11 11h10v10H11z" />
    </SvgIcon>
  );
};

MicrosoftLogo.propTypes = {
  /**
   * Custom class name to apply to the outermost element container
   */
  className: PropTypes.string,
  /**
   * Title of SVG that is displayed when hovered; necessary
   * for accessibility
   */
  title: PropTypes.string.isRequired,
};

MicrosoftLogo.defaultProps = {
  className: "",
  title: "Microsoft Logo",
};

export default MicrosoftLogo;
