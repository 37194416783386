import { useEffect, useRef } from "react";

/**
 * A hook which returns the previous value after the next value is applied.
 *
 * @param {any} value
 * @return {any} The previous value
 */
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default usePrevious;
