import React, { useMemo } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

export default function SVGLandRegion({
  landRegionCode,
  textOverlay,
  selected,
  dataMap,
  disabled,
  makeColor,
  classes,
  attrs,
  staticChildren: StaticChildren,
  countNumber = 0,
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  landRegionCountOffsetY = 0,
}) {
  // const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    onClick(landRegionCode);
    handleMouseLeave(landRegionCode);
  };

  const handleMouseEnter = () => {
    // setIsHovered(true);
    onMouseEnter(landRegionCode);
  };

  const handleMouseLeave = () => {
    // setIsHovered(false);
    onMouseLeave(landRegionCode);
  };

  /** @type {function} */
  const fillColor = useMemo(
    () => makeColor(countNumber),
    [makeColor, countNumber]
  );

  /** @type {number | string} */
  const formattedCountNumber = dataMap?.valueFormatter
    ? dataMap.valueFormatter(countNumber)
    : countNumber;

  return (
    <g
      {...(!disabled && {
        className: classes.pointer,
        onClick: handleClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        ...attrs,
      })}
    >
      <StaticChildren
        fill={fillColor}
        className={clsx(classes.landRegionPath, {
          [classes.deSelectedLandRegion]:
            disabled || !selected /* && !isHovered*/,
        })}
      />

      {textOverlay.rect && (
        <rect
          className={clsx(classes.landRegionPath, {
            [classes.deSelectedLandRegion]:
              disabled || !selected /* && !isHovered*/,
          })}
          fill={fillColor}
          width={textOverlay.rect.width}
          height={textOverlay.rect.height}
          x={textOverlay.rect.x}
          y={textOverlay.rect.y}
          d={textOverlay.path}
        />
      )}

      {textOverlay.transform && (
        <>
          <text
            className={clsx(classes.landRegionLabel, {
              [classes.pointer]: !disabled,
              [classes.defaultCursor]: disabled,
            })}
            transform={`translate(${textOverlay.transform.x}, ${textOverlay.transform.y})`}
          >
            {landRegionCode}
          </text>
          {!disabled && (
            <text
              className={classes.number}
              transform={`translate(${textOverlay.transform.x}, ${
                textOverlay.transform.y + landRegionCountOffsetY
              })`}
            >
              {!disabled && formattedCountNumber}
            </text>
          )}
        </>
      )}
    </g>
  );
}

SVGLandRegion.propTypes = {
  landRegionCode: PropTypes.string.isRequired,
  countNumber: PropTypes.number,
  textOverlay: PropTypes.shape({
    transform: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }).isRequired,
    rect: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  dataMap: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  staticChildren: PropTypes.func.isRequired,

  /** Called with landRegionCode as parameter */
  onMouseEnter: PropTypes.func.isRequired,

  /** Called with landRegionCode as parameter */
  onMouseLeave: PropTypes.func.isRequired,

  landRegionCountOffsetY: PropTypes.number,
};
