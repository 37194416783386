/* eslint-disable no-param-reassign */
import produce from "immer";

export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const SILENT_LOGIN = "@account/silent-login";
export const LOGOUT = "@account/logout";
export const REGISTER = "@account/register";
export const UPDATE_PROFILE = "@account/update-profile";

const initialState = {
  appSettings: null,
  user: null,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const {
        user,
        appSettings,
        roles,
        clientQoreCompanyCode = null,
      } = action.payload;
      const userData = {
        ...user,
        roles,
      };
      return produce(state, (draft) => {
        draft.appSettings = appSettings;
        draft.user = userData;
        draft.clientQoreCompanyCode = clientQoreCompanyCode;
      });
    }

    case LOGIN_FAILURE: {
      const { error } = action.payload || {};
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const {
        user,
        appSettings,
        roles,
        clientQoreCompanyCode = null,
      } = action.payload;
      const userData = {
        ...user,
        roles,
      };
      return produce(state, (draft) => {
        draft.appSettings = appSettings;
        draft.user = userData;
        draft.clientQoreCompanyCode = clientQoreCompanyCode;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;
      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
