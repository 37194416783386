import React from 'react';
import ErrorPage from 'src/components/ErrorPage';
import PropTypes from 'prop-types';

const SomethingWentWrongView = ({ message, ...rest }) => {

  const serverDownIcon = 'https://nsdstaticasset.blob.core.windows.net/assets/Platform_Status_Images/500.png';
  return (
    <ErrorPage
      title='Something Went Wrong'
      // header='Server Error'
      message={message}
      imgSrc={serverDownIcon}
      imgAlt='Something Went Wrong'
      {...rest}
    />
  );
};

SomethingWentWrongView.propTypes = {
  message: PropTypes.string
};

SomethingWentWrongView.defaultProps = {
  // message: `Please take a screenshot with the current url and report
  // this to us so we can fix it. Be as detailed as possible and explain
  // what you did right before this error.`
};

export default SomethingWentWrongView;
