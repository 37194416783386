import React from "react";
import { useFetch } from "src/hooks/useFetch";
import PropTypes from "prop-types";

/**
 * HOC component to be used with the apex chart library and
 * the CardChart component; responsible for fetching data
 * and forwarding it as a prop to the ChartCard component
 */
export default (ChartCard) => {
  const ChartCardWithAsync = ({ src = {}, ...rest }) => {
    const { url, params } = src;
    const { isLoading, response } = useFetch(url, params);
    return <ChartCard {...rest} isLoading={isLoading} response={response} />;
  };
  ChartCardWithAsync.propTypes = {
    /**
     * Config object to determine where the data will be
     * fetched from along with a params object of key-val
     * pairs
     */
    src: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.object,
    }),
  };

  ChartCardWithAsync.defaultProps = {
    url: null,
    src: null,
  };
  return ChartCardWithAsync;
};
