import  React, { useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Higher order component to be used with charts from
 * react-chartjs-2; enables a download and print
 * functionality by passing down a ref to the wrapped component
 */
export default (Chart) => {
  const ChartWithPrintAndDownload = ({ chartTitle, ...rest }) => {
    const chartJsRef = useRef();
    const downloadRef = useRef();
    const getBase64Image = () =>
      chartJsRef.current.canvas.toDataURL("image/jpg");

    useImperativeHandle(downloadRef, () => ({
      download: () => {
        const a = document.createElement("a");
        a.href = getBase64Image();
        a.download = `${chartTitle || "chart"}.png`;
        a.click();
      },
      print: () => {
        const newWindow = window.open("", "", "width=100, height=100");
        const document = newWindow.document.open();
        const pageContent = `
        <!DOCTYPE html>
          <html>
            <head>
              <meta charset="utf-8" />
              <style type="text/css">
                body { 
                  -webkit-print-color-adjust: exact;
                  font-family: Arial;
                  text-align: center; 
                }
                img { width: ${Chart.name === "PieChart" ? "50%" : "100%"} }
                .labels { 
                  display:flex;
                  justify-content: center;
                }
                h4 {
                  margin-bottom: 5px;
                }
                .stat {
                  margin: 0px 25px;
                }
              </style>
            </head>
            <body>
              <h4>${chartTitle}</h4>
              <img src="${getBase64Image()}" />
              ${
                Chart.name === "PieChart"
                  ? `<div class="labels">
                  ${rest.data.labels.map((label, i) => {
                    return `<div class="stat"><h4>${rest.data.datasets[0].data[i]}%</h4><span>${label}</span></div>`;
                  })}
                </div>`
                  : ""
              }
            </body>
          </html>
      `;
        document.write(pageContent);
        document.close();
        newWindow.moveTo(0, 0);
        newWindow.resizeTo(window.screen.width, window.screen.height);
        setTimeout(() => {
          newWindow.print();
          newWindow.close();
        }, 250);
      },
    }));

    return (
      <Chart chartJsRef={chartJsRef} downloadRef={downloadRef} {...rest} />
    );
  };

  ChartWithPrintAndDownload.propTypes = {
    /**
     * Title of the downloaded chart file
     */
    title: PropTypes.string,
  };

  return (props) => <ChartWithPrintAndDownload {...props} />;
};
