import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import mexicoStates from "./mexicoStates";
import PropTypes from "prop-types";
import DynamicMap from "../common/components/DynamicMap";
// Mexican map states: https://en.wikipedia.org/wiki/File:Mexican_States_with_mafia_conflicts.png
// Mexican map state codes: https://en.wikipedia.org/wiki/Template:Mexico_State-Abbreviation_Codes
import svgUrl from "./Mexico_blank_map.svg";

const useMexicoStyles = makeStyles(() => ({
  // i.e. "Claim Count"
  number: {
    textAnchor: "middle",
    fill: "white",
    fontSize: 16,
  },
  landRegionLabel: {
    textAnchor: "middle",
    fill: "white",
    fontSize: 16,
  },
}));

export default function MexicoMap({ ...rest }) {
  return (
    <DynamicMap
      useCountryMapStyles={useMexicoStyles}
      svgUrl={svgUrl}
      landRegionTextOverlayPositions={mexicoStates}
      landRegionCountOffsetY={20}
      innerMapOffsetX={30}
      innerMapScaling={0.6}
      {...rest}
    />
  );
}

MexicoMap.propTypes = {
  /**
   * Title displayed in legend above smaller land region squares.
   *
   * i.e. "States" or "Provinces" */
  legendLandRegionsTitle: PropTypes.string.isRequired,

  /** Called with the selected land regions, on change, passes
   * the selected state/province code as a single arguement
   */
  onChangeSelectedLandRegions: PropTypes.func.isRequired,

  /** Array of state/province codes you want pre-selected when
   * the map loads
   */
  selectedLandRegions: PropTypes.arrayOf(PropTypes.string),

  /**
   * Map configuration object that sets the legend title
   * and includes a function called valueFormatter which accepts a number as an argument
   * and returns a formated currency string (with $ appended)
   * (meant to indicate usd currency)
   */
  dataMap: PropTypes.shape({
    legend: PropTypes.string.isRequired,
    valueFormatter: PropTypes.func.isRequired,
  }).isRequired,

  /** @type {Object.<landRegionCode: string, count: number>}
   * Object with key name corresponding to the displayed
   * map's state/provice, with the value being the count
   * (whatever the unit may be).
   *
   * e.g.
   * const landRegionToCountMap = {
   *  NY: 1,
   *   NJ: 2,
   *  };
   */
  landRegionToCountMap: PropTypes.object.isRequired,

  /**
   * Vertical spacing between land region code and number text overlays
   * (default = 0)
   **/
  landRegionCountOffsetY: PropTypes.number,

  /**
   * Horizontal spacing of the inner map relative to the outer SVG container
   * (default is 0)
   **/
  innerMapOffsetX: PropTypes.number,

  /**
   * Vertical spacing of the inner map relative to the outer SVG container
   * (default is 0)
   **/
  innerMapOffsetY: PropTypes.number,

  /**
   * Scaling of the inner map in relation to the outer SVG container (default
   * is 1)
   **/
  innerMapScaling: PropTypes.number,
};
