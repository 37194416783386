import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { merge } from "lodash";
import { storeSettings } from "src/utils/settings";
// import fallBackThemes from "../constants/defaultThemes";

const SettingsContext = createContext();

const defaultSettings = {
  direction: "ltr",
  responsiveFontSizes: true,
  theme: null,
};

export function SettingsProvider({ settings = defaultSettings, children }) {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
