export const getFromLocalStorage = (key, namespace = null) => {
  const val = localStorage.getItem(namespace ? `${namespace}.${key}` : key);

  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
};

export const getAllFromLocalStorageNamespace = (namespace) => {
  return Object
    .keys(localStorage)
    .filter((k) => k.startsWith(`${namespace}.`))
    .map((key) => localStorage.get(key));
};

export const saveToLocalStorage = (key, value, namespace = null) => {
  let val = value;
  if (typeof value === 'object') val = JSON.stringify(value);

  localStorage.setItem(namespace ? `${namespace}.${key}` : key, val);
};

export const clearLocalStorage = (key, namespace = null) => {
  localStorage.removeItem(namespace ? `${namespace}.${key}` : key);
};

export const clearAllLocalStorageNamespace = (namespace) => {
  return Object
    .keys(localStorage)
    .filter((k) => k.startsWith(`${namespace}.`))
    .forEach((key) => localStorage.removeItem(key));
};

export const clearAllLocalStorage = () => localStorage.clear();
