import  React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const CacheContext = createContext({});

export const CacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  const removeFromCache = (cacheKey) => {
    setCache((prevCache) => {
      const { [cacheKey]: _, ...cacheAfterKeyRemove } = prevCache;
      return cacheAfterKeyRemove;
    });
  };

  const handleSaveToCache = (key, val, cacheTime) => {
    const cacheKey = JSON.stringify(key);
    setCache((prevCache) => {
      return {
        ...prevCache,
        ...{ [cacheKey]: val }
      };
    });

    if (cacheTime) {
      setTimeout(() => removeFromCache(cacheKey), cacheTime);
    }
  };

  const handleClearCache = (key) => {
    // clear all cache if key is undefined
    if (typeof key === 'undefined') {
      setCache({});
    } else {
      // remove key from cache
      const cacheKey = JSON.stringify(key);
      removeFromCache(cacheKey);
    }
  };

  const handleGetFromCache = (key) => {
    const cacheKey = JSON.stringify(key);
    return cache[cacheKey];
  };

  return (
    <CacheContext.Provider
      value={{
        cache,
        getFromCache: handleGetFromCache,
        saveToCache: handleSaveToCache,
        clearCache: handleClearCache
      }}
    >
      {children}
    </CacheContext.Provider>
  );
};

CacheProvider.propTypes = {
  children: PropTypes.node
};

export default CacheContext;
