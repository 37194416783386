import React from "react";
import DynamicMapCard from "../common/components/DynamicMapCard";
import MexicoMap from "./MexicoMap";

export default function MexicoMapCard({ ...rest }) {
  const adjustedProps = {
    activeLandRegions: rest.activeStates,
    countByLandRegion: rest.countByState.map(({ stateCode, ...rest }) => ({
      landRegionCode: stateCode,
      ...rest,
    })),
    onChangeSelectedLandRegions: rest.onChangeSelectedStates,
  };
  return <DynamicMapCard MapView={MexicoMap} {...adjustedProps} {...rest} />;
}
