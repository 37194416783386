export default {
  'Is the vehicle in a parking garage?': 'Is the vehicle in a parking garage?',
  'Is the clearance less than 8 feet?': 'Is the clearance less than 8 feet?',
  'Is this service a result of an Accident?':
    'Is this service a result of an Accident?',
  'Would Light Duty Towing be acceptable?':
    'Would Light Duty Towing be acceptable?',
  'Is vehicle 4 wheel drive or All wheel drive?':
    'Is vehicle 4 wheel drive or All wheel drive?',
  'Broken keys?': 'Broken keys?',
  'Does vehicle use diesel fuel?': 'Does vehicle use diesel fuel?',
  'Does vehicle use unleaded fuel?': 'Does vehicle use unleaded fuel?',
  'Is the vehicle currently in a parking space?':
    'Is the vehicle currently in a parking space?',
  'Is the vehicle backed into the parking space?':
    'Is the vehicle backed into the parking space?',
  'Are there other vehicles currently parked around it?':
    'Are there other vehicles currently parked around it?',
  'Keys inside passenger compartment?': 'Keys inside passenger compartment?',
  'Keys in trunk?': 'Keys in trunk?',
  'Does tire leak over time?': 'Does tire leak over time?',
  'Do you have more than one flat tire?':
    'Do you have more than one flat tire?',
  'Is there a good spare tire in the vehicle?':
    'Is there a good spare tire in the vehicle?',
  'Does your vehicle have locking hubcaps / wheels?':
    'Does your vehicle have locking hubcaps / wheels?',
  'Do you have the key for hubcaps / wheels?':
    'Do you have the key for hubcaps / wheels?',
  'Does your spare tire require a special tool to remove it?':
    'Does your spare tire require a special tool to remove it?',
  'Do you have that tool to remove the spare?':
    'Do you have that tool to remove the spare?',
  'Vehicle have power locks?': 'Vehicle have power locks?',
  'Are you parked next to a column?': 'Are you parked next to a column?',
  'Does vehicle shift out of park?': 'Does vehicle shift out of park?',
  'Is this an antique vehicle?': 'Is this an antique vehicle?',
  'Vehicle modified lower to the ground?':
    'Vehicle modified lower to the ground?',
  'Did your engine stop running while driving?':
    'Did your engine stop running while driving?',
  'Is the air nozzle accessible without removing the hubcap?':
    'Is the air nozzle accessible without removing the hubcap?',
  'Is vehicle in a ditch?': 'Is vehicle in a ditch?',
  'Is vehicle less than 15 feet from paved roadway?':
    'Is vehicle less than 15 feet from paved roadway?',
  'Is vehicle lodged on curb or railway?': 'Is vehicle lodged on curb or railway?',
  'Is vehicle stuck in snow or sand?': 'Is vehicle stuck in snow or sand?',
  'Does your vehicle have more than 4 tires?': 'Does your vehicle have more than 4 tires?',
  'Does your Vehicle have a rear flat tire?': 'Does your Vehicle have a rear flat tire?',
  'Does your Vehicle have a living quarters?': 'Does your Vehicle have a living quarters?',
  'Does your Vehicle have duallies?': 'Does your Vehicle have duallies?',
  'Is the trailer loaded?': 'Is the trailer loaded?',
  'What is the height of your vehicle?': 'What is the height of your vehicle?',
  'What is the length of your vehicle?': 'What is the length of your vehicle?',
  'What is the weight of your vehicle?': 'What is the weight of your vehicle?',
  'What type of hitch(Ball, Goose, Fith Wheel)?': 'What type of hitch(Ball, Goose, Fith Wheel)?',
  'Is this a tow for a trailer (no motor)?': 'Is this a tow for a trailer (no motor)?',
  'Are there any flat tires?': 'Are there any flat tires?',
  'Is having no spare the reason for a tow??': 'Is having no spare the reason for a tow??',
};
