import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const FileSpreadsheet = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01zM64 208v224c0 8.84 7.16 16 16 16h224c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16H80c-8.84 0-16 7.16-16 16zm224 208h-80v-48h80v48zm0-80h-80v-48h80v48zM96 224h192v32H96v-32zm0 64h80v48H96v-48zm0 80h80v48H96v-48z',
    [STYLE.REGULAR]: 'M80 240v176c0 8.84 7.16 16 16 16h192c8.84 0 16-7.16 16-16V240c0-8.84-7.16-16-16-16H96c-8.84 0-16 7.16-16 16zm128 32h64v48h-64v-48zm0 80h64v48h-64v-48zm-96-80h64v48h-64v-48zm0 80h64v48h-64v-48zM369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95z',
    [STYLE.SOLID]: 'M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z'
  };

  return (
    <FontAwesome
      viewBox="0 0 384 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

FileSpreadsheet.propTypes = {
  type: PropTypes.string
};

export default FileSpreadsheet;
