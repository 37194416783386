import Crud from './Crud';

// Use Crud Class to mark as read
// Use Events to set up snapshot listeners

class Events extends Crud {
  // If omitted constructor is added in automatically
  // with super() call

  // The callback is responsible for dispatching the alert
  // or notification through the redux store to be displayed
  // client side

  registerAllAlertListener = (callback, customAllAlertPath) =>
    this.db
      .collection(customAllAlertPath ?? this.generateAlertsPath())
      .where('read', '==', false)
      .onSnapshot(callback);

  registerUserAlertListener = (callback, userId, customUserAlertPath) =>
    this.db
      .collection(customUserAlertPath ?? this.generateAlertsPath())
      .where('read', '==', false)
      .where('to', '==', userId)
      .onSnapshot(callback);

  registerAllNotificationListener = (callback, customAllNotificationPath) =>
    this.db
      .collection(customAllNotificationPath ?? this.generateNotificationsPath())
      .where('read', '==', false)
      .onSnapshot(callback);

  registerUserNotificationListener = (
    callback,
    userId,
    customUserNotificationPath
  ) =>
    this.db
      .collection(
        customUserNotificationPath ?? this.generateNotificationsPath()
      )
      .where('read', '==', false)
      .where('to', '==', userId)
      .onSnapshot(callback);
}

export default Events;
