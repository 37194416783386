import React from 'react';
import ErrorPage from 'src/components/ErrorPage';
import { Button } from '@material-ui/core';
import useAccount from 'src/hooks/useAccount';
import PropTypes from 'prop-types';

const NoAppsView = ({ renderLogoutButton }) => {
  const { signOut } = useAccount();
  const noAppIcon =
    'https://nsdstaticasset.blob.core.windows.net/assets/Platform_Status_Images/no_app_access.png';

  return (
    <ErrorPage
      title="No Apps"
      header="No App Access"
      //   message={`You don't have access to any apps.
      // Please ask an administrator for access, then logout and log back in.`}
      imgSrc={noAppIcon}
      imgAlt="No App Access"
      button={
        <Button color="secondary" variant="outlined" onClick={signOut}>
          Logout
        </Button>
      }
      renderLogoutButton={renderLogoutButton}
    />
  );
};

NoAppsView.propTypes = {
  /**
   * Weather or not to render a logout button on this error page
   */
  renderLogoutButton: PropTypes.bool,
};

NoAppsView.defaultProps = {
  renderLogoutButton: false,
};

export default NoAppsView;
