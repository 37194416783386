import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Card, CardContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FilesDropzone from "src/components/FilesDropzone";
import FilesContext from "src/context/FilesContext";
import CircularLoader from "src/components/CircularLoader";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import NoResults from "src/components/NoResults";
import FileCard from "./FileCard";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  dropZone: {
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Component for displaying a list of FileCards based on files
 * that are pulled out of the FilesProvider. The files are rendered
 * with their each download butotn and preview (if an image)
 */
const Files = ({ className, canUpload, noResultsProps, ...rest }) => {
  const classes = useStyles();
  const {
    files: { filesInStorage },
    isLoadingFiles,
  } = useContext(FilesContext);

  if (isLoadingFiles) return <CircularLoader />;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {canUpload && (
        <Card className={classes.dropZone}>
          <CardContent>
            <FilesDropzone />
          </CardContent>
        </Card>
      )}
      {filesInStorage.length === 0 && (
        <NoResults
          icon={InsertDriveFileIcon}
          title="No Files found"
          {...noResultsProps}
        />
      )}
      <Grid container spacing={3}>
        {filesInStorage?.map((file) => (
          <Grid item key={file.id} lg={4} md={4} sm={6} xs={12}>
            <FileCard file={file} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Files.propTypes = {
  /**
   * Class name you wish to apply to the outermost container element
   */
  className: PropTypes.string,

  /**
   * Weather or not to render an area to upload more files above
   * the Files component
   */
  canUpload: PropTypes.bool,

  /**
   * Props to pass to the NoResults component if there are
   * no files found in the FileProvider
   */
  noResultsProps: PropTypes.shape({
    /**
     * Class name you wish to apply to the outermost container element
     */
    className: PropTypes.string,
    /**
     * Text to appear on the component
     */
    title: PropTypes.string,
    /**
     * String choosen from enum that determines how large the text is (h1 - h6)
     */
    fontVariant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
    /**
     * Number (in px) for the width and height of the icon
     */
    iconSize: PropTypes.number,
    /**
     * Node or Component to be displayed above the title text; defaults to a
     * not-allowed icon
     */
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
    /**
     * Color for the icon
     */
    iconColor: PropTypes.oneOf([
      "inherit",
      "primary",
      "secondary",
      "action",
      "error",
      "disabled",
    ]),
  }),
};

Files.defaultProps = {
  className: "",
  canUpload: false,
  noResultsProps: null,
};

export default Files;
