import { gql } from '@apollo/client';
import BaseService from './baseService';

class ServicesService extends BaseService {
  constructor(uri) {
    super(uri);
    this.serviceGql = `
      serviceId
      serviceName
      serviceType
    `;
  }

  getServices = () =>
    new Promise((resolve, reject) => {
      const SERVICES = gql`
      query {
        services{
            ${this.serviceGql}
        }
      }
    `;

      this.client
        .query({
          fetchPolicy: 'no-cache',
          query: SERVICES
        })
        .then(result => {
          if (result.data.services) {
            resolve(result.data.services);
          } else {
            reject(result.data.error);
          }
        })
        .catch(error => {
          console.log('error', error);
          reject(error);
        });
    });
}

const servicesService = new ServicesService(
  `${process.env.REACT_APP_PROVIDER_API}/graphql`
);

export default servicesService;
