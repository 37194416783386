import React from 'react';
import  { useCallback } from "react";
import PropTypes from "prop-types";
import ChartCard from "src/components/cardCharts/apex/ChartCard";
import PopoverTable from "../PopoverTable";
import { average, fractionDigits } from "src/utils/math";
import DataSourceButton from "src/components/DataSourceButton";

// import { useTranslation } from 'react-i18next';

/**
 * Component that uses ChartCard to render a multi
 * bar graph. It does not use the withAysnc HOC to fetch
 * ; you must pass the response object directly as a prop
 */
export default function OperationalBarChart({
  chartTypes,
  isLoading,
  response,
  activeChartType,
  onChangeChartType,
  onOpenDataSourceDialog,
  dataSourceButtonSettings,
  averagesButtonSettings,
}) {
  // const { t } = useTranslation();

  const createChart = useCallback(
    (theme, chartData, chartTypeDetails, chartProps) => {
      const numMonths = chartData.length;
      const { yaxis } = chartTypeDetails;
      const yaxisTooltipFormatter =
        yaxis?.tooltipFormatter ??
        ((val) => {
          return `${val} ${yaxis.label}`;
        });

      const colors = [
        "#1de9b6",
        "#ffeb3b",
        "#3f51b5",
        "#ff5722",
        "#4caf50",
        "#009688",
        "#9c27b0",
        "#e91e63",
        "#cddc39",
        "#f44336",
        "#13affe",
        "#fbab49",
      ].slice(-numMonths);

      const chart = {
        type: "bar",
        options: {
          chart: {
            animations: {
              enabled: false,
            },
            background: theme.palette.background.paper,
            toolbar: {
              show: false,
            },
          },
          colors,
          dataLabels: {
            enabled: false,
          },
          grid: {
            borderColor: theme.palette.divider,
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          legend: {
            show: true,
            labels: {
              colors: theme.palette.text.secondary,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "40%",
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          theme: {
            mode: theme.palette.type,
          },
          tooltip: {
            theme: theme.palette.type,
            y: {
              formatter: yaxisTooltipFormatter,
            },
          },
          xaxis: {
            axisBorder: {
              show: true,
              color: theme.palette.divider,
            },
            axisTicks: {
              show: true,
              color: theme.palette.divider,
            },
            categories: chartTypeDetails?.xaxis?.categories ?? [
              // `${t('week')} 1`,
              // `${t('week')} 2`,
              // `${t('week')} 3`,
              // `${t('week')} 4`,
              // `${t('week')} 5`
              "week 1",
              "week 2",
              "week 3",
              "week 4",
              "week 5",
            ],
            labels: {
              style: {
                colors: theme.palette.text.secondary,
              },
            },
          },
          yaxis: {
            ...(!yaxis.hide && { title: { text: yaxis.label } }),
            axisBorder: {
              show: true,
              color: theme.palette.divider,
            },
            axisTicks: {
              show: true,
              color: theme.palette.divider,
            },
            labels: {
              style: {
                colors: theme.palette.text.secondary,
              },
              formatter:
                yaxis?.labels?.formatter ??
                ((value) => {
                  return value;
                }),
            },
          },
        },
        series: chartData.map(({ month, category, data }) => ({
          name: month ?? category,
          data,
        })),
      };
      return chart;
    },
    []
  );

  const activeChartTypeValue = activeChartType ?? chartTypes[0].value;
  const averagesData = response?.data?.[activeChartTypeValue]?.map(
    ({ month, data }) => ({
      month,
      average: fractionDigits(average(data), 2),
    })
  );
  return (
    <ChartCard
      isLoading={isLoading}
      response={response}
      activeChartType={activeChartTypeValue}
      onChangeChartType={onChangeChartType}
      createChart={createChart}
      chartTypes={chartTypes}
      cardAction={
        <>
          {dataSourceButtonSettings?.display && (
            <DataSourceButton onOpenDataSourceDialog={onOpenDataSourceDialog} />
          )}
          {averagesButtonSettings?.display && (
            <PopoverTable data={averagesData} />
          )}
        </>
      }
    />
  );
}

OperationalBarChart.propTypes = {
  /**
   * Array of objects representing the possible types of
   * charts that can be displayed and switched via an dropdown
   * menu in the CardChart actions, can also hold configuration
   * for that specified chart (see options ). One of this objects
   * is passed in to createChart as the 3rd argument
   * https://apexcharts.com/docs/options/
   * https://apexcharts.com/docs/options/xaxis/
   * https://apexcharts.com/docs/options/yaxis/
   */
  chartTypes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      info: PropTypes.string,
      type: PropTypes.string,
      yaxis: {
        label: PropTypes.string.isRequired,
      },
      xaxis: PropTypes.object,
      yaxis: PropTypes.object,
    })
  ),
  /**
   * While true a circular loader is rendered in the chart
   */
  isLoading: PropTypes.bool,
  /**
   * The response from the fetched data; only used if the
   * src prop is passed in; this prop is generated by the
   * withAysnc HOC
   */
  response: PropTypes.object,
  /**
   * String for the type of chart you are currently rendering.
   * https://apexcharts.com/docs/options/chart/type/
   */
  activeChartType: PropTypes.oneOf([
    "line",
    "area",
    "bar",
    "radar",
    "histogram",
    "pie",
    "donut",
    "radialBar",
    "scatter",
    "bubble",
    "heatmap",
    "candlestick",
  ]),
  /**
   * Callback that runs when the dropdown menu has a new
   * option clicked. It receives 1 argument; the value key
   * from the selected chartTypes array that rendered the
   * option in the dropdown
   */
  onChangeChartType: PropTypes.func,
  /**
   * Callback that runs when the DataSource Button component
   * is clicked. It receives 1 argument; the generated event
   * object
   */
  onOpenDataSourceDialog: PropTypes.func,
  /**
   * If the data source button is rendered on the ChartCard
   */
  showDataSourceButton: PropTypes.bool,
  /**
   * If the average popover table button is rendered on the ChartCard
   */
  showAveragesButton: PropTypes.bool,
};
