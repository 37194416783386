import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

/**
 * Component which is used to create pagnation actions (buttons/icons)
 * on the TabTable component. If withLoadMore HOC is enabled this will
 * be disabled / not rendered. The JSX returned is directly passed
 * to Material UI's TablePagnation component with the ActionsComponent prop
 * https://v4.mui.com/api/table-pagination/#tablepagination-api
 */
const TablePaginationActions = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange,
    translationStrings: { firstPageText, nextPageText, previousPageText, lastPageText } } = props;

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);

  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={firstPageText}
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={previousPageText}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={nextPageText}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={lastPageText}
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  /**
   * Total number of records that have been displayed so far,
   * (e.g. If there are 5 records/rows per page and you are on the
   * second page (with only 3 records displayed) count is 8 )
   */
  count: PropTypes.number.isRequired,

  /**
   * Callback from TabTable that accepts 2 arguments;
   * the event generated by the click, and the new page number
   * to set in the pagination state
   */
  onPageChange: PropTypes.func.isRequired,

  /**
   * What current page number you are up to
   */
  page: PropTypes.number.isRequired,

  /**
   *  How many rows of data to display per table page
   */
  rowsPerPage: PropTypes.number.isRequired,

  /**
   * Object to support table labels/text in different languages if desired,
   * for react-i18next useTranslation hook pass in your translated string (e.g. t('something'))
   */
  translationStrings: PropTypes.shape({
    firstPageText: PropTypes.string,
    previousPageText: PropTypes.string,
    nextPageText: PropTypes.string,
    lastPageText: PropTypes.string
  })
};

TablePaginationActions.defaultProps = {
  count: 0,
  page: 1,
  rowsPerPage: 4,
  translationStrings: {
    firstPageText: 'first page',
    previousPageText: 'previous page',
    nextPageText: 'next page',
    lastPageText: 'last page'
  }
};

export default TablePaginationActions;
