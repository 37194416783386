import React, { useState } from 'react';
import { Tooltip, Button } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useIsMountedRef } from '@nsd/fe';
import { getHostSite } from 'src/services/siteConfigService';
import { SITE } from 'src/sites';
import { makeStyles } from '@material-ui/core/styles';
import useAgents from 'src/hooks/useAgents';
import { Building as BuildingIcon } from '@nsd/fe';
import AgentCodeDialog from 'src/components/AgentCodeDialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  buttonText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  startIcon: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  endIcon: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  agentIcon: {
    height: 17,
  },
  agentCode: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginLeft: 5,
    padding: '2px 5px',
    borderRadius: 40,
  },
}));

const Agents = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [isProviderCodeDialogOpen, setIsProviderCodeDialogOpen] = useState(
    false,
  );
  const handleCloseProviderCodeDialog = () => {
    if (isMountedRef.current) {
      setIsProviderCodeDialogOpen(false);
    }
  };
  const handleOpenProviderCodeDialog = () => setIsProviderCodeDialogOpen(true);
  const { agents, activeAgents } = useAgents();

  let agentType = t('TopBar.agents');
  let tooltipTitle = agentType;
  if (getHostSite() === SITE.NSD_DEMO) {
    agentType = 'Brands';
    tooltipTitle = agentType;
  }

  let buttonText;
  if (activeAgents.length === 1) {
    const activeAgent = activeAgents[0];
    buttonText = (
      <>
        {activeAgent.name}
        <span className={classes.agentCode}>{activeAgent.code}</span>
      </>
    );
  } else if (agents.length === activeAgents.length) {
    // 'All Agents'
    buttonText = t('TopBar.allBrands');
  } else if (activeAgents.length === 2) {
    // '2 Agents'
    buttonText = `2 ${t('TopBar.brands')}`;
  } else {
    // 'Many Agents'
    buttonText = t('TopBar.multipleBrands');
  }

  if (agents.length === 0) return null;

  return (
    <>
      <Tooltip
        // "Agents"
        title={tooltipTitle}
        enterDelay={400}
      >
        <Button
          id="button-agents"
          variant="outlined"
          onClick={handleOpenProviderCodeDialog}
          color="inherit"
          classes={{
            startIcon: classes.startIcon,
            endIcon: classes.endIcon,
          }}
          className={classes.button}
          startIcon={<BuildingIcon className={classes.agentIcon}/>}
          endIcon={<ExpandMoreIcon fontSize="small"/>}
        >
          <span className={classes.buttonText}>{buttonText}</span>
        </Button>
      </Tooltip>
      {isProviderCodeDialogOpen && (
        <AgentCodeDialog onClose={handleCloseProviderCodeDialog}/>
      )}
    </>
  );
};

export default Agents;
