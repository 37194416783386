/**
 * Use to compose multiple higher-order components into a single higher-order component.
 * This works exactly like the function of the same name in Redux, or lodash's flowRight().
 * 
 * Former Recompose utility.
 * Taken from https://github.com/acdlite/recompose/blob/master/src/packages/recompose/compose.js
 * Original Recompose Docs: https://github.com/acdlite/recompose/blob/HEAD/docs/API.md#compose
 * @param  {...any} funcs 
 * @returns composed function
 */
const compose = (...funcs) => funcs.reduce((a, b) => (...args) => a(b(...args)), arg => arg);

export default compose;
