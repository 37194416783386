import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  Tabs,
  Tab,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { Clear as CloseIcon } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { axios } from '@nsd/fe';
import SupportForm from './SupportForm';
import FinishRequest from './FinishRequest';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: '0 !important',
  },
  finishRequest: {
    minHeight: 250,
    textAlign: 'center',
  },
  title: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  closeBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkIcon: {
    color: '#00EC96',
    fontSize: '8em',
  },
}));

const StyledTabs = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  indicator: {
    backgroundColor: theme.palette.activeLink?.main ?? theme.palette.common.black,
  },
}))((props) => <Tabs {...props} />);

const StyledTab = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: theme.spacing(0, 3),
    minWidth: 'fit-content',
    color: theme.palette.text.secondary,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selected: {
    color: theme.palette.activeLink?.main ?? theme.palette.common.black,
  },
}))((props) => <Tab {...props} />);

function SupportDialog({ onClose }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const topics = [
    { label: t('TopBar.support.towOrder'), value: 'Tow Order' },
    { label: t('TopBar.support.dispatchReporting'), value: 'Dispatch Reporting' },
    { label: t('TopBar.support.documents'), value: 'Documents' },
    { label: t('TopBar.support.reports'), value: 'Reports' },
    { label: t('TopBar.support.billing'), value: 'Billing' },
    { label: t('TopBar.support.claims'), value: 'Claims' },
    { label: t('TopBar.support.insurance'), value: 'Insurance' },
  ];

  const SUPPORT_TYPE = {
    story: {
      label: t('TopBar.support.requestFeature'),
      value: 'story',
    },
    bug: {
      label: t('TopBar.support.reportBug'),
      value: 'bug',
    },
  };

  const [
    activeSupportTab,
    setActiveSupportTab,
  ] = useState(SUPPORT_TYPE.story.value);
  const [isRequestInProgress, setIsRequestInProgress] = useState(true);
  const [hadError, setHadError] = useState(false);
  const { appName, user } = useSelector((state) => ({
    ...state.application,
    ...state.account,
  }));

  const handleChangeTab = (event, value) => {
    setActiveSupportTab(value);
  };

  const sendSupportRequest = async (supportPayload) => {
    try {
      const formData = new FormData();

      formData.append('appName', appName);
      formData.append('description', supportPayload?.description);
      formData.append('issueType', activeSupportTab);
      formData.append('reporter', user?.email);
      formData.append('topic', supportPayload?.topic);
      supportPayload?.attachments?.forEach((attachment) => {
        formData.append('attachments[]', attachment);
      });

      const response = await axios.post('/support', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      onClose();
      enqueueSnackbar(`Submitted JIRA ticket. The created story or bug is: ${response.data.key}`, { variant: 'success' });
    } catch (error) {
      setHadError(true);
      enqueueSnackbar(`Error submitting ticket: ${error.message}`, { variant: 'error' });
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      maxWidth="sm"
      open
      fullWidth
    >
      <DialogTitle>
        <div className={classes.title}>
          <Typography variant='h2' color='textSecondary'>
            {t('TopBar.support.support').toUpperCase()}
          </Typography>
          <IconButton
            onClick={onClose}
            className={classes.closeBtn}
          >
            <SvgIcon fontSize="small">
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isRequestInProgress ? (
          <>
            <StyledTabs
              className={classes.tabs}
              variant='scrollable'
              value={activeSupportTab}
              onChange={handleChangeTab}
            >
              {Object.keys(SUPPORT_TYPE).map((type) => (
                <StyledTab
                  key={type}
                  value={type}
                  label={SUPPORT_TYPE[type].label}
                  disableRipple
                />
              ))}
            </StyledTabs>
            <Divider />
            <SupportForm
              topics={topics}
              onSubmit={sendSupportRequest}
              allowAttachments={activeSupportTab === SUPPORT_TYPE.bug.value}
            />
          </>
        ) : (
          <FinishRequest hadError={hadError} />
        )}
      </DialogContent>
    </Dialog>
  );
}

SupportDialog.propTypes = {
  onClose: PropTypes.func,
};

export default SupportDialog;
