import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import FormDialog from 'src/components/FormDialog';
import INPUT_FIELD_TYPE from 'src/constants/inputFieldType';
import { useFetch } from 'src/hooks/useFetch';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  /* Styles applied to the group's label elements. */
  groupLabel: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
  },
  /* Styles applied to the group's ul elements. */
  groupUl: {
    padding: 0,
    '& $option': {
      paddingLeft: 24,
    },
  },
  groupText: {
    color: '#546e7a',
    fontSize: '0.875rem',
    boxSizing: 'border-box',
    listStyle: 'none',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
  },
}));

/**
 * Component that renders a dialog and multi-select input that
 * lets a user choose the roles they wish to assign to the currently
 * selectedUser object. If the selectedUser doesn't have the roles
 * array it will be fetched from the API along with all the role
 * options. A successful role update sends a PUT request to
 * /users/:userId/roles with the new roles array
 */
const UserRolesDialog = ({
  onClose,
  onExited,
  selectedUser,
  companyCode,
  open,
  clearAdminUsersTableCache,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const INPUT_NAME = 'roleIds';

  let queryParam = '';
  if (companyCode) {
    queryParam = `?companyCode=${companyCode}`
  }
  // Only make the request for roles if the user object doesnt have it
  const {
    response: userAssignedRolesResponse,
    isLoading: isUserAssignedRolesResponseLoading,
    clearResponseCache,
  } = useFetch(
    open && !selectedUser?.roles && `/users/${selectedUser?.id}/roles${queryParam}`,
  );

  let { roles: userRoles } = selectedUser || {};
  if (!userRoles) userRoles = userAssignedRolesResponse?.data?.roles;
  const { response: rolesResponse, isLoading: isRolesResponseLoading } =
    useFetch(open && '/roles');

  const handleSave = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      // Must be in shape {roleIds: [1,2,3]}
      const rolesBody = {};
      rolesBody[INPUT_NAME] = values[INPUT_NAME].map(({ value }) => value);
      rolesBody.companyCode = companyCode;
      if (!rolesResponse) throw new Error('Roles were not properly fetched');
      setSubmitting(true);
      await axios.put(`users/${selectedUser.id}/roles`, rolesBody);
      clearResponseCache();
      clearAdminUsersTableCache();
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      enqueueSnackbar('User roles saved successfully', { variant: 'success' });
      onClose();
    } catch (error) {
      setStatus({ success: false });
      setErrors({ submit: error.password });
      setSubmitting(false);
      enqueueSnackbar('Unable to save user roles', { variant: 'error' });
    }
  };

  return (
    <>
      <FormDialog
        isLoading={isUserAssignedRolesResponseLoading || isRolesResponseLoading}
        size="xs"
        open={open}
        onClose={onClose}
        onExited={onExited}
        title="User Roles"
        subTitle="Assign roles for this user"
        formSettings={{
          onSubmit: handleSave,
          fields: [
            {
              label: 'User Roles',
              name: INPUT_NAME,
              type: INPUT_FIELD_TYPE.AUTOCOMPLETE_MULTI_SELECT,
              options:
                rolesResponse?.data?.roles?.map((role) => ({
                  value: role.id,
                  label: role.name,
                })) || [],
            },
          ],
          initialValues: {
            roleIds:
              userRoles?.map((role) => ({
                value: role.id,
                label: role.name,
              })) || [],
          },
          submitButton: {
            text: 'Save',
          },
        }}
      />
    </>
  );
};

UserRolesDialog.propTypes = {
  /**
   * Selected company code
   */
  companyCode: PropTypes.string,

  /**
   * Weather or not the dialog is open
   */
  open: PropTypes.bool.isRequired,

  /**
   * Callback to run when the dialog cancel button is clicked;
   * takes no arguments
   */
  onClose: PropTypes.func,
  /**
   * Callback to run when the dialog is completely exited off
   * the screen; takes no arguments
   */
  onExited: PropTypes.func,
  /**
   * Selected user object representing the user who
   *  will get their roles
   */
  selectedUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    createdAt: PropTypes.string,
    admin: PropTypes.bool,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
  /**
   * Calls a function meant to refresh the data in the table after
   * a successful PUT request to alert a user's roles. When used
   * with CrudTablePage this is the refresh() function found
   * in the HOC withAsync that refreshes the table
   */
  clearAdminUsersTableCache: PropTypes.func,
};

UserRolesDialog.defaultProps = {
  onClose: () => { },
  onExited: () => { },
  clearAdminUsersTableCache: () => { },
  open: false,
  selectedUser: {},
};

export default UserRolesDialog;
