/* eslint-disable no-param-reassign */
import produce from 'immer';

/*
  Notification shape
  {
    to: '', //Leave to blank to send to all users
    from: '',
    read: false,
    text: '',
    //Relative link
    pageLink: '' ,
    severity: ['info' | 'warning' | 'error' | 'success'],
    docId:
  }  
 */

import {
  CLEAR_ALL_NOTIFICATIONS,
  CLEAR_ONE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  QUEUE_NOTIFICATION,
  SET_NOTIFICATION_SETTINGS,
} from '../actions/notificationActions';

const initialState = {
  messages: [],
  pulseAnimation: false,
  displayNumberedBadge: true,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL_NOTIFICATIONS: {
      return produce(state, (draft) => {
        draft.messages = [];
      });
    }
    case QUEUE_NOTIFICATION: {
      const { messages = {} } = action?.payload || {};
      // Purge duplicates based on ID as well
      const finalMessages = messages.filter((notification, index, arr) => {
        return index === arr.findIndex((t) => t.docId === notification.docId);
      });
      return produce(state, (draft) => {
        draft.messages = finalMessages;
      });
    }
    case UPDATE_NOTIFICATION: {
      // Purge duplicates based on ID as well
      const { messages = [] } = action?.payload || {};
      const finalMessages = messages.filter(
        (notification, index, arr) =>
          index === arr.findIndex((t) => t.docId === notification.docId)
      );
      return produce(state, (draft) => {
        draft.messages = finalMessages;
      });
    }
    case SET_NOTIFICATION_SETTINGS: {
      const { pulseAnimation = false, displayNumberedBadge = true } =
        action?.payload || {};
      return produce(state, (draft) => {
        draft.pulseAnimation = pulseAnimation;
        draft.displayNumberedBadge = displayNumberedBadge;
      });
    }

    case CLEAR_ONE_NOTIFICATION: {
      // Has already been marked as read just remove from queue
      const { docId } = action?.payload || {};
      const copy = [...state.messages];
      const newMessages = copy.filter(
        ({ docId: curDocId }) => curDocId !== docId
      );
      return produce(state, (draft) => {
        draft.messages = newMessages;
      });
    }
    default: {
      return state;
    }
  }
};

export default notificationsReducer;
