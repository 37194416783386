import  React, { useRef } from "react";
import DropdownMenu from "src/components/DropdownMenu";
import { makeStyles } from "@material-ui/core/styles";
import {Grid, Box} from "@material-ui/core";
import { InsertEmoticon as InsertEmoticonIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  emojiBox: {
    textAlign: "center",
    padding: "5px 5px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      cursor: "pointer",
    },
    fontSize: 25,
  },
  popover: {
    width: 320,
  },
}));

/**
 * Dropdown of different emojis / reactions to be used
 * for user feedback
 */
const EmojiDropDown = ({ PopoverProps, IconButtonProps, onClick }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const emojis = [
    "😀",
    "😃",
    "😄",
    "😁",
    "😆",
    "😅",
    "🤣",
    "😂",
    "🙂",
    "😉",
    "😊",
    "😇",
    "🤩",
    "😛",
    "🙁",
    "😎",
    "😱",
    "🥺",
    "😮",
    "🥱",
    "🤨",
    "😗",
    "😺",
    "👋",
  ];

  const handleClick = (emoji) => () => {
    ref.current.close();
    if (onClick) onClick(emoji);
  };

  return (
    <DropdownMenu
      ref={ref}
      IconButtonProps={IconButtonProps}
      PopoverProps={PopoverProps}
      Icon={<InsertEmoticonIcon />}
      title="Emojis"
    >
      <Grid container spacing={1}>
        {emojis.map((emoji) => (
          <Grid key={uuidv4()} item xs={2}>
            <Box onClick={handleClick(emoji)} className={classes.emojiBox}>
              {emoji}
            </Box>
          </Grid>
        ))}
      </Grid>
    </DropdownMenu>
  );
};

EmojiDropDown.propTypes = {
  /**
   * Props to pass into the IconButton component generated
   * by the DropdownMenu component
   * https://v4.mui.com/api/icon-button/#iconbutton-api
   */
  IconButtonProps: PropTypes.object,
  /**
   * Props to pass into the PopOver component generated
   * by the DropdownMenu component
   * https://v4.mui.com/api/popover/
   */
  PopoverProps: PropTypes.object,
  /**
   * Callback that is triggered when any emoji in the
   * dropdown is clicked. It is passed exactly 1 argument;
   * the text for the clicked emoji
   */
  onClick: PropTypes.func,
};

EmojiDropDown.defaultProps = {
  onClick: () => {},
  IconButtonProps: null,
  PopoverProps: null,
};

export default EmojiDropDown;
