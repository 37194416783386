export default {
  'callDateTime': 'Call Date/Time',
  'calledAt': 'Called At',
  'callbackNumber': 'Callback Number',
  'callbackNumber_hash': 'Call Back#',
  'callerCity': 'Caller City',
  'callerFirstName': 'Caller First Name',
  'callId': 'Call Id',
  'callerLastName': 'Caller Last Name',
  'callerName': 'Caller Name',
  'callerState': 'Caller State',
  'caller': 'Caller',
  'callerId': 'Caller ID',
  'callBackNumber_hash': 'Call Back #',
  'policyNumber_hash': 'Policy #',
  'company': 'Company',
  'eta_min_abbreviation': 'ETA (min)',
  'member': 'Member',
  'mobileTexts': 'Mobile Texts',
  'editedBy': 'Edited By',
  'editedDate': 'Edited Date',
  'vehicle': 'Vehicle',
  'rideshareDescription': 'Rideshare Description',
  'dispatchLocation': 'Dispatch Location',
  'towToLocation': 'Tow To Location',
  'confirmation': 'Confirmation',
  'mva_number_hash_abbreviation': 'MVA#',
  'stateOrProvince': 'State / Province',
  'vin_abbreviation': 'Vin #',
  'status': 'Status',
  'personWithVehicle': 'Person with Vehicle',
  'completedDate': 'Completed Date',
  'deliveryLatitude': 'Delivery Latitude',
  'deliveryLongitude': 'Delivery Longitude',
  'requestDate': 'Request Date',
  'commentAs': 'Comment As',
  'customDateAndTime': 'Custom Date And Time',
  'selectCustomDateAndTimeRange': 'Select Custom Date And Time Range',
  'startDateTime': 'Start Date Time',
  'endDateTime': 'End Date Time',
  'dispatchId': 'Dispatch ID',
  'thirdPartyId': 'Third Party ID',
  'towServiceDescription': 'Service Description',
  'replacementVehicle': 'Replacement Vehicle',
  'returnLocation': 'Return Location',
  'customerLocation': 'Customer Location',
};
