import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import FormDialog from 'src/components/FormDialog';
import INPUT_FIELD_TYPE from 'src/constants/inputFieldType';
import { useFetch } from 'src/hooks/useFetch';
/**
 * Component that renders a dialog that generates a multi select with
 * an initial value representing the packages already applied to the company.
 * You are able to update a companies packages from this select
 */
const CompanyPackagesDialog = ({ onClose, onExited, selectedCompany }) => {
  const { enqueueSnackbar } = useSnackbar();
  const INPUT_NAME = 'packageIds';
  const {
    response: companyAssignedPackagesResponse,
    isLoading: isCompanyAssignedPackagesResponseLoading,
    clearResponseCache,
  } = useFetch(`/companies/${selectedCompany.id}/packages`);

  const { response: packagesResponse, isLoading: isPackagesResponseLoading } =
    useFetch('/packages');

  const handleSave = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      // Must be in shape {packageIds: [1,2,3]}
      const packagesBody = {};
      packagesBody[INPUT_NAME] = values[INPUT_NAME].map(({ value }) => value);
      setSubmitting(true);
      await axios.put(`companies/${selectedCompany.id}/packages`, packagesBody);
      clearResponseCache();
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      enqueueSnackbar('Company packages saved successfully', {
        variant: 'success',
      });
      onClose();
    } catch (error) {
      setStatus({ success: false });
      setErrors({ submit: error.password });
      setSubmitting(false);
      enqueueSnackbar('Unable to save company packages', { variant: 'error' });
    }
  };

  return (
    <FormDialog
      isLoading={
        isCompanyAssignedPackagesResponseLoading || isPackagesResponseLoading
      }
      size="xs"
      open={true}
      onClose={onClose}
      onExited={onExited}
      title="Company Packages"
      subTitle="Assign packages for this company"
      formSettings={{
        onSubmit: handleSave,
        fields: [
          {
            label: 'Company Packages',
            name: INPUT_NAME,
            type: INPUT_FIELD_TYPE.AUTOCOMPLETE_MULTI_SELECT,
            options:
              packagesResponse?.data?.packages?.map((appPackage) => ({
                value: appPackage.id,
                label: appPackage.name,
              })) || [],
          },
        ],
        initialValues: {
          packageIds:
            companyAssignedPackagesResponse?.data?.packages?.map(
              (appPackage) => ({
                value: appPackage.id,
                label: appPackage.name,
              })
            ) || [],
        },
        submitButton: {
          text: 'Save',
        },
      }}
    />
  );
};

CompanyPackagesDialog.propTypes = {
  /**
   * Callback to run when the dialog cancel button is clicked;
   * takes no arguments
   */
  onClose: PropTypes.func,

  /**
   * Callback to run when the dialog is completely exited off
   * the screen; takes no arguments
   */
  onExited: PropTypes.func,
  /**
   * The selected company object which requires an id
   * in order to fetch the company packages currently
   * registered; only id is required for this component
   */
  selectedCompany: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    code: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

CompanyPackagesDialog.defaultProps = {
  onClose: () => {},
  onExited: () => {},
  selectedCompany: {},
};

export default CompanyPackagesDialog;
