import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, alpha } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: "center",
    borderRadius: 2,
    display: "inline-flex",
    flexGrow: 0,
    whiteSpace: "nowrap",
    cursor: "default",
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: "center",
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: "uppercase",
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
  info: {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.08),
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08),
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.08),
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08),
  },
}));

/**
 * Component for marking pieces of information with additional context;
 * not to be confused with the native label tag which is paired with
 * the input element
 */
function Label({ className, color, children, style, ...rest }) {
  const classes = useStyles();
  return (
    <span
      className={clsx(
        classes.root,
        {
          [classes[color]]: color,
        },
        className
      )}
      {...rest}
    >
      {children}
    </span>
  );
}

Label.propTypes = {
  /**
   * Renderable content to be placed with the container span element
   */
  children: PropTypes.any,
  /**
   * Class name you wish to apply to the label
   */
  className: PropTypes.string,
  /**
   * Style object as a final option to override specificity
   */
  style: PropTypes.object,
  /**
   * Possible color values for the label
   */
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "warning",
    "success",
  ]),
};

Label.defaultProps = {
  className: "",
  color: "primary",
  children: "Sample label text",
  style: {},
};

export default Label;
