import { useState, useEffect } from 'react';
import { useSettings, axios } from '@nsd/fe';
import { createTheme } from 'src/theme';
import fallBackThemes from 'src/constants/defaultTheme';
import { getClientCompanyCode } from 'src/utils/clientUtils';

/**
 * On a fresh visit (no settings in localstorage) the theme is null,
 * it will take the first theme out of the /appTheme response and apply that.
 * Should enable custom themed client-qore sites to immediately have that theme applied on
 * the login-screen
 * @returns {Object}
 */
const useInitialTheme = () => {
  const { settings } = useSettings();
  const [theme, setTheme] = useState(null);
  const detectDefaultThemePreference = () => fallBackThemes[0]?.name;

  const isThemeValid = (themeIn, themeList) =>
    themeList.find((theme) => theme.name === themeIn);

  const generateTheme = (x = {}) => {
    const themes = x.data?.themes ?? fallBackThemes;
    // If there is a theme set in settings attempt to use that
    const currentThemeName = settings?.theme || themes[0].name;
    themes && isThemeValid(currentThemeName, themes)
      ? setTheme(createTheme({ ...settings, theme: currentThemeName }, themes))
      : setTheme(
          createTheme(
            { ...settings, theme: detectDefaultThemePreference() },
            themes
          )
        );
  };

  /**
   * Custom axios instance from registry will send attach companyCode env var
   * as header, will fetch company themes based on companyCode. For client-qore sites
   * (dynamically changing companyCode; determined by Origin header from client-side request)
   * it will fetch the code server-side and send back the correct theme
   */
  useEffect(() => {
    axios
      .get('/appTheme', { params: { companyCode: getClientCompanyCode(), defaultThemes: false } })
      .then(generateTheme)
      .catch(generateTheme);
  }, [settings]);

  return { theme, setTheme };
};

export default useInitialTheme;
