export default {
  dispatch: 'Envío',
  dispatches: 'Despachos',
  reports: 'Informes',
  calls: 'Llamadas',
  networkMap: 'Mapa de Red',
  operationalReports: 'Informes Operativos',
  towOrder: 'Orden de remolque',
  impoundOrder: 'Orden de incautación genérica',
  sixtImpoundOrder: 'Sixt Orden de incautación',
  callSearch: 'Búsqueda de Llamadas',
  dispatchSearch: 'Búsqueda de envío',
  impoundCalls: 'Llamadas Incautadas',
  submittedOrders: 'Pedidos Enviados',
  roadsideCalls: 'Llamadas en carretera',
};