import React from 'react';
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Used in place of the react-router-dom Route component.
 * Checks the redux store for a logged in user; only if
 * the user is logged in will this route render
 * the using the render or component prop
 */
function AuthRoute({ component: Component, render, ...rest }) {
  const account = useSelector((state) => state.account);
  if (!account?.user) return <Redirect to="/login" />;
  return render ? render({ ...rest }) : <Component {...rest} />;
}

AuthRoute.propTypes = {
  /**
   * component you wish to render if the user is logged
   * in; all props to AuthRoute are passed to this component.
   * Can also be null
   */
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  /**
   * Render function that returns a component/element to render
   * if the user is logged; all props to AuthRoute are
   * passed to this component
   */
  render: PropTypes.func,
};

AuthRoute.defaultProps = {
  component: null,
  render: null,
};

export default AuthRoute;
