export default {
  "actions": "Actions",
  "agentCodes": "Codes d'agent",
  "allRoles": "Tous les Rôles",
  "avatar": "Avatar",
  "admin": "Administrateur",
  "name.firstName": "Prénom",
  "name.lastName": "Nom de Famille",
  "email": "E-mail",
  "edit": "Éditer",
  "roles": "Les Rôles",
  "dateAdded": "Date Ajoutée",
  "userRoles": "Rôles des Utilisateurs",
  "sendEmail": "Envoyer un E-mail",
  "resetPassword": "Réinitialiser le Mot de Passe",
  "role": "Rôle",
  "users": "Utilisateurs",
  "user": "Utilisateur",
  "search": "Chercher",
  "searchUsers": "Rechercher des utilisateurs",
  "createdAt": "Créé À",
  "newestToOldest_lowerCase": "le plus récent-le plus ancien",
  "oldestToNewest_lowerCase": "le plus ancien-le plus récent",
  "required": "Obligatoire",
  "emailAddress": "Adresse E-mail",
  "password": "Mot de passe",
};