// generate a random number between to and from, non-inclusive of to
export const randomInt = (from, to) => {
  return Math.floor(Math.random() * (to - from) + from);
};

// returns true or false randomly
export const randomBool = () => {
  return Boolean(randomInt(0, 2));
};

// generate a random date between from and to
export const randomDate = (from, to) => {
  let end = to || new Date();
  return new Date(from.getTime() + Math.random() * (end.getTime() - from.getTime()))
};

// randomly sorts and returns an array slice
export const randomArraySlice = (array) => {
  return [...array]
    .sort(() => 0.5 - Math.random())
    .slice(0, randomInt(0, array.length + 1));
};

// select a random value from an array
export const randomArrayVal = (array) => {
  return array[randomInt(0, array.length)];
};

export const randomId = () => randomInt(0, 999999999999);