import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Input,
  Paper,
  Tooltip,
  useMediaQuery,
  Avatar,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    width: 1,
    height: 24,
  },
  fileInput: {
    display: "none",
  },
  placeholderTruncate: {
    "&::placeholder": {
      textOverflow: "ellipsis",
    },
  },
  actionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      justifyContent: "space-evenly",
    },
  },
  iconsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  textInputContainer: {
    minWidth: "100%",
    order: "5",
    [theme.breakpoints.up("sm")]: {
      order: "0",
      minWidth: "80%",
    },
  },
  avatar: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

/**
 * Small section with a text input and file input styled to look
 * like a social media status update.
 */

function PostAdd({ className, onSend, showAvatar, userObject, ...rest }) {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const [value, setValue] = useState("");
  const [file, setFile] = useState(null);
  let { user = null } = useSelector((state) => state.account);
  if (userObject) user = userObject;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChange = (event) => setValue(event.target.value);
  const handleFileInputClick = () => fileInputRef.current.click();
  const handleAttachFile = (event) => {
    const [file] = event.target.files;
    if (!file) reutrn;
    setFile(file);
  };
  const handleSend = (text, attachedFile) => {
    if (onSend) onSend(text, attachedFile);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box className={classes.actionsContainer}>
          {matches && showAvatar && user?.avatar && (
            <Avatar
              className={classes.avatar}
              alt={`${user?.firstName || "Logged in user"} profile photo`}
              src={user.avatar}
            />
          )}

          <Paper
            className={classes.textInputContainer}
            component={Box}
            flexGrow={1}
            py={0.5}
            px={2}
            variant="outlined"
          >
            <Input
              disableUnderline
              fullWidth
              onChange={handleChange}
              placeholder={`What's on your mind, ${user?.firstName || "User"}`}
            />
          </Paper>
          <div className={classes.iconsContainer}>
            <Tooltip title="Send">
              <span>
                <IconButton
                  color={value.length > 0 ? "primary" : "default"}
                  onClick={() => handleSend(value, file)}
                  disabled={value.length > 0 || file ? false : true}
                >
                  <SendIcon />
                </IconButton>
              </span>
            </Tooltip>
            {matches && <Divider className={classes.divider} />}
            <Tooltip title="Attach image">
              <IconButton edge="end" onClick={handleFileInputClick}>
                <AddPhotoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Attach file">
              <IconButton edge="end" onClick={handleFileInputClick}>
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
            <input
              className={classes.fileInput}
              type="file"
              hidden
              onChange={handleAttachFile}
              ref={fileInputRef}
            />
          </div>
        </Box>
      </CardContent>
    </Card>
  );
}

PostAdd.propTypes = {
  /**
   * Class name you wish to apply to the container around this
   * component
   */
  className: PropTypes.string,
  /**
   * Callback to be triggered when the send button is clicked.
   * 2 arguments are passed; the text the user typed and the
   * file the user selected
   */
  onSend: PropTypes.func,
  /**
   * Boolean indicating if the logged (or user object's) avatar
   * will be displayed with the component
   */
  showAvatar: PropTypes.bool,

  /**
   * Object to pass in if you wish to display this component for
   * another user. If this prop is omitted the logged in user's
   * information will be pulled out of the redux store and used
   */
  userObject: PropTypes.shape({
    avatar: PropTypes.string,
    firstName: PropTypes.string,
  }),
};

PostAdd.defaultProps = {
  className: "",
  onSend: (text, file) => console.log({ text }, { file }),
  showAvatar: true,
  userObject: null,
};

export default PostAdd;
