import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Reactions from "./Reactions";
import getInitials from "src/utils/getInitials";

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 200,
    backgroundColor: theme.palette.background.dark,
  },
  likedButton: {
    color: red[600],
  },

  locationContainer: {
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
  },
  locationText: {
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

/**
 * Media Card component used to display details of a
 * project object passed in as a prop. It emulates a common social
 * media UI design with a like counter
 */
function ProjectCard({ project, className, onLike, ...rest }) {
  const classes = useStyles();
  const { projectLink = "#" } = project;
  const { authorLink = "#" } = project?.author;

  if (!project) return null;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box p={3}>
        <CardMedia className={classes.media} image={project.media} />
        <Box display="flex" alignItems="center" mt={2}>
          <Avatar alt="Author" src={project.author.avatar}>
            {getInitials(project.author.name)}
          </Avatar>
          <Box ml={2}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={projectLink}
              variant="h5"
            >
              {project.title}
            </Link>
            <Typography variant="body2" color="textSecondary">
              by{" "}
              <Link
                color="textPrimary"
                component={RouterLink}
                to={authorLink}
                variant="h6"
              >
                {project.author.name}
              </Link>{" "}
              | Updated {formatDistanceToNow(project.updatedAt)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pb={2} px={3}>
        <Typography color="textSecondary" variant="body2">
          {project.description}
        </Typography>
      </Box>
      <Box py={2} px={3}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item className={classes.locationContainer}>
            <Typography
              variant="h5"
              className={classes.locationText}
              color="textPrimary"
            >
              {project.location}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Location
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {project.type}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Type
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {project.technology}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Technology
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2} pl={2} pr={3} display="flex" alignItems="center">
        <Reactions
          likeObj={{
            onLike,
            likes: project?.likes,
            liked: project?.liked,
            order: 1,
          }}
          userSubscribersObj={{
            order: 2,
            subscribersString: project.subscribers,
          }}
          ratingObj={{
            size: "small",
            order: 4,
          }}
        />
      </Box>
    </Card>
  );
}

ProjectCard.propTypes = {
  /**
   * Class name you wish to apply to the outermost container
   * of this component
   */
  className: PropTypes.string,
  /**
   * Object with all details needed to represent a project in
   * the UI. Rating controls the number of stars; 5 maximum are
   * displayed. media and avatar are both links to image formats
   */
  project: PropTypes.shape({
    projectLink: PropTypes.string,
    liked: PropTypes.bool,
    likes: PropTypes.number,
    media: PropTypes.string,
    author: PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string,
      authorLink: PropTypes.string,
    }),
    title: PropTypes.string,
    updatedAt: PropTypes.object,
    description: PropTypes.string,
    location: PropTypes.string,
    type: PropTypes.string,
    technology: PropTypes.string,
    subscribers: PropTypes.string,
    rating: PropTypes.number,
  }).isRequired,
  /**
   * Callback that runs when the like button is clicked. Accepts
   * 2 arguments; the total # of likes before the button was pressed
   * and a boolean indicating if the current click is to like the post or
   * dislike it
   */
  onLike: PropTypes.func,
};

ProjectCard.defaultProps = {
  className: "",
  onLike: (likes, isBeingLiked) => console.log({ likes }, { isBeingLiked }),
  project: null,
};

export default ProjectCard;
