import React, { useEffect } from "react";
import NProgress from "nprogress";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    minHeight: "100vh",
    padding: theme.spacing(3),
  },
}));

/**
 * Component to show a small loading indicator at the top of
 * viewport taking up the whole page. Pass a class with a
 * min-height property to change the default height
 */
function LoadingScreen({ className, ...rest }) {
  const classes = useStyles();
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
}

LoadingScreen.propTypes = {
  /**
   * Class name to apply to the container around the loading screen
   */
  className: PropTypes.string,
};

LoadingScreen.defaultProps = {
  className: "",
};

export default LoadingScreen;
