export const APP_ENV = {
  LOCAL: 'local',
  DEVELOPMENT: 'dev',
  PERFORMANCE: 'perf',
  STAGING: 'stg',
  UAT: 'uat',
  DEMO: 'demo',
  PRODUCTION: 'v1'
};

export const getEnvironment = () => {
  const url = document.location.host;
  let environment = process.env.REACT_APP_ENV;

  if (url.includes('localhost')) {
    environment = APP_ENV.LOCAL;
  } else if (url.includes('-develop') || url.includes('-dev')) {
    environment = APP_ENV.DEVELOPMENT;
  } else if (url.includes('-staging') || url.includes('-stg')) {
    environment = APP_ENV.STAGING;
  } else if (url.includes('-demo')) {
    environment = APP_ENV.DEMO;
  } else if (url.includes('-uat')) {
    environment = APP_ENV.UAT;
  } else {
    environment = APP_ENV.PRODUCTION;
  }

  return environment;
};

export const getUrl = sitePrefix => {
  let envSuffix = '-dev';
  const env = getEnvironment();

  if (env === APP_ENV.STAGING) {
    envSuffix = '-stg';
  } else if (env === APP_ENV.UAT) {
    envSuffix = '-uat';
  } else if (env === APP_ENV.DEMO) {
    envSuffix = '-demo';
  } else if (env === APP_ENV.PRODUCTION) {
    envSuffix = '';
  }

  envSuffix = `${envSuffix}-2`;

  return `https://${sitePrefix}${envSuffix}.nationsafedrivers.com`;
};

export const isDevelopmentEnv = () => {
  return getEnvironment() === APP_ENV.DEVELOPMENT
}

export const isLocalEnv = () => {
  return getEnvironment() === APP_ENV.LOCAL
}

export const isDevOrLocalEnv = () => isDevelopmentEnv() || isLocalEnv();