import React from 'react';
import ErrorPage from 'src/components/ErrorPage';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const notFoundIcon = 'https://nsdstaticasset.blob.core.windows.net/assets/Platform_Status_Images/404.png';

const Error404View = (props) => {
  return (
    <ErrorPage
      title='404: Not found'
      // header='404: The page you are looking for isn’t here'
      // message={`You either tried some shady route or you
      //     came here by mistake. Whichever it is, try using the navigation.`}
      imgSrc={notFoundIcon}
      imgAlt='404: Not found'
      button={(
        <Button
          color='secondary'
          component={RouterLink}
          to='/'
          variant='outlined'
        >
          Back to home
        </Button>
      )}
    {...props}
    />
  );
};

export default Error404View;
