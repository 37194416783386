import { pick } from "lodash";
import { FIRST_PAGE } from "src/components/tables/withPagination";

/**
 * Determines if the given string is included in the given word.
 *
 * @param {string} word
 * @param {string} searchQuery
 * @return {boolean} i.e. true if searchQuery (do) is found in word (dog)
 */
const isSearchQueryFound = (word, searchQuery) =>
  // FIXME: (jh) Shouldn't searchQuery be lower-cased as well?
  word.toLowerCase().includes(searchQuery);

/**
 *
 * @param {string} needle
 * @param {string[]} haystack
 * @return {string[]} Filtered haystack which matches search query.
 */
export const search = (needle, haystack) => {
  if (typeof needle !== "string") return false;

  const searchQuery = needle.toLowerCase();
  return Object.values(haystack).some((value) => {
    let tableRowValue = value ?? "";
    if (Array.isArray(tableRowValue)) {
      return tableRowValue.some((tableRowVal) =>
        isSearchQueryFound(tableRowVal, searchQuery)
      );
    }

    if (typeof tableRowValue === "number") {
      tableRowValue = tableRowValue.toString();
    } else if (typeof tableRowValue === "object") {
      // if there's an object, recursively search
      return search(needle, tableRowValue);
    } else if (typeof tableRowValue !== "string") {
      return false;
    }

    return isSearchQueryFound(tableRowValue, searchQuery);
  });
};

// only shows table rows that match search input
export const applyQuery = (tableData, query, { queryFields } = {}) => {
  return tableData.filter((tableRow) => {
    let matches = true;
    const queryKeys =
      typeof queryFields === "string" ? [queryFields] : queryFields;

    if (query) {
      const haystack =
        queryKeys === undefined ? tableRow : pick(tableRow, queryKeys);

      const containsQuery = search(query, haystack);

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

// filter table rows
export const applyFilters = (tableData, filters, selectedFilters) =>
  tableData.filter((tableRow) => {
    let matches = true;

    filters.forEach(({ name, filter } = {}) => {
      const selectedFilterValue = selectedFilters[name];
      if (
        filter &&
        typeof selectedFilterValue !== "undefined" &&
        selectedFilterValue !== "all" &&
        filter(selectedFilterValue, tableRow) === false
      ) {
        matches = false;
      }
    });

    return matches;
  });

export const applyPagination = (tableData, page, limit) => {
  const start = (page - FIRST_PAGE) * limit;
  const end = start + limit;
  return tableData.slice(start, end);
};

export const descendingComparator = (a, b, orderBy) => {
  let aValue = a[orderBy];
  let bValue = b[orderBy];

  // ignore case (A vs a)
  if (typeof aValue === "string" && typeof bValue === "string") {
    aValue = aValue.toLowerCase();
    bValue = bValue.toLowerCase();
  }

  if (bValue < aValue) {
    return -1;
  }

  if (bValue > aValue) {
    return 1;
  }

  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// sort table rows
export const applySort = (tableData, sort) => {
  const [orderBy, order] = sort.split("|");
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line no-shadow
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};
