import React, { forwardRef, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import { pageview } from "../utils/analytics";
import clsx from "clsx";

/**
 * Generic page wrapper component to set a title and attach
 * a ref to the container around the apge
 */
const Page = forwardRef(({ title, children, className, ...rest }, ref) => {
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <main ref={ref} className={clsx(className)} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </main>
  );
});

Page.propTypes = {
  /**
   * Class name you wish to apply around the container element
   */
  className: PropTypes.string,

  /**
   * Node or Component to be wrapped in by this component
   */
  children: PropTypes.node,

  /**
   * String for page title to be injected by react-helmet
   */
  title: PropTypes.string,
};

Page.defaultProps = {
  className: "",
  children: <section>This is the main content of the page</section>,
  title: "Sample page title",
};

export default Page;
