export default {
  totalCalls: 'Total de Llamadas',
  dispatchCalls: 'Despachar llamadas',
  dispatched: 'Enviado',
  notDispatched: 'No Enviado',
  callsByPercent: 'Llamadas por porcentaje',
  cash: 'Dinero en Efectivo',
  goneOnArrival: 'Ido a la Llegada',
  cancelled: 'Cancelado',
  callMessaging: 'Mensajería de Llamadas',
  callRecording: 'Grabacion de Llamada',
  owner: 'Dueño',
  year: 'Año',
  make: 'Hacer',
  model: 'Modelo',
  vin_abbreviation: 'VIN',
  color: 'Color',
  serviceType: 'Tipo de Servicio',
  breakdownReason: 'Razón de Avería',
  noDispatchesFound: 'No se Encontraron Envíos',
  noImpoundsFound: 'No se Encontraron Incautaciones',
  'noDispatches.message':
    'Intente con otro rango de tiempo o seleccione diferentes agentes.',
  new: 'Nuevo',
  accepted: 'Aceptado',
  inRoute: 'En ruta',
  onScene: 'En escena',
  towInProgress: 'Remolque en curso',
  destinationArrival: 'Destino Llegada',
  complete: 'Íntegro',
  digitalDispatch: 'Despacho Digital',
};