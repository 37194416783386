import React from "react";
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const TowTruck = (props) => {
    return (
      <SvgIcon {...props} viewBox="0 0 181.92 138.71">
        <g transform="translate(-6.3133 -43.781)">
            <path transform="scale(.26458)" d="m91.379 165.47v139.7h-0.04102a16.379 18.104 0 0 1 0.04102 1.2754 16.379 18.104 0 0 1-16.379 18.104 16.379 18.104 0 0 1-16.379-18.104 16.379 18.104 0 0 1 0.04102-1.2754h-34.688a57.931 55.173 0 0 0-0.11328 3.4492 57.931 55.173 0 0 0 57.932 55.172 57.931 55.173 0 0 0 57.932-55.172 57.931 55.173 0 0 0-0.11328-3.4492h0.38867v-58.887l155.43 155.43h-160c-17.67 0-32 14.33-32 32v64h-16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h49.611c-0.76 5.27-1.6113 10.52-1.6113 16 0 61.86 50.14 112 112 112 61.86 0 112-50.14 112-112 0-5.48-0.84937-10.73-1.6094-16h67.23c-0.75999 5.27-1.6094 10.52-1.6094 16 0 61.86 50.14 112 112 112 61.86 0 112-50.14 112-112 0-5.48-0.85134-10.73-1.6113-16h49.6c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-16v-64c0-17.67-14.33-32-32-32h-48l-108.78-135.98a64.025 64.025 0 0 0-49.98-24.02h-113.24c-17.67 0-32 14.33-32 32v47.188l-155.43-155.43zm268.05 140.24h81.24l76.801 96h-158.04zm-112 224c26.47 0 48 21.53 48 48 0 26.47-21.53 48-48 48-26.47 0-48-21.53-48-48 0-26.47 21.53-48 48-48zm288 0c26.47 0 48 21.53 48 48 0 26.47-21.53 48-48 48-26.47 0-48-21.53-48-48 0-26.47 21.53-48 48-48z"/>
        </g>
      </SvgIcon>
    );
};

export default TowTruck;
