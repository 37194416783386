import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import PropTypes from "prop-types";

/**
 * Stylized dot badge for notifications with a pulse
 * animation; default is green but can be customized.
 * For full props see https://v4.mui.com/api/badge/
 */
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const DotBadge = ({ children, ...rest }) => (
  <StyledBadge {...rest}>{children}</StyledBadge>
);

DotBadge.propTypes = {
  /**
   * Children of this component to be rendered with the badge
   * attached to it
   */
  children: PropTypes.any,
};

DotBadge.defaultProps = {
  children: null,
};

export default DotBadge;
