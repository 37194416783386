/* eslint-disable no-param-reassign */
// Make this setable with some actions from the users

// When appInternalName set gather all the apps, features, functions
// send in both appName and suiteId to endpoint (when ready)
import produce from "immer";

const GET_APPLICATION_INTERNAL_NAME =
  "@applications/get-application-internal-name";

const SET_APPLICATION_DETAILS = "@applications/set-application-details";

const initialState = {
  appInternalName: "default-app",
  type: "client",
  appName: "defaultName",
  appVersion: "1.0.0",
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATION_INTERNAL_NAME: {
      const { applications } = action.payload;
      return produce(state, (draft) => {
        draft.appInternalName = applications.internalName;
      });
    }
    case SET_APPLICATION_DETAILS: {
      const {
        type = "client",
        appName = "defaultName",
        appVersion = "1.0.0",
        appInternalName = "default-app",
      } = action.payload;
      return produce(state, (draft) => {
        draft.type = type;
        draft.appName = appName;
        draft.appVersion = appVersion;
        draft.appInternalName = appInternalName;
      });
    }
    default: {
      return state;
    }
  }
};

export default applicationReducer;
