const mexicoStates = [
  {
    // Aguascalientes
    landRegionCode: 'AG',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 1007,
        y: 120
      },
      transform: {
        x: 1031,
        y: 140
      }
    }
  },
  {
    // Baja California
    landRegionCode: 'BC',
    textOverlay: {
      transform: {
        x: 80,
        y: 50
      }
    }
  },
  {
    // Baja California Sur
    landRegionCode: 'BS',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 1007,
        y: 270
      },
      transform: {
        x: 1031,
        y: 290
      }
    }
  },
  {
    // Campeche
    landRegionCode: 'CM',
    textOverlay: {
      transform: {
        x: 885,
        y: 520
      }
    }
  },
  {
    // Chiapas
    landRegionCode: 'CS',
    textOverlay: {
      transform: {
        x: 810,
        y: 610
      }
    }
  },
  {
    // Chihuahua
    landRegionCode: 'CH',
    textOverlay: {
      transform: {
        x: 356.40094,
        y: 170
      }
    }
  },
  {
    // Coahuila
    landRegionCode: 'CO',
    textOverlay: {
      transform: {
        x: 485,
        y: 230
      }
    }
  },
  {
    // Colima
    landRegionCode: 'CL',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 907,
        y: 270
      },
      transform: {
        x: 931,
        y: 290
      }
    }
  },
  {
    // Mexico City
    landRegionCode: 'DF',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 957,
        y: 170
      },
      transform: {
        x: 981,
        y: 190
      }
    }
  },
  {
    // Durango
    landRegionCode: 'DG',
    textOverlay: {
      transform: {
        x: 395,
        y: 315
      }
    }
  },
  {
    // Guanajuato
    landRegionCode: 'GT',
    textOverlay: {
      transform: {
        x: 520,
        y: 455
      }
    }
  },
  {
    // Guerrero
    landRegionCode: 'GR',
    textOverlay: {
      transform: {
        x: 550,
        y: 570
      }
    }
  },
  {
    // Hidalgo
    landRegionCode: 'HG',
    textOverlay: {
      rect: {
        width: 50,
        // NOTE: For some reason, this height has to be higher than the previous 50, in order to appear the same size?
        height: 60,
        x: 1007,
        y: 210
      },
      transform: {
        x: 1031,
        y: 240
      }
    }
  },
  {
    // Jalisco
    landRegionCode: 'JA',
    textOverlay: {
      transform: {
        x: 420,
        y: 480
      }
    }
  },
  {
    // México
    landRegionCode: 'EM',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 1007,
        y: 70
      },
      transform: {
        x: 1031,
        y: 90
      }
    }
  },
  {
    // Michoacán
    landRegionCode: 'MI',
    textOverlay: {
      transform: {
        x: 500,
        y: 510
      }
    }
  },
  {
    // Morelos
    landRegionCode: 'MO',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 957,
        y: 270
      },
      transform: {
        x: 981,
        y: 290
      }
    }
  },
  {
    // Nayarit
    landRegionCode: 'NA',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 1007,
        y: 320
      },
      transform: {
        x: 1031,
        y: 340
      }
    }
  },
  {
    // Nuevo León
    landRegionCode: 'NL',
    textOverlay: {
      transform: {
        x: 565,
        y: 290
      }
    }
  },
  {
    // Oaxaca
    landRegionCode: 'OA',
    textOverlay: {
      transform: {
        x: 670,
        y: 595
      }
    }
  },
  {
    // Puebla
    landRegionCode: 'PU',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 1007,
        y: 170
      },
      transform: {
        x: 1031,
        y: 190
      }
    }
  },
  {
    // Querétaro
    landRegionCode: 'QT',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 957,
        y: 120
      },
      transform: {
        x: 981,
        y: 140
      }
    }
  },
  {
    // Quintana Roo
    landRegionCode: 'QR',
    textOverlay: {
      transform: {
        x: 940,
        y: 500
      }
    }
  },
  {
    // San Luis Potosí
    landRegionCode: 'SL',
    textOverlay: {
      transform: {
        x: 535,
        y: 400
      }
    }
  },
  {
    // Sinaloa
    landRegionCode: 'SI',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 957,
        y: 320
      },
      transform: {
        x: 981,
        y: 340
      }
    }
  },
  {
    // Sonora
    landRegionCode: 'SO',
    textOverlay: {
      transform: {
        x: 230,
        y: 140
      }
    }
  },
  {
    // Tabasco
    landRegionCode: 'TB',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 957,
        y: 70
      },
      transform: {
        x: 981,
        y: 90
      }
    }
  },
  {
    // Tamaulipas
    landRegionCode: 'TM',
    textOverlay: {
      transform: {
        x: 597,
        y: 350
      }
    }
  },
  {
    // Tlaxcala
    landRegionCode: 'TL',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 907,
        y: 320
      },
      transform: {
        x: 931,
        y: 340
      }
    }
  },
  {
    // Veracruz
    landRegionCode: 'VE',
    textOverlay: {
      rect: {
        width: 50,
        height: 50,
        x: 957,
        y: 220
      },
      transform: {
        x: 981,
        y: 240
      }
    }
  },
  {
    // Yucatán
    landRegionCode: 'YU',
    textOverlay: {
      transform: {
        x: 917,
        y: 450
      }
    }
  },
  {
    // Zacatecas
    landRegionCode: 'ZA',
    textOverlay: {
      transform: {
        x: 455,
        y: 370
      }
    }
  }
];

export default mexicoStates;
