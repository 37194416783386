import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FilesDropzone } from '@nsd/fe';
import {
  Grid,
  TextField,
  Button,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomFieldSingleSelect from '../CustomFieldSingleSelect';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
  },
  description: {
    marginTop: theme.spacing(2),
  },
  dropzone: {
    margin: 0,
  },
  dropzoneGrid: {
    width: '100%',
  },
  dropzoneLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  submitBtn: {
    marginTop: theme.spacing(2),
  },
}));

function SupportForm({
  topics,
  onSubmit,
  allowAttachments = false,
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        topic: '',
        description: '',
        ...(allowAttachments ? { attachments: null } : {}),
      }}
      validationSchema={Yup.object().shape({
        topic: Yup.string().required(),
        description: Yup.string().required(),
        ...(allowAttachments ? { attachments: Yup.mixed() } : {}),
      })}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.content}
        >
          <Grid container spacing={2}>
            <Grid item xs>
              <CustomFieldSingleSelect
                inputLabel='Topic'
                inputPlaceholder={t('TopBar.support.topic')}
                setFieldValue={setFieldValue}
                options={topics}
                value={values.topic}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.topic && errors.topic)}
                helperText={errors.topic}
                name="topic"
                id="topic"
                required
              />
              <TextField
                className={classes.description}
                variant='outlined'
                label='Description'
                name="description"
                id="description"
                onChange={handleChange}
                values={values.description}
                minRows={4}
                fullWidth
                multiline
                required
              />
            </Grid>
            {allowAttachments && (
              <Grid item className={classes.dropzoneGrid}>
                <InputLabel className={classes.dropzoneLabel}>
                  {t('TopBar.support.addScreenshot')}
                </InputLabel>
                <FilesDropzone
                  className={classes.dropzone}
                  disableUpload={true}
                  updateFileList={(files) => {
                    handleChange({
                      target: {
                        name: 'attachments',
                        value: files,
                      },
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Button
            type="submit"
            variant='contained'
            color='secondary'
            className={classes.submitBtn}
            disabled={isSubmitting}
          >
            {
              isSubmitting
                ? (
                  <CircularProgress
                    size={20}
                  />
                )
                : t('common.dialog.submit')
            }
          </Button>
        </form>
      )}
    </Formik>
  );
}

SupportForm.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onSubmit: PropTypes.func,
  allowAttachments: PropTypes.bool,
};

export default SupportForm;
