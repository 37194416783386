import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from 'src/components/ErrorPage';
// import comingSoonIcon from 'https://nsdstaticasset.blob.core.windows.net/assets/Platform_Status_Images/coming_soon.png';

const ComingSoonView = ({ message, header , ...rest}) => {
  const comingSoonIcon = 'https://nsdstaticasset.blob.core.windows.net/assets/Platform_Status_Images/coming_soon.png';
  return (
    <ErrorPage
      title='Coming Soon'
      header={header}
      message={message}
      imgSrc={comingSoonIcon}
      imgAlt='Coming Soon'
      {...rest}
    />
  );
};

ComingSoonView.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string
};

ComingSoonView.defaultProps = {
  header: 'Coming Soon',
  message: `We're working hard to create this app. Once we're finished you'll
  be able to access this page.`
};

export default ComingSoonView;
