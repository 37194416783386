import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, SvgIcon } from '@material-ui/core';
import { CheckCircle as CheckIcon, Error as ErrorIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  finishRequest: {
    minHeight: 250,
    textAlign: 'center',
  },
  checkIcon: {
    color: '#00ec96',
    fontSize: '8em',
  },
  errorIcon: {
    color: '#ff4c51',
    fontSize: '8em',
  },
}));

function FinishRequest({ hadError }) {
  const { t } = useTranslation();

  const classes = useStyles();

  if (hadError) {
    return (
      <div className={classes.finishRequest}>
        <SvgIcon className={classes.errorIcon}>
          <ErrorIcon />
        </SvgIcon>
        <Typography variant='h2'>
          {t('TopBar.support.couldNotSubmitRequest')}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.finishRequest}>
      <SvgIcon className={classes.checkIcon}>
        <CheckIcon />
      </SvgIcon>
      <Typography variant='h2'>
        {t('TopBar.support.thankYouForYourFeedback')}
        <br />
        {t('TopBar.support.weAppreciateYourPartnership')}
      </Typography>
    </div>
  );
}

FinishRequest.propTypes = {
  hadError: PropTypes.bool,
};

export default FinishRequest;
