/*
 Helper file to generate a variable number of alerts in order to
 test the queuing capability of the Alert system.
*/
export const createSystemAlertMessages = async (
  number = 2,
  FirebaseCrudInstance
) => {
  if (!FirebaseCrudInstance) return;
  const path = FirebaseCrudInstance.getAlertsPath();

  const genericMessage = {
    to: '',
    from: 'SYSTEM',
    read: false,
    text: 'Please check this link',
    severity: 'warning',
    pageLink: '/admin/users',
  };
  const genericMessage2 = {
    to: '',
    from: 'SYSTEM',
    read: false,
    text: 'This is a generic warning message in the queue',
    severity: 'info',
    pageLink: 'https://www.google.com',
  };
  const promises = [];
  for (let i = 0; i < number; i++) {
    if (i >= 1) {
      promises.push(FirebaseCrudInstance.createOne(path, genericMessage2));
    } else {
      promises.push(FirebaseCrudInstance.createOne(path, genericMessage));
    }
  }
  await Promise.all(promises);
  console.log(`Successfully created ${number} alerts in firestore`);
};

export const createSystemNotifications = async (
  number = 2,
  FirebaseCrudInstance
) => {
  if (!FirebaseCrudInstance) return;
  const path = FirebaseCrudInstance.getNotificationsPath();
  const genericMessage = {
    to: '',
    from: 'SYSTEM',
    read: false,
    text: 'Please check this link',
    severity: 'warning',
    pageLink: '/admin/users',
  };
  const genericMessage2 = {
    to: '',
    from: 'SYSTEM',
    read: false,
    text: 'This is a generic warning message in the queue',
    severity: 'info',
    pageLink: 'https://www.google.com',
  };
  const promises = [];
  for (let i = 0; i < number; i++) {
    if (i >= 1) {
      promises.push(FirebaseCrudInstance.createOne(path, genericMessage2));
    } else {
      promises.push(FirebaseCrudInstance.createOne(path, genericMessage));
    }
  }
  await Promise.all(promises);
  console.log(`Successfully created ${number} notifications in firestore`);
};
