import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  ChevronLeft as BackIcon,
  ChevronRight as ForwardIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  Error as ErrorIcon,
} from "@material-ui/icons";
import { ZoomIn, ZoomOut } from "react-feather";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;



const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    color: theme.palette.error.main,
    fontFamily: theme.typography.h3.fontFamily,
  },
  errorIcon: {
    margin: theme.spacing(3),
    fontSize: theme.typography.h1.fontSize,
  },
}));

/**
 * A component that renders a PDF with a fullscreen dialog from
 * a file url or base64 url. Does not pull from blob storage for files
 * there will be a separate document viewer component made for that in the future.
 * Can be used as composite component within AllDocumentViewer or
 * used individually
 */
const PdfViewer = ({ show, close, file, className, renderMenu, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { dispatch, state } = rest || {};
  const { pageNumber, fileZoom, fileViewPages, error } = state || {};
  const closePreview = () => close();

  if (error)
    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
        alignContent="center"
        textAlign="center"
        className={classes.error}
      >
        <ErrorIcon className={classes.errorIcon} color="error" />
        <Typography variant="h4">{error}</Typography>
      </Box>
    );
  if (!file) return null;

  return (
    <>
      {renderMenu ? (
        <Dialog open={show} fullScreen onClose={closePreview}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={close}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Box flexGrow={1} />
              <IconButton
                disabled={fileZoom === 0.5}
                onClick={() => {
                  dispatch({ type: "zoomOut" });
                }}
              >
                <ZoomOut color={theme.palette.primary.contrastText} />
              </IconButton>
              <IconButton
                disabled={fileZoom === 5}
                onClick={() => {
                  dispatch({ type: "zoomIn" });
                }}
              >
                <ZoomIn color={theme.palette.primary.contrastText} />
              </IconButton>
              <Box flexGrow={1} />
              <Button
                autoFocus
                color="inherit"
                variant={"outlined"}
                startIcon={<CloudDownloadIcon />}
                onClick={() => {
                  window.open(file.accessUrl, "_blank");
                  closePreview();
                }}
              >
                Download
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PerfectScrollbar style={{ paddingTop: 50 }}>
              <Document
                onLoadSuccess={(pdf) => {
                  dispatch({
                    type: "setFileViewPages",
                    payload: { fileViewPages: pdf?.numPages },
                  });
                }}
                file={
                  file?.base64Url
                    ? `data:application/pdf;base64,${file?.base64Url}`
                    : file?.uri
                }
                error={error}
                onLoadError={(error) =>
                  dispatch({ type: "setError", payload: { error } })
                }
                onSourceError={(error) =>
                  dispatch({ type: "setError", payload: { error } })
                }
              >
                <Page scale={fileZoom} pageNumber={pageNumber} />
              </Document>
            </PerfectScrollbar>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BottomNavigation showLabels className={classes.bottomNav}>
              <BottomNavigationAction
                label="Previous"
                value="recents"
                onClick={() => {
                  dispatch({ type: "decrementPage" });
                }}
                disabled={pageNumber === 1}
                className={clsx({
                  [classes.bottomNavItem]: true,
                  [classes.bottomNavItemDisabled]: pageNumber === 1,
                })}
                icon={<BackIcon />}
              />
              <BottomNavigationAction
                disabled
                className={classes.bottomNavItem}
                label={
                  fileViewPages === 0
                    ? `${0} of ${fileViewPages}`
                    : `${pageNumber} of ${fileViewPages}`
                }
                value="recents"
              />
              <BottomNavigationAction
                label="Next"
                value="recents"
                onClick={() => {
                  dispatch({ type: "incrementPage" });
                }}
                className={clsx({
                  [classes.bottomNavItem]: true,
                  [classes.bottomNavItemDisabled]: pageNumber === fileViewPages,
                })}
                disabled={pageNumber === fileViewPages}
                icon={<ForwardIcon />}
              />
            </BottomNavigation>
          </DialogActions>
        </Dialog>
      ) : (
        <Document
          className={className}
          onLoadSuccess={(pdf) => {
            dispatch({
              type: "setFileViewPages",
              payload: { fileViewPages: pdf?.numPages },
            });
          }}
          file={
            file?.base64Url
              ? `data:application/pdf;base64,${file?.base64Url}`
              : file?.uri
          }
          error={error}
          onLoadError={(error) =>
            dispatch({ type: "setError", payload: { error } })
          }
          onSourceError={(error) =>
            dispatch({ type: "setError", payload: { error } })
          }
        >
          <Page
            scale={fileZoom}
            pageNumber={pageNumber}
            style={{
              width: "100%",
            }}
          />
        </Document>
      )}
    </>
  );
};

PdfViewer.propTypes = {
  /**
   * Weather or not the component is rendered
   */
  show: PropTypes.bool,
  /**
   * Callback that is invoked when the close icon is clicked.
   * It does not receive any arguments
   */
  close: PropTypes.func.isRequired,
  /**
   * Document/pdf to fetch from a URL; can pass in
   * one of the 2 fields (base64Url or Url) to render
   * the file
   */
  document: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    base64Url: PropTypes.string,
    uri: PropTypes.string,
  }),
  /**
   * Weather or not the component is rendered
   */
  renderMenu: PropTypes.bool,
};

PdfViewer.defaultProps = {
  show: true,
  close: () => {},
  document: null,
  renderMenu: false,
};

export default PdfViewer;
