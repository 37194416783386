import React, { useEffect, useState } from "react";
import NProgress from "nprogress";
import {
  Box,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Logo from "./Logo";
import APPS from "../constants/apps";
const LOGO_URL =
  "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Logos/Qore_Full_Color_logo-01.png";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    minHeight: "100%",
    padding: theme.spacing(3),
  },
  logo: {
    width: 150,
    maxWidth: "100%",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

/**
 * Component to be used as fallback prop to Suspense component to
 * show an appropriate loading screen for the current app
 */
function LoadingTableData({ appName, children }) {
  const classes = useStyles();
  const theme = useTheme();
  const [logo, setLogo] = useState(LOGO_URL);

  useEffect(() => {
    NProgress.start();

    APPS.forEach((app) => {
      if (
        (app.logo &&
          (location.hostname && location.hostname.indexOf(app.appName)) >= 0) ||
        (appName && appName.indexOf(app.appName) >= 0)
      ) {
        setLogo(app.logo);
      }
    });

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <TableRow>
      <TableCell colSpan={100}>
        <div className={classes.root}>
          <Logo className={classes.logo} image={logo} />
          <br />
          <LinearProgress style={{ width: 400 }} />
          <Box
            margin={1}
            width={400}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant={"h4"}>{children}</Typography>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
}

LoadingTableData.propTypes = {
  /**
   * String representing one of the app names developed by NSD
   */
  appName: PropTypes.oneOf([
    "dispatch-qore",
    "lender-qore",
    "client-qore",
    "provider-qore",
    "claims-qore",
    "contact-qore",
    "insight-qore",
    "sales-qore",
    "marketing-qore",
    "market-qore",
    "developer-qore",
    "pricebook-qore",
    "underwriter-qore",
    "integration-qore",
    "admin-qore",
    "driver-qore",
    "billing-qore",
    "agent-qore",
    "dealer-qore",
    "licensing-qore",
    "ledger-qore",
    "data-qore",
    "notifications-qore",
  ]),
  /**
   * String message to be displayed in the table row below the loading
   * indicator
   */
  children: PropTypes.string,
};

LoadingTableData.defaultProps = {
  appName: "dispatch-qore",
  children: "Loading...",
};

export default LoadingTableData;
