export default {
  pageHeaders: {
    refresh: 'Refresh',
  },
  charts: {
    download: 'Download',
    moreCharts: 'More charts',
    moreOptions: 'More options',
    print: 'Print',
    completedBy_fragment: 'Completed by',
    total: 'Total',
    clearSelection: 'Clear Selection',
    least: 'Least',
    most: 'Most',
    averages: 'Averages',
    averageDay: 'Average/Day',
    day: 'Day',
    export: 'Export',
  },
  detailTabs: {
    details: 'Details',
    overview: 'Overview',
    notes: 'Notes',
    photos: 'Photos',
    attachments: 'Attachments',
    providerNotes: 'Provider Notes',
    internalNotes: 'Internal Notes',
    closedLoop: 'Closed Loop',
    offerList: 'Offer List',
    other: 'Other',
  },
  dialog: {
    comments: 'Comments',
    customDateAndTime: 'Custom Date and Time',
    startDateTime: 'Start Date/Time',
    endDateTime: 'End Date/Time',
    selectCustomDateAndTimeRange: 'Select a custom date and time range',
    submit: 'Submit',
  },
  files: {
    selectFiles: 'Select Files',
    dropHere_fragment: 'Drop files here or click',
    browse_fragment: 'browse',
    throughYourMachine_fragment: 'through your machine',
    sizeLimit: 'File size limit is',
    size: 'Size',
  },
  form: {
    cancel: 'Cancel',
    emailMustBeValid: 'Email must be valid',
  },
  location: {
    label: {
      canada: 'Canada',
      mexico: 'Mexico',
      unitedStates: 'United States',
    },
    state: 'State',
    states: 'States',
    province: 'Province',
    provinces: 'Provinces',
    country: 'Country',
    addLocation: 'Add Location',
  },
  menu: {
    close: 'Close',
    save: 'Save',
  },
  options: {
    all: 'All',
    noOptions: 'No Options',
  },
  reports: {
    lastUpdated: 'Last Updated',
    segment: 'Segment',
    service: 'Service',
    by_fragment: 'by',
  },
  table: {
    actions: 'Actions',
    details: 'Details',
    rowsPerPage: 'Rows per Page',
    of_N_Rows: 'of',
    more_than_N_Rows: 'more',
    loadMore: 'Load More',
    newestToOldest_lowerCase: 'newest-oldest',
    oldestToNewest_lowerCase: 'oldest-newest',
    sortDisabledForTimeRange: 'Sort Disabled for Time Range',
    anyDate: 'Any Date',
    anyHour: 'Any Hour',
    anyMonthYear: 'Any Month Year',
    dateCreated: 'Date Created',
    sortBy: 'Sort By',
    searchBy: 'Search By',
    notes: 'Notes',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    homePhone: 'Home Phone',
    city: 'City',
    stateOrProvince: 'State / Province',
    state: 'State',
    address: 'Address',
    zipCode: 'Zip Code',
    business: 'Business',
    agentName: 'Agent Name',
    agentCode: 'Agent Code',
    product: 'Product',
    plan: 'Plan',
    claimNumber: 'Claim Number',
    attachments: 'Attachments',
    fileName_aToZ: 'File Name (a-z)',
    fileName_zToA: 'File Name (z-a)',
    no: 'No',
    yes: 'Yes',
    pagination: {
      firstPage: 'First Page',
      previousPage: 'Previous Page',
      nextPage: 'Next Page',
      lastPage: 'Last Page',
    },
    towDistance: 'Tow Distance',
  },
  time: {
    today: 'Today',
    yesterday: 'Yesterday',
    last7Days: 'Last 7 Days',
    last30Days: 'Last 30 Days',
    last60Days: 'Last 60 Days',
    last90Days: 'Last 90 Days',
    allTime: 'All Time',
    custom: 'Custom',
    month: 'Month',
    months: 'Months',
    months_apr: 'Apr',
    months_aug: 'Aug',
    months_dec: 'Dec',
    months_feb: 'Feb',
    months_jan: 'Jan',
    months_jul: 'Jul',
    months_jun: 'Jun',
    months_mar: 'Mar',
    months_may: 'May',
    months_nov: 'Nov',
    months_oct: 'Oct',
    months_sep: 'Sep',
    date: 'Date',
    week: 'Week',
    minutes: 'Minutes',
    hour: 'Hour',
    day: 'Day',
    midnight: 'Midnight',
    noon: 'Noon',
    year: 'Year',
    monthYear: 'MonthYear',
  },
};
