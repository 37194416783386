export default {
  operational: "Operational",
  "dispatches": "Dispatches",
  "dispatchCount": "Dispatch Count",
  "dispatchCount_joined": "Dispatch Count",
  "totalDispatchesCompletedByDayMonth": "Total Dispatches Completed by Day/Month",
  "completedImpoundTowsByWeekOfMonth": "Completed Impound Tows by Week of Month",
  "allImpoundTowsMustBeCompletedToBeCounted": "All impound tows must be completed to be counted.",
  "completedImpoundAverageMilesPerTowPerDayByWeekOfMonth": "Completed Impound Average miles per Tow per Day by Week of Month",
  "impoundTows": "Impound Tows",
  "avgETAByDayMonthCompleted": "Average Estimated Time of Arrival (ETA) by Day/Month (Completed)",
  "avgMilesPerTowByDayMonthCompleted": "Average Miles Per Towing (MPR) per Day/Month (Completed)",
  "completedLongTowsAsAPercentageOfTotalTows": "Completed Long Tows as a % of Total Tows  (Tows >= 100Miles)",
  "percentIsCalulatedByTwoWayTowsPlusTotalDispatches": "Percent is calculated by two way tows ÷ total dispatches.",
  "aTowWithLessThanOrEqualTo100MilesIsALongTow": "A tow with >= 100 miles is a long tow.",
  "percentIsCalculatedByLongTowsPlusTotalTows": "Percent is calculated by long tows ÷ total tows.",
  "milesPerImpoundTow": "Miles/Impound Tow",
  "averageMilesCompletedImpoundTowAreShown": "Average Miles/Completed Impound Tows are shown.",
  "completedTwoWayTowsAsAPercentageOfTotalDispatches": "Completed Two Way Tows (TWT) as a % of Total Dispatches",
};