import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { makeStyles } from "@material-ui/core/styles";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    "& .rdw-option-wrapper": {
      background: "transparent",
      border: "none",
      minWidth: 26,
      padding: 6,
      "&:hover": {
        boxShadow: "none",
        backgroundColor: theme.palette.action.hover,
      },
    },
    "& .rdw-option-active": {
      boxShadow: "none",
      backgroundColor: theme.palette.action.selected,
    },
    "& .rdw-dropdown-wrapper": {
      boxShadow: "none",
      background: "transparent",
    },
    "& .rdw-dropdown-optionwrapper": {
      overflowY: "auto",
      boxShadow: theme.shadows[10],
      padding: theme.spacing(1),
    },
  },
  toolbar: {
    marginBottom: 0,
    borderLeft: "none",
    borderTop: "none",
    borderRight: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: "transparent",
  },
  editor: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
}));

/**
 * Draft editor component that displays WSIWYG GUI editor
 * with basic editor features like font size/style, link insertion
 * and image insertion
 */
function DraftEditor({
  wrapperClass,
  toolbarClass,
  editorClass,
  onEditorChange,
  ...rest
}) {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    if (onEditorChange) onEditorChange(newEditorState);
    setEditorState(newEditorState);
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      wrapperClassName={clsx(classes.root, wrapperClass)}
      toolbarClassName={clsx(classes.toolbar, toolbarClass)}
      editorClassName={clsx(classes.editor, editorClass)}
      {...rest}
    />
  );
}

DraftEditor.propTypes = {
  /**
   * Class name you wish to apply to the wrapper/container around the Editor
   */
  wrapperClass: PropTypes.string,
  /**
   * Class name you wish to apply toolbar on the editor
   */
  toolbarClass: PropTypes.string,
  /**
   * Class name you wish to apply to the text area portion of the editor
   */
  className: PropTypes.string,
  /**
   * Callback to be invoked when the editor state changes; receives
   * the new editor state object as an arguement.
   * https://draftjs.org/docs/api-reference-editor-state/
   */
  onEditorChange: PropTypes.func,
};

DraftEditor.defaultProps = {
  wrapperClass: "",
  toolbarClass: "",
  editorClass: "",
};

export default DraftEditor;
