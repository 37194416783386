import React from "react";
import PropTypes from "prop-types";

let NSD_LOGO =
  "https://nsdstaticasset.blob.core.windows.net/assets/Colour_Qore_Icon.png";

/**
 * Logo component to display any logo image or the NSD logo
 */
function Logo({ mainIcon, image, width, altText, ...props }) {
  let displayImage;
  mainIcon && !image ? (displayImage = NSD_LOGO) : (displayImage = image);
  return <img alt={altText} src={displayImage} width={width} {...props} />;
}

Logo.propTypes = {
  /**
   * Boolean indicating weather or not to use the NSD logo
   */
  mainIcon: PropTypes.bool,
  /**
   * Valid URL to new logo image
   */
  image: PropTypes.string,
  /**
   * The width of the logo
   */
  width: PropTypes.number,

  /**
   * Alt text for the passed in image
   */
  altText: PropTypes.string,
};

Logo.defaultProps = {
  mainIcon: true,
  image: NSD_LOGO,
  width: 50,
  altText: 'Logo'
};

export default Logo;
