import React, { useState } from "react";
import {
  Popover,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {},
  averageChip: {
    marginRight: 10,
  },
}));

/**
 * Component to display a small popup table when the rendered
 * chip is hovered. Used to render an array of data that
 * signifies the month and average properties on each item
 * in the array (used primarily to display monthly revnue etc...)
 */
const PopoverTable = ({ data, title, className }) => {
  // const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <Chip
        className={clsx(className, classes.averageChip)}
        variant="outlined"
        color="primary"
        size="small"
        label={title || "Averages"}
        // label={t("averages")}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell align="right">
                {
                  "Average/Day"
                  // t("averageDay")
                }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((entry) => (
              <TableRow hover key={entry.month}>
                <TableCell>{entry.month}</TableCell>
                <TableCell align="right">{entry.average}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Popover>
    </div>
  );
};

PopoverTable.propTypes = {
  /**
   * Custom class to apply to the rendered Chip component
   */
  className: PropTypes.string,
  /**
   * Array of data display within the table
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      average: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ),
  /**
   * Text to be rendered in the chip
   */
  title: PropTypes.string,
};

PopoverTable.defaultProps = {
  className: "",
  data: null,
  title: "",
};

export default PopoverTable;
