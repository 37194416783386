import { lazy } from 'react';
import { AuthGuard } from '@nsd/fe';
import UsersIcon from 'react-feather/dist/icons/user';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { appIcon } from '../appIcon';

const MODULES = {
  USERS: 'users-7293807b-815f-4fde-8f32-8a7dbfede045',
};

const appConfig = {
  appId: 'admin-cf196b57-0774-4a2c-bff0-04a4f44d7f0d',
  appName: 'admin.admin',
  priority: 8,
  path: 'admin',
  icon: appIcon.adminQoreApp,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: 'users',
      view: lazy(() => import('src/views/AdminUsersView/index')),
    },
  ],
  topBarConfig: {
    contacts: false,
    search: false,
    notifications: true,
    settings: true,
  },
  navConfig: {
    navBarItems: [
      {
        subheader: 'admin.assets',
        items: [
          {
            title: 'admin.users',
            icon: UsersIcon,
            href: 'users',
            module: MODULES.USERS,
          },
        ],
      },
    ],
  },
};

export default appConfig;
