/* eslint-disable no-param-reassign */
import axios, { CancelToken, isCancel } from 'axios';
import { getEnvironment, APP_ENV } from 'src/utils/env';

export const getNsdApiBaseUrl = () => {
  const environment = getEnvironment();

  // if (environment === APP_ENV.STAGING) {
  //   return process.env.REACT_APP_API_STAGING;
  // }

  let nsdApiBaseUrl = process.env.REACT_APP_API;
  // if (nsdApiBaseUrl) {
  //   return nsdApiBaseUrl;
  // }

  switch (environment) {
    case APP_ENV.LOCAL:
      nsdApiBaseUrl = process.env.REACT_APP_APPLICATION_API;
      break;
    case APP_ENV.DEVELOPMENT:
      nsdApiBaseUrl = process.env.REACT_APP_APPLICATION_API;
      break;
    case APP_ENV.STAGING:
      nsdApiBaseUrl = process.env.REACT_APP_APPLICATION_API;
      break;
    default:
      nsdApiBaseUrl = process.env.REACT_APP_APPLICATION_API;
  }

  return nsdApiBaseUrl;
};

const getAPIHeader = (env) => {
  let header;
  if (env !== APP_ENV.PRODUCTION) {
    header = {
      common: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_NSD_API_KEY,
        'x-functions-key': process.env.REACT_APP_NSD_APP_FUNC_KEY
      }
    };
  } else {
    header = {
      common: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_NSD_API_KEY,
        'x-functions-key': process.env.REACT_APP_NSD_APP_FUNC_KEY
      }
    };
  }
  return header;
};
const nsdAPIHeader = getAPIHeader(getEnvironment());

const appInstance = axios.create({
  headers: nsdAPIHeader,
  withCredentials: true,
  baseURL: getNsdApiBaseUrl()
});

appInstance.interceptors.request.use(
  (config) => {
    config.headers.common['Host-Site'] = 'abg';

    // use functions directly instead of api management for develop and staging
    let apiCode = process.env.REACT_APP_API_CODE;
    const environment = getEnvironment();
    if (environment === APP_ENV.STAGING) {
      apiCode = process.env.REACT_APP_API_CODE_STAGING;
    }
    if (apiCode && environment !== APP_ENV.PRODUCTION) {
      config.params = { ...config.params, code: apiCode };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default appInstance;
export { CancelToken, isCancel };
