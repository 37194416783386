import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

/**
 * Component that asynchronously injects the script tags needed
 * to enable Google Tag Manager and/or Google Analytics.
 */
function GoogleAnalytics({ googleMeasurementId }) {
  if (!GA_MEASUREMENT_ID || !googleMeasurementId) return null;
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script>
    </Helmet>
  );
}

GoogleAnalytics.propTypes = {
  /**
   * String representing your Google Tag Manager ID, may also be
   * set through an environment variable called REACT_APP_GA_MEASUREMENT_ID
   */
  googleMeasurementId: PropTypes.string,
};

GoogleAnalytics.defaultProps = {
  googleMeasurementId: "",
};

export default GoogleAnalytics;
