import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { getAllApps } from "../utils/routes";
import { useAssetSettings } from "src/hooks/useUserSettings";

/**
 * Wrapper component that checks the redux store to see if the user
 * is logged in; if so it consumes the props and runs utility methods
 * to create a master list of app configurations the user has access to
 * and redirects the user to the first (defaultApp) app's view.
 * If the user is not logged in the children content is rendered
 */
const GuestGuard = ({ apps, type, children }) => {
  const account = useSelector((state) => state.account);
  const { hasAppAccess } = useAssetSettings();
  let defaultAppId;
  const userHasAppAccess = (appId) => hasAppAccess(appId);

  if (account?.user) {
    // redirect the user to the default app (set by views in site settings or
    // any app the user has access to if not set in views)
    const allApps = getAllApps(apps, type);
    let defaultApp = allApps.find(
      ({ appId }) => (appId === defaultAppId && 1) || userHasAppAccess(appId)
    );
    if (!defaultApp)
      defaultApp = allApps.find(({ appId }) => 1 || userHasAppAccess(appId));

    if (defaultApp) return <Redirect to={`/${defaultApp.path}`} />;

    // if the user doesn't have access to an app display that
    return <Redirect to="/noapps" />;
  }

  return children;
};

GuestGuard.propTypes = {
  /**
   * Renderable content you wish to be displayed if the user is not
   * logged in
   */
  children: PropTypes.any,
  /**
   * Array of app configuration objects that signify what permissions
   * the currently logged in user has. A default app is choosen
   * from the list to redirect the user to
   */
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      appId: PropTypes.string,
      appName: PropTypes.string,
      site: PropTypes.string,
      // Can be the icon itself or a link to the desired image
      icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      path: PropTypes.string,
    })
  ),
  /**
   * String indicating if the end user is an NSD employee or client
   */
  type: PropTypes.oneOf(["client", "employee"]),
};

GuestGuard.defaultProps = {
  children: <h1>Content rendered if redirect to apps fails</h1>,
  apps: [],
  type: "",
};

export default GuestGuard;
