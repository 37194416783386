import React from "react";
import PropTypes from "prop-types";
import { IconButton, SvgIcon, Tooltip } from "@material-ui/core";

/**
 * Singular component to display an IconButton with a specified
 * icon
 */
const TooltipAction = ({ title, onClick, Icon, IconButtonProps }) => {
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick} {...IconButtonProps}>
        <SvgIcon fontSize="small">{Icon}</SvgIcon>
      </IconButton>
    </Tooltip>
  );
};

TooltipAction.propTypes = {
  /**
   * Text that appears on the tooltip when the action is hovered
   */
  title: PropTypes.string,
  /**
   * Callback that fires when one of the actions is clicked.
   * It is passed 1 argument; the event generated by the click
   */
  onClick: PropTypes.func,
  /*
   *  The icon element/component to appear within the button
   */
  Icon: PropTypes.element,
  /**
   * Additional props you can use to customize the IconButton
   * https://v4.mui.com/api/icon-button/#iconbutton-api
   */
  IconButtonProps: PropTypes.object,
};

/**
 * Composite component made up of ToolTipAction components that
 * are mapped over
 */
const TooltipActions = ({ actions }) => {
  return actions.map((action) => (
    <TooltipAction key={action.title} {...action} />
  ));
};

TooltipActions.propTypes = {
  /**
   * Array of action icon buttons to generate
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      Icon: PropTypes.element.isRequired,
      IconButtonProps: PropTypes.object,
    })
  ),
};

TooltipActions.defaultProps = {
  actions: [],
};

export default TooltipActions;
