import React, { useState, forwardRef, useImperativeHandle } from "react";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

/**
 * Component to be used with the ResetPasswordDialog; this is
 * used within that component to generate a series of number input fields
 * that lets users specify the criteria (number of alphanumeric chars,
 * number of special chars etc...) for their generated password. It
 * accepts a forwarded ref and allows that ref to use
 * generateRandomPassword() (returns string)
 *
 */
const ResetPasswordDialogSettings = forwardRef(({ open, settings }, ref) => {
  const initialNumberOfType = Object.values(settings).reduce((acc, cur) => {
    acc[cur.key] = cur.initialNumberOfType;
    return acc;
  }, {});
  const [generatePasswordCharacterCounts, setGeneratePasswordCharacterCounts] =
    useState(initialNumberOfType);

  const handleChange = (characterType) => (event) => {
    const newLength = Number(event.target.value);
    setGeneratePasswordCharacterCounts((prevState) => ({
      ...prevState,
      [characterType]: newLength,
    }));
  };

  useImperativeHandle(ref, () => ({
    generateRandomPassword: () => {
      const charset = settings.map(({ key, options }) => ({
        numberOfType: generatePasswordCharacterCounts[key],
        options,
      }));

      const passwordArray = charset.reduce((acc, cur) => {
        const random = [...Array(cur.numberOfType)].map(() => {
          return cur.options.charAt(
            Math.floor(Math.random() * cur.options.length)
          );
        });
        return acc.concat(random);
      }, []);

      // shuffle all characters
      return passwordArray.sort(() => Math.random() - 0.5).join("");
    },
  }));

  return (
    <Collapse in={open} timeout="auto">
      <Box mt={4}>
        <Grid container spacing={3}>
          {settings.map((setting) => {
            return (
              <Grid item xs={4} key={setting.key}>
                <TextField
                  label={setting.title}
                  onChange={handleChange(setting.key)}
                  value={generatePasswordCharacterCounts[setting.key]}
                  type="number"
                  inputProps={{ min: "0" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Collapse>
  );
});

ResetPasswordDialogSettings.propTypes = {
  /**
   * Weather or not the settings dialog is rendered
   */
  open: PropTypes.bool.isRequired,

  /**
   * Array of objects specifying the configuration for what
   * fields you want to have generated. For each a number
   * input is rendered, title is the label.
   * initialNumberOfType is how many characters to use for this
   * specific setting when generating the password
   * options is a string of all possible characters for this
   * group of characters. (e.g for all letters
   * options: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ")
   *
   *
   */
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      initialNumberOfType: PropTypes.number,
      options: PropTypes.string,
    })
  ),
};

ResetPasswordDialogSettings.defaultProps = {
  open: false,
  settings: [
    {
      title: "Letters",
      key: "letters",
      initialNumberOfType: 9,
      options: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    },
    {
      title: "Numbers",
      key: "numbers",
      initialNumberOfType: 2,
      options: "0123456789",
    },
    {
      title: "Special Chars",
      key: "specialCharacters",
      initialNumberOfType: 1,
      options: "!@#$%^&*=-_",
    },
  ],
};

export default ResetPasswordDialogSettings;
