import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Hook that resets the scroll to the top of the window
 * whenever the pathname (url) is changed
 */
export default function useScrollReset() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
