export const TURO_US_AGENTS = [
  155879,
  156199,
  156200
];

export const TURO_CANADA_AGENTS = [
  152427,
  156201,
  156202
];

export const TURO_US_ROADSIDE = 155879

export const TURO_CANADA_ROADSIDE = 152427

