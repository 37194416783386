/* eslint-disable no-param-reassign */
import React from "react";
import produce from "immer";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  HIDE_ALERT,
  SHOW_ALERT,
  UPDATE_ALERT_QUEUE,
  SET_ALERT_SETTINGS,
} from "../actions/systemAlertActions";
const CollapseTransition = React.lazy(() =>
  import("@material-ui/core/Collapse")
);
const FadeTransition = React.lazy(() => import("@material-ui/core/Fade"));
const GrowTransition = React.lazy(() => import("@material-ui/core/Grow"));
const SlideTransition = React.lazy(() => import("@material-ui/core/Slide"));
const ZoomTransition = React.lazy(() => import("@material-ui/core/Zoom"));

/*
  Alert shape
  {
    to: '', //Leave to blank to send to all users
    from: '',
    read: false,
    text: '',
    severity: ['info' | 'warning' | 'error' | 'success'],
    // Relative or absolute link
    pageLink: "/somePlace"
  }  
 */

const initialState = {
  open: false,
  alertsQueue: [],
  currentAlert: null,
  actionButtonContent: CloseIcon,
  startIcon: null,
  // Used standard or filled
  variant: "filled",
  // top or bottom
  position: "top",
  transitionComponent: GrowTransition,
};

const systemAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT: {
      // Make sure there is something in the queue first
      const alertPresent = state.alertsQueue.length >= 0;
      const [currentAlert] = state.alertsQueue;
      return produce(state, (draft) => {
        draft.open = alertPresent;
        draft.currentAlert = currentAlert;
      });
    }
    case HIDE_ALERT: {
      return produce(state, (draft) => {
        draft.currentAlert = null;
        draft.open = false;
      });
    }
    case UPDATE_ALERT_QUEUE: {
      const { newAlerts } = action?.payload;
      return produce(state, (draft) => {
        draft.alertsQueue = newAlerts;
      });
    }
    case SET_ALERT_SETTINGS: {
      const {
        actionButtonContent = CloseIcon,
        startIcon = null,
        variant,
        position = "top",
        transitionComponent = GrowTransition,
      } = action?.payload || {};
      return produce(state, (draft) => {
        draft.actionButtonContent = actionButtonContent;
        draft.startIcon = startIcon;
        draft.variant = variant;
        draft.position = position;
        draft.transitionComponent = transitionComponent;
      });
    }
    default: {
      return state;
    }
  }
};

export default systemAlertReducer;
