import  React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useAssetSettings } from 'src/hooks/useUserSettings';
import { Redirect } from 'react-router-dom';

const ModuleContext = createContext(null);

export const ModuleProvider = ({ module, children }) => {
  const { hasModuleAccess } = useAssetSettings();
  return hasModuleAccess(module) ? (
    <ModuleContext.Provider
      value={module}
    >
      {children}
    </ModuleContext.Provider>
  ) : <Redirect to="/403" />;
};

ModuleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  module: PropTypes.string.isRequired
};

export default ModuleContext;
