import React, { useImperativeHandle, useContext } from 'react';
import { useFetch } from 'src/hooks/useFetch';
import PropTypes from 'prop-types';
import axios from 'src/utils/axios';
// To support nested properties with responseKey prop
import { get } from 'lodash';

/**
 * Higher order component to be wrapped around TabTable as
 * long as the url prop is not null. It sets up the parameters
 * objects, creates responseKey (if not null), initiates the fetch
 * request for tableData and adds a function to refresh the table
 * on the ref
 */
export default (Table) => {
  const TableWithAsync = ({
    activeTab,
    forwardref,
    limit,
    onTableDataLoaded,
    page,
    paginationState,
    responseKey,
    server,
    sort,
    tabs,
    url,
    isGraphQLQuery,
    afterInitialFetch,
    gqlService,
    gqlServiceInitialMethod,
    gqlServiceInitialParams,
    method,
    body,
    ...rest
  }) => {
    const { params } = rest;
    const [pagination] = paginationState;
    const isActiveTabEmpty = tabs
      && tabs.options.find((option) => option.value === activeTab)?.empty;     
    const asyncParams = {
      // GQL query and variables should be in here
      ...params,
      ...(server && {
        pagination: {
          ...pagination,
          filters: {
            ...pagination.filters,
            ...(tabs && !isActiveTabEmpty && { [tabs.name]: activeTab })
          }
        }
      })
    };

    const respKey = responseKey ?? url.split('/').slice(-1)[0];
    const { response, isLoading, fetchData, clearResponseCache } = useFetch(
      url,
      asyncParams,
      body,
      method,
      null,
      afterInitialFetch ?? null,
      true,
      true,
      true,
      isGraphQLQuery,
      gqlService,
      gqlServiceInitialMethod,
      gqlServiceInitialParams
    );

    useImperativeHandle(forwardref, () => ({
      ...forwardref.current,
      refresh: async () => {
        clearResponseCache();
        await fetchData(true);
      }
    }));

    // server csv download
    const getServerCsvBlob = async () => {
      const { page: tablePage, ...restAsyncParams } = asyncParams;
      const { data } = await axios.get('calls/download', {
        responseType: 'blob',
        params: restAsyncParams
      });
      return new Blob([data]);
    };

    let tableData;
    let totalCount;
    let skiptoken;
    if (response) {
      const { totalCount: totalCountResponse, skiptoken: skiptokenResponse } =
        response?.data;
      const tableDataResponse = get(response?.data, respKey);
      tableData = tableDataResponse;
      totalCount = totalCountResponse;
      skiptoken = skiptokenResponse;
      if (onTableDataLoaded) onTableDataLoaded();
    }

    return (
      <Table
        activeTab={activeTab}
        server={server}
        {...(pagination.skiptoken && {
          requestContainsSkipToken: !!pagination.skiptoken
        })}
        tableData={tableData}
        totalCount={totalCount}
        skiptoken={skiptoken}
        getServerCsvBlob={getServerCsvBlob}
        forwardref={forwardref}
        paginationState={paginationState}
        tabs={tabs}
        {...rest}
        isLoading={rest.isLoading || isLoading}
      />
    );
  };

  TableWithAsync.propTypes = {
    /**
     * String corresponding to the current Tab on the table
     * you are using; not used if tabs prop is null / not rendered
     */
    activeTab: PropTypes.string,
    /**
     * The ref forwared from the CrudTablePage tableSettings; it is
     * used here to add the refesh() function on the ref
     */
    forwardref: PropTypes.any,
    /**
     * Not used in this component; limit variable is found rather in
     * the pagination object
     */
    limit: PropTypes.number,
    /**
     * Callback with that triggers when the fetch call in
     * withAsync is successfully loaded. Is is not passed any
     * arguments
     */
    onTableDataLoaded: PropTypes.func,
    /**
     * From withPagination HOC, the page number you are
     * currently on
     */
    page: PropTypes.number,

    /**
     * The parameters object from TabTable to be used/ sent
     * along with every request
     */
    params: PropTypes.object,

    /**
     * Pagnation state array; first index is the actual pagination
     * object (see shape) and the 2nd index in the array is the
     * setter function for the managed pagination state
     * (PropTypes doesn't support mixed type arrays so here is the shape)
     *
     *  pagnationState: {
     *      limit: PropTypes.number,
     *      filters: PropTypes.object,
     *      skiptoken: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
     *      page: PropTypes.number,
     *      sort: PropTypes.string,
     *  }
     *
     */
    paginationState: PropTypes.array,

    /**
     * The key name the data will be attached to when the
     * fetch request for the url prop is completed. If null it
     * will attempt to create a key from the path name
     */
    responseKey: PropTypes.string,
    /**
     * Weather or not to filter the data on the client
     * side or server side
     */
    server: PropTypes.bool,
    /**
     * The sort string from pagination (?); Not used in this component
     */
    sort: PropTypes.string,
    /**
     *  Tabs confirguation object for rendering tabs on
     * the CrudTablePage ; leave null if not used
     * TODO: Render as example
     */
    tabs: PropTypes.object,
    /**
     * Url to make the fetch request to
     */
    url: PropTypes.string,

    /**
     * The GQL service (has endpoint and methods for GQL calls setup) you wish to invoke
     * for the initial fetch of data to populate the table (e.g. dispatchService)
     */
    gqlService: PropTypes.object,

    /**
     * A method (static or on the instance) to be invoked by your gqlService prop to facilitate the
     * initial data fetch
     */
    gqlServiceInitialMethod: PropTypes.string,

    /**
     * Params to be passed into the gqlServiceInitialMethod on your gqlService instance (if not null)
     */
    gqlServiceInitialParams: PropTypes.string,

    /**
     * Boolean to indicate that the initial fetch of the tableData should
     * be from a GraphQL endpoint; this alters 3 things about this components behavior
     *  1) The useFetch hook ignores the url and other params
     *  2) The first call is now fetched through the passed gqlService[gqlServiceInitialMethod](gqlServiceInitialParams)
     */
    isGraphQLQuery: PropTypes.bool,

    /**
     * A callback function used to transform the response data from the initial fetch of the table.
     * Passed into the withAsync HOC component and useFetch() as the 4th argument (order matters, array destructuring is used)
     * called afterFetch. You must return the response in the expected shape (called with lodash get(response.data, responseKey))
     * indicated by your responseKey so withAsync is able to parse it correctly
     */
    afterInitialFetch: PropTypes.func,

    /**
     * The HTTP verb to use when fetching data.
     */
    method: PropTypes.string,

    /**
     * The payload body for the HTTP request/
     */
    body: PropTypes.object,
  };

  TableWithAsync.defaultProps = {
    onTableDataLoaded: () => {},
    responseKey: null,
    sort: null,
    tabs: null,
    page: null,
    limit: null,
    activeTab: null,
    paginationState: [{}, () => {}],
    url: null,
    params: null,
    isGraphQLQuery: false,
    afterInitialFetch: null,
    gqlService: null,
    gqlServiceInitialMethod: null,
    gqlServiceInitialParams: null,
    method: "get",
    body: null,
  };

  return TableWithAsync;
};
