import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {},
  noBorder: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  cursorOnRowHover: {
    cursor: 'pointer'
  },
  tableRow: {
    textDecoration: 'none'
  },
  oneLineEllipsis: {
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  disabledStyle: {
    opacity: 0.6,
    pointerEvents: 'none'
  }
}));

/**
 * Component used internally within TabTable to gernate rows of the Table;
 * one row per resource that was fetched. The array of data is mapped over
 * and for each object one of these components are output in the table.
 * Supoorts configuration for adding checkboxes or a collapsable area
 * to a TableRow
 */
const DataTableRow = ({
  className,
  collapsibleTableRowDisplay,
  cursorOnRowHover,
  displayCheckBoxes,
  displayLoadingRows,
  handleSelectOneTableRow,
  idKey,
  isLoading,
  isTableRowSelected,
  onClickRow,
  noWrap,
  rowLink,
  // Data for row is in here
  tableRow,
  tableRowDisplay,
  tableCellProps,
  tableColBorder
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);
  const handleClickRow = (row) => async (event) => {
    if (rowLink) history.push(rowLink(row));
    if (onClickRow) await onClickRow(row);
  };

  const handleCollapsibleToggle = () =>
    setCollapsibleOpen((prevCollapseState) => !prevCollapseState);

  return (
    <>
      <TableRow
        className={clsx(className, {
          [classes.noBorder]: collapsibleTableRowDisplay || !tableColBorder,
          [classes.cursorOnRowHover]: cursorOnRowHover,
          [classes.tableRow]: true
        })}
        hover={cursorOnRowHover}
        key={tableRow.id}
        selected={isTableRowSelected}
        onClick={handleClickRow(tableRow)}
      >
        {collapsibleTableRowDisplay && (
          <TableCell>
            <IconButton size="small" onClick={handleCollapsibleToggle}>
              {collapsibleOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        {displayCheckBoxes ? (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isTableRowSelected}
              onChange={(event) => handleSelectOneTableRow(event, tableRow.id)}
              value={isTableRowSelected}
            />
          </TableCell>
        ) : null}
        {tableRowDisplay &&
          tableRowDisplay(tableRow).map((rowData, ind, arr) => {
            const tableRowKey = tableRow[idKey];
            return (
              <TableCell
                className={clsx({
                  [classes.oneLineEllipsis]: rowData?.oneLineEllipsis,
                  [classes.noWrap]: noWrap
                })}
                key={`${tableRowKey}-${ind}`}
                style={tableCellProps?.style}
                align={arr.length - 1 === ind ? 'right' : 'left'}
              >
                {displayLoadingRows && isLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  <>
                    {/* Could be content, the name, or an identifier */}
                    {rowData?.content ?? rowData}
                  </>
                )}
              </TableCell>
            );
          })}
      </TableRow>
      {collapsibleTableRowDisplay && (
        <TableRow
          className={clsx(className, {
            [classes.noBorder]: true
          })}
        >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan="100%">
            <Collapse in={collapsibleOpen} timeout="auto" unmountOnExit>
              <Box margin={1}>{collapsibleTableRowDisplay(tableRow)}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

DataTableRow.propTypes = {
  /**
   * Class name to apply to the outermost container element
   */
  className: PropTypes.string,

  /**
   * Callback function that is passed to the TableRow component;
   */
  collapsibleTableRowDisplay: PropTypes.func,

  /**
   * Weather to show a cursor icon when a row is hovered indicating
   * it is clickable
   */
  cursorOnRowHover: PropTypes.bool,

  /**
   * Weather or not to display checkboxes on table headers
   */
  displayCheckBoxes: PropTypes.bool,

  /**
   * Weather or not to render a Skeleton loading animation
   * while a table's row data is loading
   */
  displayLoadingRows: PropTypes.bool,

  /**
   * Callback that is only used if the displayCheckboxes
   * prop is true. When a checkbox on a row is changed this callback
   * runs with 2 arguments; handleSelectOneTableRow(event, tableRow.id);
   * The event object and the id of the checked tableRow / resource
   */
  handleSelectOneTableRow: PropTypes.func,

  /**
   * Key name for the id on your resources; usually just 'id'
   */
  idKey: PropTypes.string,

  /**
   * Weather or not the data fetched is still loading, is passed
   * from withAsync HOC after the { useFetch }hook is initiated
   */
  isLoading: PropTypes.bool,

  /**
   * Weather or not the TableRow that is being renedered has
   * been selected; In TabTable a piece of state called selectedTableData
   * (array) is looked through for the specified id of the
   * resource used to generate the row
   */
  isTableRowSelected: PropTypes.bool,

  /**
   * Weather or not the text should wrap in the TableRow
   */
  noWrap: PropTypes.bool,

  /**
   * Callback that receives the selected resource (from the
   * row that was clicked) as a single argument;
   */
  onClickRow: PropTypes.func,

  /**
   * Callback function that is used when a table row is clicked.
   * Is is passed one argument (the single row from the tableRow array config)
   * The return result is passed to history.push()
   * e.g.  history.push(rowLink(row))
   */
  rowLink: PropTypes.func,

  /**
   * The current table row, representing 1 object and 1
   * row of the table (could be a user object, role object etc...)
   */
  tableRow: PropTypes.object.isRequired,

  /**
   * Callback which is essentially identical to the tableRows function
   * passed into CrudTablePage's tableSettings Prop but with an additonal
   * set of actions appended as the last item (actions) of the row
   */
  tableRowDisplay: PropTypes.func.isRequired,

  /**
   *  TableCell component props from Material UI; can
   *  also include a style prop
   * https://v4.mui.com/api/table-cell/#tablecell-api
   */
  tableCellProps: PropTypes.object,

  /**
   * Weather or not to render a border on table columns
   */
  tableColBorder: PropTypes.bool
};

DataTableRow.defaultProps = {
  onClickRow: () => { },
  idKey: 'id',
  isLoading: false,
  displayCheckBoxes: false,
  tableColBorder: true,
  tableCellProps: null,
  tableRowDisplay: () => [],
  tableRow: null,
  rowLink: () => { },
  isTableRowSelected: false,
  noWrap: false,
  cursorOnRowHover: false,
  className: '',
  collapsibleTableRowDisplay: null,
  displayLoadingRows: true,
  handleSelectOneTableRow: () => { }
};

export default DataTableRow;
