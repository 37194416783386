import { blueGrey, common } from "@material-ui/core/colors";
import { softShadows, strongShadows } from "../theme/shadows";

export default [
  {
    name: "LIGHT",
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: blueGrey[600],
          },
        },
      },
    },
    palette: {
      type: "light",
      Accepted: { main: "#00c853" },
      "In Route": { main: "#00c853" },
      Delayed: { main: "#ffab00" },
      "On Scene": { main: "#00c853" },
      "Tow In Progress": { main: "#00c853" },
      "Destination Arrival": { main: "#00c853" },
      Complete: { main: "#00c853" },
      New: { main: "$0091ea" },
      Cancelled: { main: "#dd2c00" },
      action: {
        active: blueGrey[600],
        focus: "rgba(255, 255, 255, 0.12)",
      },
      selected: {
        active: "rgba(84, 110, 122,.454)",
      },
      background: {
        default: common.white,
        dark: "#f4f6f8",
        paper: common.white,
      },
      primary: {
        main: "#0451ab",
      },
      secondary: {
        main: "#1c9cd4",
      },
      tertiary: {
        main: "#30c4f8",
      },
      text: {
        primary: blueGrey[900],
        secondary: blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "ONE_DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      selected: {
        active: "rgba(84, 110, 122,.454)",
      },
      background: {
        default: "#282C34",
        dark: "#1c2025",
        paper: "#282C34",
      },
      primary: {
        main: "#8a85ff",
      },
      secondary: {
        main: "#0080C8",
      },
      tertiary: {
        main: "#89CAC5",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
      },
    },
    shadows: strongShadows,
  },
  {
    name: "UNICORN",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      selected: {
        active: "rgba(84, 110, 122,.454)",
      },
      background: {
        default: "#2a2d3d",
        dark: "#222431",
        paper: "#2a2d3d",
      },
      primary: {
        main: "#a67dff",
      },
      secondary: {
        main: "#a67dff",
      },
      tertiary: {
        main: "#89CAC5",
      },
      text: {
        primary: "#f6f5f8",
        secondary: "#9699a4",
      },
    },
    shadows: strongShadows,
  },
];
