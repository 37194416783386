import adminUserStrings from './AdminUsersView';

export default {
  ...adminUserStrings,
  createOrder: 'Crear pedido',
  customerInformation: 'Información del cliente',
  customer: 'cliente',
  vehicleInformation: 'Información del vehículo',
  vehicle: 'Vehículo',
  pickupLocation: 'Lugar de recogida',
  pickup: 'recogida',
  dropOffLocation: 'Lugar de entrega',
  dropOff: 'entrega',
  reviewTowOrder: 'Revisar la orden de remolque',
  notReviewed: 'no revisado',
  submitTowOrder: 'Enviar pedido de remolque',
  tradeIn: 'comerciar con',
  locations: 'Ubicaciones',
  /** Customer Information step */
  pickupDateTimeRequested: 'Fecha / Hora de recogida solicitada',
  serviceIsRequired: 'Se requiere servicio',
  selectedService: 'Servicio seleccionado',
  gatePass: 'Pase de puerta',
  phoneNumber: 'Número de Teléfono',
  lotNumber: 'Número de lote',
  loadNumber: 'Número de carga',
  auctionHouseInfo: 'Información de la casa de subastas',
  auctionHouseContactInformation:
    'Información de contacto de la casa de subastas',
  auctionHouseFirstNameIsRequired:
    'Se requiere el nombre de contacto de la casa de subastas',
  auctionHouseLastNameIsRequired:
    'Se requiere apellido de contacto de la casa de subastas',
  auctionHouseMobilePhoneIsRequired:
    'Se requiere teléfono móvil de contacto de la casa de subastas',
  lotNumberIsRequired: 'Lot # is required',
  loadNumberIsRequired: 'Load # is required',
  readyLogisticsContactInfo: 'Información de contacto de Ready Logistics',
  readyLogisticsEscalationEmailIsRequired:
    'Se requiere un número de teléfono de escalamiento de logística listo',
  readyLogisticsEscalationEmailMustBeValid:
    'El correo electrónico de escalamiento logístico listo debe ser un correo electrónico válido',
  readyLogisticsEscalationFirstNameIsRequired:
    'Se requiere el nombre de pila de escalamiento logístico listo',
  readyLogisticsEscalationLastNameIsRequired:
    'Se requiere el nombre de pila de escalamiento logístico listo',
  readyLogisticsEscalationPhoneNumberIsRequired:
    'Se requiere un número de teléfono de escalamiento de logística listo',
  customerContactInformation: 'Información de contacto del cliente',
  customerEmailMustBeValid:
    'El correo electrónico del cliente debe ser un correo electrónico válido',
  customerFirstNameIsRequired: 'Se requiere el nombre del cliente',
  customerLastNameIsRequired: 'Se requiere el apellido del cliente',
  customerPhoneNumberIsRequired:
    'Se requiere el número de teléfono del cliente',
  specialInstructions: 'Instrucciones especiales',
  thisFieldIsRequired: 'Este campo es obligatorio',
  /** Purchase Vehicle step */
  purchaseVehicle: 'Comprar Vehículo',
  tradeVehicle: 'Vehículo comercial',
  makeIsRequired: 'Se requiere hacer',
  modelIsRequired: 'Se requiere modelo',
  licensePlateIsRequired:
    'Se requiere matrícula. Proporcione \'N/A\' si no corresponde.',
  yearIsRequired: 'Año requerido',
  make: 'Hacer',
  model: 'Modelo',
  color: 'Color',
  licensePlate: 'Matrícula',
  currentMileage: 'Kilometraje actual',
  description: 'Descripción',
  inoperable: 'Inoperable',
  back: 'Atrás',
  next: 'Próximo',
  /** Pickup Location step */
  pickupLocationIsRequired: 'Se requiere lugar de recogida',
  /** Drop Off Location step */
  dropOffLocationIsRequired: 'Se requiere la ubicación de entrega',
  /** TradeIn Location step*/
  tradeInDropOffLocationIsRequired:
    'Se requiere la ubicación de entrega de intercambio',
  tradeInDropOffLocation: 'Lugar de entrega de Trade In',
  sameAsPickupLocation: 'Igual que el lugar de recogida',
  /** Review Tow Order */
  towOrderInfo: 'Información de pedido de remolque',
  orderDate: 'Fecha del pedido',
  serviceRequested: 'Servicio solicitado',
  distance: 'Distancia',
  createDispatch: 'Crear despacho',
  /** Submit Tow Order step */
  validateDropOffLocation: 'Validar la ubicación de entrega',
  validatePickupLocation: 'Validar la ubicación de recogida',
  validateTradeInDropOffLocation: 'Validar el comercio en el lugar de entrega',
  addCustomerToDispatchSystem: 'Agregar cliente al sistema de despacho',
  createTowOrderInDispatchSystem:
    'Crear pedido de remolque en el sistema de despacho',
  attachAuctionHouseContactInfo:
    'Adjuntar información de contacto de la casa de subastas',
  attachDocuments: 'Adjuntar documentos',
  attachReadyLogisticsContactInfo:
    'Adjuntar información de contacto de logística lista',
  pickupLocationWillBeValidatedToEnableDispatch:
    'La ubicación de recogida se validará para los detalles requeridos con el fin de permitir el envío.',
  pickupLocationWillBeValidatedToEnableTowOrder:
    'El lugar de recogida se validará para los detalles necesarios con el fin de habilitar el pedido de remolque.',
  tradeInDropOffLocationWasValidated:
    'El comercio en el lugar de entrega se validó en el sistema de despacho.',
  tradeInDropOffLocationWillBeValidated:
    'El comercio en el lugar de entrega se validará para obtener los detalles necesarios para permitir el envío.',
  customerAlongWithTheVehicleAndLocationsWillBeAdded:
    'El comercio en el lugar de entrega se validará para obtener los detalles necesarios para permitir el envío.',
  withAllDataVerifiedTheTowOrderWillBeCreated:
    'Con todos los datos verificados, se creará el pedido de remolque.',
  allAssociatedDocumentsAreBeingUploadedAndAttachedToTheTowOrder:
    'Todos los documentos asociados se cargan y se adjuntan a la orden de remolque.',
  allAuctionHouseContactInformationWillBeAttachedToTheTowOrder:
    'Toda la información de contacto de la casa de subastas se adjuntará a la orden de remolque.',
  allReadyLogisticsContactInformationWillBeAttachedToTheTowOrder:
    'Toda la información de contacto de logística lista se adjuntará a la orden de remolque.',
  addingCustomerToDispatchSystem: 'Adición de clientes al sistema de despacho',
  customerWasAddedToDispatchSystem:
    'El cliente se agregó en el sistema de despacho.',
  attachingDocumentsToTowOrder:
    'Adjuntar documentos a la orden de remolque en el sistema de despacho',
  documentsAttachedToTowOrder: 'Documentos adjuntos a la orden de remolque.',
  attachingAuctionHouseContactInformation:
    'Adjuntar información de contacto de la casa de subastas para remolcar el pedido en el sistema de despacho.',
  auctionHouseContactInformationAttachedToTowOrder:
    'Información de contacto de la casa de subastas adjunta a la orden de remolque.',
  attachingReadyLogisticsContactInformationToTowOrder:
    'Adjuntar información de contacto de logística lista para remolcar el pedido en el sistema de despacho.',
  readyLogisticsContactInformationAttachedToTowOrder:
    'Información de contacto de logística lista adjunta al pedido de remolque.',
  validatingPickupLocationInDispatchSystem:
    'Validación de la ubicación de recogida en el sistema de despacho',
  validatingTradeInDropOffLocation:
    'Validación del comercio en el lugar de entrega en el sistema de despacho',
  validatingDropOffLocation:
    'Validación de la ubicación de entrega en el sistema de despacho',
  dropOffLocationWasValidated:
    'La ubicación de entrega se validó en el sistema de despacho.',
  creatingTowOrderInDispatchSystem:
    'Creación de pedidos de remolque en el sistema de despacho',
  pickupLocationWasValidated:
    'La ubicación de recogida se validó en el sistema de despacho.',
  dropOffLocationWillBeValidatedToEnableDispatch:
    'La ubicación de entrega se validará para los detalles necesarios para permitir el envío.',
  dropOffLocationWillBeValidatedToEnableTowOrder:
    'La ubicación de entrega se validará para los detalles necesarios para habilitar la orden de remolque.',
  towOrderHasBeenCompleted:
    'La orden de remolque se ha completado. ¡Gracias por elegir NSD!',
  wasCreated: 'se creó en el sistema de despacho',
  createAnother: 'Crear otro',
  sendingOrderToKustomer: 'Enviar orden de remolque al Kustomer',
  orderSuccessfullySentToKustomer: 'Pedido de remolque enviado con éxito a Kustomer',
  dispatchClientPurchaseOrderId: 'Identificación de orden de compra',


  charactersLimitStart: 'Máximo de',
  charactersLimitEnd: 'caracteres excedidos',
  charactersNotExactLength: 'El campo debe ser exactamente',
  characters: 'caracteres',

    /* Added for Preferred locations */
    dropOffLocationInstructions: 'Instrucciones de ubicación de entrega',
    preferredLocations: 'Lugares preferidos',
    approximateDistance: 'Distancia aproximada',
    state: 'Estado',
    address: 'Dirección',
    towDropOffAddress: 'Dirección de entrega de remolque',
    timezone: 'Zona horaria',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    hoursOfOperation:'Horas de operación',
    towDropWeekdayHours: 'Entrega de camiones de remolque horas de lunes a viernes',
    towDropWeekendHours: 'Horas de fin de semana de entrega de camiones de remolque',
  
};
