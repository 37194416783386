import React from 'react';
import { Formik } from 'formik';
import {
  Button,
  Grid,
  DialogContent,
  DialogActions,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LoadingButton from 'src/components/LoadingButton';
import InputField from 'src/components/InputField';
import INPUT_FIELD_TYPE, { INPUT_FIELD_TYPE_VALUES } from 'src/constants/inputFieldType';

const useStyles = (submitButtonWidth) =>
  makeStyles((theme) => ({
    loader: {
      margin: '20px auto',
    },
    actionsContainer: {
      display: 'flex',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      justifyContent: 'flex-end',
    },
    buttonTextAlign: {
      textAlign: 'center',
      width: submitButtonWidth || 'initial',
    },
  }));

/**
 * Generic form component to be used to create any type of
 * form. Can be used to render within a page dialog or just as
 * a standard form. Validation through Yep and state management
 * through Formik
 */
const Form = ({
  fields,
  initialValues: initialVals,
  onClose,
  onSubmit,
  onChange,
  buttonText,
  submitButtonWidth,
  validationSchema,
  dialogContent,
  isLoading,
  isFormDialog,
  formikRef,
  ...rest
}) => {
  // if no initialValues are provided, use empty space as a default
  const initialValues = fields.reduce((acc, cur) => {
    if (acc.hasOwnProperty(cur.name)) {
      return acc;
    }

    if (cur.type === INPUT_FIELD_TYPE.BOOLEAN_CHECKBOX) {
      acc[cur.name] = false;
    } else if (
      [
        INPUT_FIELD_TYPE.MULTI_SELECT,
        INPUT_FIELD_TYPE.AUTOCOMPLETE_MULTI_SELECT,
      ].includes(cur.type)
    ) {
      acc[cur.name] = [];
    } else {
      acc[cur.name] = '';
    }
    return acc;
  }, initialVals ?? {});

  const classes = useStyles(submitButtonWidth)();
  if (isLoading)
    return <CircularProgress className={classes.loader} size={50} />;

  return (
    <Formik
      innerRef={formikRef}
      validateOnBlur={false}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      {...rest}
    >
      {(formikProps) => {
        const {
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setSubmitting,
          setFieldValue,
        } = formikProps;

        return (
          <form
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onSubmit={(e) => {
              handleSubmit(e);
              setSubmitting(false);
            }}
          >
            {isFormDialog ? (
              <>
                <DialogContent>
                  <Grid container spacing={2}>
                    {fields.map(
                      (
                        { name, size = null, onChange, onBlur, label, isDisabled, disabled, ...fieldsRest },
                        index
                      ) => {
                        return (
                          <Grid key={name} item {...(size || { xs: 12 })}>
                            <InputField
                              {...(index === 0 && { autoFocus: true })}
                              error={Boolean(errors[name])}
                              fullWidth
                              helperText={errors[name]}
                              name={name}
                              onBlur={(...blurParams) => {
                                onBlur?.(...blurParams, formikProps);
                                handleBlur(...blurParams);
                              }}
                              disabled={isDisabled?.(formikProps) ?? disabled ?? false}
                              onChange={(event, newValues) => {
                                fieldsRest.type ===
                                  INPUT_FIELD_TYPE.AUTOCOMPLETE_MULTI_SELECT
                                  ? setFieldValue(name, newValues)
                                  : handleChange(event);
                                // On touch clear errors
                                setErrors({});
                                if (onChange) onChange(event);
                              }}
                              value={values[name]}
                              variant="outlined"
                              label={label}
                              handleDelete={(chipValue) => {
                                /**
                                 * Manually alter chip formik values
                                 * when the delete icon is clicked
                                 */
                                const newValues = values[name].filter(
                                  (chip) => chip !== chipValue
                                );
                                setFieldValue(name, newValues);
                              }}
                              {...fieldsRest}
                            />
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                  {dialogContent}
                </DialogContent>
                <DialogActions>
                  {onClose && (
                    <Button onClick={onClose} color="primary">
                      Cancel
                    </Button>
                  )}
                  <LoadingButton
                    disabled={Object.keys(errors).length === 0 ? false : true}
                    type="submit"
                    variant="contained"
                    loaderColor="red"
                    loading={isSubmitting}
                    buttonWidth={submitButtonWidth}
                  >
                    <span className={classes.buttonTextAlign}>
                      {buttonText ?? 'Submit'}
                    </span>
                  </LoadingButton>
                </DialogActions>
              </>
            ) : (
              <>
                <Box>
                  <Grid container spacing={2}>
                    {fields.map(
                      (
                        { name, size, onChange, label, ...fieldsRest },
                        index
                      ) => (
                        <Grid key={name} item {...(size || { xs: 12 })}>
                          <InputField
                            {...(index === 0 && { autoFocus: true })}
                            error={Boolean(touched[name] && errors[name])}
                            fullWidth
                            helperText={touched[name] && errors[name]}
                            name={name}
                            onBlur={handleBlur}
                            onChange={(event, newValues) => {
                              fieldsRest.type ===
                                INPUT_FIELD_TYPE.AUTOCOMPLETE_MULTI_SELECT
                                ? setFieldValue(name, newValues)
                                : handleChange(event);
                              // On touch clear errors
                              setErrors({});
                              if (onChange) onChange(event);
                            }}
                            value={values[name]}
                            variant="outlined"
                            label={label}
                            handleDelete={(chipValue) => {
                              /**
                               * Manually alter chip formik values
                               * when the delete icon is clicked
                               */
                              const newValues = values[name].filter(
                                (chip) => chip !== chipValue
                              );
                              setFieldValue(name, newValues);
                            }}
                            {...fieldsRest}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
                <Box className={classes.actionsContainer}>
                  {onClose && (
                    <Button onClick={onClose} color="primary">
                      Cancel
                    </Button>
                  )}
                  <LoadingButton
                    disabled={Object.keys(errors).length === 0 ? false : true}
                    type="submit"
                    variant="contained"
                    loaderColor="red"
                    loading={isSubmitting}
                    buttonWidth={submitButtonWidth}
                  >
                    <span className={classes.buttonTextAlign}>
                      {buttonText ?? 'Submit'}
                    </span>
                  </LoadingButton>
                </Box>
              </>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

Form.propTypes = {
  /**
   * Element or component to render within the dialog
   * directly underneath the rendered input; does not appear
   * if the isFormDialog prop is falsy
   */
  dialogContent: PropTypes.element,
  /**
   * Array of objects corresponding to the rendered input
   * configurations
   */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
      size: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
      }),
    })
  ),
  /**
   * Object with keys corresponding to input names (in your
   * fields array) with intial set starting values as the
   * object value
   */
  initialValues: PropTypes.object,
  /**
   * Callback function that runs when the cancel button
   * is clicked. Is invoked with 1 parameter; the generated
   * event object from the button click
   */
  onClose: PropTypes.func,
  /**
   * Callback function that runs on submit.
   * Is invoked with 1 parameter; an object
   * with keys corresponding to input names, and values
   * corresponding to the input values
   */
  onSubmit: PropTypes.func,
  /**
   * Callback function that runs when the any of the form
   * inputs are changed. Is invoked with the same parameter
   * as in onSubmit
   */
  onChange: PropTypes.func,
  /**
   * A validation object returned by the Yep https://github.com/jquense/yup
   * library. Keys of the object must match a name within your
   * fields array to validate that particular field
   */
  validationSchema: PropTypes.object,
  /**
   * Boolean indicating weather or not to trigger the loading
   * spinner on the submit button
   */
  isLoading: PropTypes.bool,
  /**
   * Text to appear on the loading button
   */
  buttonText: PropTypes.string,
  /**
   *  CSS string value of submit button width
   */
  submitButtonWidth: PropTypes.string,
};

Form.defaultProps = {
  fields: [],
  onClose: null,
  onSubmit: () => { },
  buttonText: 'Save',
  isFormDialog: false,
  validationSchema: null,
  isLoading: false,
  initialValues: null,
  dialogContent: null,
  submitButtonWidth: null,
};

export default Form;
