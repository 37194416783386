import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Grid, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import startsWith from "lodash/startsWith";
import { Delete as RemoveIcon, PlusSquare as AddIcon } from "react-feather";
import clone from "lodash/clone";
import useIsMountedRef from "../hooks/useIsMountedRef";
import phoneTypesService from "../services/phoneTypesService";

const useStyles = makeStyles((theme) => ({
  root: {},
  formType: { width: 200 },
  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
    },
    width: "100%",
  },

  formButton: {
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
    },
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      justifyContent: "initial",
    },
  },
  adjustLabel: {
    "& .form-control": {
      // borderColor:   theme.palette.error.main : "0",
    },
    "&  .special-label": {
      // color: errors ? theme.palette.error.main : "0",
      top: "5px",
    },
    "& .selected-flag": {
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(3),
      },
    },
  },
}));

/**
 * Comprehensive multi-input for phone numbers. All countries
 * supported with appropriate regex/masks, auto detects
 * country as phone number is being inputted.
 * Rest props are passed to the PhoneInput component
 * https://www.npmjs.com/package/react-phone-input-2#style
 * (See here for all possible override props)
 */
function PhoneNumberInput({ className, phones, updatePhones, ...rest }) {
  const [newPhoneNumber, setNewPhoneNumber] = useState();
  const [newPhoneType, setNewPhoneType] = useState();
  const [phoneTypes, setPhoneTypes] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [phoneNumbers, setPhoneNumbers] = useState(clone(phones) || []);
  const classes = useStyles();

  useEffect(() => {
    getPhoneTypes();
  }, [getPhoneTypes]);

  useEffect(() => {
    if (updatePhones) updatePhones(phoneNumbers);
  }, [phoneNumbers]);

  const getPhoneTypes = useCallback(() => {
    phoneTypesService
      .getPhoneTypes()
      .then((types) => {
        setPhoneTypes(types);
      })
      .catch((err) => {
        console.error(err);
        setPhoneTypes([{ id: -1, type: "No options (fetch failed)" }]);
      });
  }, [isMountedRef]);

  const onPhoneTypeChange = (event, phoneNumberIndex) => {
    let newPhoneNumbers = clone(phoneNumbers);
    newPhoneNumbers[phoneNumberIndex] = {
      ...newPhoneNumbers[phoneNumberIndex],
      phoneTypeId: parseInt(event.target.value),
    };
    setPhoneNumbers(newPhoneNumbers);
  };

  const onPhoneNumberChange = (newNumber, phoneNumberIndex) => {
    let newPhoneNumbers = clone(phoneNumbers);
    newPhoneNumbers[phoneNumberIndex] = {
      ...phoneNumbers[phoneNumberIndex],
      phone: newNumber,
    };
    setPhoneNumbers(newPhoneNumbers);
  };

  const removePhoneNumberInput = (phoneNumberIndex) => {
    let newPhoneNumbers = clone(phoneNumbers).filter(
      (_, idx) => idx !== phoneNumberIndex
    );
    setPhoneNumbers(newPhoneNumbers);
  };

  const addPhoneNumberInput = () => {
    setPhoneNumbers((phoneNumbers) => [
      ...phoneNumbers,
      {
        phoneTypeId: newPhoneType || 1,
        phone: newPhoneNumber,
      },
    ]);
    setNewPhoneNumber("");
  };

  const checkIfPhoneNumberValid = (inputNumber, country, countries) =>
    countries.some(
      (country) =>
        startsWith(inputNumber, country.dialCode) ||
        startsWith(country.dialCode, inputNumber)
    );

  return (
    <div className={className}>
      <Grid container spacing={2}>
        {phoneNumbers.map((phoneNumber, phoneNumberIndex) => {
          return (
            /* Using the actual phone number as key unfocuses on each
            type*/
            <React.Fragment key={phoneNumberIndex}>
              <Grid item xs={12} sm={4}>
                <TextField
                  className={clsx(classes.formInput, classes.formType)}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Loading..."}
                  label="Type"
                  name="preferredContactMethodId"
                  onChange={(event) =>
                    onPhoneTypeChange(event, phoneNumberIndex)
                  }
                  select
                  SelectProps={{ native: true }}
                  value={phoneNumber.phoneTypeId}
                  variant="outlined"
                  error={phoneTypes[0]?.id === -1 || phoneTypes.length === 0}
                >
                  {phoneTypes.map((phoneType) => (
                    <option
                      key={`${phoneType.type}-${phoneType.id}`}
                      value={phoneType.id}
                    >
                      {phoneType.type}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={8} className={classes.inputContainer}>
                <PhoneInput
                  containerClass={classes.adjustLabel}
                  inputClass={classes.formInput}
                  inputStyle={{ width: "100%" }}
                  country={"us"}
                  defaultErrorMessage={
                    "Phone number is invalid. Please check the country code and full number."
                  }
                  preferredCountries={["us", "ca"]}
                  variant="outlined"
                  placeholder="Enter phone number"
                  value={phoneNumber.phone}
                  onChange={(newNumber) =>
                    onPhoneNumberChange(newNumber, phoneNumberIndex)
                  }
                  isValid={checkIfPhoneNumberValid}
                  {...rest}
                  disabled={phoneTypes[0]?.id === -1 || phoneTypes.length === 0}
                />
                <IconButton
                  onClick={() => removePhoneNumberInput(phoneNumberIndex)}
                  className={classes.formButton}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12} sm={4}>
          <TextField
            className={clsx(classes.formInput, classes.formType)}
            InputLabelProps={{ shrink: true }}
            placeholder={"Loading..."}
            label="Type"
            name="preferredContactMethodId"
            onChange={(event) => setNewPhoneType(parseInt(event.target.value))}
            select
            SelectProps={{ native: true }}
            value={newPhoneType}
            variant="outlined"
            error={
              phoneTypes[0]?.id === -1 || !phoneTypes || phoneTypes.length === 0
            }
          >
            {phoneTypes.map((phoneType) => (
              <option key={`${phoneType.type}`} value={phoneType.id}>
                {phoneType.type}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.inputContainer}>
          <PhoneInput
            inputClass={classes.formInput}
            inputStyle={{ width: "100%" }}
            containerClass={classes.adjustLabel}
            country={"us"}
            defaultErrorMessage={
              "Phone number is invalid. Please check the country code and full number."
            }
            preferredCountries={["us", "ca"]}
            variant="outlined"
            placeholder="Enter phone number"
            value={newPhoneNumber}
            onChange={setNewPhoneNumber}
            isValid={checkIfPhoneNumberValid}
            disabled={
              phoneTypes[0]?.id === -1 || !phoneTypes || phoneTypes.length === 0
            }
            {...rest}
          />
          <IconButton
            onClick={addPhoneNumberInput}
            className={classes.formButton}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

PhoneNumberInput.propTypes = {
  /**
   * Classname to apply to the outermost element container
   */
  className: PropTypes.string,
  /**
   * Callback function that runs when the phone inputs are
   * changed. Is passed 1 argument; an array of phone number
   * objects with values gathered from the selects
   */
  updatePhones: PropTypes.func,
  /**
   * Array of prefilled out phone inputs to be initially
   * rendered
   */
  phones: PropTypes.arrayOf(
    PropTypes.shape({
      phoneTypeId: PropTypes.number,
      phone: PropTypes.string,
    })
  ),
};

PhoneNumberInput.defaultProps = {
  className: "",
  updatePhones: null,
  phones: null,
};

export default PhoneNumberInput;
