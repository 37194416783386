import { lazy } from 'react';
import { AuthGuard } from '@nsd/fe';
import {
  BarChart as BarChartIcon,
  DollarSign as DollarSignIcon,
  FileText as FileTextIcon,
} from 'react-feather';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { appIcon } from '../appIcon';

const appConfig = {
  appId: 'insurance-26108cd7-53f2-4f06-b2db-5030d86a08c3',
  appName: 'insurance.insurance',
  priority: 5,
  path: 'insurance',
  icon: appIcon.insuranceQoreApp,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: 'reports',
      view: lazy(() => import('src/views/InsuranceClaimsView/index')),
    },
    {
      exact: true,
      path: 'operational',
      view: lazy(() => import('src/views/InsuranceOperationalView/index')),
    },
    {
      exact: true,
      path: 'data',
      view: lazy(() => import('src/views/InsuranceDataView/index')),
    },
  ],
  topBarConfig: {
    contacts: false,
    search: false,
    notifications: true,
    settings: true,
  },
  navConfig: {
    navBarItems: [
      {
        subheader: 'insurance.reports',
        items: [
          {
            title: 'insurance.claims',
            href: 'reports',
            icon: DollarSignIcon,
          },
          {
            title: 'insurance.operationalReports',
            href: 'operational',
            icon: BarChartIcon,
          },
          {
            title: 'insurance.insuranceData',
            href: 'data',
            icon: FileTextIcon,
          },
        ],
      },
    ],
  },
};

export default appConfig;
