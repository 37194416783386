import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useAccount from 'src/hooks/useAccount';
import PORTAL_TYPE from 'src/constants/portalType';
import { useAssetSettings } from 'src/hooks/useUserSettings';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

/**
 * Component meant to be used in TopBar; renders the logged-in
 * user's name and avatar and displays a logout and Edit profile
 * button
 */
function Account({ type, ...rest }) {
  const classes = useStyles();
  const ref = useRef(null);
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const { isNSD } = useAssetSettings();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { signOut } = useAccount();

  const handleLogout = async () => {
    try {
      handleClose();
      await signOut();
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={account?.user?.avatar}
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${account?.user?.firstName} ${account?.user?.lastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {(type === PORTAL_TYPE.EMPLOYEE || isNSD()) && (
          <MenuItem
            component={RouterLink}
            to={{
              pathname:
                'https://nationsafedrivers-my.sharepoint.com/person.aspx',
            }}
            target="_blank"
          >
            Profile
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

Account.propTypes = {
  /**
   * Used to determine how to style the navbar
   */
  type: PropTypes.oneOf(['client', 'employee']),
};

export default Account;
