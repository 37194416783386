const CdnAppIcon = "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/dispatchQoreAppIcon.png";


const appConfig = {
  appId: 'dispatch-d2781de2-3559-4dcf-abd9-33cea0f9d926',
  appName: 'Dispatch',
  site: 'dispatch-qore',
  icon: CdnAppIcon
};

export default appConfig;
