import React, { useRef, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Users as UsersIcon } from "react-feather";
import OnlineIndicator from "src/components/OnlineIndicator";

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
  list: {
    padding: theme.spacing(1, 3),
  },
  listItemText: {
    marginRight: theme.spacing(1),
  },
  lastActivity: {
    whiteSpace: "nowrap",
  },
}));

/**
 * Component to be rendered in the TopBar; displays a list of
 * the logged-in user's contacts, along with avatars + names,
 * the current availibility of the users and the last time they
 * appeared online
 */
function Contacts() {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { contacts } = useSelector((state) => state.chat);
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {}, [dispatch]);

  return (
    <>
      <Tooltip title="Contacts">
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <SvgIcon fontSize="small">
            <UsersIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          Contacts
        </Typography>
        <Box mt={2}>
          <List disablePadding>
            {contacts.allIds.map((contactId) => {
              const contact = contacts.byId[contactId];

              return (
                <ListItem disableGutters key={contact.id}>
                  <ListItemAvatar>
                    <Avatar alt="Person" src={contact.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    disableTypography
                    primary={
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        display="block"
                        underline="none"
                        noWrap
                        to="#"
                        variant="h6"
                      >
                        {contact.name}
                      </Link>
                    }
                  />
                  {contact.status !== "offline" ? (
                    <OnlineIndicator size="large" status={contact.status} />
                  ) : (
                    <Typography color="textSecondary" noWrap variant="caption">
                      {formatDistanceToNow(contact.lastActivity)}
                    </Typography>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default Contacts;
