import firebaseApp from "firebase/app";
import "firebase/firebase-firestore";
/**
 * Initializes and instantiates a firebase instance
 * based on values read from the .env file. Also returns
 * firestore FieldValue and FieldPath classes to more easily
 * manipulate array data within Firestore. Meant to be used at
 * the highest level of the app and passed into the FirebaseProvider
 */
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
let firebase = null;
try {
  firebase = firebaseApp.app();
} catch (e) {
  firebase = firebaseApp.initializeApp(config);
}
// Helps us do array operations on our documents easier
// https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue
const { FieldValue, FieldPath } = firebaseApp.firestore;
export { firebase, FieldValue, FieldPath };
