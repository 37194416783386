export default {
  pageHeaders: {
    refresh: 'Actualizar',
  },
  charts: {
    download: 'Descargar',
    moreCharts: 'Más gráficos',
    moreOptions: 'Mas opciones',
    print: 'Impresión',
    completedBy_fragment: 'Completado por',
    total: 'Total',
    clearSelection: 'Selección Clara',
    least: 'Menos',
    most: 'La Mayoría',
    averages: 'Promedios',
    averageDay: 'Promedio/Dia',
    day: 'Dia',
    export: 'Exportar',
  },
  detailTabs: {
    details: 'Detalles',
    overview: 'Visión General',
    notes: 'Notas',
    photos: 'Fotos',
    attachments: 'Archivos adjuntos',
    providerNotes: 'Notas del Proveedor',
    internalNotes: 'Notas Internas',
    closedLoop: 'Bucle Cerrado',
    offerList: 'Lista de Ofertas',
    other: 'Otro',
  },
  dialog: {
    comments: 'Comentarios',
    customDateAndTime: 'Fecha y Hora Personalizadas',
    startDateTime: 'Fecha / Hora de Inicio',
    endDateTime: 'Fecha / Hora de Finalización',
    selectCustomDateAndTimeRange:
      'Seleccione un rango de fecha y hora personalizado',
    submit: 'Entregar',
  },
  files: {
    selectFiles: 'Seleccione archivos',
    dropHere_fragment: 'Suelta los archivos aquí o haz clic en',
    browse_fragment: 'navegar',
    throughYourMachine_fragment: 'a través de su máquina',
    sizeLimit: 'El límite de tamaño de archivo es',
    size: 'Tamaño',
  },
  form: {
    cancel: 'Cancelar',
    emailMustBeValid: 'El email debe ser válido',
  },
  location: {
    label: {
      canada: 'Canadá',
      mexico: 'México',
      unitedStates: 'Estados Unidos',
    },
    state: 'Estado',
    states: 'Estados',
    province: 'Provincia',
    provinces: 'Provincias',
    country: 'País',
    addLocation: 'Agregar una ubicación',
  },
  menu: {
    close: 'Cerrar',
    save: 'Guardar',
  },
  options: {
    all: 'Todos',
    noOptions: 'Sin Optins',
  },
  reports: {
    lastUpdated: 'Última Actualización',
    segment: 'Segmento',
    service: 'Servicio',
    by_fragment: 'por',
  },
  table: {
    actions: 'Comportamiento',
    details: 'Detalles',
    rowsPerPage: 'Filas por Página',
    of_N_Rows: 'de',
    more_than_N_Rows: 'mas qe',
    loadMore: 'Carga Más',
    newestToOldest_lowerCase: 'más nuevo-más antiguo',
    oldestToNewest_lowerCase: 'más antiguo-más nuevo',
    sortDisabledForTimeRange: 'Ordenar Deshabilitado para el Rango de Tiempo',
    anyDate: 'Cualquier Fecha',
    anyHour: 'Cualquier Hora',
    dateCreated: 'Fecha de Creacion',
    sortBy: 'Ordenar por',
    searchBy: 'Búsqueda Por',
    notes: 'Notas',
    name: 'Nombre',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    homePhone: 'Teléfono de casa',
    city: 'Ciudad',
    stateOrProvince: 'Estado / Provincia',
    state: 'Estado',
    address: 'Dirección',
    zipCode: 'Código postal',
    business: 'Negocio',
    agentName: 'Nombre del agente',
    agentCode: 'Código de Agente',
    product: 'Producto',
    plan: 'Plan',
    claimNumber: 'Número de reclamación',
    attachments: 'Archivos Adjuntos',
    fileName_aToZ: 'Nombre del Archivo (a-z)',
    fileName_zToA: 'Nombre del Archivo (z-a)',
    no: 'No',
    yes: 'Sí',
    pagination: {
      firstPage: 'Primera Página',
      previousPage: 'Pagina Anterior',
      nextPage: 'Siguiente Página',
      lastPage: 'Última Página',
    },
  },
  time: {
    today: 'Hoy Dia',
    yesterday: 'El dia de ayer',
    last30Days: 'Últimos 30 días',
    last60Days: 'Últimos 60 días',
    last7Days: 'Los últimos 7 días',
    last90Days: 'Últimos 90 días',
    allTime: 'Todo el Tiempo',
    custom: 'Personalizado',
    month: 'Mes',
    months: 'Meses',
    months_apr: 'Abr',
    months_aug: 'Ago',
    months_dec: 'Dec',
    months_feb: 'Feb',
    months_jan: 'Ene',
    months_jul: 'Jul',
    months_jun: 'Jun',
    months_mar: 'Mar',
    months_may: 'May',
    months_nov: 'Nov',
    months_oct: 'Oct',
    months_sep: 'Sep',
    date: 'Fecha',
    week: 'Semana',
    minutes: 'Minutos',
    hour: 'Hora',
    day: 'Dia',
    midnight: 'Doce de la noche',
    noon: 'Mediodía',
    year: 'Año',
  },
};
