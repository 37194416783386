import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SplashScreen } from '../components';
import { logout, setUserData } from '../actions/accountActions';
import authService from '../services/authService';

/**
 * Wrapper component that checks site cookies for a JWT that determines
 * if the user is authenticated; if so it proceeds to log the user in
 * and dispatch that information to the redux store
 */
function Auth({ children }) {
  const dispatch = useDispatch();
  const { appInternalName } = useSelector((state) => state.application);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      });

      authService.handleAuthentication();
      if (authService.isAuthenticated()) {
        const { user, settings, roles, clientQoreCompanyCode } =
          await authService.loginWithToken();
        await dispatch(
          setUserData(user, settings, roles, clientQoreCompanyCode)
        );
      }
      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen appName={appInternalName} />;
  }

  return children;
}

Auth.propTypes = {
  /**
   * Component or renderable content shown after the Auth
   * validation is complete (on both success and failure the loading
   * screen is hidden afterwards)
   */
  children: PropTypes.any,
};

export default Auth;
