import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import INPUT_FIELD_TYPE, { INPUT_FIELD_TYPE_VALUES } from "src/constants/inputFieldType";
import InputField from "./InputField";

// import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    width: "100%",
    position: "relative",
  },
  editFieldInput: {
    fontSize: 14,
    width: "100%",
  },
  inputTextField: {
    width: "100%",
  },
  chips: {
    display: "inline-block",
    marginTop: 4,
    marginBottom: 5,
    wordBreak: "break-all",
    marginRight: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  hidden: {
    visibility: "hidden",
  },
  chip: {
    marginRight: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
}));

/**
 * Component to render any type of input that once rendered
 * will allow users to click a new option/value that has its
 * value sent it to your  asynchronous onSave callback. It is
 * specifically triggered when the input is  unfocused (e.g onBlur)
 * TODO: translation strategy meeting needed
 */
const EditableInput = ({ type, value, label, options, onSave }) => {
  // const { t } = useTranslation();
  const classes = useStyles();
  const node = useRef();
  const inputRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [editVal, setEditVal] = useState(value);

  const handleBlur = async (event) => {
    const newVal = event.target.value;
    if (value !== newVal) {
      try {
        await onSave(event.target.value);
        setIsEditing(false);
        // enqueueSnackbar(t("changeWasSuccessful"), { variant: "success" });
        enqueueSnackbar("change was successful", { variant: "success" });
      } catch (error) {
        enqueueSnackbar(
          // error.response ? t("changeWasNotSaved") : error.message,
          error.response ? "change was not saved" : error.message,
          { variant: "error" }
        );
      }
    } else {
      setIsEditing(false);
    }
  };
  const handleClickIn = async () => setIsEditing(true);
  const handleChange = (event) => setEditVal(event.target.value);

  let displayVal = value;
  let selectMap;
  switch (type) {
    case INPUT_FIELD_TYPE.SELECT:
      displayVal = options?.find((option) => option?.value === value)?.label;
      break;
    case INPUT_FIELD_TYPE.MULTI_SELECT:
      // create map of select values to labels
      selectMap = options.reduce((acc, cur) => {
        acc[cur.value] = cur.label;
        return acc;
      }, {});

      displayVal = value?.map((val) => (
        <Chip key={val} label={selectMap[val]} className={classes.chip} />
      ));
      break;
    default:
  }

  return (
    <span onClick={handleClickIn} className={classes.root} ref={node}>
      {isEditing ? (
        <InputField
          type={type}
          label={label}
          options={options}
          onBlur={handleBlur}
          className={classes.inputTextField}
          autoFocus
          inputRef={inputRef}
          InputProps={{
            classes: {
              input: classes.editFieldInput,
            },
          }}
          onChange={handleChange}
          value={editVal}
        />
      ) : (
        <span className={classes.chips}>{displayVal}</span>
      )}
    </span>
  );
};

EditableInput.propTypes = {
  /**
   * Enum of possible strings representing the different types
   * of inputs to render
   */
  type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
  /**
   * Value to be populated in the select initially; must match
   * the value of one of the objects in your options array.
   * If type="multiSelect" this must be an array of values;
   * otherwise it is just a string
   */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  /**
   * Array of options to be used if a select input type is
   * choosen
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  /**
   * Callback that is triggered when the select input is changed.
   * It receives 1 argument; the string value of the clicked
   * item. This function is awaited so it is expected to be
   * asynchronous
   */
  onSave: PropTypes.func,
  /**
   * Label for the input
   */
  label: PropTypes.string.isRequired,
};

EditableInput.defaultProps = {
  type: "select",
  options: [],
  onSave: () => { },
  value: "",
};

export default EditableInput;
