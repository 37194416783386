import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  FormControl,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import getInitials from "../utils/getInitials";
import PropTypes from "prop-types";
import find from "lodash/find";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
}));

/**
 * Component to display a select of
 * claim processors users that are pulled out
 * from the redux store. Once choosen the setValue
 * callback is passed the users id. If the logged in
 * user has a role name of "Processor" in
 * the NSD Claims application the selection is disabled.
 */
function UserSelection({ setValue, value, disabled, ...rest }) {
  const classes = useStyles();
  const { claimUsers } = useSelector((state) => state.claimsUsers);
  const { user } = useSelector((state) => state.account);
  const [currentSelection, setCurrentSelection] = useState(value || "na");
  const [userRole, setUserRole] = useState(null);
  const [disableSelection, setDisabledSelection] = useState(
    userRole ? userRole.displayName === "Processor" : false
  );

  const handleChange = async (event) => {
    setCurrentSelection(event.target.value);
    if (setValue) {
      try {
        setValue(event.target.value);
      } catch (e) {}
    }
  };

  useEffect(() => {
    let role = find(user.applications, { resourceDisplayName: "NSD Claims" });
    setUserRole(role);
    setDisabledSelection(role ? role.displayName === "Processor" : false);
  }, [user]);

  return (
    <div>
      <FormControl
        disabled={disabled || disableSelection}
        fullWidth
        color="primary"
        className={classes.root}
      >
        <InputLabel id="demo-customized-select-label">Assignee</InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={currentSelection}
          onChange={handleChange}
        >
          <MenuItem value="na">
            <ListItem disableGutters component="div">
              <ListItemAvatar>
                <Avatar alt="Author" className={classes.avatar}>
                  {getInitials("Un Assigned")}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"Unassigned"}
                primaryTypographyProps={{ variant: "h6" }}
              />
            </ListItem>
          </MenuItem>
          {claimUsers &&
            claimUsers.processors &&
            claimUsers.processors.map((user, index) => (
              <MenuItem value={user.id} key={user.id}>
                <ListItem disableGutters component="div">
                  <ListItemAvatar>
                    <Avatar
                      alt="Author"
                      className={classes.avatar}
                      src={user.avatar}
                    >
                      {getInitials(user.name)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.displayName}
                    primaryTypographyProps={{ variant: "h6" }}
                    secondary={user.jobTitle}
                  />
                </ListItem>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

UserSelection.propTypes = {
  /**
   * Callback that is passed the selected Users ID
   */
  setValue: PropTypes.func,

  /**
   * The Users ID that is preselected when the component renders; must
   * be an ID from the list of rendered users
   */
  value: PropTypes.string,

  /**
   * Boolean indicating if the select is disabled
   */
  disabled: PropTypes.bool,
};

UserSelection.defaultProps = {
  setValue: (value) => console.log({ value }),
  value: "",
  disabled: false,
};

export default UserSelection;
