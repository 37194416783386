import React, { useState, useReducer, useRef, lazy, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
  Modal,
  useMediaQuery,
} from "@material-ui/core";
import {
  ChevronLeft as BackIcon,
  ChevronRight as ForwardIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  Description as DescriptionIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import { ZoomIn, ZoomOut } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import axios from "src/utils/axios";
import PropTypes from "prop-types";
import last from "lodash/last";
import { SUPPORTED_DOC_TYPES } from "src/constants/supportedDocTypes";
import DropdownMenu from "src/components/DropdownMenu";
import FilesDropzone from "src/components/FilesDropzone";
import InputField from "src/components/InputField";
import { INPUT_FIELD_TYPE } from "src/constants";
import { useSnackbar } from "notistack";

const AudioPlayer = lazy(() => import("./AudioPlayer"));
const MiscDocViewer = lazy(() => import("./MiscDocumentViewer"));
const PdfViewer = lazy(() => import("./PdfViewer"));

const useStyles = (isMiscDoc, isAudio) =>
  makeStyles((theme) => ({
    root: {},
    miscMediaPlayer: {
      width: "100%",
      height: "100%",
    },
    toolbar: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    zoomIcon: {
      color: theme.palette.primary.contrastText,
    },
    moreButton: {
      color: theme.palette.primary.contrastText,
    },
    perfectScrollBar: {
      width: "100%",
      paddingTop: "60px",
    },
    fileName: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "initial",
      },
    },
    audioPlayer: {
      margin: "0 auto",
      width: "100%",
    },
    center: {
      // Only needed if audio player
      width: isAudio ? "100%" : "auto",
      display: "flex",
    },
    image: {
      maxWidth: "100%",
      paddingTop: "60px",
    },
    video: {
      maxWidth: "100%",
      height: 300,
      [theme.breakpoints.up("sm")]: {
        height: "500px",
      },
    },
    pdf: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    fullWidth: {
      width: "100%",
      height: "100%",
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1),
      },
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: isMiscDoc ? "initial" : "center",
      justifyContent: isMiscDoc ? "initial" : "center",
      marginTop: isMiscDoc ? theme.spacing(1) : "initial",
      padding: isMiscDoc ? "0" : `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    fileDropZone: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      maxHeight: "80vh",
      overflowY: "scroll",
    },
    dropZoneComponent: {
      margin: 0,
    },
    marginSpacing: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    actionButtons: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.6rem",
      },
    },
  }));

const INITIAL_STATE = {
  pageNumber: 1,
  fileZoom: 1.5,
  fileViewPages: 0,
  error: "",
  activeFile: null,
};
const fileReducer = (state, action) => {
  switch (action.type) {
    case "incrementPage": {
      let newPageNum = state.pageNumber;
      newPageNum++;
      return { ...state, pageNumber: newPageNum };
    }
    case "decrementPage": {
      let newPageNum = state.pageNumber;
      newPageNum--;
      return { ...state, pageNumber: newPageNum };
    }
    case "zoomIn": {
      let newZoom = state.fileZoom;
      newZoom += 0.25;
      return { ...state, fileZoom: newZoom };
    }
    case "zoomOut": {
      let newZoom = state.fileZoom;
      newZoom -= 0.25;
      return { ...state, fileZoom: newZoom };
    }
    case "resetState": {
      return { ...INITIAL_STATE };
    }
    case "clearError": {
      return { ...state, error: "" };
    }
    case "setError": {
      return { ...state, error: action.payload.error?.message };
    }
    case "setFileViewPages": {
      return { ...state, fileViewPages: action.payload.fileViewPages };
    }

    default:
      throw new Error();
  }
};

/**
 * Component that can render a full screen dialog of
 * most commonly found file types in
 * the browser (.doc, .pdf, videos, images, .ppt, .xls , etc...)
 * Includes a dropdown menu to choose from multiple passed in files
 */
const AllDocumentViewer = ({
  show,
  close,
  documentsIn,
  blobStorageConfig,
  ...rest
}) => {
  const {
    getDirectoryContents,
    filePath,
    fileName,
    providerId,
    containerName,
    fileType,
  } = blobStorageConfig || {};
  const dropdownRef = useRef(null);
  const [state, dispatch] = useReducer(fileReducer, INITIAL_STATE);
  const [files, setFiles] = useState(
    blobStorageConfig ? null : documentsIn || []
  );
  const [activeFile, setActiveFile] = useState(
    blobStorageConfig ? null : files[0] || null
  );
  const { enqueueSnackbar } = useSnackbar();
  const [uploadPathInput, setUploadPathInput] = useState(filePath);
  const [containerNameInput, setContainerNameInput] = useState(containerName);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  // Disable if there the inputs not filled
  // or if its in the process of uploading currently
  const disableUpload = !uploadPathInput || !containerNameInput || isUploading;
  const { pageNumber, fileZoom, fileViewPages, error } = state || {};
  const ext = last(activeFile?.fileName?.split("."));
  const classes = useStyles(
    SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext),
    SUPPORTED_DOC_TYPES.AUDIOPLAYER.includes(ext)
  )();

  let mediaRenderer;
  const dropdownList = files?.map(({ fileName }) => ({
    value: fileName,
    Icon: <DescriptionIcon />,
    selected: fileName === activeFile?.fileName,
  }));

  const getFileNameFromAccessUrl = (accessUrl) =>
    accessUrl.substring(
      accessUrl.lastIndexOf("/") + 1,
      accessUrl.lastIndexOf("?")
    );

  const handleBlobFileUpload = async (files) => {
    // Since the endpoint only handles 1 file upload at a time
    // loop through files
    try {
      for (const file of files) {
        setIsUploading(true);
        const formData = new FormData();
        const { name } = file;
        formData.append(name, file);
        const response = await axios("/file", {
          headers: {
            "Content-type": "multipart/form-data",
          },
          method: "POST",
          params: {
            fileName: name,
            filePath: uploadPathInput,
            container: containerNameInput,
          },
          data: formData,
        });
      }
      setIsUploading(false);
      // Not closing on upload; just removing files
      // setUploadModalOpen(false);
      enqueueSnackbar(
        `Successfully uploaded ${
          files.length === 1 ? " 1 file" : `${files.length} files`
        } `,
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        `Encountered ${error} error  while attempting to upload files`,
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    const fetchFromBlobStorage = async () => {
      try {
        const response = await axios.get("/file", {
          // await Axios.get("http://localhost:7071/api/file", {
          withCredentials: true,
          params: {
            container: containerName,
            fileName,
            providerId,
            fileType,
            filePath,
            ...(getDirectoryContents && { getDirectoryContents }),
          },
        });
        const { body } = response.data;
        if (body?.message) throw new Error(body?.message);
        let fetchedFiles = [];
        if (getDirectoryContents) {
          // Parse fileNames from accessUrl
          fetchedFiles = response.data.body.map(
            ({ body: { accessUrl, downloadedFile, fileName } }) => ({
              uri: accessUrl,
              fileName: fileName ?? getFileNameFromAccessUrl(accessUrl),
              base64Url: downloadedFile,
            })
          );
        } else {
          const { downloadedFile, accessUrl } = response.data.body;
          fetchedFiles.push({
            uri: accessUrl,
            fileName: fileType ? `${fileName}.${fileType}` : fileName,
            base64Url: downloadedFile,
          });
        }
        setFiles(fetchedFiles);
        setActiveFile({ ...fetchedFiles[0] });
      } catch (error) {
        enqueueSnackbar(
          `Encountered ${error} error  while attempting to fetch file ${filePath}/${fileName}`,
          { variant: "error" }
        );
      }
    };
    const fetchFromProviderBlobStorage = async () => {
      try {
        // should hit http://localhost:7072/file
        // ?file=${fileName}&providerId=${providerId}&container=providerDocuments&fileType=${fileType}
        // const response = await Axios.get("http://localhost:7072/file", {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_STANDARD_URL}/file`,
          {
            params: {
              file: fileName,
              providerId,
              fileType,
              // Actually refers to the directory; always this path
              container: "providerDocuments",
            },
          }
        );
        const fetchedFiles = [];

        // May or may not have body key (check for it)
        let { accessUrl, downloadedFile } = response?.data;
        if (!accessUrl || !downloadedFile) {
          accessUrl = response?.data?.body?.accessUrl;
          downloadedFile = response?.data?.body?.downloadedFile;
        }

        fetchedFiles.push({
          uri: accessUrl,
          fileName: fileName ?? getFileNameFromAccessUrl(accessUrl),
          base64Url: downloadedFile,
        });
        setFiles(fetchedFiles);
        setActiveFile({ ...fetchedFiles[0] });
      } catch (error) {
        enqueueSnackbar(
          `Encountered ${error} error  while attempting to fetch file ${filePath}/${fileName}`,
          { variant: "error" }
        );
      }
    };

    if (!activeFile && blobStorageConfig)
      providerId ? fetchFromProviderBlobStorage() : fetchFromBlobStorage();
  }, [blobStorageConfig]);

  // Receives value of dropdown item clicked; the fileName
  const onDropdownListClick = (value) => {
    dispatch({
      type: "resetState",
    });
    dropdownRef?.current?.close();
    const found = files?.find((file) => file.fileName === value);
    if (found <= -1) return;
    setActiveFile(found);
  };

  const dropdownJsx = (
    <>
      {dropdownList?.map(({ value, Icon, selected }, idx) => (
        <MenuItem
          key={`${value}-${idx}`}
          selected={selected}
          onClick={() => onDropdownListClick(value)}
        >
          <ListItemIcon> {Icon}</ListItemIcon>
          <ListItemText>{value}</ListItemText>
        </MenuItem>
      ))}
    </>
  );

  // For misc docs , videos and audio
  const disableZoomButtons =
    SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext) ||
    SUPPORTED_DOC_TYPES.VIDEOPLAYER.includes(ext) ||
    SUPPORTED_DOC_TYPES.AUDIOPLAYER.includes(ext);

  // For misc docs, videos, audio
  const disablePaginationActions =
    SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext) ||
    SUPPORTED_DOC_TYPES.VIDEOPLAYER.includes(ext) ||
    SUPPORTED_DOC_TYPES.AUDIOPLAYER.includes(ext) ||
    SUPPORTED_DOC_TYPES.IMAGEVIWER.includes(ext);

  // For audio (built in) and misc docs and video (built in)
  const disableDownloadButton =
    SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext) ||
    SUPPORTED_DOC_TYPES.AUDIOPLAYER.includes(ext) ||
    SUPPORTED_DOC_TYPES.VIDEOPLAYER.includes(ext);

  // Already included in PDF viewer
  const disableScrollbar =
    SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext) ||
    SUPPORTED_DOC_TYPES.IMAGEVIWER.includes(ext) ||
    SUPPORTED_DOC_TYPES.VIDEOPLAYER.includes(ext);

  const wrapperStyles = SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext)
    ? clsx(classes.center, classes.fullWidth)
    : classes.center;

  const closePreview = () => close();

  const chooseRenderer = () => {
    if (SUPPORTED_DOC_TYPES.AUDIOPLAYER.includes(ext)) {
      mediaRenderer = (
        <AudioPlayer src={activeFile?.uri} className={classes.audioPlayer} />
      );
    } else if (SUPPORTED_DOC_TYPES.VIDEOPLAYER.includes(ext)) {
      mediaRenderer = (
        <video controls className={classes.video} src={activeFile?.uri}></video>
      );
    } else if (SUPPORTED_DOC_TYPES.PDFVIEWER.includes(ext)) {
      mediaRenderer = (
        <PdfViewer
          show={true}
          close={close}
          file={{
            ...activeFile,
          }}
          dispatch={dispatch}
          state={state}
          className={classes.pdf}
        />
      );
    } else if (SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext)) {
      mediaRenderer = (
        <MiscDocViewer
          className={classes.miscMediaPlayer}
          docs={[
            {
              ...activeFile,
              fileType: ext,
            },
          ]}
          withUploadButton={false}
        />
      );
    } else {
      mediaRenderer = (
        <img
          alt={activeFile?.fileName}
          className={classes.image}
          src={activeFile?.uri}
        ></img>
      );
    }
  };

  chooseRenderer();

  return (
    <>
      <Modal
        className={classes.modal}
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload files to a specified Azure blob"
      >
        <div className={classes.fileDropZone}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setUploadModalOpen(false)}
            aria-label="close"
            disabled={isUploading}
          >
            <CloseIcon />
          </IconButton>
          <InputField
            type={INPUT_FIELD_TYPE.TEXT}
            variant="outlined"
            fullWidth
            label="Upload path"
            value={uploadPathInput}
            className={classes.marginSpacing}
            placeholder="somedir/somedir/somedir"
            onChange={(e) => setUploadPathInput(e.target.value)}
            required
          />
          <InputField
            type={INPUT_FIELD_TYPE.TEXT}
            variant="outlined"
            fullWidth
            label="Container name"
            onChange={(e) => setContainerNameInput(e.target.value)}
            value={containerNameInput}
            className={classes.marginSpacing}
            placeholder="somecontainername"
            required
          />
          <FilesDropzone
            className={clsx(classes.dropZoneComponent, classes.marginSpacing)}
            disableUpload={disableUpload}
            uploadFileCallback={handleBlobFileUpload}
          />
        </div>
      </Modal>
      <Dialog open={show} fullScreen onClose={closePreview}>
        <AppBar className={classes.appBar}>
          <Toolbar variant="dense" disableGutters className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
              disabled={SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext)}
            >
              <CloseIcon />
            </IconButton>
            <Typography className={classes.fileName} variant="h4">
              {activeFile?.fileName || ""}
            </Typography>
            <Box flexGrow={1} />
            <IconButton
              disabled={fileZoom === 0.5 || disableZoomButtons}
              onClick={() => {
                dispatch({ type: "zoomOut" });
              }}
            >
              <ZoomOut
                className={classes.zoomIcon}
                style={
                  disableZoomButtons
                    ? {
                        color: "rgba(0, 0, 0, 0.26)",
                        backgroundColor: "transparent",
                      }
                    : {}
                }
              />
            </IconButton>
            <IconButton
              disabled={fileZoom === 5 || disableZoomButtons}
              onClick={() => {
                dispatch({ type: "zoomIn" });
              }}
            >
              <ZoomIn
                className={classes.zoomIcon}
                style={
                  disableZoomButtons
                    ? {
                        color: "rgba(0, 0, 0, 0.26)",
                        backgroundColor: "transparent",
                      }
                    : {}
                }
              />
            </IconButton>
            <Box flexGrow={1} />
            <DropdownMenu
              title="Available Files"
              Icon={<MoreVertIcon className={classes.moreButton} />}
              tooltip
              ref={dropdownRef}
            >
              {dropdownJsx}
            </DropdownMenu>
            <Button
              disableRipple
              className={classes.actionButtons}
              disabled={disableDownloadButton}
              autoFocus
              color="inherit"
              variant={"outlined"}
              startIcon={!matches && <CloudDownloadIcon />}
              onClick={() => {
                const fileUrl = activeFile?.uri;
                window.open(fileUrl, "_blank");
              }}
            >
              Download
            </Button>
            {blobStorageConfig && (
              <Button
                disableRipple
                className={classes.actionButtons}
                disabled={disableDownloadButton}
                autoFocus
                color="inherit"
                variant={"outlined"}
                startIcon={!matches && <CloudUploadIcon />}
                onClick={() => setUploadModalOpen(true)}
              >
                Upload
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          {!disableScrollbar ? (
            <>
              <PerfectScrollbar className={classes.perfectScrollBar}>
                {mediaRenderer}
              </PerfectScrollbar>
            </>
          ) : (
            <Box className={wrapperStyles}>{mediaRenderer}</Box>
          )}
          {error && (
            <Box
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              justifyItems={"center"}
              alignContent={"center"}
              color={theme.palette.error.main}
            >
              {error}
            </Box>
          )}
        </DialogContent>
        {!disablePaginationActions && (
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BottomNavigation showLabels className={classes.bottomNav}>
              <BottomNavigationAction
                label="Previous"
                value="recents"
                onClick={() => {
                  dispatch({ type: "decrementPage" });
                }}
                disabled={
                  pageNumber === 1 ||
                  SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext)
                }
                className={clsx({
                  [classes.bottomNavItem]: true,
                  [classes.bottomNavItemDisabled]: pageNumber === 1,
                })}
                icon={<BackIcon />}
              />
              <BottomNavigationAction
                disabled
                className={classes.bottomNavItem}
                label={
                  fileViewPages === 0
                    ? `${0} of ${fileViewPages}`
                    : `${pageNumber} of ${fileViewPages}`
                }
                value="recents"
              />
              <BottomNavigationAction
                label="Next"
                value="recents"
                onClick={() => {
                  dispatch({ type: "incrementPage" });
                }}
                className={clsx({
                  [classes.bottomNavItem]: true,
                  [classes.bottomNavItemDisabled]: pageNumber === fileViewPages,
                })}
                disabled={
                  pageNumber === fileViewPages ||
                  SUPPORTED_DOC_TYPES.DOCVIEWER.includes(ext)
                }
                icon={<ForwardIcon />}
              />
            </BottomNavigation>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

AllDocumentViewer.propTypes = {
  /**
   * Weather or not the component is rendered
   */
  show: PropTypes.bool,
  /**
   * Callback that is invoked when the close icon is clicked.
   * It does not receive any arguments
   */
  close: PropTypes.func.isRequired,
  /**
   * Documents to render in the viewer. Rendering a PDF specifically
   * requires the providerId and fileType fields as well because it
   * is fetched from NSD blob/file storage. Every other type of file
   * just needs file name and uri fields
   */
  documentsIn: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      base64Url: PropTypes.string,
    })
  ),

  /**
   * This document viewer can also be used to connect directly
   * to an Azure blob storage account given the logged-in user
   * has the appropriate credientials. If this object is used
   * the "documentsIn" prop will be ignored. You may leave
   * on or off the ending '/' in the path as well.
   *
   * If you wish to view all the views in the specified
   * directory set getDirectoryContents=true; in this
   * instance the fileName and fileType param are not used.
   *
   * If you wish to fetch provider-portal documents in particular
   * set the providerId field. If not set it will attempt to
   * fetch the document using /file route of nsd-api-common-services
   * not nsd-api-provider-portal
   */
  blobStorageConfig: PropTypes.shape({
    fileType: PropTypes.string.isRequired,
    getDirectoryContents: PropTypes.bool.isRequired,
    filePath: PropTypes.string,
    fileName: PropTypes.string,
    providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    containerName: PropTypes.string,
  }),
};

AllDocumentViewer.defaultProps = {
  show: true,
  close: () => {},
  documentsIn: null,
};
export default AllDocumentViewer;
