import React, { useRef, useState, useEffect, } from 'react';
import { Globe as GlobeIcon, } from 'react-feather';
import {
  IconButton,
  SvgIcon,
  Tooltip,
  MenuItem,
  Menu,
} from '@material-ui/core';
import { useAssetSettings, axios, } from '@nsd/fe';
import DEV_LOCAL_STORAGE_KEY from 'src/constants/localStorage/dev';
import { isLocalEnvironment, } from 'src/utils/clientUtils';
import { useSelector, } from 'react-redux';

function Sites() {
  const ref = useRef(null);
  const [isOpen, setOpen,] = useState(false);
  const [loading, setLoading,] = useState(false);
  const [clientCompanies, setClientCompanies,] = useState(null);
  const { isNSD, } = useAssetSettings();
  const { user, } = useSelector((state) => state.account);

  useEffect(() => {
    const fetchClientCompanies = async () => {
      const response = await axios.get('/companies', { params: { companyType: 'client', }, });
      if (response?.data?.companies) {
        setClientCompanies(response?.data?.companies);
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    if (user) fetchClientCompanies();
  }, [user,]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  if (loading) {
    return null;
  }

  const MenuItems = clientCompanies?.filter(({ siteName, }) => siteName).map(({ id, name, siteName, }) => {
    const environment = isLocalEnvironment ? 'dev' : process.env.REACT_APP_ENVIRONMENT; //Local environments should fetch '%-dev.nationsafedrivers.com' sites
    let href = siteName.find(url => url.includes(`${environment}.nationsafedrivers.com`));

    // If we're in local environment, store the mocked client portal in Local Storage
    if (isLocalEnvironment) {
      href = `${window.location.href}?dev.${DEV_LOCAL_STORAGE_KEY.SITE}=${href}`;
    }

    return (
      <MenuItem href={href} component="a" key={id}>
        {name}
      </MenuItem>
    );
  });

  if (isNSD()) {
    return (
      <>
        <Tooltip title="Sites">
          <IconButton color="inherit" onClick={handleOpen} ref={ref}>
            <SvgIcon fontSize="small">
              <GlobeIcon />
            </SvgIcon>
          </IconButton>
        </Tooltip>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          PaperProps={{
            style: { maxHeight: 170, },
          }}
        >
          {MenuItems}
        </Menu>
      </>
    );
  }

  return null;
}

export default Sites;
