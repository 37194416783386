export default {
  totalRoadsideCalls: 'Nombre total d’appels routiers',
  accepted: 'Accepté',
  inRoute: 'En route',
  cashCall: 'Appel de caisse',
  goa: 'GOA',
  onScene: 'Sur les lieux',
  towInProgress: 'Remorquage en cours',
  destinationArrival: 'Arrivée à destination',
  complete: 'Complet',
  cancelled: 'Annulé',
  new: 'Nouveau',
  PieChartCard: {
    title: 'Assistance routière par pourcentage',
  },
  ErrorPage: {
    title: 'Aucun appel de dépannage trouvé',
    message:
      'Veuillez essayer une autre plage horaire ou sélectionner des agents différents.',
  },
};
