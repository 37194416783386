import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

/**
 * Mask for an input element that constrains user text; this
 * particular regex creates a phone number mask. Rendered alone
 * this is a valid input but if you wish to use a custom Input
 * component you need a ref.Default mask is American phone numbers
 * mask
 *
 * Can pass in your own mask and others through rest
 * https://www.npmjs.com/package/react-text-mask
 *
 */
const MaskedPhoneInput = ({ inputRef, ...rest }) => {
  return (
    <MaskedInput
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      {...rest}
    />
  );
};

MaskedPhoneInput.propTypes = {
  /**
   * React ref to the input element that this mask will be applied on;
   * if not passed in the default input element will be rendered with a mask
   */
  inputRef: PropTypes.func,
};

MaskedPhoneInput.defaultProps = {
  inputRef: null,
};

export default MaskedPhoneInput;
