import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { AccountTree as AccountTreeIcon } from '@material-ui/icons';
import { blue } from '@material-ui/core/colors';
import {
  Box,
  Checkbox,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import useAgents from 'src/hooks/useAgents';
import { useSnackbar } from 'notistack';
import { FixedSizeList } from 'react-window';
import { useTranslation } from 'react-i18next';
import useCountryContext from 'src/hooks/useCountryContext';
import { TURO_US_AGENTS, TURO_CANADA_AGENTS } from 'src/constants/turo';
import lodash from 'lodash';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  checkAll: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  box: {},
  list: {
    paddingTop: 0,
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const DialogTitle = withStyles(styles)(
  ({ classes, onToggleSelectAll, areAllAgentsSelected, children }) => {
    return (
      <MuiDialogTitle className={classes.root} disableTypography>
        <Typography variant="h4">{children}</Typography>
        <Checkbox
          id="agent-list-select-all-checkbox"
          className={classes.checkAll}
          edge="end"
          onChange={onToggleSelectAll}
          checked={areAllAgentsSelected}
        />
      </MuiDialogTitle>
    );
  },
);

DialogTitle.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
  areAllAgentsSelected: PropTypes.bool,
  onToggleSelectAll: PropTypes.func.isRequired,
};

DialogTitle.defaultProps = {
  onToggleSelectAll: () => {
  },
};

const AgentCodeListItem = (
  {
    agent,
    index,
    id,
    style,
    isSelected,
    toggleSelected,
  },
) => {
  const classes = useStyles();
  const { name, code } = agent;

  return (
    <ListItem
      button
      selected={isSelected}
      onClick={toggleSelected}
      ContainerProps={{ style: style }}
      ContainerComponent="div"
      key={index}
    >
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <AccountTreeIcon/>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        title={name}
        classes={{ primary: classes.text }}
        primary={name}
        secondary={code}
      />
      <ListItemSecondaryAction>
        <Checkbox
          id={`${id}`}
          edge="end"
          onChange={toggleSelected}
          checked={isSelected}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

AgentCodeListItem.propTypes = {
  agent: PropTypes.object,
  index: PropTypes.number,
  id: PropTypes.string,
  style: PropTypes.object,
  isSelected: PropTypes.bool,
  toggleSelected: PropTypes.func,
};

AgentCodeListItem.defaultProps = {
  toggleSelected: () => {
  },
  isSelected: () => {
  },
  style: {},
};

const AgentCodeDialog = ({ onClose }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { countries, setCountry } = useCountryContext();

  const {
    agents,
    toggleAllSelectedAgents,
    setSelectedAgentsAsActiveAgents,
    toggleSelectedAgent,
    isAgentCodeSelected,
    areAllAgentsSelected,
    turoAgentCodesAreSelected,
  } = useAgents();

  const agentsOrderedByCode = lodash.sortBy(agents, ['code']);

  const handleClose = () => {
    onClose();
  };

  const switchCountryForTuro = () => {
    /**
     * This is so that the country map
     * changes dynamically when certain
     * Turo agents are selected.
     */
    if (turoAgentCodesAreSelected(TURO_CANADA_AGENTS) &&
      !turoAgentCodesAreSelected(TURO_US_AGENTS)) {
      setCountry(countries[1].value); // Canada
      return;
    }

    setCountry(countries[0].value); // US
  };

  const handleSave = async () => {
    try {
      await setSelectedAgentsAsActiveAgents();
      switchCountryForTuro();
      onClose();
    } catch (error) {
      enqueueSnackbar(
        // 'Unable to save changes'
        error.message ?? 'Unable to save changes',
        { variant: 'error' },
      );
    }
  };

  return (
    <Dialog onClose={handleClose} open maxWidth="xs" fullWidth>
      <DialogTitle
        areAllAgentsSelected={areAllAgentsSelected}
        onToggleSelectAll={toggleAllSelectedAgents}
      >
        {t('TopBar.brandCodes')}
      </DialogTitle>
      <Box className={classes.box}>
        {// use a virtualized list if there are over 100 agents to prevent slowness
          agentsOrderedByCode.length > 100 ? (
            <FixedSizeList
              className={classes.list}
              height={250}
              width={444}
              itemSize={72}
              itemCount={agentsOrderedByCode.length}
            >
              {({ index, style }) => (
                <AgentCodeListItem
                  id={`agent-list-item-${index}`}
                  agent={agentsOrderedByCode[index]}
                  index={index}
                  style={style}
                  toggleSelected={
                    toggleSelectedAgent(agentsOrderedByCode[index].code)
                  }
                  isSelected={
                    isAgentCodeSelected(agentsOrderedByCode[index].code)
                  }
                />
              )}
            </FixedSizeList>
          ) : (
            <List id="agent-list" className={classes.list}>
              {agentsOrderedByCode.map(agent => (
                <AgentCodeListItem
                  id={`agent-list-item-${agent.code}`}
                  key={agent.code}
                  agent={agent}
                  index={agent.code}
                  toggleSelected={toggleSelectedAgent(agent.code)}
                  isSelected={isAgentCodeSelected(agent.code)}
                />
              ))}
            </List>
          )}
      </Box>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {
            // Close
            t('common.menu.close')
          }
        </Button>
        <Button onClick={handleSave} color="primary">
          {
            // Save
            t('common.menu.save')
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AgentCodeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

AgentCodeDialog.defaultProps = {
  onClose: () => {
  },
};

export default AgentCodeDialog;
