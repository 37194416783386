import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

/**
 *  Used to instantiate a i18n instance and set up
 * the react-i18next library so you can start using the
 * useTranslation hook. You can pass your own configuration object with
 * absolute paths to your json translation files.
 * Pass in your own resource object to specify JSON paths to the
 * translation files
 * NOTE: Since this is meant to be instantiated at the
 * top level (outside of React ; src/index.js)  it shouldn't
 * be a hook, moved into the utils
 */
const i18nSetup = (translationResources, useSuspense = true) => {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: "en",
      resources: translationResources,
      // NOTE: NODE_ENV also works, but this project utilizes
      // REACT_APP_ENVIRONMENT as the source of truth
      debug: process.env.REACT_APP_ENVIRONMENT === "development",
      react: {
        useSuspense,
      },
    });
};

export default i18nSetup;
