import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ReactQuill from "react-quill";
import { makeStyles } from "@material-ui/core/styles";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .ql-toolbar": {
      borderLeft: "none",
      borderTop: "none",
      borderRight: "none",
      borderBottom: `1px solid ${theme.palette.divider}`,
      "& .ql-picker-label:hover": {
        color: theme.palette.secondary.main,
      },
      "& .ql-picker-label.ql-active": {
        color: theme.palette.secondary.main,
      },
      "& .ql-picker-item:hover": {
        color: theme.palette.secondary.main,
      },
      "& .ql-picker-item.ql-selected": {
        color: theme.palette.secondary.main,
      },
      "& button:hover": {
        color: theme.palette.secondary.main,
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main,
        },
      },
      "& button:focus": {
        color: theme.palette.secondary.main,
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main,
        },
      },
      "& button.ql-active": {
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main,
        },
      },
      "& .ql-stroke": {
        stroke: theme.palette.text.primary,
      },
      "& .ql-picker": {
        color: theme.palette.text.primary,
      },
      "& .ql-picker-options": {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        border: "none",
        boxShadow: theme.shadows[10],
        borderRadius: theme.shape.borderRadius,
      },
    },
    "& .ql-container": {
      border: "none",
      "& .ql-editor": {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        color: theme.palette.text.primary,
        "&.ql-blank::before": {
          color: theme.palette.text.secondary,
        },
      },
    },
  },
  stepButton: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
}));

/**
 * Browser WSIWYG GUI editor ; the difference between this and
 * DraftEditor is the text inputted on this component is output
 * as a string of valid HTML5
 */
function QuillEditor({ className, onTextChanged, ...rest }) {
  const classes = useStyles();
  const [text, setText] = useState(null);

  const handleChange = (value) => {
    setText(value);
    if (onTextChanged) onTextChanged(value);
  };

  return (
    <ReactQuill
      value={text}
      onChange={handleChange}
      className={clsx(classes.root, className)}
      {...rest}
    />
  );
}

QuillEditor.propTypes = {
  /**
   * Class name to apply to the ReactQuill editor component
   */
  className: PropTypes.string,
  /**
   * Callback that fires when the text changes. Accepts one
   * argument; the new text value parsed as a string of valid HTML
   */
  onTextChanged: PropTypes.func,
};

QuillEditor.defaultProps = {
  className: "",
  onTextChanged: (newText) => console.log(`Text changed to ${newText}`),
};

export default QuillEditor;
