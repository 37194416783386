import React, { useState, forwardRef } from "react";
import { Dialog, IconButton, Grow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  fullScreenButton: {
    marginTop: -12,
  },
}));

const Transition = forwardRef((props, ref) => (
  <Grow style={{ transitionDelay: "50ms" }} ref={ref} {...props} />
));

/**
 * Component to represent an button that full screens the
 * chart rendered in the cardChart actions area
 */
const FullScreenButton = ({ isOpenButton, toggleFullScreen }) => {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.fullScreenButton}
      edge="end"
      onClick={toggleFullScreen}
    >
      {isOpenButton ? <FullscreenIcon /> : <FullscreenExitIcon />}
    </IconButton>
  );
};

FullScreenButton.propTypes = {
  /**
   * Weather or not this button is rendered full screen
   * icon or close full screen icon
   */
  isOpenButton: PropTypes.bool,
  /**
   * Callback the runs when the full screen button is
   * clicked; does not receive any arguments. Usually
   * used with a state variable higher in the tree
   */
  toggleFullScreen: PropTypes.func,
};

FullScreenButton.defaultProps = {
  isOpenButton: true,
  toggleFullScreen: () => {},
};

/**
 * Intermediate HOC component to wrap around CardChart and
 * provide a full screen button and functionality in the
 * actions of the CardChart
 */
const withFullScreenButton = (ChartCard) => {
  return ({ isOpenButton, toggleFullScreen, ...rest }) => (
    <ChartCard
      {...rest}
      fullScreenButton={
        <FullScreenButton
          toggleFullScreen={toggleFullScreen}
          isOpenButton={isOpenButton}
        />
      }
    />
  );
};

/**
 * Combining ChartCard with the withFullScreenButton HOC
 * to output the composite component ChartCardWithFullScreenToggle
 */
export default (ChartCard) => {
  const ChartCardWithFullScreenToggle = (props) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleOpenFullScreen = () => setIsFullScreen(true);
    const handleCloseFullScreen = () => setIsFullScreen(false);

    const ChartCardWithFullScreenButton = withFullScreenButton(
      ChartCard,
      handleOpenFullScreen,
      handleCloseFullScreen
    );

    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          open={isFullScreen}
          onClose={() => setIsFullScreen(false)}
        >
          <ChartCardWithFullScreenButton
            chartHeight="100%"
            toggleFullScreen={handleCloseFullScreen}
            isOpenButton={false}
            {...props}
          />
        </Dialog>
        <ChartCardWithFullScreenButton
          toggleFullScreen={handleOpenFullScreen}
          isOpenButton
          {...props}
        />
      </>
    );
  };

  return ChartCardWithFullScreenToggle;
};
