import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import MultiSelect from "src/components/MultiSelect";
import InputField from "src/components/InputField";

const useStyles = makeStyles((theme) => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  selectButton: {
    "&:last-child": {
      marginRight: 0,
    },
    marginRight: 20,
  },
  contextMenu: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-around",
    padding: theme.spacing(2),
  },
}));

/*
  Component to render an array of breadcrumbs along with
  the option to render Select/s , MultiSelect/s  or 
  a button next to the menu
*/
const PageHeader = ({
  className,
  title,
  breadcrumbs,
  button,
  maxBreadcrumbs,
  headerMenuSettings,
  ...rest
}) => {
  const classes = useStyles();
  const renderHeaderMenu = ({ settings }) => {
    const classes = useStyles();
    return settings?.map((setting) => {
      const { Icon, changeCallback, ...restSettings } = setting;
      return setting.type === "multi" ? (
        <MultiSelect
          key={`${setting.type}-${setting.label}`}
          className={classes.selectButton}
          {...restSettings}
          Icon={Icon}
          value={settings.filter((e) => e.value === setting.value) || []}
        />
      ) : (
        <InputField
          key={`${setting.type}-${setting.label}`}
          onChange={changeCallback}
          {...restSettings}
          type="select"
        />
      );
    });
  };
  return (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          maxItems={maxBreadcrumbs}
        >
          {breadcrumbs.map((breadcrumb) =>
            breadcrumb.href ? (
              <Link
                key={breadcrumb.resourceName || breadcrumb.title}
                variant="body1"
                color="inherit"
                to={breadcrumb.href}
                component={RouterLink}
              >
                {breadcrumb.resourceName || breadcrumb.title}
              </Link>
            ) : (
              <Typography
                key={breadcrumb.resourceName || breadcrumb.title}
                variant="body1"
                color="textPrimary"
              >
                {breadcrumb.resourceName || breadcrumb.title}
              </Typography>
            )
          )}
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary">
          {title}
        </Typography>
      </Grid>
      {/* Hide on smaller device widths? */}
      <Grid item>
        {headerMenuSettings && renderHeaderMenu(headerMenuSettings)}
        {button}
      </Grid>
    </Grid>
  );
};

PageHeader.propTypes = {
  /**
   * Class to apply to grid containing the breadcrumb menu
   * and title
   */
  className: PropTypes.string,

  /**
   * Title text rendered below the breadcrumb nav
   */
  title: PropTypes.string.isRequired,

  /**
   * The total number of breadcrumbs that can be displayed
   * before the menu collapses some options to hide them
   */
  maxBreadcrumbs: PropTypes.number,

  /**
   * Array of breadcrumbs to list out; each has a (generic) title,
   * href and resourceName (more specific resource name) properties
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      resourceName: PropTypes.string,
    })
  ),

  /**
   * Object with an array of settings that specifies the props to
   * create the Select or MultiSelect.
   */
  headerMenuSettings: PropTypes.shape({
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(["single", "multi"]).isRequired,
        value: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            Icon: PropTypes.object,
          })
        ),
        changeCallback: PropTypes.func,
        Icon: PropTypes.object,
      })
    ),
  }),
  /*
   * Element or component you wish to have displayed as
   * the menu button. Recommended component is IconButton;
   * as an button with text will not display nicely on smaller width
   * devices
   */
  button: PropTypes.object,
};

PageHeader.defaultProps = {
  className: "",
  title: "Testing the Page Title",
  maxBreadcrumbs: 4,
  breadcrumbs: [],
  button: null,
  headerMenuSettings: {
    settings: [],
  },
};

export default PageHeader;
