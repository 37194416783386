import admin from './admin';
import agent from './agent';
import billing from './billing';
import cash from './cash';
import claims from './claims';
import data from './data';
import dealer from './dealer';
import developer from './developer';
import dispatch from './dispatch';
import insight from './insight';
import insurance from './insurance';
import ledger from './ledger';
import license from './license';
import livechat from './livechat';
import market from './market';
import notifications from './notifications';
import pricebook from './pricebook';
import provider from './provider';
import reports from './reports';
import sales from './sales';
import underwriter from './underwriter';

export default [
    admin,
    agent,
    billing,
    cash,
    claims,
    data,
    dealer,
    developer,
    dispatch,
    insight,
    insurance,
    ledger,
    license,
    livechat,
    market,
    notifications,
    pricebook,
    provider,
    reports,
    sales,
    underwriter
];
