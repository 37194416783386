/* eslint-disable no-param-reassign */
// Don't believe this is used...

import produce from 'immer';

const GET_APPLICATIONS = '@applications/get-applications';

const initialState = {
  applications: []
};


const applicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATIONS: {
      const {applications} = action.payload;

      return produce(state, (draft) => {
        draft.applications = applications;
      });
    }

    default: {
      return state;
    }
  }
};

export default applicationsReducer;
