export default {
  'callDateTime': 'Fecha / Hora de Llamada',
  'callbackNumber': 'Número de Devolución de Llamada',
  'callbackNumber_hash': 'Llamar de Vuelta#',
  'calledAt': 'Llamado A',
  'callerCity': 'Ciudad que Llama',
  'callerFirstName': 'Nombre de la Persona que Llama',
  'callId': 'Identificador de Llamadas',
  'callerLastName': 'Apellido de la Persona que Llama',
  'callerName': 'Nombre de la Persona que Llama',
  'callerState': 'Estado de la Persona que Llama',
  'caller': 'Llamador',
  'callerId': 'Identificador de Llamador',
  'callBackNumber_hash': 'Llamar de Vuelta #',
  'policyNumber_hash': 'Política #',
  'company': 'Empresa',
  'eta_min_abbreviation': 'ETA (min)',
  'member': 'Miembro',
  'mobileTexts': 'Textos Móviles',
  'editedBy': 'Editado Por',
  'editedDate': 'Fecha de Edición',
  'vehicle': 'Vehículo',
  'rideshareDescription': 'Destino de Viaje Compartido',
  'dispatchLocation': 'Lugar de Envío',
  'towToLocation': 'Remolque a la Ubicación',
  'confirmation': 'Confirmación',
  'mva_number_hash_abbreviation': 'MVA#',
  'stateOrProvince': 'Estado / Provincia',
  'vin_abbreviation': 'Vin #',
  'status': 'Estado',
  'personWithVehicle': 'Persona con Vehículo',
  'completedDate': 'Fecha Completada',
  'deliveryLatitude': 'Latitud de Entrega',
  'deliveryLongitude': 'Longitud de Entrega',
  'requestDate': 'Fecha de Solicitud',
  'commentAs': 'Comenta esta llamada como',
  'customDateAndTime': 'Fecha y Hora Personalizadas',
  'selectCustomDateAndTimeRange': 'Seleccione un rango de fecha y hora personalizado',
  'startDateTime': 'Fecha / Hora de Inicio',
  'endDateTime': 'Fecha / Hora de Finalización',
  'dispatchId': 'Despacho ID',
  'thirdPartyId': 'Tercero ID',
  'towServiceDescription': 'Descripción del servicio',
};
