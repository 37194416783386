import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {AppInsightsContext, ReactPlugin} from '@microsoft/applicationinsights-react-js';

class AppInsightsService {
    reactPlugin = null;

    appInsights = null;

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history
     * @param {string} cloudRoleName - Role Name to use for all telemetry
     * @param {Object} config - Application Insights config object
     * @param {Object.<string, any>} additionalTags - Additional tags to add to all telemetry, role name is already included
     * @return {void}
     */
    initialize(
        instrumentationKey,
        browserHistory,
        cloudRoleName,
        config,
        additionalTags
    ) {
        if (!browserHistory) {
            throw new Error('Could not initialize App Insights, no browser history was provided');
        }

        this.reactPlugin = new ReactPlugin();
        this.appInsights = new ApplicationInsights({
            config: !config ? {
                    instrumentationKey: instrumentationKey ?? '',
                    disableTelemetry: !instrumentationKey, // if key is empty, disable telemetry
                    maxBatchInterval: 15000,
                    disableDataLossAnalysis: false,
                    enableAutoRouteTracking: false, // the React plugin takes care of this itself
                    enableCorsCorrelation: true,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    enableAjaxErrorStatusText: true,
                    enableAjaxPerfTracking: true,
                    enableUnhandledPromiseRejectionTracking: true,
                    disableCorrelationHeaders: false,
                    correlationHeaderExcludedDomains: [
                        '*.visualstudio.com',
                        '*.googleapis.com',
                        '*.firebase.com'
                    ],
                    correlationHeaderDomains: ['*.nationsafedrivers.com', 'localhost'],
                    loggingLevelConsole: 0,
                    loggingLevelTelemetry: 2,
                    maxAjaxCallsPerView: -1,
                    disableFetchTracking: false,
                    extensions: [this.reactPlugin],
                    extensionConfig: {
                        [this.reactPlugin.identifier]: {
                            history: browserHistory
                        }
                    }
                }
                : config
        });

        this.appInsights.loadAppInsights();

        // add custom tags to all telemetry
        this.appInsights.addTelemetryInitializer((item) => {
            if (item) {
                item.tags['ai.cloud.role'] = cloudRoleName ?? '';
                item.tags = Object.assign(item.tags, additionalTags);
            }
        });
    }
}

const appInsightsService = new AppInsightsService();

export { appInsightsService, AppInsightsContext };
