import { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { queueNotification } from 'src/actions/notificationActions';
import { updateAlertQueue } from 'src/actions/systemAlertActions';
import FirebaseContext from 'src/context/FirebaseContext';

/**
 * Used along with a redux system to facilatate user targeted (by azure ID)
 * alerts and notifications (rendered in TopBar)
 *
 * TODO: For system wide alerts/notifications (sent to all users) there is no
 * current way in firebase to easily check a documents array field and check for the
 * absense of a value. This means system wide alerts and notifications will not be
 * done through this but rather needs a new system to set that up; a good candidate would be
 * Firebase's Cloud message system https://firebase.google.com/docs/cloud-messaging
 */

const useFirebaseListener = (
  listenToAlerts = true,
  listenToNotifications = true,
  notificationPath,
  alertPath
) => {
  const { FirebaseCrud, FirebaseEvents } = useContext(FirebaseContext);
  const { user } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  // To set the company code in the FirebaseCrud, FirebaseEvents
  useEffect(() => {
    FirebaseCrud.setCompanyCode();
    FirebaseEvents.setCompanyCode();
  }, [user]);
  // Set the path properties in both FirebaseCrud and FirebaseEvents
  useEffect(() => {
    if (alertPath) {
      FirebaseCrud.setAlertsPath(alertPath);
      FirebaseEvents.setAlertsPath(alertPath);
    }
    if (notificationPath) {
      FirebaseCrud.setNotificationsPath(notificationPath);
      FirebaseEvents.setNotificationsPath(notificationPath);
    }
  }, [notificationPath, alertPath]);

  useEffect(() => {
    // If user is set/logged in we have the id and company code
    // and there is a valid alerts / notification path
    if (!user) return;

    const onNewAlertDetected = (querySnapshot) => {
      if (
        querySnapshot.docChanges().length <= 0 &&
        querySnapshot.empty === true
      ) {
        return;
      }
      const newAlerts = querySnapshot?.docs?.map((singleDoc) => ({
        ...singleDoc?.data(),
        docId: singleDoc.id,
      }));
      dispatch(updateAlertQueue(newAlerts));
    };

    const onNewNotificationDetected = (querySnapshot) => {
      if (
        querySnapshot.docChanges().length <= 0 &&
        querySnapshot.empty === true
      ) {
        return;
      }
      const messages = querySnapshot?.docs?.map((doc) => {
        return { ...doc.data(), docId: doc.id };
      });
      dispatch(queueNotification(messages));
    };

    let unsubscribeUserNotifications;
    let unsubscribeUserAlerts;
    let unsubscribeAllNotifications;
    let unsubscribeAllAlerts;
    if (listenToAlerts) {
      unsubscribeUserAlerts = FirebaseEvents.registerUserAlertListener(
        onNewAlertDetected,
        user?.id,
        FirebaseEvents.getAlertsPath()
      );
      unsubscribeAllAlerts = FirebaseEvents.registerAllAlertListener(
        onNewAlertDetected,
        FirebaseEvents.getAlertsPath()
      );
    }
    if (listenToNotifications) {
      unsubscribeUserNotifications =
        FirebaseEvents.registerUserNotificationListener(
          onNewNotificationDetected,
          user?.id,
          FirebaseEvents.getNotificationsPath()
        );
      unsubscribeAllNotifications =
        FirebaseEvents.registerAllNotificationListener(
          onNewNotificationDetected,
          FirebaseEvents.getNotificationsPath()
        );
    }
    return () => {
      unsubscribeUserAlerts();
      unsubscribeUserNotifications();
      unsubscribeAllNotifications();
      unsubscribeAllAlerts();
    };
  }, [FirebaseCrud, FirebaseEvents, user]);
};

export default useFirebaseListener;
