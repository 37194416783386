import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const MoneyCheckAlt = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 416H32V64h576v384zM296 320h80c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-80c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm240-32h-80c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h80c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-240-64h240c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H296c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm-161.28 33.72l42.19 11.44c4.19 1.14 7.09 4.55 7.09 8.3 0 4.8-4.5 8.7-10.06 8.7H147.6c-4.15 0-8.23-1.04-11.77-2.95-3.08-1.67-6.84-1.37-9.24 1.18l-12.07 12.73c-3.11 3.28-2.6 8.64 1.13 11.19 8.3 5.65 18.06 8.88 28.35 9.52V328c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-10.25c22.18-1.1 40-18.57 40-40.3 0-18.17-12.62-34.28-30.72-39.17l-42.19-11.44c-4.19-1.14-7.09-4.55-7.09-8.3 0-4.8 4.5-8.7 10.06-8.7h26.34c4.15 0 8.23 1.04 11.77 2.95 3.08 1.66 6.84 1.37 9.24-1.18l12.07-12.73c3.11-3.28 2.6-8.64-1.13-11.19-8.3-5.65-18.06-8.88-28.35-9.52V168c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v10.25c-22.18 1.1-40 18.57-40 40.3 0 18.17 12.62 34.28 30.72 39.17z',
    [STYLE.REGULAR]: 'M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm-16 400H48V80h544v352zM296 320h80c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8h-80c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8zm240-48h-80c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h80c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8zm-240-32h240c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8H296c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8zm-161.28 17.72l42.19 11.44c4.19 1.14 7.09 4.55 7.09 8.3 0 4.8-4.5 8.7-10.06 8.7H147.6c-4.15 0-8.23-1.04-11.77-2.95-3.08-1.67-6.84-1.37-9.24 1.18l-12.07 12.73c-3.11 3.28-2.6 8.64 1.13 11.19 8.3 5.65 18.06 8.88 28.35 9.52V328c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-10.25c22.18-1.1 40-18.57 40-40.3 0-18.17-12.62-34.28-30.72-39.17l-42.19-11.44c-4.19-1.14-7.09-4.55-7.09-8.3 0-4.8 4.5-8.7 10.06-8.7h26.34c4.15 0 8.23 1.04 11.77 2.95 3.08 1.66 6.84 1.37 9.24-1.18l12.07-12.73c3.11-3.28 2.6-8.64-1.13-11.19-8.3-5.65-18.06-8.88-28.35-9.52V168c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v10.25c-22.18 1.1-40 18.57-40 40.3 0 18.17 12.62 34.28 30.72 39.17z',
    [STYLE.SOLID]: 'M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z'
  };

  return (
    <FontAwesome
      viewBox="0 0 640 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

MoneyCheckAlt.propTypes = {
  type: PropTypes.string
};

export default MoneyCheckAlt;
