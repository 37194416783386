const CdnAppIcon = "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Brand_Icons/claimsQoreAppIcon.png";


const appConfig = {
  appId: 'claims-9930570c-81f3-44fb-b0df-70e16ed66a48',
  appName: 'Claims',
  site: 'claims-qore',
  icon: CdnAppIcon
};

export default appConfig;
