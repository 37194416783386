import React, { lazy } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";
import { green, grey, orange, red } from "@material-ui/core/colors";
import { makeStyles, alpha } from "@material-ui/core/styles";
const WifiIcon = lazy(() => import("@material-ui/icons/Wifi"));
const WifiOffIcon = lazy(() => import("@material-ui/icons/WifiOff"));
const WifiLockIcon = lazy(() => import("@material-ui/icons/WifiLock"));
const NotificationsPausedIcon = lazy(() =>
  import("@material-ui/icons/NotificationsPaused")
);

const STATUS_COLORS = {
  online: green[600],
  offline: grey[50],
  away: orange[600],
  busy: red[600],
};

const useStyles = (status, pulseBool) =>
  makeStyles((theme) => ({
    root: {
      display: "inline-block",
      borderRadius: "50%",
      flexGrow: 0,
      flexShrink: 0,
      animation: `${pulseBool ? "$pulse 2s ease infinite" : ""}`,
    },
    small: {
      height: theme.spacing(1),
      width: theme.spacing(1),
    },
    medium: {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
    large: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
    offline: {
      color: grey[50],
    },
    away: {
      color: orange[600],
    },
    busy: {
      color: red[600],
    },
    online: {
      color: green[600],
    },
    "@keyframes pulse": {
      "0%": {
        transform: "scale(0.95)",
        boxShadow: `0 0 0 0 ${alpha(STATUS_COLORS[status], 0.7)}`,
      },

      "70%": {
        transform: "scale(1)",
        boxShadow: `0 0 0 10px ${alpha(STATUS_COLORS[status], 0)}`,
      },
      "100%": {
        transform: "scale(0.95)",
        boxShadow: `0 0 0 0 ${alpha(STATUS_COLORS[status], 0)}`,
      },
    },
  }));

/**
 * Component to show an icon that uses window.navigator to determine
 * if you are online/offline and shows the corresponding icon. If the
 * status prop is omitted you will be either 'online' or 'offline'
 */
function OnlineIndicator({
  className,
  size,
  status,
  pulseAnimation,
  user,
  ...rest
}) {
  const classes = useStyles(status, pulseAnimation)();
  const rootClassName = clsx(
    {
      [classes.root]: true,
      [classes[size]]: size,
      [classes[status]]: status,
    },
    className
  );
  switch (status) {
    case "online":
      return (
        <Tooltip title="online">
          <WifiIcon className={rootClassName} {...rest} />
        </Tooltip>
      );
    case "offline":
      return (
        <Tooltip title="offline">
          <WifiOffIcon className={rootClassName} {...rest} />
        </Tooltip>
      );
    case "away":
      return (
        <Tooltip title="away">
          <NotificationsPausedIcon className={rootClassName} {...rest} />
        </Tooltip>
      );
    case "busy":
      return (
        <Tooltip title="busy">
          <WifiLockIcon className={rootClassName} {...rest} />
        </Tooltip>
      );
    default:
      break;
  }
}

OnlineIndicator.propTypes = {
  /**
   * Class you wish to apply to style the online indicator icon
   */
  className: PropTypes.string,
  /**
   * Size of the online indicator icon
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Possible statuses to set in the component; if offline that status
   * will override your prop and be displayed instead
   */
  status: PropTypes.oneOf(["away", "busy", "online", "offline"]),
  /**
   * Boolean indicating weather or not to have the icon perform a
   * pulse animation
   */
  pulseAnimation: PropTypes.bool,
};

OnlineIndicator.defaultProps = {
  size: "large",
  className: "",
  status: "offline",
  pulseAnimation: true,
};

export default OnlineIndicator;
