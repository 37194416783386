export default {
  dispatch: 'Envoi',
  dispatches: 'Dépêches',
  reports: 'Rapports',
  calls: 'Appels',
  networkMap: 'Carte du Réseau',
  operationalReports: 'Rapports Opérationnels',
  towOrder: 'Ordre de remorquage',
  impoundOrder: 'Ordonnance de mise en fourrière générique',
  sixtImpoundOrder: 'Sixt Ordonnance de mise en fourrière',
  callSearch: 'Recherche d\'Appel',
  dispatchSearch: 'Recherche d’expédition',
  impounds: 'Fourrières',
  submittedOrders: 'Commandes Soumises',
  impoundCalls: 'Appels en Fourrière',
  roadsideCalls: 'Appels routiers',
};