export default {
  "confieChargedClients": "Clients Facturés Confie",
  "nsdChargedConfie": "Confie Chargée NSD",
  "applicationsReceivedByNSD": "Demandes Reçues par NSD",
  "nsdChargedConfiePerApplicationAverage": "Confie Facturée par NSD par Application (Moyenne)",
  "confieChargedClientsPerApplicationAverage": "Clients Facturés par Confie par Application (Moyenne)",
  "confieProfit": "Bénéfice Confié",
  "profit": "Bénéfice",
  "totalProfit": "Bénéfice Total",
  "totalProfitByState": "Bénéfice Total par État",
  "searchProducts": "Recherche de produits",
  "office": "Bureau",
  "district": "Quartier",
  "product": "Produit",
};