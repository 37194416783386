import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  SettingsProvider,
  i18nSetup,
  FirebaseProvider,
  FirebaseCrudService,
  FirebaseEventsService,
  restoreSettings,
} from '@nsd/fe';
import { store } from 'src/store';
import { loadDevQueryParamsIntoLocalStorage } from 'src/utils/urlQueryParams';
import { LANGUAGE_CODES_I18N_SETUP_OBJECT } from './constants/languageCodes';
import App from 'src/App';

// Same name from App.js; for making an educated
// guess on the alert and notification path
// (also can pass in manual path in App.js)
const APP_INTERNAL_NAME = 'Client-Qore';

enableES5();
const settings = restoreSettings();
i18nSetup(LANGUAGE_CODES_I18N_SETUP_OBJECT);
loadDevQueryParamsIntoLocalStorage();

ReactDOM.render(
  <Provider store={store}>
    <FirebaseProvider
      FirebaseCrud={new FirebaseCrudService(APP_INTERNAL_NAME)}
      FirebaseEvents={new FirebaseEventsService(APP_INTERNAL_NAME)}
    >
      <SettingsProvider settings={settings}>
        <App />
      </SettingsProvider>
    </FirebaseProvider>
  </Provider>,
  document.getElementById('root')
);