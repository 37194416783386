const APPS = [
  {
    appName: "dispatch-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Dispatch%20Qore%20Logo.png",
  },
  {
    appName: "lender-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Lender_Qore_Logo-01.png",
  },
  {
    appName: "client-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Client_Qore_Logo-01.png",
  },
  {
    appName: "provider-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Provider_Qore_Logo-01.png",
  },
  {
    appName: "claims-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Claims_Qore_Logo-01.png",
  },
  {
    appName: "contact-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Contact_Qore_Logo-01.png",
  },
  {
    appName: "insight-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Insight_Qore_Logo-01.png",
  },
  {
    appName: "sales-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Sales_Qore_Logo-01.png",
  },
  {
    appName: "marketing-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Marketing_Qore_Logo-01.png",
  },
  {
    appName: "market-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Market_Qore_Logo-01.png",
  },
  {
    appName: "developer-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Developer_Qore_Logo-01.png",
  },
  {
    appName: "pricebook-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Pricebook_Qore_Logo-01.png",
  },
  {
    appName: "underwriter-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Underwriter_Qore_Logo-01.png",
  },
  {
    appName: "integration-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Integration_Qore_Logo-01.png",
  },
  {
    appName: "admin-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Admin_Qore_Logo-01.png",
  },
  {
    appName: "driver-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Driver_Qore_Logo-01.png",
  },
  {
    appName: "billing-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Billing_Qore_Logo-01.png",
  },
  {
    appName: "agent-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Agent_Qore_Logo-01.png",
  },
  {
    appName: "dealer-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Dealer_Qore_Logo-01.png",
  },
  {
    appName: "licensing-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Licensing_Qore_Logo-01.png",
  },
  {
    appName: "ledger-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Ledger_Qore_Logo-01.png",
  },
  {
    appName: "data-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Data_Qore_Logo-01.png",
  },
  {
    appName: "notifications-qore",
    logo: "https://nsdstaticasset.blob.core.windows.net/assets/Qore_Product_Platform_Logo/Notifications_Qore_Logo-01.png",
  },
];

export default APPS;
