import React from 'react'; 
import PropTypes from 'prop-types';
import FontAwesome, { STYLE } from './FontAwesome';

const SwatchBook = ({ type, ...rest }) => {
  const variations = {
    [STYLE.LIGHT]: 'M96 432c8.84 0 16-7.16 16-16s-7.16-16-16-16-16 7.16-16 16 7.16 16 16 16zm384-112H327.77l107.65-107.65c12.5-12.5 12.5-32.76 0-45.26l-90.52-90.5c-6.25-6.25-14.44-9.37-22.63-9.37s-16.38 3.12-22.63 9.37L192 184.23V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v384c0 53.02 42.98 96 96 96h384c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zm-320 96c0 35.29-28.71 64-64 64s-64-28.71-64-64V288h128v128zm0-160H32v-96h128v96zm0-128H32V32h128v96zm32 101.47L322.24 99.22h.03l90.51 90.51L192 410.51V229.47zM480 480H167.76l128-128H480v128z',
    [STYLE.REGULAR]: 'M112 424c13.25 0 24-10.75 24-24 0-13.26-10.75-24-24-24s-24 10.74-24 24c0 13.25 10.75 24 24 24zm368-136h-97.61l69.02-69.02c12.5-12.5 12.5-32.76 0-45.25L338.27 60.59c-6.25-6.25-14.44-9.37-22.63-9.37s-16.38 3.12-22.63 9.37L224 129.61V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v368c0 61.86 50.14 112 112 112h368c17.67 0 32-14.33 32-32V320c0-17.67-14.33-32-32-32zM176 400c0 17.88-7.41 34.03-19.27 45.65-3.65 3.57-7.7 6.53-11.99 9.05-.86.51-1.76.96-2.64 1.43-4.47 2.34-9.12 4.31-14.02 5.57-5.16 1.35-10.48 2.29-16.06 2.29H112c-35.29 0-64-28.71-64-64v-96h128V400zm0-144H48v-80h128v80zm0-128H48V48h128v80zm48 69.49l91.65-91.65 90.51 90.51L224 378.51V197.49zM464 464H206.39l128-128H464v128z',
    [STYLE.SOLID]: 'M434.66,167.71h0L344.5,77.36a31.83,31.83,0,0,0-45-.07h0l-.07.07L224,152.88V424L434.66,212.9A32,32,0,0,0,434.66,167.71ZM480,320H373.09L186.68,506.51c-2.06,2.07-4.5,3.58-6.68,5.49H480a32,32,0,0,0,32-32V352A32,32,0,0,0,480,320ZM192,32A32,32,0,0,0,160,0H32A32,32,0,0,0,0,32V416a96,96,0,0,0,192,0ZM96,440a24,24,0,1,1,24-24A24,24,0,0,1,96,440Zm32-184H64V192h64Zm0-128H64V64h64Z'
  };

  return (
    <FontAwesome
      viewBox="0 0 512 512"
      variations={variations}
      type={type}
      {...rest}
    />
  );
};

SwatchBook.propTypes = {
  type: PropTypes.string
};

export default SwatchBook;
