import { getCompanyCode } from 'src/utils/company';
import { firebase } from './firestore';

/* 
  To be passed into FirebaseProvider to make use of singleton pattern;
  Redux recommends to not keep class instances in the store
*/
class Crud {
  constructor(appName) {
    this.firebase = firebase;
    this.db = this.firebase.firestore();
    // specific to company; could be null before token present
    this.companyCode = getCompanyCode();
    this.appName = appName;
    this.alertsPath = '';
    this.notificationsPath = '';
  }

  setCompanyCode = () => {
    this.companyCode = getCompanyCode();
  };

  // Generates an educated guess at the firestore schema path
  // (not correct; pass in your own custom path)
  generateAlertsPath = () => `${this.appName}/alert/${this.companyCode}`;

  // Generates an educated guess at the firestore schema path
  // (not correct; pass in your own custom path)
  generateNotificationsPath = () =>
    `${this.appName}/notification/${this.companyCode}`;

  setAlertsPath = (path) => {
    this.alertsPath = path;
  };

  setNotificationsPath = (path) => {
    this.notificationsPath = path;
  };

  getAlertsPath = () => this.alertsPath;

  getNotificationsPath = () => this.notificationsPath;

  // Returns promise
  find = async (collection, query) => {
    const collectionRef = this.db.collection(collection);
    const queryRef = collectionRef.where(
      query.field,
      query.operator,
      query.value
    );
    return queryRef.get();
  };

  // Returns Promise
  readAll = async (collection) => {
    const configDoc = this.db.collection(collection);
    return configDoc.get();
  };

  // Returns Promise
  readOne = async (collection, docId) =>
    this.db.collection(collection).doc(docId).get();

  // Returns Promise<void>; success if a try catch
  // doesn't fail
  updateOne = async (collection, id, data, merge) => {
    try {
      const mergeData = merge !== null && merge !== undefined ? merge : true;
      return this.db
        .collection(collection)
        .doc(id)
        .set(data, { merge: mergeData });
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  // Returns Promise<void>; success if a try catch
  // doesn't fail
  deleteOne = async (collection, id) => {
    try {
      return this.db.collection(collection).doc(id).delete();
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  // Returns Promise
  createOne = async (collection, data) =>
    this.db.collection(collection).add(data);

  // Returns Promise<void>; success if a try catch
  // doesn't fail
  createOneWithId = async (collection, data, id) => {
    try {
      return this.db.collection(collection).doc(id).set(data);
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  updateAll = async (collection, data, merge = null) => {
    try {
      const mergeData = merge ? merge : true;
      const collectionRef = this.db.collection(collection);
      const query = collectionRef.where('read', '==', false);
      const snapshot = await query.get();
      if (snapshot.size === 0) return 0;
      // Need to break the collection docs in to chunks if over 500
      const batchArray = [this.db.batch()];
      let operationCounter = 0;
      let batchIndex = 0;
      snapshot.forEach((doc) => {
        batchArray[batchIndex].set(
          doc.ref,
          { ...doc.data(), ...data },
          { merge: mergeData }
        );
        operationCounter++;
        if (operationCounter === 499) {
          batchArray.push(this.db.batch());
          batchIndex++;
          operationCounter = 0;
        }
      });
      batchArray.forEach(async (batch) => await batch.commit());
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  deleteCollection = async (collectionPath, batchSize) => {
    const collectionRef = this.db.collection(collectionPath);
    const query = collectionRef.orderBy('__name__').limit(batchSize);

    return new Promise((resolve, reject) => {
      this.deleteQueryBatch(query, batchSize, resolve, reject);
    });
  };

  // FIX: Do I need return if use promise methods
  deleteQueryBatch = async (query, batchSize, resolve, reject) => {
    try {
      const snapshot = await query.get();
      if (snapshot.size === 0) return 0;
      const batch = this.db.batch();
      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
      const numDeleted = snapshot.size;
      if (numDeleted === 0) {
        resolve();
        return;
      }
      if (numDeleted < batchSize) {
        await this.deleteQueryBatch(query, batchSize, resolve, reject);
      }
    } catch (error) {
      reject(error);
    }
  };
}

export default Crud;
