import React, { useEffect, useState } from 'react';
import { Checkbox, Chip, TextField } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@material-ui/icons';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomFieldSingleSelect = ({
  value,
  onBlur,
  onChange,
  required,
  name,
  error,
  helperText,
  setFieldValue,
  options,
  inputLabel,
  inputPlaceholder,
}) => {
  const [inputVal, setInputVal] = useState(value);

  useEffect(() => {
    setFieldValue(name, inputVal);
  }, [options]);

  return (
    <Autocomplete
      options={options}
      disabled={false}
      multiple={false}
      fullWidth
      id={`${name}-list`}
      value={value}
      inputValue={inputVal}
      onChange={(event, newValue, reason) => {
        if (reason === 'select-option') {
          setInputVal(newValue.label);
          onChange({
            target: {
              name,
              value: newValue.value,
            },
          });
        } else if (reason === 'clear') {
          setInputVal('');
          onChange({
            target: {
              name,
              value: '',
            },
          });
        }
      }}
      onBlur={() => {
        if (onBlur) {
          onBlur({
            target: {
              name,
            },
          });
        }
      }}
      getOptionSelected={(option, valueToTest) =>
        option.value === valueToTest.value
      }
      getOptionLabel={(option) => option.label ?? 'No Label'}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip key={index} label={option.label} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          required={required}
          fullWidth
          error={error}
          helperText={error && helperText}
          {...params}
          label={inputLabel}
          variant="outlined"
          placeholder={inputPlaceholder}
        />
      )}
      renderOption={(option, { inputValue, selected }) => {
        const matches = match(option.label, inputValue);
        const parts = parse(option.label, matches);
        return (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </>
        );
      }}
    />
  );
};

CustomFieldSingleSelect.propTypes = {
  value: PropTypes.any.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }).isRequired
  ).isRequired,
  inputLabel: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
};

CustomFieldSingleSelect.defaultProps = {
  helperText: i18n.t('DispatchTowOrderView.thisFieldIsRequired'),
  required: false,
};

export default CustomFieldSingleSelect;
