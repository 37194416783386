import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import objectSupport from 'dayjs/plugin/objectSupport';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat';

export const setUpDayjs = () => {
  dayjs.extend(relativeTime);
  dayjs.extend(objectSupport);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(isBetween);
  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);
}