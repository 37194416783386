/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import bytesToSize from "../utils/bytesToSize";
import CloseIcon from '@material-ui/icons/Close';
const fileImage =
  "https://nsdstaticasset.blob.core.windows.net/assets/Nsd_FE_Assets/undraw_add_file2_gvbb.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: "pointer",
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

/**
 * Component for creating an area where files can be dropped (or selected via
 * a file explorer). Uploaded files are listed below the drop area with the
 * file size and an option to delete single files. Accepts an on file upload
 * callback to execute
 *
 * @component
 */
function FilesDropzone({
  className,
  updateFileList,
  uploadFileCallback,
  disableUpload,
  hideUploadButton,
  clearFiles,
  ...rest
}) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [filesUploading, setFilesUploading] = useState(false);

  const handleDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleRemoveAll = () => setFiles([]);

  const handleFileRemove = (fileToRemove) =>
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileToRemove.name)
    );

  useEffect(() => {
    if (updateFileList) updateFileList(files);
  }, [files]);

  useEffect(() => {
    if (clearFiles) handleRemoveAll();
  }, [clearFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img alt="Select file" className={classes.image} src={fileImage} />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            Select files
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              Drop files here or click <Link underline="always">browse</Link>{" "}
              thorough your machine
            </Typography>
          </Box>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{
                      variant: "h5",
                      className: classes.truncate,
                    }}
                    secondary={bytesToSize(file.size)}
                  />
                  {filesUploading ? (
                    <CircularProgress size={30} />
                  ) : (
                    <Tooltip title="Delete file" placement="right-end">
                      <IconButton
                        edge="end"
                        onClick={() => handleFileRemove(file)}
                      >
                        <CloseIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button
              disabled={filesUploading}
              size="small"
              onClick={handleRemoveAll}
            >
              Remove all
            </Button>
            {!hideUploadButton &&
              <Button
                onClick={async () => {
                  setFilesUploading(true);
                  await uploadFileCallback(files);
                  setFilesUploading(false);
                  handleRemoveAll();
                }}
                color="secondary"
                size="small"
                variant="contained"
                disabled={files.length === 0 || disableUpload || filesUploading}
              >
                Upload files
              </Button>
            }
          </div>
        </>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  /**
   * Class name you wish to apply to the container around FileDropzone
   */
  className: PropTypes.string,
  /**
   * Optional callback function to be invoked whenever the file state changes
   * (e.g. file is removed or added).  It is passed the files array as an arguement.
   */
  updateFileList: PropTypes.func,
  /**
   * Callback function to be invoked when all files are ready and the 'upload files'
   * button is clicked. It is passed the files array as an arguement. Async function
   */
  uploadFileCallback: PropTypes.func,
  /**
   * If you wish to disable the upload button until there are
   * files loaded or some other reason
   */
  disableUpload: PropTypes.bool,

  /**
   * Boolean used to indicate weather the native upload button rendered with
   * this component should be hidden or not
   */
  hideUploadButton: PropTypes.bool,

  /**
   * Boolean used to indicate weather or not the file list should be cleared
   * completely on the next render (commonly by detecting a propagated error from the consuming parent component);
   * This makes so that that if the uploaded files don't conform to a specific extension/format it can be
   * cleared without prompting the user to individually clear the file
   */
  clearFiles: PropTypes.bool
};

FilesDropzone.defaultProps = {
  className: "",
  updateFileList: null,
  uploadFileCallback: null,
};

export default FilesDropzone;
