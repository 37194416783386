import React, { useRef } from 'react';
import DropdownMenu from 'src/components/DropdownMenu';
import { Link, SvgIcon } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import { getAllApps } from '@nsd/fe';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useAssetSettings } from '@nsd/fe';
import { getUrl } from '@nsd/fe';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TRANSLATION } from 'src/constants/translationKey';
import { useTranslation } from 'react-i18next';
// Ensure webpack bundles app images by require in the dropdown

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: '3px',
    padding: '10px 7px 7px 7px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  appIcon: {
    width: '50px',
  },
  appItemText: {
    whiteSpace: 'pre-wrap',
    lineHeight: '16px',
  },
}));

/**
 * Component meant to be used in the TopBar component of
 * the Dashboard Layout view. Renders a clickable dropdown menu
 * of all apps available to the logged in user
 */
const Apps = ({ apps }) => {
  const { t } = useTranslation(TRANSLATION);

  const classes = useStyles();
  const { hasAppAccess } = useAssetSettings();
  const menuRef = useRef();
  const handleCloseDropDownMenu = () => menuRef.current.close();
  const { type } = useSelector((state) => state.application);

  if (!type) return null;
  return (
    <DropdownMenu
      id="topbar-apps"
      ref={menuRef}
      Icon={
        <SvgIcon fontSize="small">
          <AppsIcon/>
        </SvgIcon>
      }
      IconButtonProps={{ color: 'inherit' }}
      PopoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }}
      title={t('TopBar.apps')}
      tooltip
    >
      <Grid container spacing={3}>
        {getAllApps(apps, type)
          .filter((app) => hasAppAccess(app.appId))
          .map((app) => {
            const img = new Image();
            img.src = app.icon;
            return (
              <Grid id={`grid-app-${app.appId}`} key={app.appId} item xs={4}>
                <Link
                  id={`link-app-${app.appId}`}
                  color="textPrimary"
                  display="block"
                  underline="none"
                  noWrap
                  {...(app.path
                    ? {
                      to: `/${app.path}`,
                      component: RouterLink,
                    }
                    : {
                      onClick: (e) => {
                        window.location = getUrl(app.site);
                        handleCloseDropDownMenu(e);
                      },
                    })}
                  variant="h6"
                  className={classes.link}
                >
                  <Box>
                    <img
                      alt={app.name}
                      className={classes.appIcon}
                      src={app?.icon}
                    />
                  </Box>
                  <Box className={classes.appItemText}>
                    {t(app.appName)}
                  </Box>
                </Link>
              </Grid>
            );
          })}
      </Grid>
    </DropdownMenu>
  );
};

Apps.propTypes = {
  /**
   * Array of app objects that the logged in user has access to
   */
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      appId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      site: PropTypes.string,
    }),
  ),
  /**
   * Type of user that is currently logged in
   */
  // type: PropTypes.oneOf(["employee", "client"]),
};

Apps.defaultProps = {
  type: 'client',
  apps: null,
};

export default Apps;
