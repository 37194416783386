import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {},
  menu: {
    width: 250,
  },
  menuItem: {
    padding: 0,
  },
  formControlLabel: {
    padding: theme.spacing(0.5, 2),
    width: "100%",
    margin: 0,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
  },
  secondaryIcon: {
    color: theme.palette.text.secondary,
  },
  menuItemButton: {
    margin: theme.spacing(0.5, 1.5),
  },
}));

/**
 * Component to dislpay a dropdown menu of checkboxes along with
 * a save button. You can customize the callbacks for changeCallback and
 * when the save button is clicked
 */
const MultiSelect = ({
  className,
  Icon,
  label,
  options,
  value,
  dropDownArrow,
  saveConfirmation,
  onSave,
  changeCallback,
  notificationTime,
  ...rest
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [tempVal, setTempVal] = useState(value || []);
  const handleMenuOpen = () => setOpenMenu(true);
  const handleMenuClose = () => setOpenMenu(false);
  const closeNotification = () =>
    setTimeout(() => {
      closeSnackbar();
    }, notificationTime);

  const handleConfirmSave = async () => {
    try {
      await onSave(tempVal);
      handleMenuClose();
    } catch (error) {
      enqueueSnackbar(error.message ?? "Unable to save changes", {
        variant: "error",
      });
      closeNotification();
    }
  };

  const handleOptionToggle = (event) => {
    let newValues = [...tempVal];
    event.target.checked
      ? newValues.push(event.target.value)
      : (newValues = newValues.filter((item) => item !== event.target.value));
    setTempVal(newValues);
    changeCallback(newValues);
  };

  return (
    <span className={className}>
      <Button onClick={handleMenuOpen} ref={anchorRef} color="primary">
        {Icon && <Icon className={classes.actionIcon} />}
        {label}
        {dropDownArrow && <ArrowDropDownIcon />}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{ className: classes.menu }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map(({ value: optionValue, label, Icon }) => (
          <MenuItem
            className={classes.menuItem}
            key={`${optionValue}-${label}`}
          >
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  checked={tempVal.indexOf(optionValue) > -1}
                  onClick={handleOptionToggle}
                  value={optionValue}
                />
              }
              label={label}
            />
            {Icon && (
              <ListItemSecondaryAction>
                <Icon className={classes.secondaryIcon} />
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        ))}
        {saveConfirmation && (
          <li className={classes.menuItemButton}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleConfirmSave}
            >
              Save
            </Button>
          </li>
        )}
      </Menu>
    </span>
  );
};

MultiSelect.propTypes = {
  /**
   * Class to apply to the container for this component
   */
  className: PropTypes.string,
  /**
   * Required for accessibility on the multi-select
   */
  label: PropTypes.string.isRequired,
  /**
   * Callback to be invoked when save button is clicked; receives
   * one parameter - The array of checked values
   */
  onSave: PropTypes.func,
  /**
   * Callback to be invoked when a menu item is checked or un-checked;
   * receives one parameter - The array of checked values
   */
  changeCallback: PropTypes.func,
  /**
   * Array of options to display in the multi-select
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      Icon: PropTypes.object,
    })
  ).isRequired,
  /**
   * Array of values taken from the options array that are pre-checked
   * when the component is rendered
   */
  value: PropTypes.array,
  /**
   * Icon component (passed in as object) to be rendered within
   * the button that triggers the multi-select menu
   */
  Icon: PropTypes.object,
  /**
   * If true the save button is rendered at the bottom of the multi-select
   */
  saveConfirmation: PropTypes.bool,
  /**
   * If true a dropdown arrow is rendered within the button  that triggers the multi-select menu
   */
  dropDownArrow: PropTypes.bool,
  /**
   * Number in ms for how long the snackbar notification stays on
   * the screen if a error is propagated from attempting to invoke
   * the onSave callback
   */
  notificationTime: PropTypes.number,
};

MultiSelect.defaultProps = {
  className: "",
  label: "Sample label",
  onSave: async (values) => console.log("Saved items"),
  changeCallback: (values) => console.log("Menu items changed"),
  options: [
    { value: "test1", label: "test1", Icon: null },
    { value: "test2", label: "test2", Icon: null },
  ],
  value: [],
  Icon: null,
  saveConfirmation: true,
  dropDownArrow: true,
  notificationTime: 2500,
};

export default MultiSelect;
